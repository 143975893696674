import { delay, put, takeEvery, call } from 'redux-saga/effects'
import { sbuService } from '../../services'
import { ALERT_CONFIGS } from '../../utilities/constants'
import { COMMON_ACTION_TYPES, SBU_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { CreateSbuParamDto, GetDisabledSbusParamDto, UpdateSbuParamDto } from '../../utilities/models'
import { AlertActionDto } from '../../utilities/models/alert.models'
function * allSbuList (action: { type: string, params: GetDisabledSbusParamDto }) {
  try {
    // @ts-ignore
    const sbuList = yield call(sbuService.getAllSbuList, action.params)
    yield put({
      type:
        SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: sbuList.data
    })
  } catch (error) {
    yield put({
      type: SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * entitledSbuList (action: { type: string, params: GetDisabledSbusParamDto }) {
  try {
    // @ts-ignore
    const sbuList = yield call(sbuService.getAllSbuList, action.params)
    yield put({
      type:
        SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: sbuList.data
    })
  } catch (error) {
    yield put({
      type: SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * updateSbu (action: { type: string, payload: UpdateSbuParamDto }) {
  try {
    // @ts-ignore
    const updateSbu = yield call(sbuService.updateSbu, action.payload)
    const setAlert: AlertActionDto = {
      message: updateSbu.data.message,
      severity: 'success',
      type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * createSbu (action: { type: string, payload: CreateSbuParamDto }) {
  try {
    // @ts-ignore

    const createSbu = yield call(sbuService.createSbu, action.payload)
    const setAlert: AlertActionDto = {
      message: createSbu.data.message,
      severity: 'success',
      type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * sbuSagas () {
  yield takeEvery(
    SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST,
    allSbuList
  )
  yield takeEvery(
    SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.REQUEST,
    entitledSbuList
  )
  yield takeEvery(SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.REQUEST,
    updateSbu
  )
  yield takeEvery(SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.REQUEST,
    createSbu
  )
}
export default sbuSagas
