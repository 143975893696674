import { CLIENT_ACTION_TYPES, COMMON_ACTION_TYPES }
  from '../../utilities/constants/action.constants'
import {
  CreateClientParamDto,
  GetClientHierachyParamDto,
  GetDisabledClientParamDto,
  StatusChangeClientParamDto, UpdateClientParamDto
} from '../../utilities/models'

const allClientList = (params: GetDisabledClientParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}

const getAllClientHierchy = (params: GetClientHierachyParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const getEntitledClientHierchy = (params: GetClientHierachyParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const createClient = (payload: CreateClientParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const updateClient = (payload: UpdateClientParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const clientStatusChange = (params: StatusChangeClientParamDto) => {
  return {
    type: CLIENT_ACTION_TYPES.CLIENT_STATUS_CHANGE + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
export const clientAction = {
  allClientList,
  getAllClientHierchy,
  getEntitledClientHierchy,
  createClient,
  updateClient,
  clientStatusChange
}
