import axios from 'axios'
import { CreateSupervisorParamDto, GetSupervisorsListParamDto, UnassignSupervisorParamDto } from '../utilities/models'

const getAllSupervisorsList = (params: GetSupervisorsListParamDto) => {
  return axios.get('/hera/api/v1/supervisors', { params: params })
}
const createSupervisor = (payload: CreateSupervisorParamDto) => {
  return axios.post('/hera/api/v1/supervisors', payload)
}
const unassignSupervisor = (payload: UnassignSupervisorParamDto) => {
  return axios.put('/hera/api/v1/supervisors', payload)
}

export const supervisorService = {
  getAllSupervisorsList,
  createSupervisor,
  unassignSupervisor

}
