import { Button, DialogActions, DialogContent, Divider, Grid } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from '../../employee-management/AllocationCommentPopup/AllocationCommentPopup.module.scss'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import MUIRichTextEditor from 'mui-rte'
// import { convertToRaw, EditorState } from 'draft-js'
// import { Editor } from 'react-draft-wysiwyg'
// import { stateToHTML } from 'draft-js-export-html'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
const ResourceRequestRemarks: React.FC<{
  buttonText: string
  isRemarksPopupOpen: boolean;
  resourceIndex: number;
  setIsRemarksPopupOpen(b: boolean): void;
  isRowEditingFormValue: string
  handleChange?(i: any, e: any, property: string):void
  handleEditChange?(e: any, property: string):void
  elementValue: string
  readOnly: boolean
  resourceRequestId?: number
}> = (props) => {
  const [value, setValue] = React.useState<string>(props.isRowEditingFormValue)
  //   const [editorState, setEditorState] = React.useState(
  //     () => EditorState.createEmpty()
  //   )
  //   const onEditorChange = (event: any) => {
  //     const plainText = event.getCurrentContent().getPlainText() // for plain remark
  //     // const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with remark formating
  //     // setValue(JSON.stringify(rteContent))
  //     const s = JSON.stringify(stateToHTML(event.getCurrentContent())) // .toString()
  //     setValue(s.toString())
  //     console.log(s, plainText, stateToHTML(event.getCurrentContent()))
  //   }
  React.useEffect(() => {
    setValue(value || props.elementValue)
  }, [])
  const saveRemark = () => {
    if (props.resourceRequestId && props.handleEditChange) {
      props.handleEditChange(value, 'JobDescriptionAndRemarks')
    } else {
      if (props.handleChange) {
        props.handleChange(props.resourceIndex, value, 'JobDescriptionAndRemarks')
      }
    }
    setValue(props.isRowEditingFormValue)
    props.setIsRemarksPopupOpen(false)
  }
  return (
    < BootstrapDialog
      className={styles.dialog}
      aria-labelledby="customized-dialog-title"
      open={props.isRemarksPopupOpen}>
      <BootstrapDialogTitle id="customized-dialog-title"
      onClose={() => props.setIsRemarksPopupOpen(false)}>
        <strong>Job Description & Remarks</strong><br></br>
        <Divider></Divider>
      </BootstrapDialogTitle>
      <DialogContent className={styles.dialogContent}
      sx={{ height: '280px', maxHeight: '100%', width: '100%' }}>
        <Grid container spacing={2} >
          <Grid item lg={12}>
    {(props.readOnly === true)
      ? <div dangerouslySetInnerHTML={{ __html: props.elementValue }}
      style={{ minWidth: '500px' }}/>
      : <ReactQuill theme="snow"
      value={value}
      onChange={setValue}
      style={{ maxHeight: '100%', minHeight: '220px', height: '220px' }}/>
    }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.cardActionStyle}>
    {(props.readOnly !== true) &&
        <Button
          variant="contained"
          className={'btnStyle'}
        //   onMouseEnter={() => props.setCommentRemark(remark.trim())}
          onClick={() => {
            saveRemark()
          }}>
          {/* {(props.addCommentIsloading) && (
            <CircularProgress size="13px" className={styles.loading} />
          )} */}
         {props.buttonText}
        </Button>
}
      </DialogActions>
    </BootstrapDialog >
  )
}

export default ResourceRequestRemarks
