import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'

const ResourceRequestChangePopup: React.FC<{
  isRequestResourceChangePopupOpen: boolean
  setRequestResourceChangePopupOpen(b: boolean):void
  handleClear(n: number):void
  selectedRequestType:any
  setSelectedRequestType(n:number): void
  isNewFilter:boolean
  setIsNewFilter(args: boolean):void
}> = (props) => {
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.isRequestResourceChangePopupOpen}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setRequestResourceChangePopupOpen(false)}>
        Please Confirm
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>

        <Typography gutterBottom>
        Inserted Records and changes will not be saved. Do you want to clear form?
      </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={'btnStyle'}
          onClick={() => {
            props.handleClear(props.selectedRequestType)
            props.setRequestResourceChangePopupOpen(false)
          }}>
          Confirm
        </Button>
        <Button
          variant="contained"
          className={'btnStyle'}
          color={'inherit'}
          onClick={() => {
            props.setRequestResourceChangePopupOpen(false)
            if (props.isNewFilter) {
              props.setIsNewFilter(false)
            } else {
              if (props.selectedRequestType === 1) {
                props.setSelectedRequestType(2)
              } else {
                props.setSelectedRequestType(1)
              }
            }
          }}>
          Cancel
        </Button>
      </DialogActions >
    </BootstrapDialog >
  )
}

export default ResourceRequestChangePopup
