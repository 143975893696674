import { ALERT_CONFIGS } from '../../utilities/constants'
import { COMMENTS_ACTION_TYPES, CLIENT_ACTION_TYPES, COMMON_ACTION_TYPES, EMPLOYEE_ACTION_TYPES, PROJECT_ACTION_TYPES, REPORT_ACTION_TYPES, SBU_ACTION_TYPES, SUPERVISOR_ACTION_TYPES, RESOURCE_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AlertActionDto, AlertDto } from '../../utilities/models'

// #region SBU ALERTS
// set sbu alerts
const createSbuAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const updateSbuAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// clear sbu alerts
const clearCreateSbuAlertRequest = () => {
  return { type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearUpdateAlertRequest = () => {
  return { type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// #endregion SBU ALERTS

// #region CLIENT ALERTS
// set client alerts
const createClientAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const updateProjectAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}

// clear clieant alerts
const clearCreateClientAlertRequest = () => {
  return { type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearUpdateClientAlertRequest = () => {
  return { type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// #endregion CLIENT ALERTS

// #region PROJECT ALERTS
// setproject alerts
const createProjectAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const updateClientAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// clear project alerts

// clear project
const clearCreateProjectAlertRequest = () => {
  return { type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearUpdateProjectAlertRequest = () => {
  return { type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// #endregion PROPJECT ALERTS

// #region EMPLOYEE ALRTS
// set employee alerts
const setAllocateEmployeeAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const setAlert: AlertActionDto = {
    type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return setAlert
}
const setDeallocateEmployeeAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const setAlert: AlertActionDto = {
    type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return setAlert
}
const setUpdateEmployeeAllocationAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const setAlert: AlertActionDto = {
    type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return setAlert
}
const setValidateEmployeeAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const setAlert: AlertActionDto = {
    type: EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return setAlert
}
const setReportAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.REPORT_TIMEOUT) => {
  const setAlert: AlertActionDto = {
    type: REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return setAlert
}
const clearReportAlertRequest = () => {
  return { type: REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// clear employee alerts
const clearValidateEmployeeAlertRequest = () => {
  return { type: EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearAllocateEmployeeAlertRequest = () => {
  return { type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearDeallocateEmployeeAlertRequest = () => {
  return { type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearUpdateEmployeeAllocationAlertRequest = () => {
  return { type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// #endregion EMPLOYEE ALERTS

// SET SUPERVISOR
const createSupervisorAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const unnasignSupervisorAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// CLEAR SUPERVISOR
const clearCreateSupervisorAlertRequest = () => {
  return { type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
const clearUnassignSupervisorAlertRequest = () => {
  return { type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT }
}
// SET COMMENT
const addCommentAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// CLEAR Comment
const clearAddCommentAlertRequest = () => {
  return { type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

// SET FOR RESIGNATION COMMENT
const addResignationCommentAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// CLEAR ALERT IN RESIGNATION COMMENT
const clearAddResignationCommentAlertRequest = () => {
  return { type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

// set resource alerts
const createResourceRequestAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}

// clear resource
const clearCreateResourceRequestAlertRequest = () => {
  return { type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const updateResourcesAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearUpdateResourcesAlertRequest = () => {
  return { type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const updateRequestAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearUpdateRequestAlertRequest = () => {
  return { type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

// SET FOR RESOURCE COMMENT
const addResourceCommentAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
// CLEAR ALERT IN RESOURCE COMMENT
const clearAddResourceCommentAlertRequest = () => {
  return { type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const updateResourceActionAlertRequest = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearUpdateResourceActionAlertRequest = () => {
  return { type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const deleteProposedResourceAlert = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearDeleteProposedResourceAlert = () => {
  return { type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const confirmProposedResourcesAlert = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearConfirmProposedResourcesAlert = () => {
  return { type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const proposedResourcesActionAlert = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearProposedResourcesActionAlert = () => {
  return { type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

const closeRequestAlert = (alert: AlertDto,
  autoClear: boolean = ALERT_CONFIGS.AUTO_CLEAR,
  timeout: number = ALERT_CONFIGS.TIMEOUT) => {
  const alertAction: AlertActionDto = {
    type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT_REQ,
    message: alert.message,
    severity: alert.severity,
    autoClear: autoClear,
    timeout: timeout
  }
  return alertAction
}
const clearCloseRequestAlert = () => {
  return { type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT }
}

export const alertActions = {
  createSbuAlertRequest,
  updateSbuAlertRequest,
  clearCreateSbuAlertRequest,
  clearUpdateAlertRequest,
  createClientAlertRequest,
  updateClientAlertRequest,
  clearCreateClientAlertRequest,
  clearUpdateClientAlertRequest,
  clearCreateProjectAlertRequest,
  clearUpdateProjectAlertRequest,
  createProjectAlertRequest,
  updateProjectAlertRequest,
  setAllocateEmployeeAlertRequest,
  setDeallocateEmployeeAlertRequest,
  setUpdateEmployeeAllocationAlertRequest,
  clearValidateEmployeeAlertRequest,
  setValidateEmployeeAlertRequest,
  clearAllocateEmployeeAlertRequest,
  clearDeallocateEmployeeAlertRequest,
  clearUpdateEmployeeAllocationAlertRequest,
  createSupervisorAlertRequest,
  clearCreateSupervisorAlertRequest,
  unnasignSupervisorAlertRequest,
  clearUnassignSupervisorAlertRequest,
  setReportAlertRequest,
  clearReportAlertRequest,
  addCommentAlertRequest,
  clearAddCommentAlertRequest,
  clearAddResignationCommentAlertRequest,
  addResignationCommentAlertRequest,
  createResourceRequestAlertRequest,
  clearCreateResourceRequestAlertRequest,
  updateResourcesAlertRequest,
  clearUpdateResourcesAlertRequest,
  updateRequestAlertRequest,
  clearUpdateRequestAlertRequest,
  addResourceCommentAlertRequest,
  clearAddResourceCommentAlertRequest,
  updateResourceActionAlertRequest,
  clearUpdateResourceActionAlertRequest,
  deleteProposedResourceAlert,
  clearDeleteProposedResourceAlert,
  confirmProposedResourcesAlert,
  clearConfirmProposedResourcesAlert,
  proposedResourcesActionAlert,
  clearProposedResourcesActionAlert,
  closeRequestAlert,
  clearCloseRequestAlert

}
