import { REPORTS_CUSTOMIZED_TABLE_HEADERS, TABLE_HEADERS } from '../constants'
import { ReportsTableColumnDto } from '../models'

export const createTableColumn = (status: boolean): ReportsTableColumnDto => {
  const allHeader = {} as ReportsTableColumnDto
  for (const row of REPORTS_CUSTOMIZED_TABLE_HEADERS) {
    allHeader[row.key as keyof typeof TABLE_HEADERS] = status
  }
  return allHeader
}
