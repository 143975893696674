import { COMMON_ACTION_TYPES, SUPERVISOR_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { CreateSupervisorParamDto, GetProjectsListParamDto, GetSupervisorsListParamDto, UnassignSupervisorParamDto } from '../../utilities/models/supervisorManagement.model'

const allProjectsList = (params : GetProjectsListParamDto) => {
  return {
    type: SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const allSupervisorsList = (params : GetSupervisorsListParamDto) => {
  return {
    type: SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const createSupervisor = (payload: CreateSupervisorParamDto) => {
  return {
    type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

const unassignSupervisor = (payload: UnassignSupervisorParamDto) => {
  return {
    type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
export const supervisorAction = {
  allProjectsList,
  allSupervisorsList,
  createSupervisor,
  unassignSupervisor

}
