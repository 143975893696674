
import { Alert, Box, Divider, Step, StepLabel, Stepper } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, clientAction, designationAction, employeeAction, resourceAction, sbuAction } from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_FEATURE_KEYS, BREAD_CRUB, DATE_FORMAT } from '../../utilities/constants'
import { AppAuthorizer } from '../../components'
import { AlertDto, AllClientHierachyDto, AppStateDto, EmployeeDto, GetClientHierachyParamDto, GetDisabledSbusParamDto, GetEmployeeFilterListParams, InitProjectRoleDto, sbuBriefDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto } from '../../utilities/models'
import { ResourceRequestEntries, ResourceRequestEntryFormFilter, ReviewAndSubmit } from '../../components/resource-request'
import { CreateResourcesRequestDto, FilterFormDto, ResourcesDto, ResourcesFormDto } from '../../utilities/models/resourceRequest.model'
import moment from 'moment'
import { validateFormData } from '../../utilities/helpers'
import ResourceRequestChangePopup from '../../components/resource-request/ResourceRequestChangePopup/ResourceRequestChangePopup'
const ResourceRequest: React.FC = () => {
  const INITIAL_FILTER_STATE: FilterFormDto = {
    sbu: { value: {} as sbuBriefDto, validator: 'object', isRequired: true, error: null, disable: false },
    clientName: { value: {} as UserClientHierarchyDto, validator: 'object', isRequired: true, error: null, disable: false },
    projectName: { value: {} as UserClientHierarchyTeamsDto, validator: 'object', isRequired: true, error: null, disable: false },
    employeeName: { value: {} as EmployeeDto, validator: 'object', isRequired: false, error: null, disable: false },
    resourceRequestDeadline: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false }
  }
  const INITIAL_RESOURCE_STATE: ResourcesFormDto = {
    designation: { value: {} as InitProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
    user: { value: {} as EmployeeDto, validator: 'object', isRequired: true, error: null, disable: false },
    vacancies: { value: 0, validator: 'number', isRequired: true, error: null, disable: false },
    deploymentStartDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
    deploymentEndDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
    isDeploymentExtensionConfirm: { value: false, validator: 'object', isRequired: true, error: null, disable: false },
    deploymentExtendPeriod: { value: 1, validator: 'number', isRequired: true, error: null, disable: false },
    JobDescriptionAndRemarks: { value: '', validator: 'object', isRequired: true, error: null, disable: false },
    isBillable: { value: '', validator: 'object', isRequired: false, error: null, disable: false },
    allocationPercentage: { value: 100, validator: 'number', isRequired: false, error: null, disable: false },
    // deadline: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
    index: 0
  }

  // const INITIAL_STATE_COMMENT_FORM: ResourceRequestsCommentFormDto = {
  //   comment: {
  //     value: '',
  //     validator: 'text',
  //     isRequired: true,
  //     error: null,
  //     disable: false
  //   }
  // }

  // const INITIAL_RESOURCE_PAYLOAD_STATE: CreateResourcesRequestDto = {
  //   sbuId: { value: null, validatior: '' },
  //   clientId: { value: null, validatior: '' },
  //   clientName: { value: null, validatior: '' },
  //   projectId: { value: null, validatior: '' },
  //   projectName:{ value: null, validatior: '' },
  //   requestType: { value: null, validatior: '' },
  //   resources: { value: [] as ResourcesDto[]}
  // }

  const dispatch = useDispatch()
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const [filterFormData, setFilterFormData] = useState(INITIAL_FILTER_STATE)
  const clientHierachy = useSelector((state: any) => state.client.clientHierachy.data)
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<UserClientHierarchyDto[]>([])
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<UserClientHierarchyTeamsDto[]>([])
  const [clientHierarchyUsersList, setClientHierarchyUsersList] = useState<UserClientHierarchyUserDto[]>([])
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)
  const clientHierachyIsLoading = useSelector((state: any) => state.client.clientHierachy.isLoading)
  const [briefProjectRoleList, setBriefProjectRoleList] = useState<InitProjectRoleDto[]>([])
  const designationList = useSelector((state: any) => state.designation.designationList.data)
  const AllocationList = useSelector((state: any) => state.employee.employeeAllocationList.data.data)
  // const userListBrief = useSelector((state: any) => state.user.userListBrief.data)
  // const [briefEmployeeList, setBriefEmployeeList] = useState<InitEmployeeDto[]>([])
  const createResourceAlert: AlertDto = useSelector((state: any) => state.alerts.createResource)
  const createResourceIsloading = useSelector((state: any) => state.resource.createResourceRequest.isLoading)
  React.useEffect(() => {
    // devide  client , project, users from client hierachy api
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    // setClientHierarchyUsersList(userList)
  }, [clientHierachy])
  React.useEffect(() => {
    setBriefProjectRoleList(designationList?.data)
  }, [designationList])
  // React.useEffect(() => {
  //   // setBriefEmployeeList(userListBrief?.data)
  // }, [userListBrief])
  React.useEffect(() => {
    dispatch(designationAction.getDesignationList())
    getSBUList()
    getClientHierachy()
    // getAllUserList()
  }, [])
  React.useEffect(() => {
    setClientHierarchyUsersList(AllocationList)
  }, [AllocationList])
  React.useEffect(() => {
    if (createResourceAlert.severity === 'success') {
      handleClear(1)
      setStep(1)
    }
  }, [createResourceAlert])

  const clearCreateClientAlert = () => {
    dispatch(alertActions.clearCreateResourceRequestAlertRequest())
  }
  const getAllocatedUserList = (teamId: number) => {
    const employeeFilterListParams: GetEmployeeFilterListParams = {
      projectId: teamId
    }
    dispatch(employeeAction.getFilteredList(employeeFilterListParams))
  }
  const getClientHierachy = () => {
    const clientHeirachyParams: GetClientHierachyParamDto = {
      // getAll: true
    }
    dispatch(clientAction.getAllClientHierchy(clientHeirachyParams))
  }
  const getSBUList = () => {
    const sbuListParams: GetDisabledSbusParamDto = {
      // getDisabledSbus: 'false'
    }
    dispatch(sbuAction.allSbuList(sbuListParams))
  }
  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    // const entiledSbuList: InitSbuDto[] = []
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.length > 0) {
      for (const client of clientHierachy) {
        // allSbuList?.data?.map((sbu: InitSbuDto) => sbu.sbuId === client.sbuId &&
        // !entiledSbuList.some((x) => x.sbuId === sbu.sbuId) &&
        // entiledSbuList.push(sbu)
        // )
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some((i) =>
              i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some((i) =>
                  i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    // clientHierachyObject.sbus = entiledSbuList
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const handleChangeFilterForm = (property: string, value: any) => {
    switch (property) {
      case 'sbu':
        if (resourcesFormDataArray.length > 0 && selectedRequestType === 2) {
          setRequestResourceChangePopupOpen(true)
          setIsNewFilter(true)
        }
        if (value) {
          const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === value.id)
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          const userList: UserClientHierarchyUserDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some((i) =>
                    i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                  if (team.users && team.users.length) {
                    for (const user of team.users) {
                      const isIncluded = userList.some((i) =>
                        i.userId === user.userId
                      )
                      if (!isIncluded && user.userId !== null) {
                        userList.push({
                          userId: user.userId,
                          username: user.username,
                          firstName: user.firstName,
                          lastName: user.lastName
                        })
                      }
                    }
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          setClientHierarchyUsersList(AllocationList)
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: value
            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as EmployeeDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: {} as sbuBriefDto,
              error: null

            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          setClientHierarchyUsersList(AllocationList)
        }
        break
      case 'clientName':
        if (resourcesFormDataArray.length > 0 && selectedRequestType === 2) {
          setRequestResourceChangePopupOpen(true)
          setIsNewFilter(true)
        }
        if (value) {
          setClientHierarchyProjectList(value.teams)
          const userList: UserClientHierarchyUserDto[] = []
          if (value.teams.length > 0) {
            for (const team of value.teams) {
              if (team.users && team.users.length) {
                for (const user of team.users) {
                  const isIncluded = userList.some((i) =>
                    i.userId === user.userId
                  )
                  if (!isIncluded && user.userId !== null) {
                    userList.push({
                      userId: user.userId,
                      username: user.username,
                      firstName: user.firstName,
                      lastName: user.lastName
                    })
                  }
                }
              }
            }
          }
          setClientHierarchyUsersList(userList)

          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: value
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as EmployeeDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null
            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as EmployeeDto,
              error: null
            }
          })
          if (Object.keys(filterFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === filterFormData.sbu.value.id)
            setClientHierarchyclientList(filteredClientList)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(AllocationList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
            setClientHierarchyUsersList(AllocationList)
          }
        }
        break
      case 'projectName':
        if (resourcesFormDataArray.length > 0 && selectedRequestType === 2) {
          setRequestResourceChangePopupOpen(true)
          setIsNewFilter(true)
        }
        if (value) {
          setClientHierarchyUsersList(AllocationList)
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: value,
              disable: true
            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as EmployeeDto,
              error: null

            }
          })
          if (selectedRequestType === 2) {
            getAllocatedUserList(value?.teamId)
          }
        } else {
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as EmployeeDto,
              error: null

            }
          })
          if (Object.keys(filterFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === filterFormData.clientName.value.clientId)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(AllocationList)
          } else {
            // const filterByClientBy = filterByClientHirachy()
            setClientHierarchyUsersList(AllocationList)
          }
        }
        break
      default:
        if (value) {
          setFilterFormData({
            ...filterFormData,
            [property]: {
              ...filterFormData[property as keyof typeof filterFormData],
              value: value
            }
          })
        } else {
          if (property === 'employeeName') {
            setFilterFormData({
              ...filterFormData,
              employeeName: {
                ...filterFormData['employeeName' as keyof typeof filterFormData],
                value: {} as EmployeeDto,
                error: null

              }
            })
          } else if (property === 'projectName') {
            setFilterFormData({
              ...filterFormData,
              projectName: {
                ...filterFormData['projectName' as keyof typeof filterFormData],
                value: {} as UserClientHierarchyTeamsDto,
                error: null

              }
            })
          } else if (property === 'clientName') {
            setFilterFormData({
              ...filterFormData,
              clientName: {
                ...filterFormData['clientName' as keyof typeof filterFormData],
                value: {} as UserClientHierarchyDto

              }
            })
            setFilterFormData({
              ...filterFormData,
              employeeName: {
                ...filterFormData['employeeName' as keyof typeof filterFormData],
                value: {} as EmployeeDto,
                error: null

              }
            })
          }
        }
    }
  }
  const handleFilterClear = () => {
    // sessionStorage.removeItem('savedFilterFormData')
    // setIsCollapsed(false)
    // setPage(0)
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    setFilterFormData(INITIAL_FILTER_STATE)
  }
  // Handle form input

  // const [resourcesFormDataArray, setResourcesFormDataArray] = useState<ResourcesFormDto[]>([])
  const [resourcesFormDataArray, setresourcesFormDataArray] = useState<ResourcesDto[]>([])
  const [selectedRequestType, setSelectedRequestType] = useState<number>(1)
  const [requestType, setRequestType] = useState<number>(1)
  const [formValues, setFormValues] = useState<ResourcesFormDto[]>([])
  const [isRowEditing, setIsRowEditing] = useState<boolean>(false)
  const [isRowEditingForm, setIsRowEditingForm] = useState<ResourcesFormDto>(INITIAL_RESOURCE_STATE)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [step, setStep] = useState<number>(1)
  const [isRemarksPopupOpen, setIsRemarksPopupOpen] = useState<boolean>(false)
  const [isRequestResourceChangePopupOpen, setRequestResourceChangePopupOpen] = useState<boolean>(false)
  const [isNewFilter, setIsNewFilter] = useState<boolean>(false)

  // const [allocationCommentPopupIsOpen, setAllocationCommentPopupIsOpen] = useState<boolean>(false)
  // const [allocationCommentFormData, setAllocationCommentFormData] = useState<ResourceRequestsCommentFormDto>(INITIAL_STATE_COMMENT_FORM)
  // const [isShowHelperText, setIsShowHelperText] = useState(true)

  // const [selectingRequestType, setSelectingRequestType] = useState<number>()
  const handleUpdateData = async (index: number, element: ResourcesFormDto) => {
    const [validatedata, isValid] = await validateFormData(element)
    const newFormValues = [...formValues]
    newFormValues[index] = validatedata
    setFormValues(newFormValues)
    setIsRowEditingForm(validatedata)
    if (isValid && element.deploymentStartDate.error === null) {
      setIsRowEditing(false)
      const validatedElement: ResourcesDto = {
        designationId: element.designation.value?.projectRoleId,
        noOfVacancies: selectedRequestType === 1 ? +element.vacancies?.value : 1,
        deploymentStartDate: moment(element.deploymentStartDate.value).format(DATE_FORMAT),
        deploymentEndDate: moment(element.deploymentEndDate.value).format(DATE_FORMAT),
        isDeploymentExtensionConfirm: element.isDeploymentExtensionConfirm.value,
        deploymentExtendPeriod: element.isDeploymentExtensionConfirm.value === true ? element.deploymentExtendPeriod.value : null,
        allocationPercentage: element.allocationPercentage.value,
        isBillable: (element.isBillable.value === 'Billable' || element.isBillable.value) ? element.isBillable.value === 'Billable' : null,
        // resourceRequestDeadline: moment(element.deadline.value).format(DATE_FORMAT),
        JobDescriptionAndRemarks: element.JobDescriptionAndRemarks.value,
        replacementUsername: element.user?.value?.userName || null,
        index: index
      }
      const myNextList = [...resourcesFormDataArray]
      let resource = resourcesFormDataArray.find(
        el => el.index === index
      )
      if (resource) {
        resource = validatedElement
        myNextList[index] = resource
        // setResourcesFormDataArray(myNextList)
        setresourcesFormDataArray(myNextList)
      } else {
        setresourcesFormDataArray([
          ...resourcesFormDataArray,
          {
            designationId: element.designation.value?.projectRoleId,
            noOfVacancies: selectedRequestType === 1 ? +element.vacancies?.value : 1,
            deploymentStartDate: moment(element.deploymentStartDate.value).format(DATE_FORMAT),
            deploymentEndDate: moment(element.deploymentEndDate.value).format(DATE_FORMAT),
            isDeploymentExtensionConfirm: element.isDeploymentExtensionConfirm.value,
            deploymentExtendPeriod: element.isDeploymentExtensionConfirm.value === true ? element.deploymentExtendPeriod.value : null,
            allocationPercentage: element.allocationPercentage.value,
            isBillable: (element.isBillable.value === 'Billable' || element.isBillable.value) ? element.isBillable.value === 'Billable' : null,
            // resourceRequestDeadline: moment(element.deadline.value).format(DATE_FORMAT),
            replacementUsername: element.user?.value?.userName || null,
            JobDescriptionAndRemarks: element.JobDescriptionAndRemarks.value,
            index: index
          }
        ])
      }
    }
  }
  const onRequestType = async (requestType: number) => {
    setRequestType(requestType)
    if (resourcesFormDataArray.length > 0) {
      setRequestResourceChangePopupOpen(true)
    } else {
      setFilterFormData(INITIAL_FILTER_STATE)
      setSelectedRequestType(requestType)
      setFormValues([])
      setresourcesFormDataArray([])
      setIsRowEditingForm(INITIAL_RESOURCE_STATE)
      setIsRowEditing(false)
      setCurrentIndex(0)
    }
  }
  const handleClearRow = (index: number) => {
    const newFormValues = [...formValues]
    newFormValues.splice(index, 1)
    resourcesFormDataArray.splice(index, 1)
    setFormValues(newFormValues)
    setIsRowEditing(false)
  }
  const [resourcePayload, setResourcePayload] = useState<CreateResourcesRequestDto>({
    clientId: null,
    clientName: '',
    projectId: null,
    projectName: '',
    requestType: 1,
    resourceRequestDeadline: '',
    resources: [],
    sbuId: null
  })

  const next = async () => {
    const filterForm: FilterFormDto = {
      sbu: filterFormData.sbu,
      clientName: filterFormData.clientName,
      projectName: filterFormData.projectName,
      employeeName: filterFormData.employeeName,
      resourceRequestDeadline: filterFormData.resourceRequestDeadline
    }
    const [validatedata, isValid] = await validateFormData(filterForm)
    setFilterFormData(validatedata)
    if (isValid) {
      const createResourceRequest: CreateResourcesRequestDto = {
        sbuId: filterFormData.sbu.value.id ? filterFormData.sbu.value.id : null,
        clientId: filterFormData.clientName.value.clientId,
        clientName: filterFormData.clientName.value.clientName,
        projectId: filterFormData.projectName.value.teamId,
        projectName: filterFormData.projectName.value.teamName,
        requestType: selectedRequestType!,
        resourceRequestDeadline: moment(filterFormData.resourceRequestDeadline.value).format(DATE_FORMAT),
        resources: resourcesFormDataArray
      }
      setStep(2)
      setResourcePayload(createResourceRequest)
    }
  }

  const back = async () => {
    setStep(1)
  }

  const createResourceRequest = async () => {
    dispatch(resourceAction.creatResourse(resourcePayload))
  }

  const handleClear = async (requestType: number) => {
    setFilterFormData(INITIAL_FILTER_STATE)
    setFormValues([])
    // setResourcesFormDataArray([])
    setresourcesFormDataArray([])
    setSelectedRequestType(requestType)
    setIsRowEditingForm(INITIAL_RESOURCE_STATE)
    setIsRowEditing(false)
    setCurrentIndex(0)
    handleFilterClear()
  }
  const steps = [
    'Insert Information',
    'Review and Submit'
  ]

  return (
    <React.Fragment>
      <AppLayout breadcrumb={BREAD_CRUB.RM} componentTitle="Resource Request">
        <p style={{
          paddingBottom: 7,
          margin: 0,
          fontWeight: 400,
          fontSize: '24px'
        }}>Create Resource Request</p>
        <Divider className="dividerStyle" />
        <br />
        {createResourceAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearCreateClientAlert}
            severity={createResourceAlert.severity}
          >
            {createResourceAlert.message}
          </Alert>
        )}
        <Box sx={{ padding: '20px 50px' }}>
        <Stepper activeStep={step}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </Box>
        {(step === 1)
          ? <>
          <ResourceRequestChangePopup
            isRequestResourceChangePopupOpen={isRequestResourceChangePopupOpen}
            setRequestResourceChangePopupOpen={setRequestResourceChangePopupOpen}
            handleClear={handleClear}
            selectedRequestType={requestType}
            setSelectedRequestType={setSelectedRequestType}
            isNewFilter={isNewFilter}
            setIsNewFilter={setIsNewFilter}
             />
            <AppAuthorizer
            activeRoleFeatures={activeUserRole.data.features}
            authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_RESOURCE_REQUEST]}
          >
            <ResourceRequestEntryFormFilter
              handleChangeFilterForm={handleChangeFilterForm}
              handleFilterClear={handleFilterClear}
              sbuList={allSbuList?.data || []}
              clientHierachyIsLoading={clientHierachyIsLoading}
              clientHierarchyProjectList={clientHierarchyProjectList || []}
              filterFormData={filterFormData}
              clientHierachy={clientHierarchyClientList || []}
              onRequestType={onRequestType}
              requestType={selectedRequestType} />
          </AppAuthorizer><br /><Divider className="dividerStyle" /><br />
          <AppAuthorizer
            activeRoleFeatures={activeUserRole.data.features}
            authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_RESOURCE_REQUEST]}
          >
              <ResourceRequestEntries
                briefEmployeeList={clientHierarchyUsersList || []}
                briefProjectRoleList={briefProjectRoleList}
                handleUpdateData={handleUpdateData}
                isRowEditing={isRowEditing}
                setIsRowEditing={setIsRowEditing}
                next={next}
                requestType={selectedRequestType}
                handleClear={handleClear}
                formValues={formValues}
                setFormValues={setFormValues}
                isRowEditingForm={isRowEditingForm}
                setIsRowEditingForm={setIsRowEditingForm}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setIsRemarksPopupOpen={setIsRemarksPopupOpen}
                isRemarksPopupOpen={isRemarksPopupOpen}
                handleClearRow={handleClearRow}
                filterFormData={filterFormData} />
            </AppAuthorizer>
            </>
          : <AppAuthorizer
            activeRoleFeatures={activeUserRole.data.features}
            authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_RESOURCE_REQUEST]}
          >
           <ReviewAndSubmit
                back={back}
                resourcePayload={resourcePayload}
                createResourceRequest={createResourceRequest}
                createResourceIsloading={createResourceIsloading}
                allSbuList={allSbuList?.data || []}
                briefProjectRoleList={briefProjectRoleList}
          /></AppAuthorizer>
          }
      </AppLayout>
    </React.Fragment>
  )
}

export default ResourceRequest
