import { Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { SbuEntryFormDto } from '../../../utilities/models'
import styles from './SbuEditPopup.module.scss'

const SbuEditPopup: React.FC<{
  isEditDialogOpen: boolean;
  title: string;
  isEditingRow: any;
  sbuFormData: SbuEntryFormDto;
  isShowHelperText: boolean;
  sbuEditClick(): void;
  onEditHandleChange(property: string, value: any): void;
  updateSbu():void;
  handleInputFocus(property: string): void;
}> = (props) => {
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isEditDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.sbuEditClick()
        }
      >
        {props.title}

      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
        <Grid container spacing={2} >
          <Grid item lg={12} >
            <TextField
              value={props.isEditingRow.sbuName.value}
              label="SBU Name"
              color='primary'
              size="small"
              fullWidth
              onFocus={() => props.handleInputFocus('sbuName')}
              helperText={props.isShowHelperText && props.isEditingRow.sbuName.error}
              error={!!props.isEditingRow.sbuName.error}
              required={props.isEditingRow.sbuName.isRequired}
              disabled={props.isEditingRow.sbuName.disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onEditHandleChange('sbuName', event.target.value)}

            />
          </Grid>
          <Grid item lg={12} >
            <TextField
              value={props.isEditingRow.sbuDescription.value}
              label="SBU Description"
              color='primary'
              size="small"
              fullWidth
              multiline
              rows={4}
              error={!!props.isEditingRow.sbuDescription.error}
              required={props.isEditingRow.sbuDescription.isRequired}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onEditHandleChange('sbuDescription', event.target.value)}
              onFocus={() => props.handleInputFocus('sbuDescription')}

            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.updateSbu()}
          autoFocus variant="contained"
          className={'btnStyle'}
        >
          Update SBU
        </Button>
      </DialogActions>
    </BootstrapDialog>

  )
}

export default SbuEditPopup
