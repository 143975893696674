import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, USER_ACTION_TYPES } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  userListBrief: {
    isLoading: false,
    data: [],
    status: null,
    error: null
  },
  userDetailsV2: {
    isLoading: false,
    data: [],
    status: null,
    error: null
  },
  userProfile: {
    isLoading: false,
    data: [],
    status: null,
    error: null
  }

}

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    // get user details
    case USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        userProfile: {
          isLoading: false,
          data: action.data,
          error: null,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        userProfile: {
          isLoading: false,
          data: [],
          error: action.error,
          tatus: APP_ACTION_STATUS.ERROR
        }
      }
    case USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        userListBrief: {
          ...state.userListBrief,
          isLoading: true
        }
      }
    case USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        userListBrief: {
          isLoading: false,
          data: action.data
        }
      }
    case USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        userListBrief: {
          isLoading: false,
          data: []
        }
      }
    case USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        userDetailsV2: {
          ...state.userDetailsV2,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        userDetailsV2: {
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        userDetailsV2: {
          isLoading: false,
          data: [],
          tatus: APP_ACTION_STATUS.ERROR
        }
      }
    default:
      return state
  }
}

export default userReducer
