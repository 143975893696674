import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import styles from './ProjectViewTable.module.scss'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import { Grid, Table, TableHead, TextField, Tooltip, TableBody, InputAdornment, TableContainer, CircularProgress, IconButton } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { ProjectDto } from '../../../utilities/models'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { AppAuthorizer } from '../../shared'
import { APP_FEATURE_KEYS } from '../../../utilities/constants'

const ProjectEntryTable: React.FC<{
  activeUserRole: any;
  projectList: Array<ProjectDto>;
  page: number;
  rowsPerPage: number;
  isEditingRow: any;
  isEditDialogOpen: boolean;
  projectIsloading: boolean;
  searchText: string;
  searchProject(event: any): void;
  onRowEditTrigger(arg: any): void;
  onRowStatusChangeTrigger(arg: any): void;
}> = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={3} className="sectionTitleHolder">
        <h3>Manage Project</h3>
        <p>Click the edit icon under Action to edit an existing project.<br /> <br />
          Click on the enable/disable icons to Activate/Deactivate projects.</p><br />
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          color="primary"
          value={props.searchText}
          placeholder="Search Project / Client"
          // label="Search By project name"
          onChange={props.searchProject}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon fontSize='small' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item md={9}>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left" >Name</StyledTableCell>
                <StyledTableCell align="left">Client</StyledTableCell>
                <StyledTableCell align="left" >Description</StyledTableCell>
                <StyledTableCell align="left" width={100}>Status</StyledTableCell>
                <StyledTableCell align="left" width={70}>Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(!props.projectIsloading && props.projectList.length > 0) && (props.rowsPerPage > 0
                ? props.projectList.slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                : props.projectList
              ).map((row: ProjectDto, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left" >{row.teamName}</StyledTableCell>
                <StyledTableCell align="left" >{row.clientName}</StyledTableCell>
                <StyledTableCell align="left">{row.teamDesc}</StyledTableCell>
                <StyledTableCell align="left" width={100} >
                  {row.isEnabled === true &&
                    <span className={styles.enabled}>Enabled</span>
                  }
                  {row.isEnabled === false &&
                    <span className={styles.disabled}>Disabled</span>
                  }
                </StyledTableCell>
                <StyledTableCell align="left" width={70}>
                  <div className="layout-row">
                  <AppAuthorizer
                        activeRoleFeatures={props.activeUserRole.data.features}
                        authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_PROJECT]}
                      >
                    <IconButton className={styles.iconButton} disabled={(row.applications.filter(x => x.applicationId !== 2 && x.applicationId !== 4)).length >= 1}>
                    <Tooltip title="Edit Project">
                      <EditOutlinedIcon
                        onClick={() => props.onRowEditTrigger(row)}
                        fontSize="small"></EditOutlinedIcon>
                    </Tooltip>
                    </IconButton>
                    {row.isEnabled === false &&
                    <IconButton className={styles.iconButton} disabled={(row.applications.filter(x => x.applicationId !== 2 && x.applicationId !== 4)).length >= 1}
                    sx={{
                      '&.MuiButtonBase-root:enabled': {
                        color: '#4CAF50'
                      }
                    }}>
                      <Tooltip title="Enable Project">
                        <CheckCircleOutlineIcon
                           onClick={() => props.onRowStatusChangeTrigger(row)} fontSize="small"></CheckCircleOutlineIcon>
                      </Tooltip>
                      </IconButton>
                    }
                    {row.isEnabled === true &&
                    <IconButton className={styles.iconButton} disabled={(row.applications.filter(x => x.applicationId !== 2 && x.applicationId !== 4)).length >= 1}
                    sx={{
                      '&.MuiButtonBase-root:enabled': {
                        color: '#e90c10'
                      }
                    }}>
                      <Tooltip title="Disable Project">
                        <DoDisturbOutlinedIcon
                           onClick={() => props.onRowStatusChangeTrigger(row)} fontSize="small"></DoDisturbOutlinedIcon>
                      </Tooltip>
                    </IconButton>
                    }
                    {(row.applications.filter(x => x.applicationId !== 2 && x.applicationId !== 4)).length >= 1 &&
                      <IconButton className={styles.iconButton} style={{ color: '#cec721fc' }}>
                        <Tooltip title="Hera user is not authorized to edit or disable/enable team with multiple authorized apps. Please contact Super Admin" >
                          <InfoOutlinedIcon fontSize="small" ></InfoOutlinedIcon>
                        </Tooltip>
                      </IconButton>
                      }</AppAuthorizer></div>
                </StyledTableCell>
              </StyledTableRow>
              ))}
              {props.projectIsloading &&
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    <CircularProgress className={styles.spinnerAlign} color="primary" size={20} /> Loading...
                  </StyledTableCell>
                </StyledTableRow>
              }
              {(!props.projectIsloading && props.projectList.length === 0) &&
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    No Records Available.
                  </StyledTableCell>
                </StyledTableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {props.children}
      </Grid>

    </Grid>
  )
}

export default ProjectEntryTable
