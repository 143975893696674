import React from 'react'
import { Autocomplete, Button, FormControlLabel, Grid, Stack, Switch, TextField } from '@mui/material'
import styles from './EmployeeAllocationFilterForm.module.scss'
import { AllocationTypeDto, InitialSbuDto, InitProjectRoleDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto } from '../../../utilities/models'
import { v4 as uuid } from 'uuid'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DATE_FORMAT } from '../../../utilities/constants'
const EmployeeAllocationFilterForm: React.FC<{
  filterFormData: any;
  handleChangeFilterForm(property: string, value: any): void;
  handleFilterClear(): void;
  handleFilter(): void;
  isAdvancedFilterOnclick(event: React.ChangeEvent<HTMLInputElement>): void;
  clientHierachy: UserClientHierarchyDto[];
  clientHierarchyUsersList: UserClientHierarchyUserDto[]
  searchBillableList: string[]
  clientHierarchyProjectList: UserClientHierarchyTeamsDto[]
  isAdvancedFilter: boolean;
  briefProjectRoleList: InitProjectRoleDto[];
  allocatioTypes: AllocationTypeDto[];
  sbuList: InitialSbuDto[];
  clientHierachyIsLoading: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      <Grid item md={2.4}>
        <Autocomplete
          color="primary"
          size="small"
          // disableClearable
          value={props.filterFormData.sbu.value}
          options={props.sbuList.map((sbu) => {
            return { name: sbu.sbuName, id: sbu.sbuId }
          })}
          disabled={props.clientHierachyIsLoading}
          onChange={(event, value) =>
            props.handleChangeFilterForm('sbu', value)
          }
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.name === value.name
          }
          getOptionLabel={(option: any) => option.name || ''}
          renderInput={(params) => (
            <TextField {...params} label="SBU" />
          )}
        />
      </Grid>
      <Grid item md={2.4}>
        <Autocomplete
          color="primary"
          size="small"
          // disableClearable
          disabled={props.clientHierachyIsLoading}
          value={props.filterFormData.clientName.value}
          options={
            props.clientHierachy?.map((client: UserClientHierarchyDto) => ({
              id: client.clientId,
              sbuId: client.sbuId,
              name: client.clientName,
              teams: client.teams
            })) || []
          }
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.name === value.name
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={uuid()}>
                {option.name}
              </li>
            )
          }}
          onChange={(event, value) =>
            props.handleChangeFilterForm('clientName', value)
          }
          getOptionLabel={(option: any) => option.name || ''}
          renderInput={(params) => (
            <TextField {...params} label="Client" />
          )}
        />
      </Grid>
      <Grid item md={2.4}>
        <Autocomplete
          color="primary"
          size="small"
          // disableClearable
          disabled={props.clientHierachyIsLoading}
          value={props.filterFormData.projectName.value}
          options={
            props.clientHierarchyProjectList?.map((project) => ({
              id: project.teamId,
              name: project.teamName,
              users: project.users
            }))
          }
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.name === value.name
          }
          onChange={(event, value) =>
            props.handleChangeFilterForm('projectName', value)
          }
          getOptionLabel={(option: any) => option.name || ''}
          renderInput={(params) => (
            <TextField {...params} label="Project" />
          )}
        />
      </Grid>
      <Grid item md={2.4}>
        <Autocomplete
          color="primary"
          size="small"
          // disableClearable
          disabled={props.clientHierachyIsLoading}
          value={props.filterFormData.employeeName.value}
          onChange={(event, value) =>
            props.handleChangeFilterForm('employeeName', value)
          }
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.name === value.name
          }
          options={
            props.clientHierarchyUsersList?.map((emp: any) => {
              return {
                name: emp.firstName + ' ' + emp.lastName,
                id: emp.userId,
                username: emp.username
              }
            }) || []
          }
          getOptionLabel={(option: any) => {
            return option.name || ''
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={uuid()}>
                {option.name}
              </li>
            )
          }}
          renderInput={(params) => (
            <TextField {...params} label="Employee" />
          )}
        />
      </Grid>

      <Grid item md={2.4}>
        <Autocomplete
          color="primary"
          size="small"
          // disableClearable
          value={props.filterFormData.projectRoleName.value}
          options={
            props.briefProjectRoleList?.map((desig) => ({
              id: desig.projectRoleId,
              name: `[${desig.projectRoleDisplayName}] ${desig.projectRoleName}`
            })) || []
          }
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option.name === value.name
          }
          onChange={(event, value) =>
            props.handleChangeFilterForm('projectRoleName', value)
          }
          getOptionLabel={(option: any) => option.name || ''}
          renderInput={(params) => (
            <TextField {...params} label="Project Role" />
          )}
        />
      </Grid>
      <Grid item md={2.4}>
        <Autocomplete
          onChange={(event, value) =>
            props.handleChangeFilterForm('isBillable', value)
          }
          value={props.filterFormData.isBillable.value}
          size="small"
          options={props.searchBillableList}
          renderInput={(params) => (
            <TextField {...params} label="Billable" />
          )}
        />
      </Grid>
      {props.isAdvancedFilter
        ? <React.Fragment>
        <Grid item md={2.4}>
          <Autocomplete
            color="primary"
            size="small"
            // disableClearable
            value={props.filterFormData.allocationType.value}
            onChange={(event, value) =>
              props.handleChangeFilterForm('allocationType', value)
            }
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === '' || option.name === value.name
            }
            options={
              props.allocatioTypes.map((type: any) => {
                return {
                  name: type.name,
                  id: type.id,
                  displayName: type.displayName
                }
              }) || []
            }
            getOptionLabel={(option: any) => {
              return option.displayName || ''
            }}
            // renderOption={(props, option) => {
            //   return (
            //     <li {...props} key={option.id}>
            //       {option.name}
            //     </li>
            //   )
            // }}
            renderInput={(params) => (
              <TextField {...params} label="Allocation Status" />
            )}
          />
          </Grid>
          <Grid item md={2.4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Allocation End"
                views={['year', 'month', 'day']}
                // maxDate={ props.createEmployeeFormData.endDate.value ? moment(props.createEmployeeFormData.endDate.value) : undefined }
                 value={props.filterFormData.requestedDate.value}
                inputFormat={DATE_FORMAT}
                onChange={(value) => props.handleChangeFilterForm('requestedDate', value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    color='primary'
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          </React.Fragment>
        : <Grid item md={4.8}>

        </Grid>}
          <Grid item md={4.8}>
            <Stack style={{ float: 'right' }} direction={'row'}>
              <FormControlLabel
                value="start"
                className={styles.switchStyle}
                control={<Switch

                  size='small'
                  color="primary"
                  onChange={props.isAdvancedFilterOnclick}
                />}
                label="Advanced Filter"
                labelPlacement="start"
                checked={props.isAdvancedFilter}
              />
              <Button
                className="filterBtn"
                color="primary"
                variant="contained"
                onClick={props.handleFilter}
              >
                Filter
              </Button>
              <Button
                className={styles.clearBtnStyle}
                color="inherit"
                variant="contained"
                onClick={props.handleFilterClear}
              >
                Clear
              </Button>
            </Stack>
          </Grid>
        </React.Fragment>
  )
}

export default EmployeeAllocationFilterForm
