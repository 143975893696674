import axios from 'axios'
import { GetReportParamDto } from '../utilities/models'

const getAllocationForReport = (params: GetReportParamDto) => {
  return axios.get('/hera/api/v1/reports/allocation', { params: params })
}
const getAllocationForFinanceReport = (params: GetReportParamDto) => {
  return axios.get('/hera/api/v1/reports/finance', { params: params })
}
export const reportService = {
  getAllocationForReport,
  getAllocationForFinanceReport
}
