import { delay, put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { employeeService } from '../../services/employee.service'
import { ALERT_CONFIGS } from '../../utilities/constants/app.constants'
import { AllocateEmployeeParams, DeallocateEmployeeParams, EmployeeAllocationHistoryParams, UpdateEmployeeAllocationParams } from '../../utilities/models/employeeManagement.model'
import { AlertActionDto } from '../../utilities/models'
import { COMMON_ACTION_TYPES, EMPLOYEE_ACTION_TYPES } from '../../utilities/constants'
function * getFilteredList (action: { type: string, params: any }) {
  try {
    // @ts-ignore
    const response = yield call(employeeService.getFilteredList, action.params)
    yield put({
      type: EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type: EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * updateEmployeeAllocation (action: { type: string, params: UpdateEmployeeAllocationParams }) {
  try {
    // @ts-ignore
    const response = yield call(employeeService.updateEmployeeAllocation, action.params)
    const setAlert: AlertActionDto = {
      message: response.data.message,
      severity: 'success',
      type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * allocateEmployee (action: { type: string, payload: AllocateEmployeeParams }) {
  try {
    // @ts-ignore
    const response = yield call(employeeService.allocateEmployee, action.payload)
    const setAlert: AlertActionDto = {
      message: response.data.message,
      severity: 'success',
      type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * deallocateEmployee (action: { type: string, params: DeallocateEmployeeParams }) {
  try {
    // @ts-ignore
    const response = yield call(employeeService.deallocateEmployee, action.params)
    const setAlert: AlertActionDto = {
      message: response.data.message,
      severity: 'success',
      type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * getEmployeeAllocationHistory (action: { type: string, params: EmployeeAllocationHistoryParams }) {
  try {
    // @ts-ignore
    const response = yield call(employeeService.getEmployeeAllocationHistory, action.params)
    yield put({
      type:
        EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data?.data || []
    })
  } catch (error) {
    yield put({
      type: EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * employeeSagas () {
  // yield takeLatest(EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_LIST + COMMON_ACTION_TYPES.REQUEST, getEmployeeAllocationList)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.REQUEST, getFilteredList)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.REQUEST, updateEmployeeAllocation)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST, allocateEmployee)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST, deallocateEmployee)
  yield takeLatest(EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST, getEmployeeAllocationHistory)
}
export default employeeSagas
