import { put, call, takeLatest } from 'redux-saga/effects'
import { trackService } from '../../services'
import { COMMON_ACTION_TYPES, TRACK_ACTION_TYPES } from '../../utilities/constants'

function * getTrackList (action: { type: string }) {
  try {
    // @ts-ignore
    const response = yield call(trackService.getTrackList)
    yield put({
      type:
      TRACK_ACTION_TYPES.GET_TRACK_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      TRACK_ACTION_TYPES.GET_TRACK_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * trackSagas () {
  yield takeLatest(TRACK_ACTION_TYPES.GET_TRACK_LIST + COMMON_ACTION_TYPES.REQUEST, getTrackList)
}
export default trackSagas
