import axios from 'axios'
import { CreateClientParamDto, GetClientHierachyParamDto, GetDisabledClientParamDto, StatusChangeClientParamDto, UpdateClientParamDto } from '../utilities/models'

const getAllClientList = (params: GetDisabledClientParamDto) => {
  return axios.get('/core/api/v1/clients', { params: params })
}
const getAllClientHierchy = (params: GetClientHierachyParamDto) => {
  return axios.get('/core/api/v1/clients/userHierarchy', { params: params })
}
// const getSBUList = (params: GetSBUListParamDto) => {
//   return axios.get('v1/sbu', { params: params })
// }
const createClient = (payload: CreateClientParamDto) => {
  return axios.post('/core/api/v1/clients', payload)
}
const updateClient = (payload: UpdateClientParamDto) => {
  return axios.patch('/core/api/v1/clients', payload)
}
const statuschangeClient = (payload: StatusChangeClientParamDto) => {
  return axios.patch('/core/api/v1/clients', payload)
}

export const clientService = {
  getAllClientList,
  getAllClientHierchy,
  createClient,
  updateClient,
  statuschangeClient
}
