import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, RESOURCE_ACTION_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  allResource: {
    isLoading: false,
    data: []
  },
  createResourceRequest: {
    isLoading: false,
    error: null
  },
  resourceRequestsList: {
    isLoading: false,
    data: []
  },
  updateResources: {
    isLoading: false,
    data: [],
    status: null
  },
  updateRequest: {
    isLoading: false,
    data: [],
    status: null
  },
  createResourceComment: {
    isLoading: false,
    error: null
  },
  commentList: {
    isLoading: false,
    data: []
  },
  resourceAction: {
    isLoading: false,
    data: [],
    status: null
  },
  createProposeRequest: {
    isLoading: false,
    error: null
  },
  proposedResourcesList: {
    isLoading: false,
    data: []
  },
  deleteProposedResource: {
    isLoading: false,
    error: null
  },
  confirmProposedResources: {
    isLoading: false,
    error: null
  },
  proposedResourcesAction: {
    isLoading: false,
    error: null
  },
  closeRequest: {
    isLoading: false,
    error: null
  },
  rmStatusList: {
    isLoading: false,
    error: null
  }
}
const resourceReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createResourceRequest: {
          ...state.allResource,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createResourceRequest: {
          ...state.allResource,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createResourceRequest: {
          ...state.allResource,
          isLoading: false,
          error: action.error

        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        resourceRequestsList: {
          ...state.resourceRequestsList,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        resourceRequestsList: {
          isLoading: false,
          data: action.data
        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        resourceRequestsList: {
          isLoading: false,
          data: []
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateResources: {
          ...state.updateResources,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateResources: {
          ...state.updateResources,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateResources: {
          ...state.updateResources,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateRequest: {
          ...state.updateRequest,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateRequest: {
          ...state.updateRequest,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateRequest: {
          ...state.updateRequest,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createResourceComment: {
          ...state.createResourceComment,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createResourceComment: {
          ...state.createResourceComment,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createResourceComment: {
          ...state.createResourceComment,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        commentList: {
          ...state.commentList,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        commentList: {
          isLoading: false,
          data: action.data
        }
      }
    case RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        commentList: {
          isLoading: false,
          data: []
        }
      }
    case RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        resourceAction: {
          ...state.resourceAction,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        resourceAction: {
          ...state.resourceAction,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        resourceAction: {
          ...state.resourceAction,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
      // propose request
    case RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createProposeRequest: {
          ...state.createProposeRequest,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createProposeRequest: {
          ...state.createProposeRequest,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createProposeRequest: {
          ...state.createProposeRequest,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        proposedResourcesList: {
          ...state.proposedResourcesList,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        proposedResourcesList: {
          isLoading: false,
          data: action.data
        }
      }
    case RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        proposedResourcesList: {
          isLoading: false,
          data: []
        }
      }
    case RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deleteProposedResource: {
          ...state.deleteProposedResource,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deleteProposedResource: {
          ...state.deleteProposedResource,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deleteProposedResource: {
          ...state.deleteProposedResource,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        confirmProposedResources: {
          ...state.confirmProposedResources,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        confirmProposedResources: {
          ...state.confirmProposedResources,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        confirmProposedResources: {
          ...state.confirmProposedResources,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        proposedResourcesAction: {
          ...state.proposedResourcesAction,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        proposedResourcesAction: {
          ...state.proposedResourcesAction,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        proposedResourcesAction: {
          ...state.proposedResourcesAction,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        closeRequest: {
          ...state.closeRequest,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        closeRequest: {
          ...state.closeRequest,
          isLoading: false
        }
      }
    case RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        closeRequest: {
          ...state.closeRequest,
          isLoading: false,
          error: action.error
        }
      }
    case RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        rmStatusList: {
          ...state.rmStatusList,
          isLoading: true
        }
      }
    case RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        rmStatusList: {
          isLoading: false,
          data: action.data
        }
      }
    case RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        rmStatusList: {
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  };
}

export default resourceReducer
