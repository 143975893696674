/* eslint-disable no-unused-vars */
import { Alert, DialogContent, Divider, Grid, CircularProgress, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Button, DialogActions } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { DATE_FORMAT_2, TIME_FORMAT } from '../../../utilities/constants'
import { AlertDto, AuthorizedUser, ResignationCommentFormDto, ResignationCommentsDto, ResingationDetailsDto } from '../../../utilities/models'
import styles from './ResignationDetailsPopup.module.scss'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  textarea: {
    resize: 'vertical'
  }
}))

const ResignationDetailsPopup: React.FC<{
  employeeName: string;
  userId: number;
  readMore: boolean;
  resignationDetailsPopupIsOpen: boolean;
  resignationList: Array<ResingationDetailsDto>
  authorizedUser:AuthorizedUser;
  resignationDetailsIsloading: boolean;
  toggleResignationDetailsPopup(): void;
  getResignationDetails(): void;
  onShowMoreText(): void;
  addResignationComment(resignationId: number): void;
  clearAddResignationCommentAlert(): void;
  isShowHelperText: boolean;
  addResignationCommentIsloading: boolean;
  addResignationCommentAlert: AlertDto;
  setIsShowHelperText(args: boolean):void;
  resignationCommentFormData: ResignationCommentFormDto;
  resignationCommentText: string;
  setResignationCommentText(args: string): void
}> = (props) => {
  const classes = useStyles()
  const [text, setText] = useState('')
  const onInputHandleChange = (property: string, value: any) => {
    props.setIsShowHelperText(true)
    setText(value)
  }
  React.useEffect(() => {
    if (props.userId > 0 && props.resignationDetailsPopupIsOpen) {
      props.getResignationDetails()
    }
  }, [props.userId])
  const [expanded, setExpanded] = React.useState<number | false>(false)

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handleOnClose = () => {
    setExpanded(false)
  }
  return (

    < BootstrapDialog
      aria-labelledby="customized-dialog-title"

      open={props.resignationDetailsPopupIsOpen} >
      <BootstrapDialogTitle id="customized-dialog-title"
      onClose={() => {
        props.toggleResignationDetailsPopup()
        handleOnClose()
      }}>
        <strong>Resignation Details</strong><br></br>
        <div className={styles.dialogHeading}>
          {props.employeeName}
          </div>
        <Divider></Divider>
      </BootstrapDialogTitle>
      <DialogContent className={styles.dataContent} >
          {props.addResignationCommentAlert.message && (
              <Alert
                className="mb-m"
                onClose={props.clearAddResignationCommentAlert}
                severity={props.addResignationCommentAlert.severity}
              >
                {props.addResignationCommentAlert.message}
              </Alert>
          )}
        {(props.resignationDetailsIsloading) &&
          <React.Fragment >

            <Alert style={{ paddingRight: '7px', paddingBottom: '16px', justifyContent: 'left' }} severity="info"
              iconMapping={{
                info: <CircularProgress size={20} />
              }}
            > Loading...</Alert>

          </React.Fragment>
        }
        {(props.resignationList?.length > 0 && !props.resignationDetailsIsloading) && (
          props.resignationList).map((row: ResingationDetailsDto, index) => (
            <React.Fragment key={index}>
              <Accordion expanded={expanded === row.resignationId || (index === 0 && props.resignationList.length === 1)} onChange={handleChange(row.resignationId)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <strong style={{ width: '50%', flexShrink: 0 }}>
                  {index + 1}-Resignation-{moment(row.resignationGivenDate).format(DATE_FORMAT_2)}
                  </strong>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={4} style={{ paddingTop: 4 }}>
                      <Typography>Resignation id:</Typography>
                    </Grid><Grid item xs={4} style={{ paddingTop: 4 }}><Typography>{row.resignationId}</Typography></Grid><Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{ paddingTop: 4 }}> Status:</Grid><Grid item xs={4}> {row.statusName}</Grid><Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{ paddingTop: 4 }}>Resignation given date:</Grid><Grid item xs={4}> {moment(row.resignationGivenDate).format(DATE_FORMAT_2)}</Grid><Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{ paddingTop: 4 }}>Last working date:</Grid><Grid item xs={4}>{moment(row.resignationDate).format(DATE_FORMAT_2)}</Grid><Grid item xs={4}></Grid>
                  </Grid>
                  {row.comments.length === 0 &&
                  <React.Fragment>
                    <br></br>
                    <Divider />
                    <br></br>
                    <Grid container spacing={2} >
                        <Grid item lg={12}>
                          <TextField
                            variant='outlined'
                            minRows={1}
                            label="Type your comment here..."
                            fullWidth
                            multiline
                            helperText={!props.resignationCommentText && !props.isShowHelperText && 'Comment is required'}
                            value={text}
                            error={!props.resignationCommentText && !props.isShowHelperText}
                            required={true}
                            onChange={(event) => onInputHandleChange('resignationComment', event.target.value)}
                            inputProps={{ className: classes.textarea }}
                          />
                        </Grid>
                      </Grid>
                      <DialogActions className={styles.cardActionStyle}>
                      <Button
                        variant="contained"
                        className={styles.btnStyle}
                        onMouseEnter={() => props.setResignationCommentText(text.trim())}
                        onClick={() => {
                          setText('')
                          props.addResignationComment(row.resignationId)
                        }}
                        >
                        {(props.addResignationCommentIsloading) && (
                          <CircularProgress size="13px" className={styles.loading} />
                        )}
                        Add
                      </Button>
                      </DialogActions>
                  </React.Fragment>
                  }
                  {row.comments.length > 0 &&
                    <React.Fragment>
                      {/* <Typography>Comments:</Typography> */}
                      <br></br>
                      <Divider />
                      <br></br>
                      <Grid container spacing={2} >
                        <Grid item lg={12}>
                          <TextField
                            variant='outlined'
                            minRows={1}
                            label="Type your comment here..."
                            fullWidth
                            multiline
                            helperText={!props.resignationCommentText && !props.isShowHelperText && 'Comment is required'}
                            value={text}
                            error={!props.resignationCommentText && !props.isShowHelperText}
                            required={true}
                            onChange={(event) => onInputHandleChange('resignationComment', event.target.value)}
                            inputProps={{ className: classes.textarea }}
                          />
                        </Grid>
                      </Grid>
                      <DialogActions className={styles.cardActionStyle}>
                      <Button
                        variant="contained"
                        className={styles.btnStyle}
                        onMouseEnter={() => props.setResignationCommentText(text.trim())}
                        onClick={() => {
                          setText('')
                          props.addResignationComment(row.resignationId)
                        }}
                        >
                        {(props.addResignationCommentIsloading) && (
                          <CircularProgress size="13px" className={styles.loading} />
                        )}
                        Add
                      </Button>
                      </DialogActions>
                      <br></br>
                      {row.comments.map((c: ResignationCommentsDto, index) => (
                          <React.Fragment key={index}>

                            <Grid container spacing={2}>
                              <Grid item lg={1} md={1} xs={1} alignItems="right" className={styles.infoCircleGrid}>
                                <span
                                  className={styles.infoCircle + ' layout-row layout-align-center center'}
                                >
                                  <sup>{c.createdBy.charAt(0)}</sup>
                                </span>
                              </Grid>
                              <Grid item lg={11} md={11} xs={11} sm container>
                                <Grid item xs container direction="row" spacing={2}>
                                  <Grid item lg={9} md={9} xs={9}>
                                    <Typography>{c.createdBy}</Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={3}>
                                    <span className={styles.date} style={{ textAlign: 'right' }}>{moment(c.createdAt).format(DATE_FORMAT_2)} .&nbsp; {moment(c.createdAt).format(TIME_FORMAT)}</span>
                                  </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12} className={styles.comment}>
                                  {c.comment.toString().length > 120
                                    ? <p className={styles.comment}>
                                      {c.comment.toString().substring(0, 120)}
                                      <a onClick={props.onShowMoreText}>
                                        {props.readMore && c.comment.toString().substring(60)}
                                        <span>{props.readMore ? ' ' : '... '}</span>
                                        <span className={styles.readMore}>{props.readMore ? ' Read Less' : ' Read More'}</span></a>
                                    </p>
                                    : <p>{c.comment}</p>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider className={styles.divider}></Divider>
                          </React.Fragment>
                      ))}
                    </React.Fragment>
                  }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
        ))}
      </DialogContent>

    </BootstrapDialog >

  )
}

export default ResignationDetailsPopup
