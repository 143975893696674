import { Alert, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppAuthorizer, ClientEditPopup, ClientEntryForm, ClientViewTable,
  ClientStatusChangePopup, AppTablePagination
} from '../../components'
import { clientAction, sbuAction, alertActions } from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_FEATURE_KEYS, APP_TABLE_CONFIGS, BREAD_CRUB } from '../../utilities/constants'
import { validateFormData } from '../../utilities/helpers/FormValidator'
import { ClientDto, ClientEntryFormDto, InitialSbuDto, ClientEditEntryFormDto, AlertDto, CreateClientParamDto, UpdateClientParamDto, sbuBriefDto, GetDisabledSbusParamDto, GetDisabledClientParamDto, AppStateDto } from '../../utilities/models'

const ClientManagement: React.FC = () => {
  const INITIAL_STATE: ClientEntryFormDto = {
    clientName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    strategicBusinessUnit: {
      value: {} as sbuBriefDto,
      validator: 'object',
      isRequired: true,
      error: null,
      disable: false
    },
    clientDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    }
  }

  const INITIAL_EDITING_ROW_STATE: ClientEditEntryFormDto = {
    clientId: {
      value: -1,
      isRequired: true,
      error: null,
      disable: false
    },
    clientName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    strategicBusinessUnit: {
      value: {} as sbuBriefDto,
      validator: 'object',
      isRequired: true,
      error: null,
      disable: false
    },
    clientDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    },
    isEnabled: {
      value: true,
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const INITIAL_CLIENT_STATUS_STATE = {
    clientStatus: {
      value: {} as UpdateClientParamDto
    }
  }

  const dispatch = useDispatch()
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)

  const allClientList = useSelector((state: any) => state.client.allClientList.data)
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)
  const clientIsLoading = useSelector((state: any) => state.client.allClientList.isLoading)
  const createClientAlert: AlertDto = useSelector((state: any) => state.alerts.createClient)
  const updateClientAlert: AlertDto = useSelector((state: any) => state.alerts.updateClient)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [clientFormData, setClientFormData] = useState<ClientEntryFormDto>(INITIAL_STATE)
  const [isShowHelperText, setIsShowHelperText] = useState(true)
  const [filteredRows, setFilteredRows] = useState<ClientDto[]>([])
  const [defaultSbuList, setDefaultSbuList] = useState<InitialSbuDto[]>([])
  const [isEditingRow, setIsEditingRow] = useState(INITIAL_EDITING_ROW_STATE)
  const [isStatusChangeRow, setIsStatusChangeRow] = useState(INITIAL_CLIENT_STATUS_STATE)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  React.useEffect(() => {
    setFilteredRows(allClientList?.data)
    searchClients(searchText)
  }, [allClientList])
  React.useEffect(() => {
    setDefaultSbuList(allSbuList?.data)
  }, [allSbuList])

  React.useEffect(() => {
    getAllClientList()
    getSBUList()
  }, [])
  React.useEffect(() => {
    if (createClientAlert.severity === 'success') {
      setClientFormData({
        ...clientFormData,
        clientName: {
          ...clientFormData['clientName' as keyof typeof clientFormData],
          value: ''
        },
        strategicBusinessUnit: {
          ...clientFormData['strategicBusinessUnit' as keyof typeof clientFormData],
          value: {} as sbuBriefDto
        },
        clientDescription: {
          ...clientFormData['clientDescription' as keyof typeof clientFormData],
          value: ''
        }
      })
      getAllClientList()
    }
  }, [createClientAlert])

  React.useEffect(() => {
    if (updateClientAlert.severity === 'success') {
      getAllClientList()
    }
  }, [updateClientAlert])

  const clearCreateClientAlert = () => {
    dispatch(alertActions.clearCreateClientAlertRequest())
  }

  const clearUpdateClientAlert = () => {
    dispatch(alertActions.clearUpdateClientAlertRequest())
  }

  const getAllClientList = () => {
    const clientListParams: GetDisabledClientParamDto = {
      getDisabledClients: 'true'
    }
    dispatch(clientAction.allClientList(clientListParams))
  }

  const getSBUList = () => {
    const sbuListParams: GetDisabledSbusParamDto = {
      getAll: true
      // getDisabledSbus: 'false'
    }
    dispatch(sbuAction.allSbuList(sbuListParams))
  }

  const handleInputFocus = (property: string) => {
    setClientFormData({
      ...clientFormData,
      [property]: {
        ...clientFormData[property as keyof typeof clientFormData],
        error: null
      }
    })
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        error: null
      }
    })
  }

  const createclient = async () => {
    const [validatedata, isValid] = await validateFormData(clientFormData)
    setClientFormData(validatedata)
    if (isValid) {
      const clientCreateParams: CreateClientParamDto = {
        clientName: clientFormData.clientName.value.trim(),
        clientDesc: clientFormData.clientDescription.value,
        isEnabled: true,
        sbuId: clientFormData.strategicBusinessUnit.value.id
      }
      dispatch(clientAction.createClient(clientCreateParams))
    }
  }

  const updateClient = async () => {
    const [validateData, isValid] = await validateFormData(isEditingRow)
    setIsEditingRow(validateData)
    if (isValid) {
      const clientUpdateParams: UpdateClientParamDto = {
        clientId: isEditingRow.clientId.value,
        clientName: isEditingRow.clientName.value.trim(),
        clientDesc: isEditingRow.clientDescription.value,
        sbuId: isEditingRow.strategicBusinessUnit.value.id,
        isEnabled: isEditingRow.isEnabled.value
      }
      dispatch(clientAction.updateClient(clientUpdateParams))
      setIsEditDialogOpen(false)
    }
  }
  const clientStatusChange = () => {
    const clientStatusChangeParams: UpdateClientParamDto = {
      clientId: isStatusChangeRow.clientStatus.value.clientId,
      clientName: isStatusChangeRow.clientStatus.value.clientName,
      clientDesc: isStatusChangeRow.clientStatus.value.clientDesc,
      isEnabled: isStatusChangeRow.clientStatus.value.isEnabled !== true,
      sbuId: isStatusChangeRow.clientStatus.value.sbuId
    }
    dispatch(clientAction.updateClient(clientStatusChangeParams))
    setIsStatusChangeDialogOpen(false)
  }

  const searchClients = (e: any) => {
    let keyword: any
    if (e.target !== undefined) {
      keyword = e.target.value
    } else {
      keyword = e
    }
    setSearchText(keyword)
    if (keyword !== '') {
      const results = allClientList?.data
        .filter((client: ClientDto) => {
          // Filter results by doing case insensitive match on name here
          return client.clientName.toLowerCase().includes(keyword.toLowerCase()) ||
            client.sbuName.toLowerCase().includes(keyword.toLowerCase())
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (a.clientName.toLowerCase().indexOf(keyword.toLowerCase()) > b.clientName.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.sbuName.toLowerCase().indexOf(keyword.toLowerCase()) > b.sbuName.toLowerCase().indexOf(keyword.toLowerCase())

          ) {
            return 1
          } else if (a.clientName.toLowerCase().indexOf(keyword.toLowerCase()) < b.clientName.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.sbuName.toLowerCase().indexOf(keyword.toLowerCase()) < b.sbuName.toLowerCase().indexOf(keyword.toLowerCase())
          ) {
            return -1
          } else {
            if (a.clientName > b.clientName || a.sbuName > b.sbuName) { return 1 } else { return -1 }
          }
        })
      setFilteredRows(results)
    } else {
      setFilteredRows(allClientList?.data)
    }
  }

  // Handle form input
  const onInputHandleChange = (property: string, value: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowHelperText(true)
    setClientFormData({
      ...clientFormData,
      [property]: {
        ...clientFormData[property as keyof typeof clientFormData],
        value: value
      }
    })
  }
  // Handle form edit
  const onEditHandleChange = (property: string, value: React.ChangeEvent<HTMLInputElement>) => {
    setIsShowHelperText(true)
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        value: value
      }
    })
  }
  // row Editing  data trigger
  const onRowEditTrigger = (data: ClientDto) => {
    setIsEditDialogOpen(true)
    setIsEditingRow({
      ...isEditingRow,
      clientId: {
        ...isEditingRow.clientId,
        value: data.clientId
      },
      clientName: {
        ...isEditingRow.clientName,
        value: data.clientName
      },
      strategicBusinessUnit: {
        ...isEditingRow.strategicBusinessUnit,
        value: {
          id: data.sbuId,
          name: data.sbuName
        }
      },
      clientDescription: {
        ...isEditingRow.clientDescription,
        value: data.clientDesc
      },
      isEnabled: {
        ...isEditingRow.isEnabled,
        value: data.isEnabled
      }

    })
  }
  // row status change  trigger
  const onRowStatusChangeTrigger = (data: UpdateClientParamDto) => {
    setIsStatusChangeDialogOpen(true)
    setIsStatusChangeRow({
      ...isStatusChangeRow,

      clientStatus:
      {
        value: {
          clientId: data.clientId,
          clientName: data.clientName,
          clientDesc: data.clientDesc,
          isEnabled: data.isEnabled,
          sbuId: data.sbuId
        }
      }
    })
  }
  //  if edit ok
  const clientEditClick = () => {
    setIsEditDialogOpen(false)
  }
  const clientStatusChangeClick = () => {
    setIsStatusChangeDialogOpen(false)
  }
  // pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <React.Fragment>
      <AppLayout breadcrumb={BREAD_CRUB.CLIENT_MANAGEMENT} componentTitle="Client Management">
        <p style={{
          paddingBottom: 7,
          margin: 0,
          fontWeight: 400,
          fontSize: '24px'
        }} >Client Management</p>
        <Divider className="dividerStyle" />
        <br />
        {createClientAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearCreateClientAlert}
            severity={createClientAlert.severity}
          >
            {createClientAlert.message}
          </Alert>
        )}
        {updateClientAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearUpdateClientAlert}
            severity={updateClientAlert.severity}
          >
            {updateClientAlert.message}
          </Alert>
        )}
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_CLIENT]}
        >
          <ClientEntryForm
            clientFormData={clientFormData}
            sbuList={defaultSbuList || []}
            isShowHelperText={isShowHelperText}
            onInputHandleChange={onInputHandleChange}
            createClient={createclient}
            handleInputFocus={handleInputFocus}
          />
        </AppAuthorizer>

        <br />
        <Divider className="dividerStyle" />
        <br />
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_CLIENT]}
        >
          <ClientViewTable
          activeUserRole={activeUserRole}
            clientList={filteredRows || []}
            rowsPerPage={rowsPerPage}
            isEditingRow={isEditingRow}
            isEditDialogOpen={isEditDialogOpen}
            page={page}
            onRowEditTrigger={onRowEditTrigger}
            onRowStatusChangeTrigger={onRowStatusChangeTrigger}
            clientListIsLoading={clientIsLoading}
            searchClients={searchClients}
            searchText={searchText}
            clientEditClick={clientEditClick}
          >
            <AppTablePagination
              data={filteredRows || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ClientViewTable>
        </AppAuthorizer>

          <ClientEditPopup
            isEditDialogOpen={isEditDialogOpen}
            clientFormData={clientFormData}
            isShowHelperText={isShowHelperText}
            title="Edit Client Details"
            isEditingRow={isEditingRow}
            sbuList={defaultSbuList || []}
            clientEditClick={clientEditClick}
            onEditHandleChange={onEditHandleChange}
            updateClient={updateClient}
            handleInputFocus={handleInputFocus}
          />
          <ClientStatusChangePopup
            isStatusChangeDialogOpen={isStatusChangeDialogOpen}
            clientStatusChangeClick={clientStatusChangeClick}
            clientStatusChange={clientStatusChange}
            isStatusChangeRow={isStatusChangeRow}
          />

      </AppLayout>
    </React.Fragment>

  )
}

export default ClientManagement
