import { IconButton, Tooltip, TableRow, TableBody, Button, Box, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { APP_FEATURE_KEYS } from '../../../utilities/constants'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ProposedResources, ProposedResourcesActionDto, RmStatus } from '../../../utilities/models/resourceRequest.model'
import { ResourceChildTableCell } from '../../../assets/theme/theme'
import { MoreVertOutlined, CheckCircleOutlineOutlined, CancelOutlined } from '@mui/icons-material'
import { AppAuthorizer } from '../../shared'
import LaunchIcon from '@mui/icons-material/Launch'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { AlertDto, ResourceManagementConfigDto, AppStateDto } from '../../../utilities/models'
import { useSelector } from 'react-redux'
const ProposedResourcesRows: React.FC<{
  proposedResources: ProposedResources[]
  deleteProposedResource(args: number) :void
  deleteProposeResourceAlert: any
  resource:any
  expandedResource: number | null
  setIsProposeResourcesPopupOpen(args: boolean):void
  proposeStatuses: any
  proposedResourcesAction(resourceId: number, proposeId: number, status: ProposedResourcesActionDto): void
}> = (props) => {
  const RESOURCE_REQUEST_STATUS_CONFIG_DATA = useSelector((state: AppStateDto) => state.resource.rmStatusList.data)
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = () => { setAnchorEl(null) }
  const openMore = Boolean(anchorEl)
  const configList = useSelector((state: any) => state.config.configList.data)
  const proposeDeleteStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_delete_enbale_status_ids')
  const proposeDeleteStatuses = proposeDeleteStatus?.values.split(',')
  const proposeActionEnabledStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_action_enable_status_ids')
  const proposeActionEnabledStatuses = proposeActionEnabledStatus?.values.split(',')
  const proposeActionCompleteEnabledStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_action_complete_enable_status_ids')
  const proposeActionCompleteEnabledStatuses = proposeActionCompleteEnabledStatus?.values.split(',')
  const proposeActionStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_action_status_ids')
  const proposeActionStatuses = proposeActionStatus?.values.split(',')
  const proposeActionCompletedStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_action_complete_status_ids')
  const proposeActionCompletedStatusLevel1 = proposeActionCompletedStatus?.values.split(',')
  const proposeActionCompletedStatusLevel2 = proposeActionCompletedStatusLevel1[0]?.split('-')
  proposeActionCompletedStatusLevel1[0] = proposeActionCompletedStatusLevel2[proposeActionCompletedStatusLevel2.length - 1]
  const proposeActionCompletedStatuses = proposeActionCompletedStatusLevel1
  const getNextStatus = (statusId: number) => {
    let sendingStatus
    if (proposeActionStatuses.some((id: number) => id === statusId)) {
      const index = proposeActionStatuses.findIndex((id: number) => id === statusId)
      if (index === 0) {
        if (!proposeActionEnabledStatuses.some((id: number) => id === statusId)) {
          sendingStatus = -1
        } else {
          sendingStatus = proposeActionStatuses[index + 1]
        }
      } else {
        if (!(statusId === proposeActionStatuses[index - 1])) {
          sendingStatus = -1
        } else {
          sendingStatus = proposeActionStatuses[index + 1]
        }
      }
    } else {
      sendingStatus = proposeActionStatuses[0]
    }
    return sendingStatus
  }
  const proposedResourcesActionAlert: AlertDto = useSelector((state: any) => state.alerts.proposedResourcesAction)
  return (
      <TableBody>
      {props.proposedResources.length <= 0 && (
        <TableRow> <ResourceChildTableCell colSpan={7}>No Records Found</ResourceChildTableCell></TableRow>
      )
}
        {props.proposedResources.map((element: ProposedResources, index: number) => (
      <TableRow key={index}>
          <ResourceChildTableCell>{element.name}</ResourceChildTableCell>
          <ResourceChildTableCell>{element.username}</ResourceChildTableCell>
          <ResourceChildTableCell>{element.designation}</ResourceChildTableCell>
          <ResourceChildTableCell>{element.experience}</ResourceChildTableCell>
          <ResourceChildTableCell>
          <IconButton
            // onClick={() => {
            //   setIsProposeResourcesPopupOpen(true)
            // } }
            size='small'
          >
              <Tooltip title="Open in new tab">
                <LaunchIcon sx={{ fontSize: '20px', mr: '-1' }} />
              </Tooltip>
            </IconButton>
          </ResourceChildTableCell>
          <ResourceChildTableCell>{element.status}</ResourceChildTableCell>
          <ResourceChildTableCell>
            <Box>
          {/* resource action */}
          <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[
            APP_FEATURE_KEYS.PROPOSED_RESOURCE_ACTION

          ]}>
          {(proposeActionEnabledStatuses?.filter((x: any) => +x === element.statusId).length > 0 &&
          proposeActionCompleteEnabledStatuses?.filter((x: any) => +x === element.statusId).length <= 0) &&
             <IconButton
            disabled={proposedResourcesActionAlert.severity === 'success'}
            onClick={() => {
              const status: ProposedResourcesActionDto = {
                statusId: +getNextStatus(element.statusId)
              }
              props.proposedResourcesAction(props.resource.resourceId, element.proposedResourcesId, status)
            } }
            size='small'
          >
              <Tooltip title={'Change status to ' + RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === +getNextStatus(element.statusId))?.name || 'Resource Action'}>
                <AssignmentTurnedInOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'green' }} />
              </Tooltip>
            </IconButton>
           }
          {(proposeActionCompleteEnabledStatuses?.filter((x: any) => +x === element.statusId).length > 0) &&
            <><IconButton
            size='small'
            onClick={handleClick}
          >
            <Tooltip title='More'>
              <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }} />
            </Tooltip>
          </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={openMore}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
              }}
            >
                {proposeActionCompletedStatuses?.map((status: number, index: number) => (
                    <div key={index}>
                      <MenuItem
                        onClick={() => {
                          const statusPayload: ProposedResourcesActionDto = {
                            statusId: +status
                          }
                          props.proposedResourcesAction(props.resource.resourceId, element.proposedResourcesId, statusPayload)
                        } }
                        disabled={proposedResourcesActionAlert.severity === 'success'}
                      >
                        <ListItemIcon>
                          {/* assumption is that the first item in the proposeActionCompletedStatuses is the rejection status */}
                          {index !== 0
                            ? <CancelOutlined sx={{ fontSize: '20px', mr: '-1', color: 'red' }} />
                            : <CheckCircleOutlineOutlined sx={{ fontSize: '20px', mr: '-1', color: 'blue' }} />}
                        </ListItemIcon>
                        {RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === +status)?.title} Request
                      </MenuItem>
                      {index < (proposeActionCompletedStatuses?.length - 1) && (
                        <Divider />
                      )}
                    </div>
                ))}
              </Menu>
              </>
          }
          </AppAuthorizer>
          {/* resource delete button */}
          <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[
            APP_FEATURE_KEYS.DELETE_PROPOSED_RESOURCE

          ]}>
          {proposeDeleteStatuses?.filter((x: any) => +x === element.statusId).length > 0 &&
          <IconButton
            onClick={() => {
              props.deleteProposedResource(element.proposedResourcesId)
            } }
            disabled={props.deleteProposeResourceAlert.severity === 'success'}
            size='small'
          >
              <Tooltip title="Delete Resource">
                <DeleteOutlineIcon sx={{ fontSize: '20px', mr: '-1', color: 'red' }} />
              </Tooltip>
            </IconButton>
            }
            </AppAuthorizer>
            </Box>
          </ResourceChildTableCell>
        </TableRow>
        ))}
        <AppAuthorizer
                  activeRoleFeatures={activeUserRole.data.features}
                  authorizedFeatureKey={[APP_FEATURE_KEYS.PROPOSE_RESOURCE]}
                >
  {props.proposeStatuses?.filter((x: any) => +x === props.resource.statusId).length > 0 && props.resource.isEnabled &&

        <TableRow> <ResourceChildTableCell colSpan={7}>
        <Button
          disabled={props.expandedResource !== props.resource.resourceId}
          sx={{ textTransform: 'capitalize', color: '8B8B8B' }}
          onClick={() => { props.setIsProposeResourcesPopupOpen(true) }}
        >
          + Add New Resources
          </Button>
        </ResourceChildTableCell></TableRow>
}
</AppAuthorizer>
      </TableBody>
  )
}
export default ProposedResourcesRows
