import { all } from 'redux-saga/effects'
import userSaga from './user.saga'
import sbuSaga from './sbu.saga'
import alertSaga from './alert.saga'
import projectSaga from './project.saga'
import clientSaga from './client.saga'
import employeeSaga from './employee.saga'
import supervisorSaga from './supervisor.saga'
import authSaga from './auth.saga'
import reportSaga from './report.saga'
import commentsSaga from './comments.saga'
import dashboardSaga from './dashboard.saga'
import resignationSaga from './resignation.saga'
import designationSaga from './designation.saga'
import resourceSagas from './resource.saga'
import configSaga from './config.saga'
import trackSaga from './track.saga'
export default function * rootSaga () {
  yield all(
    [
      authSaga(),
      userSaga(),
      sbuSaga(),
      alertSaga(),
      projectSaga(),
      clientSaga(),
      employeeSaga(),
      supervisorSaga(),
      reportSaga(),
      commentsSaga(),
      dashboardSaga(),
      resignationSaga(),
      designationSaga(),
      resourceSagas(),
      configSaga(),
      trackSaga()

    ]
  )
}
