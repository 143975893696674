
import React, { useState } from 'react'
import {
  AddOutlined, CloseOutlined, EditOutlined, KeyboardArrowDownOutlined, KeyboardArrowRightOutlined,
  MoreVertOutlined,
  SaveOutlined
} from '@mui/icons-material'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import {
  Autocomplete,
  // Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import {
  HtmlTooltip,
  StyledParentTableRow,
  StyledTableCell
} from '../../../assets/theme/theme'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { v4 as uuid } from 'uuid'
import { APP_FEATURE_KEYS, DATE_FORMAT, TIME_COLORED_ALLOWED_DAYS } from '../../../utilities/constants'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import EmployeeViewTableChildRows from '../EmployeeViewTableChildRows/EmployeeViewTableChildRows'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import {
  AllocationDto,
  EmployeeDto,
  EmployeeEditFormDto,
  InitBriefProjectRoleDto,
  InitProjectRoleDto,
  UpdateEmployeeAllocationDto,
  AppStateDto
} from '../../../utilities/models'
// import DesignationAbbreviation from '../../../utilities/helpers/DesignationAbbreviation'
import styles from './EmployeeViewTableRows.module.scss'
import { validateFormData } from '../../../utilities/helpers/FormValidator'
import { AppAuthorizer } from '../../shared'
import { useSelector } from 'react-redux'

const EmployeeViewTableRows: React.FC<{
  employee: EmployeeDto;
  index: string;
  selectedEmployee: EmployeeDto | null;
  briefProjectRoleList: InitProjectRoleDto[];
  isRowEditing: boolean;
  selectedProject: AllocationDto | null;
  isCollapsed: boolean;
  // employeeAllocationList: EmployeeDto[]
  setSelectedEmployee(arg: EmployeeDto): void;
  setSelectedProject(arg: AllocationDto): void;
  setIsRowEditing(arg: boolean): void;
  setIsCollapsed(arg: boolean): void;
  toggleDrawer(row: any): void;
  toggleDeallocateEmployeeDialog(): void;
  toggleUpdateAllocationDialog(): void;
  handleUpdateData(data: UpdateEmployeeAllocationDto): void;
  compareWithCurrentDate(data: any): boolean
  addAllocation(): void
  employeeDataSession(): void;
  toggleAallocationCommentPopup(): void;
  toggleResignationDetailsPopup(): void;
  deallocationCustomAlert(message: string):void;
}> = (props) => {
  const INITIAL_EDITING_ROW_STATE: EmployeeEditFormDto = {
    projectRoleDisplayName: {
      value: {
        id: props.employee.projectList[0]?.projectRoleId ? props.employee.projectList[0]?.projectRoleId : props.briefProjectRoleList?.find((role) => role.projectRoleName === props.employee.designation)?.projectRoleId,
        name: props.employee.projectList[0]?.projectRoleDisplayName ? props.employee.projectList[0]?.projectRoleDisplayName : props.briefProjectRoleList?.find((role) => role.projectRoleName === props.employee.designation)?.projectRoleDisplayName
      } as InitBriefProjectRoleDto,
      isRequired: true,
      error: null,
      validator: 'object'
    },
    startDate: {
      value: props.employee.projectList[0]?.projectStartDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    endDate: {
      value: props.employee.projectList[0]?.projectEndDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    allocationPercentage: {
      value: props.employee.projectList[0]?.allocationPercentage !== null ? props.employee.projectList[0]?.allocationPercentage.toString() : 100,
      isRequired: true,
      error: null,
      validator: 'text'
    },
    isBillable: {
      value: (props.employee.projectList[0]?.isBillable) ? 'Billable' : 'Non-billable',
      isRequired: true,
      error: null,
      validator: 'text'
    },
    billingStartDate: {
      value: props.employee.projectList[0]?.billingStartDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    billingEndDate: {
      value: props.employee.projectList[0]?.billingEndDate,
      isRequired: true,
      error: null,
      validator: 'date'

    },
    projectId: {
      value: props.employee.projectList[0]?.projectId,
      isRequired: true,
      error: null,
      validator: 'text'
    }
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = () => { setAnchorEl(null) }
  const open = Boolean(anchorEl)
  // const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) }
  const [isEditingRow, setIsEditingRow] = useState(INITIAL_EDITING_ROW_STATE)

  const convBillable = (billable: boolean) => {
    return billable ? 'Billable' : 'Non-billable'
  }
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)

  // Handle form edit
  const onEditHandleChange = (property: string, value: any) => {
    switch (property) {
      case 'startDate':
        if (value) {
          setIsEditingRow({
            ...isEditingRow,
            startDate: {
              ...isEditingRow[
              'startDate' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'date'

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'date'

            }
          })
        }
        break
      case 'endDate':
        if (value) {
          setIsEditingRow({
            ...isEditingRow,
            endDate: {
              ...isEditingRow[
              'endDate' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'date'

            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'date'

            }
          })
        }
        break
      case 'isBillable':
        if (value === 'Billable') {
          setIsEditingRow({
            ...isEditingRow,
            isBillable: {
              ...isEditingRow[
              'isBillable' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'text'

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: isEditingRow.startDate.value,
              isRequired: true,
              error: null,
              validator: 'date'
            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: isEditingRow.endDate.value,
              isRequired: true,
              error: null,
              validator: 'date'

            }
          })
        } else {
          setIsEditingRow({
            ...isEditingRow,
            isBillable: {
              ...isEditingRow[
              'isBillable' as keyof typeof isEditingRow
              ],
              value: value,
              isRequired: true,
              error: null,
              validator: 'text'

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: null,
              isRequired: true,
              error: null,
              validator: 'date'
            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: null,
              isRequired: true,
              error: null,
              validator: 'date'

            }
          })
        }
        break
      default:
        setIsEditingRow({
          ...isEditingRow,
          [property]: {
            ...isEditingRow[
            property as keyof typeof isEditingRow
            ],
            value: value
          }
        })
    }
  }
  const handleEdit = (data: EmployeeDto) => {
    props.setSelectedEmployee(props.employee)
    props.setSelectedProject(props.employee?.projectList[0])
    props.setIsRowEditing(true)
  }
  const handleComment = (data: EmployeeDto) => {
    props.setSelectedEmployee(props.employee)
    props.setSelectedProject(props.employee?.projectList[0])
  }
  const addNewAllocationForEmployee = () => {
    // props.setIsNewAllocation(false)
    if (props.employee.projectList.length === 0) {
      props.setSelectedEmployee(props.employee)
    } else {
      props.setSelectedEmployee(props.employee)
      props.setSelectedProject(props.employee?.projectList[0])
    }
  }
  const compareWithCurrentProject = () => {
    let isExist = false
    if (props.employee?.projectList.length > 0) {
      const currentProjectId = props.employee.projectList[0]?.projectId
      for (const project of props.employee.projectList) {
        if (currentProjectId === project.projectId) {
          if (moment(project.projectStartDate).format(DATE_FORMAT) > moment(props.employee.projectList[0]?.projectEndDate).format(DATE_FORMAT)) {
            isExist = true
            break
          }
        }
      }
    }
    if (isExist === true) {
      props.deallocationCustomAlert('Can not revoke the deallocation. Already has a future allocation')
    } else {
      props.toggleDeallocateEmployeeDialog()
    }
  }
  const handleCollapseClose = () => {
    addNewAllocationForEmployee()
    props.setIsCollapsed(false)
  }
  const handleCollapseOpen = () => {
    addNewAllocationForEmployee()
    props.setIsCollapsed(true)
  }
  const validateCollapse = () => {
    if (
      props.isCollapsed &&
      props.selectedEmployee?.userId === props.employee.userId
    ) {
      return true
    } else {
      return false
    }
  }
  const resetUpdateFields = () => {
    setIsEditingRow({
      ...isEditingRow,
      isBillable: {
        ...isEditingRow.isBillable,
        value: props.employee.projectList[0]?.isBillable ? 'Billable' : 'Non-billable'
      },
      endDate: {
        ...isEditingRow.endDate,
        value: props.employee.projectList[0]?.projectEndDate
      },
      allocationPercentage: {
        ...isEditingRow.allocationPercentage,
        value: props.employee.projectList[0]?.allocationPercentage

      }
    })
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        error: null
      }
    })
  }
  const handleUpdateData = async () => {
    if (isEditingRow.isBillable.value !== 'Billable') {
      delete isEditingRow.billingEndDate
      delete isEditingRow.billingStartDate
    }

    const [validateData, isValid] = await validateFormData(isEditingRow)
    setIsEditingRow(validateData)
    if (isValid) {
      const value: boolean = isEditingRow.isBillable.value === 'Billable'
      const data: UpdateEmployeeAllocationDto = {
        projectRoleId: isEditingRow.projectRoleDisplayName.value.id,
        isBillable: value,
        startDate: moment(isEditingRow.startDate.value).format(DATE_FORMAT),
        endDate: moment(isEditingRow.endDate.value).format(DATE_FORMAT),
        billingStartDate: moment(isEditingRow.billingStartDate?.value).format(DATE_FORMAT),
        billingEndDate: moment(isEditingRow.billingEndDate?.value).format(DATE_FORMAT),
        allocationPercentage: isEditingRow.allocationPercentage.value,
        projectId: isEditingRow.projectId.value
        // projectRoleId: projectRoleId
      }
      props.handleUpdateData(data)
      props.setIsRowEditing(false)
      props.toggleUpdateAllocationDialog()
    }
  }
  return (
    <>
      {props.employee.projectList.length === 0 && (
        <StyledParentTableRow >

          <StyledTableCell padding="checkbox" align="left" style={{ zIndex: '1', position: 'sticky', left: 0, paddingLeft: 0, paddingRight: 0 }}>
            <Stack direction={'row'} sx={{ height: '100%', alignItems: 'left' }}>
              {props.employee.isRequestResign === true &&
                <span className={styles.errorStyle}>i</span>
              }
            </Stack>
          </StyledTableCell>
          <StyledTableCell align="left" style={{ left: 34, position: 'sticky', wordWrap: 'break-word', marginLeft: '9px' }}>

            {props.employee.empId}</StyledTableCell>
          <StyledTableCell align="left" style={{ left: 114, position: 'sticky', wordWrap: 'break-word', paddingLeft: 0 }}>
            <Stack direction={'row'} sx={{ height: '100%', alignItems: 'center' }}>

              <Stack>
                {props.employee.employeeName}

                {props.employee.designation &&
                  <Tooltip title={props.employee.designation}>
                    <Typography className={styles.displayNameStyle} >
                      {`[${props.employee.designationDisplayName}]`}

                    </Typography>

                  </Tooltip>
                }
              </Stack>
            </Stack>
          </StyledTableCell>

          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="center">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell align="left">-</StyledTableCell>
          <StyledTableCell style={{ zIndex: '1', position: 'sticky', right: 0 }}>

            <Box width={'100%'} display="flex" alignItems="end">

        <Box width={'100%'} display="flex" alignItems="end">
        <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_ALLOCATION]}
                        >
              <IconButton
                size='small'
                disabled={true}
                onClick={() => handleEdit(props.employee)}>
                <Tooltip title="Edit">
                  <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              </AppAuthorizer>
              <IconButton
                disabled={true}
                size='small'
              >
                <Tooltip title="Comment">
                  <InsertCommentIcon sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                        >
              <IconButton
                size='small'
                disabled={true}
              >
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit"><b>Revoke Deallocation</b></Typography>
                      {'Here able to '} <b>{'revoke '}</b>{' '}
                      {'de-allocation from the project. So user reassigned of this project at the relevant time'}
                      .{' '}<br></br>
                    </React.Fragment>
                  }
                >
                  <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                </HtmlTooltip>
              </IconButton>
              </AppAuthorizer>
              </Box>
              <Box alignItems="end">
              <IconButton
                size='small'
                onClick={handleClick}
              >
                <Tooltip title="More">
                  <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }}
                  />
                </Tooltip>
              </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter:
                      'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top'
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
              >
                <NavLink
                  className="navLink"
                  to={`/allocation-management/${props.employee.userName}`}
                  state={{ username: props.employee.userName }}
                >
                  <MenuItem
                  >
                    <Tooltip title="Profile">
                      <PermIdentityOutlinedIcon
                        className={styles.profileIconStyle}
                        sx={{ fontSize: '20px', mr: '-1' }} />
                    </Tooltip>
                    View Profile
                  </MenuItem>
                </NavLink>
                <Divider />
                {
                  props.employee.isEnable &&
                    props.employee.isEnable === true
                    ? <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_ALLOCATION]}
                        >
                    <MenuItem
                      disabled={!props.employee.isEnable}
                      onClick={() => {
                        addNewAllocationForEmployee()
                        props.toggleDrawer(props.employee)
                        props.addAllocation()
                      }}
                    >
                      <ListItemIcon>
                        <Tooltip title="Add Allocation">
                          <AddOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                        </Tooltip>
                      </ListItemIcon>
                      Add allocation
                    </MenuItem>
                    </AppAuthorizer>
                    : null
                }
                <Divider />
                {
                  props.employee.isRequestResign &&
                    props.employee.isRequestResign === true
                    ? <MenuItem
                      onClick={() => {
                        props.setSelectedEmployee(props.employee)
                        props.toggleResignationDetailsPopup()
                      }}
                    >
                      <Tooltip title="View Resignation">
                        <RemoveRedEyeOutlinedIcon className={styles.profileIconStyle} sx={{ fontSize: '20px', mr: '-1' }} />
                      </Tooltip>
                      View resignation
                    </MenuItem>
                    : null
                }

              </Menu>
            </Box>
          </StyledTableCell>
        </StyledParentTableRow>
      )}

      {props.employee.projectList.length > 0 && (
        <>
          <StyledParentTableRow
            className={(moment(props.employee.projectList[0]?.projectEndDate).format(DATE_FORMAT) !== 'Invalid date' &&
              moment(props.employee.projectList[0]?.projectEndDate).subtract(TIME_COLORED_ALLOWED_DAYS, 'days') <= moment() &&
              (moment(props.employee.projectList[0]?.projectEndDate) >= moment()) && props.employee.projectList[0]?.isConfirmed === false)
              ? 'future'
              : moment(props.employee.projectList[0]?.projectEndDate).format(DATE_FORMAT) !== 'Invalid date' &&
                moment(props.employee.projectList[0]?.projectEndDate).format(
                  DATE_FORMAT
                ) <= moment(new Date()).format(DATE_FORMAT) && props.employee.projectList[0]?.isConfirmed === false
                ? 'expired'
                : ''}>
            <StyledTableCell padding="checkbox" align="left" style={{ zIndex: '1', position: 'sticky', left: 0, paddingLeft: 0, paddingRight: 0 }}>
              <Stack direction={'row'} sx={{ height: '100%', alignItems: 'left' }}>
                {props.employee.isRequestResign === true &&
                  <span className={styles.errorStyle}>i</span>
                }
                {props.employee.projectList.length > 1
                  ? <>
                    {validateCollapse() === true
                      ? (
                        <IconButton size='small' onClick={handleCollapseClose}>
                          <KeyboardArrowDownOutlined />
                        </IconButton>
                        )
                      : (
                        <IconButton size='small'
                          onClick={() => { handleCollapseOpen(); props.setIsRowEditing(false) }}
                        >
                          <KeyboardArrowRightOutlined />
                        </IconButton>
                        )}</>
                  : null
                }
              </Stack>
            </StyledTableCell>

            <StyledTableCell align="left" style={{ zIndex: 1, left: 34, position: 'sticky', wordWrap: 'break-word', height: 0, padding: 0 }}>
              {props.employee.empId}
            </StyledTableCell>
            <StyledTableCell align="left" style={{
              zIndex: 1,
              left: 114,
              position: 'sticky',
              wordWrap: 'break-word',
              height: 0,
              padding: 0,
              minWidth: 165,
              maxWidth: 165

            }}>
              <Stack>
                {props.employee.employeeName}
                {props.employee.designation &&
                  <Tooltip title={props.employee.designation}>
                    <Typography className={styles.displayNameStyle}>
                      {`[${props.employee.designationDisplayName}]`}
                    </Typography>

                  </Tooltip>
                }
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }} >
              {props.employee.projectList[0].client}
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }} >
              {props.employee.projectList[0]?.projectName
                ? props.employee.projectList[0]?.projectName
                : <span className={styles.missingInfo}>- -</span>
              }

            </StyledTableCell>
            <StyledTableCell align="center" >
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? <Grid className='numberTextFielStyle' item md={6}>
                  <Autocomplete
                    size='small'
                    disablePortal
                    options={
                      props.briefProjectRoleList?.map((desig: any) => ({
                        id: desig.projectRoleId,
                        name: desig.projectRoleDisplayName,
                        roleName: desig.projectRoleName
                      })) || []
                    }
                    getOptionLabel={(option) => option.name || ''}
                    value={isEditingRow.projectRoleDisplayName.value}
                    renderOption={(props, option, state) => {
                      return (
                        <Box component={'li'} {...props} key={option.id}>
                          <Tooltip placement="right"
                            title={`${option.roleName}`}
                          ><Typography
                            style={{ textAlign: 'left', paddingLeft: '6px', paddingBottom: '6px' }}>
                              {`${option.name}`}</Typography>
                          </Tooltip>
                        </Box>
                      )
                    }}
                    onChange={(event, value) => onEditHandleChange('projectRoleDisplayName', value)}
                    disableClearable
                    renderInput={(params) =>
                      <TextField variant="standard"
                        {...params} size={'small'}
                        sx={{ width: 110, margin: 0, padding: 0, textAlign: 'start' }} />}
                  />
                </Grid>

                : props.employee.projectList[0]?.projectRoleName
                  ? <Tooltip title={props.employee.projectList[0]?.projectRoleName}>
                    <Typography >
                      {props.employee.projectList[0]?.projectRoleDisplayName}
                    </Typography>
                  </Tooltip>
                  : <span className={styles.missingInfo}>- -</span>}
            </StyledTableCell>
            <StyledTableCell align="left" style={{ wordWrap: 'break-word', padding: 0 }}>
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? (<LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    closeOnSelect={true}
                    views={['year', 'month', 'day']}
                    value={isEditingRow.startDate.value}
                    // maxDate={isEditingRow.endDate.value !== null ? moment(isEditingRow.endDate.value) : undefined}
                    onChange={(value) => onEditHandleChange('startDate', value)}
                    inputFormat={DATE_FORMAT}
                    renderInput={(params) =>
                      <TextField {...params}
                        variant="standard"
                        onFocus={() => handleInputFocus('startDate')}
                        error={isEditingRow.startDate.error !== null}
                        required={true}
                        size={'small'} sx={{
                          svg: { width: '15px', height: '15px' },
                          maxWidth: 100,
                          margin: 0,
                          padding: 0
                        }} />}
                  />
                </LocalizationProvider>
                  )
                : (
                  <>
                    {props.employee.projectList[0]?.projectStartDate &&
                      moment(props.employee.projectList[0]?.projectStartDate).format(DATE_FORMAT) !== 'Invalid date'
                      ? moment(props.employee.projectList[0]?.projectStartDate).format(DATE_FORMAT)
                      : <span className={styles.missingInfo}>- - / - - / - -</span>}
                  </>
                  )}
            </StyledTableCell>
            <StyledTableCell align="left" style={{ wordWrap: 'break-word' }}>
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? (<LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={['year', 'month', 'day']}
                    value={isEditingRow.endDate.value}
                    minDate={isEditingRow.startDate.value ? moment(isEditingRow.startDate.value) : undefined}
                    onChange={(value) => onEditHandleChange('endDate', value)}
                    inputFormat={DATE_FORMAT}
                    renderInput={(params) =>
                      <TextField {...params}
                        error={isEditingRow.endDate.error !== null}
                        onFocus={() => handleInputFocus('endDate')}

                        variant="standard"
                        required={true}
                        sx={{
                          svg: { width: '15px', height: '15px' },
                          maxWidth: 100,
                          margin: 0,
                          padding: 0
                        }}
                        size={'small'} />}
                  />
                </LocalizationProvider>
                  )
                : (
                  <React.Fragment>
                    {props.employee.projectList[0]?.projectEndDate &&
                      moment(props.employee.projectList[0]?.projectEndDate).format(
                        DATE_FORMAT
                      ) !== 'Invalid date'
                      ? (moment(props.employee.projectList[0]?.projectEndDate).format(DATE_FORMAT))

                      : (<span className={styles.missingInfo}>- - / - - / - -</span>)
                    }

                  </React.Fragment>
                  )}
            </StyledTableCell>
            <StyledTableCell align="center">
              <Grid container alignItems={'center'}>
                {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                  props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                  props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                  props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                  ? <Grid className='numberTextFielStyle' item md={6}>
                    <TextField variant="standard"
                      style={{ width: '70px' }}
                      onFocus={() => handleInputFocus('allocationPercentage')}

                      required={true}
                      type={'number'}
                      size={'small'}
                      value={isEditingRow.allocationPercentage.value}
                      error={isEditingRow.allocationPercentage.error !== null}
                      InputProps={{
                        inputProps: {
                          max: 100, min: 0
                        }
                      }}
                      onChange={(e) => onEditHandleChange('allocationPercentage', e.target.value)}
                    ></TextField>
                  </Grid>
                  : <>
                    {props.employee.projectList[0]?.allocationPercentage !== null
                      ? <>
                        <Grid item md={4}>
                          <LinearProgress
                            style={{ textAlign: 'center' }}
                            variant='determinate'
                            color='primary'
                            value={props.employee.projectList[0]?.allocationPercentage} />
                        </Grid>
                        <Grid item md={2} ml={'5px'}>
                          <Typography align={'center'}>
                            {props.employee.projectList[0]?.allocationPercentage || 0}%
                          </Typography>
                        </Grid>
                      </>
                      : <span className={styles.missingInfo}>- -</span>
                    }

                  </>}
              </Grid>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ wordWrap: 'break-word' }}>
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? <Autocomplete
                  size='small'
                  disablePortal
                  options={['Billable', 'Non-billable']}

                  value={isEditingRow.isBillable.value}
                  onChange={(event, value) => onEditHandleChange('isBillable', value)}
                  onFocus={() => handleInputFocus('isBillable')}

                  disableClearable
                  renderInput={(params) =>
                    <TextField variant="standard"
                      error={isEditingRow.isBillable.error !== null}
                      required={true}
                      {...params} size={'small'}
                      sx={{ maxWidth: 120, margin: 0, padding: 0 }} />}
                />
                : <>
                  {(props.employee.projectList[0]?.projectStartDate &&
                    props.employee.projectList[0]?.projectEndDate)
                    ? `${convBillable(props.employee.projectList[0]?.isBillable)}`
                    : <span className={styles.missingInfo}>- -</span>}
                  {/* {convBillable(props.employee.projectList[0]?.isBillable)} */}
                </>
              }</StyledTableCell>
            <StyledTableCell align="left">
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? (<LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={['year', 'month', 'day']}
                    disabled={!(isEditingRow.isBillable.value === 'Billable')}
                    value={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingStartDate?.value : null}
                    minDate={moment(isEditingRow.startDate?.value)}
                    maxDate={moment(isEditingRow.endDate?.value)}
                    onChange={(value) => onEditHandleChange('billingStartDate', value)}

                    inputFormat={DATE_FORMAT}
                    renderInput={(params) =>
                      <TextField {...params}
                        disabled={!(isEditingRow.isBillable.value === 'Billable')}
                        error={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingStartDate?.error !== null : false}
                        onFocus={() => handleInputFocus('billingStartDate')}
                        variant="standard"
                        size={'small'} sx={{
                          svg: { width: '15px', height: '15px' },
                          maxWidth: 100,
                          margin: 0,
                          padding: 0
                        }} />}
                  />
                </LocalizationProvider>
                  )
                : (
                    props.employee.projectList[0]?.isBillable === false
                      ? <span >N/A</span>
                      : props.employee.projectList[0]?.billingStartDate &&
                      moment(props.employee.projectList[0]?.billingStartDate).format(DATE_FORMAT) !== 'Invalid date'
                        ? moment(props.employee.projectList[0]?.billingStartDate).format(DATE_FORMAT)
                        : <span className={styles.missingInfo}>- - / - - / - -</span>

                  )}
            </StyledTableCell>
            <StyledTableCell align="left">
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? (<LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={['year', 'month', 'day']}
                    value={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingEndDate?.value : null}
                    onChange={(value) => onEditHandleChange('billingEndDate', value)}
                    minDate={moment(isEditingRow.startDate?.value)}
                    maxDate={moment(isEditingRow.endDate?.value)}
                    disabled={!(isEditingRow.isBillable.value === 'Billable')}
                    inputFormat={DATE_FORMAT}
                    renderInput={(params) =>
                      <TextField {...params}
                        size="small"
                        disabled={!(isEditingRow.isBillable.value === 'Billable')}
                        error={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingEndDate?.error !== null : false}
                        onFocus={() => handleInputFocus('billingEndDate')}
                        variant="standard"
                        sx={{
                          svg: { width: '15px', height: '15px' },
                          maxWidth: 100,
                          margin: 0,
                          padding: 0
                        }} />}
                  />
                </LocalizationProvider>
                  )
                : (
                    props.employee.projectList[0]?.isBillable === false
                      ? <span >N/A</span>
                      : props.employee.projectList[0]?.billingEndDate &&
                      moment(props.employee.projectList[0]?.billingEndDate).format(DATE_FORMAT) !== 'Invalid date'
                        ? moment(props.employee.projectList[0]?.billingEndDate).format(DATE_FORMAT)
                        : <span className={styles.missingInfo}>- - / - - / - -</span>
                  )}
            </StyledTableCell>
            <StyledTableCell style={{ zIndex: '1', position: 'sticky', right: 0 }}>
              {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
                props.selectedProject?.projectId === props.employee.projectList[0]?.projectId &&
                props.selectedProject?.projectStartDate === props.employee.projectList[0]?.projectStartDate &&
                props.selectedProject?.projectEndDate === props.employee.projectList[0]?.projectEndDate
                ? (<Box width={'100%'}>
                  <IconButton size='small' onClick={() => { handleUpdateData() }}>
                    <SaveOutlined color='primary' sx={{ fontSize: '20px', mr: '-1' }} />
                  </IconButton>
                  <IconButton
                    size='small'
                    className="actionClosedButtonStyle"
                    onClick={() => {
                      props.setIsRowEditing(false)
                      resetUpdateFields()
                    }}
                  >
                    <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                  </IconButton>
                </Box>
                  )
                : (

                  <Box width={'100%'} display="flex" alignItems="end">
                     <Box width={'100%'} display="flex" alignItems="end">
                     <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_ALLOCATION]}
                        >
                    <IconButton
                      size='small'
                      onClick={() => handleEdit(props.employee)}
                      disabled={props.employee.projectList[0].isConfirmed === true}
                    >
                      <Tooltip title="Edit">
                        <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                      </Tooltip>
                    </IconButton>
                    </AppAuthorizer>
                    <IconButton
                      size='small'
                      onClick={() => {
                        handleComment(props.employee)
                        props.toggleAallocationCommentPopup()
                      }}
                    >
                      <Tooltip title="Comment">
                        <InsertCommentIcon sx={{ fontSize: '20px', mr: '-1' }} />
                      </Tooltip>
                    </IconButton>
                    {props.employee.projectList[0].isConfirmed === true
                      ? (
                        <AppAuthorizer
                        activeRoleFeatures={activeUserRole.data.features}
                        authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                      >

                        <IconButton
                          size='small'
                          className="isconfirmedActionButtonStyle"
                          onClick={() => {
                            addNewAllocationForEmployee()
                            compareWithCurrentProject()
                          }}
                        >
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit"><b>Revoke Deallocation</b></Typography>
                                {'Here able to '} <b>{'revoke  '}</b>{' '}
                                {'de-allocation from the project. So user reassigned of this project at the relevant time'}
                                .{' '}<br></br>

                              </React.Fragment>
                            }
                          >
                            <SettingsBackupRestoreOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                          </HtmlTooltip>
                        </IconButton>
                        </AppAuthorizer>
                        )
                      : props.employee.projectList[0].allocationId !== null
                        ? (
                          <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                        >
                        <IconButton
                            size='small'
                            className="deallocateButtonStyle"
                            onClick={() => {
                              addNewAllocationForEmployee()
                              props.toggleDeallocateEmployeeDialog()
                            }}
                          >
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit"><b>Deallocation</b></Typography>
                                  {'Here able to revoke the project allocation'} <b>{'partially. '}</b>{' '}
                                  {'But the user able to manage their time entries for the respective period.'}
                                  .{' '}<br></br>

                                </React.Fragment>
                              }
                            >
                              <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                            </HtmlTooltip>
                          </IconButton>
                        </AppAuthorizer>
                          )
                        : (
                          <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                        >
                          <IconButton
                            disabled={true}
                            size='small'
                            className="disabledDeallocateButtonStyle"
                            onClick={() => {
                              addNewAllocationForEmployee()
                              props.toggleDeallocateEmployeeDialog()
                            }}
                          >
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit"><b>Deallocation</b></Typography>
                                  {'Here able to revoke the project allocation'} <b>{'partially. '}</b>{' '}
                                  {'But the user able to manage their time entries for the respective period.'}
                                  .{' '}<br></br>

                                </React.Fragment>
                              }
                            >
                              <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                            </HtmlTooltip>
                          </IconButton>
                          </AppAuthorizer>
                          )}
                          </Box>
                           <Box alignItems="end">
                    <IconButton
                      size='small'
                      onClick={handleClick}
                    >
                      <Tooltip title="More">
                        <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }}

                        />
                      </Tooltip>
                    </IconButton>
                    </Box>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter:
                            'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 30,
                            ml: -0.5,
                            mr: 1
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                      }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                      }}
                    >
                      <NavLink
                        className="navLink"
                        to={`/allocation-management/${props.employee.userName}`}
                        state={{ username: props.employee.userName }}
                      >
                        <MenuItem
                          onClick={() => { props.employeeDataSession() }}
                        >

                          <Tooltip title="Profile">
                            <PermIdentityOutlinedIcon
                              className={styles.profileIconStyle}
                              sx={{ fontSize: '20px', mr: '-1' }} />
                          </Tooltip>
                          View profile

                        </MenuItem>
                      </NavLink>
                      <Divider />
                      {
                        props.employee.isEnable &&
                          props.employee.isEnable === true
                          ? <AppAuthorizer
                          activeRoleFeatures={activeUserRole.data.features}
                          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_ALLOCATION]}
                        >
                            <MenuItem
                            disabled={!props.employee.isEnable}
                            onClick={() => {
                              addNewAllocationForEmployee()
                              props.toggleDrawer(props.employee)
                            }}
                          >
                            <ListItemIcon>
                              <Tooltip title="Add Allocation">
                                <AddOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                              </Tooltip>
                            </ListItemIcon>
                            Add allocation
                          </MenuItem>
                          </AppAuthorizer>

                          : null
                      }
                      <Divider />
                      {
                        props.employee.isRequestResign &&
                          props.employee.isRequestResign === true
                          ? <MenuItem
                            onClick={() => {
                              props.setSelectedEmployee(props.employee)
                              props.toggleResignationDetailsPopup()
                            }}
                          >
                            <Tooltip title="View Resignation">
                              <RemoveRedEyeOutlinedIcon className={styles.profileIconStyle} sx={{ fontSize: '20px', mr: '-1' }} />
                            </Tooltip>
                            View resignation
                          </MenuItem>
                          : null
                      }
                    </Menu>
                  </Box>
                  )}
            </StyledTableCell>
          </StyledParentTableRow>
        </>
      )}
      {props.employee.projectList.length > 1 && (
        <StyledParentTableRow>
          <StyledTableCell colSpan={13} style={{ padding: 0, margin: 0 }}>
            <Collapse in={validateCollapse()} timeout={'auto'}>
              <Table>
                <TableBody>
                  {props.employee.projectList
                    .slice(1)
                    .map((row: AllocationDto, index: number) => (
                      <EmployeeViewTableChildRows
                        key={uuid()}
                        activeUserRole={activeUserRole}
                        employee={props.employee}
                        project={row}
                        briefProjectRoleList={props.briefProjectRoleList}
                        index={row.projectName + index}
                        selectedEmployee={props.selectedEmployee}
                        setSelectedEmployee={props.setSelectedEmployee}
                        selectedProject={props.selectedProject}
                        setSelectedProject={props.setSelectedProject}
                        isRowEditing={props.isRowEditing}
                        setIsRowEditing={props.setIsRowEditing}
                        toggleDrawer={props.toggleDrawer}
                        toggleDeallocateEmployeeDialog={
                          props.toggleDeallocateEmployeeDialog
                        }
                        toggleUpdateAllocationDialog={
                          props.toggleUpdateAllocationDialog
                        }
                        handleUpdateData={props.handleUpdateData}
                        toggleAallocationCommentPopup={props.toggleAallocationCommentPopup}
                        deallocationCustomAlert={props.deallocationCustomAlert}
                      />
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledParentTableRow>
      )}
    </>
  )
}

export default EmployeeViewTableRows
