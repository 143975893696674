
import React from 'react'
import { Grid, Button, Checkbox, FormControlLabel, FormGroup, Menu, Divider } from '@mui/material'
import styles from './EmpHistoryAndUtilizeReportTableView.module.scss'
import { ReportsCustomizedTableHeaderDto, ReportsTabDto, ReportsTableColumnDto, ReportsTableHeaderDto } from '../../../utilities/models'
import { REPORTS_TABLE_HEADERS, REPORTS_TABS } from '../../../utilities/constants'
const EmpHistoryAndUtilizationReportTableView: React.FC<{
  anchorEl: null | HTMLElement;
  openSelectMenu: boolean;
  reportsTableHeaders: ReportsCustomizedTableHeaderDto[];
  handleClick(event: React.MouseEvent<HTMLElement>): void;
  handleClose(): void;
  headerStatus: ReportsTableColumnDto;
  headerSelect(event: React.ChangeEvent<HTMLInputElement>, head: ReportsCustomizedTableHeaderDto): void;
  allColumnsSelect(event: React.ChangeEvent<HTMLInputElement>): void
  allColumnChecked(headerStatus: ReportsTableColumnDto): boolean;
  exportSelectedData(tabs: ReportsTabDto,
    columnHeaders: ReportsTableHeaderDto[],
    headerStatus: ReportsTableColumnDto,
    fileName: string): void;
  // isHistoryReport: boolean;
  isDisableExport: boolean;
}> = (props) => {
  return (
    <React.Fragment>
      <Grid item alignItems="right" display="flex" justifyContent="flex-end" >
        <Button
          variant="contained"
          color='inherit'
          className={'btnStyle'}
          onClick={props.handleClick}
        >
          Customize
        </Button>
        <Menu
          anchorEl={props.anchorEl}
          open={props.openSelectMenu}
          onClose={props.handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter:
                'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          className={styles.menuStyle}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
        >

          <FormGroup>
            <div className={styles.allSelectCheckbox}>
              <ul>
                <li>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size='small'
                        onChange={(event) => props.allColumnsSelect(event)}
                        checked={props.allColumnChecked(props.headerStatus)}
                      />
                    }
                    label={'Select All'}
                  />
                </li>
              </ul>
            </div>
          </FormGroup>
          <Divider />
          <FormGroup >
            <div className={styles.checkboxView}>
              <ul>
                {props.reportsTableHeaders.map((head: ReportsCustomizedTableHeaderDto) => (
                  // style={{ whiteSpace: 'nowrap' }}
                  <li key={head.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          size='small'
                          onChange={(event) => props.headerSelect(event, head)}
                          checked={!!props.headerStatus[head.key as keyof typeof props.headerStatus]}
                        />
                      }
                      label={head.displayName}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </FormGroup>
        </Menu>
        <Button
          variant="contained"
          className={'btnStyle'}
          disabled={props.isDisableExport}
          // onClick={() => props.exportSelectedData(REPORTS_TABS, REPORTS_TABLE_HEADERS, props.headerStatus, props.isHistoryReport === true ? 'Employee History Report' : 'Utilization Report')}
          onClick={() => props.exportSelectedData(REPORTS_TABS, REPORTS_TABLE_HEADERS, props.headerStatus, 'Employee  Report')}

        >
          Export
        </Button>
      </Grid>
    </React.Fragment>
  )
}

export default EmpHistoryAndUtilizationReportTableView
