import axios from 'axios'
import { ResignationCommentParamsDto, ResignationDetailsParamDto } from '../utilities/models'

const getResignationDetails = (params: ResignationDetailsParamDto) => {
  return axios.get('/core/api/v1/resignations', { params: params })
}
const addComment = (payload: ResignationCommentParamsDto, resignationId: number) => {
  return axios.post(`/core/api/v1/resignations/${resignationId}/comments`, payload)
}

export const resignationService = {
  getResignationDetails,
  addComment
}
