import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from './UnasssignSupervisorPopup.module.scss'

const UnassignSupervisorPopup: React.FC<{
  isUnassignSupervisorDialogOpen: boolean;
  unassignSupervisorChangeClick(): void;
  unassignSupervisorChange(): void;
  isUnassignRow: any;
}> = (props) => {
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isUnassignSupervisorDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.unassignSupervisorChangeClick()
        }
      >
        Please Confirm

      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>

        {props.isUnassignRow.supervisorStatus.value.isEnabled === true &&
          <Typography gutterBottom>
            {`Do you want to Unassign "${props.isUnassignRow.supervisorStatus.value.email}" as External Supervisor?`}
          </Typography>
        }
        {props.isUnassignRow.supervisorStatus.value.isEnabled === false &&
          <Typography gutterBottom>
            {`Do you want to Assign "${props.isUnassignRow.supervisorStatus.value.email}" as External Supervisor?`}
          </Typography>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.unassignSupervisorChange()}
          autoFocus variant="contained"
          className={'btnStyle'}
        >
          Confirm
        </Button>
        <Button onClick={() => props.unassignSupervisorChangeClick()}
          autoFocus variant="contained"
          className={'btnStyle'}
          color={'inherit'}

        >
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>

  )
}

export default UnassignSupervisorPopup
