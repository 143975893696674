import { COMMON_ACTION_TYPES, COMMENTS_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AllocationCommentParamsDto } from '../../utilities/models'
const allComments = (params : AllocationCommentParamsDto) => {
  return {
    type: COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const addComment = (payload:AllocationCommentParamsDto) => {
  return {
    type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
export const commentsAction = {
  allComments,
  addComment
}
