import { COMMON_ACTION_TYPES, DESIGNATION_ACTION_TYPES } from '../../utilities/constants'

const getDesignationList = () => {
  return {
    type: DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.REQUEST
  }
}

export const designationAction = {
  getDesignationList

}
