import React, { useState } from 'react'
import { Autocomplete, Box, Button, CircularProgress, Divider, Drawer, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Slider, Stack, TextField, Typography } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { AllocateEmployeeDto, EmployeeDto, EmployeeEntryFormDto, InitEmployeeDto, InitProjectRoleDto } from '../../../utilities/models/employeeManagement.model'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import styles from './EmployeeAllocationEntryForm.module.scss'
import { InitialSbuDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto } from '../../../utilities/models'
import { DATE_FORMAT } from '../../../utilities/constants'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
const EmployeeAllocationEntryForm: React.FC<{
  allocateEmployeeIsloading: boolean;
  createEmployeeFormData: EmployeeEntryFormDto;
  isShowHelperText: boolean;
  selectedEmployee: EmployeeDto;
  clientHierarchyProjectList: UserClientHierarchyTeamsDto[]
  designationList: Array<InitProjectRoleDto>;
  employeeList: Array<InitEmployeeDto>;
  sbuList: InitialSbuDto[];
  clientHierachy: UserClientHierarchyDto[];
  isNewAllocation: boolean
  drawerOpen: boolean;
  newAllocation: AllocateEmployeeDto
  toggleDrawerClose(): void;
  setIsNewAllocation(arg: boolean): void
  onInputHandleChange(property: string, value: any): void;
  createEmployee(): void;
  toggleDrawer(row: any): void;
  setNewAllocation(arg: AllocateEmployeeDto): void
  handleInputFocus(property: string): void;
}> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [allocationValue, setAllocationValue] = useState(100)
  const onInputHandleAllocationChange = (property: string, value: any) => {
    setAllocationValue(value)
    props.createEmployeeFormData.allocationPercentage.value = value
  }
  return (
    <Drawer
      sx={{
        width: '462px',
        overflow: 'scroll',
        zIndex: '1001',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '462px',
          boxSizing: 'border-box',
          paddingTop: '25px',
          paddingBottom: '120px',
          paddingRight: '40px',
          paddingLeft: '12px',
          overflow: 'scroll',
          zIndex: '1001'

        }
      }}
      anchor="right"
      open={props.drawerOpen}
    >
      <div className={'layout-row drawerStyle'}>
        <Typography className={styles.title}>Create New Allocation</Typography>
        <IconButton onClick={() => {
          props.toggleDrawerClose()
          props.setIsNewAllocation(false)
        }} edge="start" className={styles.iconbutton}>
          <CloseOutlined />
        </IconButton>
      </div>
      <Stack alignItems={'left'} spacing={3} className={styles.stack}>
        <Divider />
        <Grid container className={styles.container} spacing={2}>
          {/* {props.isNewAllocation
            ? */}
          <Grid item md={12}>
            <Autocomplete
              color='primary'
              size="small"
              value={props.isNewAllocation === true
                ? props.createEmployeeFormData.employeeName?.value
                : ({ id: props.selectedEmployee?.userId, name: props.selectedEmployee?.employeeName })}
              onFocus={() => props.handleInputFocus('employeeName')}
              onChange={(event, value) => props.onInputHandleChange('employeeName', value)}
              options={props.employeeList.map((emp) => {
                return { name: emp.firstName + ' ' + emp.lastName, id: emp.userId, username: emp.username }
              })}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option: any) => { return option.name || '' }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              renderInput={(params) =>
                <TextField
                  helperText={props.isShowHelperText && props.createEmployeeFormData.employeeName?.error === null ? '' : 'Employee Name is required'}
                  {...params}
                  error={!!props.createEmployeeFormData.employeeName?.error}
                  required={props.createEmployeeFormData.employeeName?.isRequired}
                  label="Employee" />}
            />
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              color="primary"
              size="small"
              // disableClearable
              value={props.createEmployeeFormData.sbu.value}
              options={props.sbuList.map((sbu) => {
                return { name: sbu.sbuName, id: sbu.sbuId }
              })}
              // disabled={props.clientHierachyIsLoading}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(event, value) =>
                props.onInputHandleChange('sbu', value)
              }
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) => (
                <TextField {...params} label="SBU" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              color="primary"
              size="small"
              // disabled={props.clientHierachyIsLoading}
              value={props.createEmployeeFormData.clientName.value}
              options={
                props.clientHierachy?.map((client: UserClientHierarchyDto) => ({
                  id: client.clientId,
                  sbuId: client.sbuId,
                  name: client.clientName,
                  teams: client.teams
                })) || []
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={uuid()}>
                    {option.name}
                  </li>
                )
              }}
              onChange={(event, value) =>
                props.onInputHandleChange('clientName', value)
              }
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) => (
                <TextField {...params} label="Client" />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              color='primary'
              size="small"
              value={props.createEmployeeFormData.projectName.value}
              options={
                props.clientHierarchyProjectList?.map((project) => ({
                  id: project.teamId,
                  name: project.teamName,
                  users: project.users
                })) || []
              }
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onFocus={() => props.handleInputFocus('projectName')}
              onChange={(event, value) => props.onInputHandleChange('projectName', value)}
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!props.createEmployeeFormData.projectName.error}
                  required={props.createEmployeeFormData.projectName.isRequired}
                  helperText={props.isShowHelperText && props.createEmployeeFormData.projectName.error === null ? '' : 'Project Name is required'}
                  label="Project" />}
            />
          </Grid>
          <Grid item md={12}>
            <Autocomplete
              color='primary'
              size="small"
              // value={props.selectedEmployee?.employeeName === '' ? props.createEmployeeFormData.projectRoleName.value : props.selectedEmployeeRole() }
              value={props.createEmployeeFormData.projectRoleName.value}
              options={props.designationList.map((desig) => ({
                id: desig.projectRoleId,
                name: `[${desig.projectRoleDisplayName}] ${desig.projectRoleName}`
              }))}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onFocus={() => props.handleInputFocus('projectRoleName')}
              onChange={(event, value) => props.onInputHandleChange('projectRoleName', value)}
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!props.createEmployeeFormData.projectRoleName.error}
                  required={props.createEmployeeFormData.projectRoleName.isRequired}
                  helperText={props.isShowHelperText && props.createEmployeeFormData.projectRoleName.error === null ? '' : 'Project Role Name is required'}
                  label="Project Role" />}
            />
          </Grid>
          <Grid item md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Allocation Start"
                views={['year', 'month', 'day']}
                // maxDate={ props.createEmployeeFormData.endDate.value ? moment(props.createEmployeeFormData.endDate.value) : undefined }
                value={props.createEmployeeFormData.startDate.value}
                inputFormat={DATE_FORMAT}
                onChange={(value) => props.onInputHandleChange('startDate', value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    color='primary'
                    size="small"
                    {...params}
                    onFocus={() => props.handleInputFocus('startDate')}
                    helperText={props.isShowHelperText && props.createEmployeeFormData.startDate.error}
                    error={!!props.createEmployeeFormData.startDate.error}
                    required={props.createEmployeeFormData.startDate.isRequired}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                label="Allocation End"
                value={props.createEmployeeFormData.endDate.value}
                minDate={props.createEmployeeFormData.startDate.value ? moment(props.createEmployeeFormData.startDate.value) : undefined}
                inputFormat={DATE_FORMAT}
                onChange={(value) => props.onInputHandleChange('endDate', value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    color='primary'
                    size="small"
                    {...params}
                    onFocus={() => props.handleInputFocus('endDate')}
                    helperText={props.isShowHelperText && props.createEmployeeFormData.endDate.error}
                    error={!!props.createEmployeeFormData.endDate.error}
                    required={props.createEmployeeFormData.endDate.isRequired}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12}>
            <FormControl>
              <RadioGroup onChange={(event, value) => props.onInputHandleChange('isBillable', value)} row defaultValue={'Billable'}>
                <FormControlLabel value="Billable" control={<Radio />} label="Billable" />
                <FormControlLabel value="Non-billable" control={<Radio />} label="Non-billable" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {props.createEmployeeFormData.isBillable.value === 'Billable'
            ? <>
              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={['year', 'month', 'day']}
                    InputProps={{ readOnly: false }}
                    label="Billing Start"
                    disabled={props.createEmployeeFormData.startDate.value === null ||
                      props.createEmployeeFormData.endDate.value === null
                    }
                    minDate={moment(props.createEmployeeFormData.startDate.value)}
                    maxDate={moment(props.createEmployeeFormData.endDate.value)}
                    value={props.createEmployeeFormData.billingStartDate?.value}
                    inputFormat={DATE_FORMAT}
                    onChange={(value) => props.onInputHandleChange('billingStartDate', value)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        color='primary'
                        size="small"
                        {...params}
                        onFocus={() => props.handleInputFocus('billingStartDate')}
                        helperText={props.isShowHelperText && props.createEmployeeFormData.billingStartDate?.error}
                        error={!!props.createEmployeeFormData.billingStartDate?.error}
                        required={props.createEmployeeFormData.billingStartDate?.isRequired}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={['year', 'month', 'day']}
                    disabled={props.createEmployeeFormData.startDate.value === null ||
                      props.createEmployeeFormData.endDate.value === null
                    }
                    label="Billing End"
                    minDate={moment(props.createEmployeeFormData.startDate.value)}
                    maxDate={moment(props.createEmployeeFormData.endDate.value)}
                    value={props.createEmployeeFormData.billingEndDate?.value}
                    inputFormat={DATE_FORMAT}
                    onChange={(value) => props.onInputHandleChange('billingEndDate', value)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        color='primary'
                        size="small"
                        {...params}
                        onFocus={() => props.handleInputFocus('billingEndDate')}
                        helperText={props.isShowHelperText && props.createEmployeeFormData.billingEndDate?.error}
                        error={!!props.createEmployeeFormData.billingEndDate?.error}
                        required={props.createEmployeeFormData.billingEndDate?.isRequired}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </>
            : null}
            <Grid item md={9}>
              <FormLabel>Allocation Percentage</FormLabel>
              <Box className={styles.box}>
                <Slider
                  // getAriaValueText={valuetext}
                  // defaultValue={100}
                  valueLabelDisplay="auto"
                  step={5}
                  marks
                  min={0}
                  max={100}
                   value={props.createEmployeeFormData.allocationPercentage.value}
                  className={styles.slider}
                  onChange={(event, value) => onInputHandleAllocationChange('allocationPercentage', value)}
                />
                <Typography
                  className={styles.percentage}
                  >
                  {props.createEmployeeFormData.allocationPercentage.value}%
                </Typography>
                </Box>
                </Grid>
                <Grid item md={3}>
                <br></br>
                <TextField variant="outlined"
                className={styles.numberText}
                  style={{ paddingLeft: '10px', paddingTop: '3px' }}
                  required={true}
                  type={'number'}
                  size={'small'}
                  helperText={props.isShowHelperText && props.createEmployeeFormData.allocationPercentage.error}
                  error={!!props.createEmployeeFormData.allocationPercentage.error}
                  value={props.createEmployeeFormData.allocationPercentage.value}
                  inputProps={{
                    inputMode: 'numeric',
                    max: 100,
                    min: 0

                  }}
                  onFocus={() => props.handleInputFocus('allocationPercentage')}
                  onChange={(e) => onInputHandleAllocationChange('allocationPercentage', e.target.value)}
                ></TextField>
              {/* </Box> */}
              </Grid>

          <Grid item md={12}>
            <Button className={styles.drawerbutton}
              type='submit'
              size='small'
              variant='contained'
              onClick={props.createEmployee}>
              {props.allocateEmployeeIsloading && (
                <CircularProgress size="13px" className={styles.loading} />
              )}
              Allocate</Button>
          </Grid>
        </Grid>
      </Stack>
    </Drawer>
  )
}

export default EmployeeAllocationEntryForm
