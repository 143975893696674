import { PROJECT_ACTION_TYPES, COMMON_ACTION_TYPES }
  from '../../utilities/constants/action.constants'
import { CreateProjectParamDto, GetProjectListParamDto, UpdateProjectParamDto } from '../../utilities/models/projectManagement.model'

const allProjectList = (params : GetProjectListParamDto) => {
  return {
    type: PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
// const clientList = (params: GetClientListBriefParamDto) => {
//   return {
//     type: PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST,
//     params: params

//   }
// }
const createProject = (payload: CreateProjectParamDto) => {
  return {
    type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const updateProject = (payload: UpdateProjectParamDto) => {
  return {
    type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

export const projectAction = {
  allProjectList,
  createProject,
  updateProject
  // clientList

}
