import axios from 'axios'
import { ConfirmProposedResourcesDto, CreateResourcesRequestDto, ProposedResourcesActionDto, ProposeRequestPayloadDto, ResourceActionDto, ResourceCommentParamsDto, UpdateRequestDto, UpdateResourcesDto, ViewResourcesRequestParams } from '../utilities/models/resourceRequest.model'

const createResource = (payload: CreateResourcesRequestDto) => {
  return axios.post('/hera/api/v1/resource-requests', payload)
}

const getResourceRequests = (params: ViewResourcesRequestParams) => {
  return axios.get('/hera/api/v1/resource-requests', { params: params })
}
const updateResources = (payload: UpdateResourcesDto, requestId: number, resourceId: number) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}`, payload)
}
const updateRequest = (payload: UpdateRequestDto, requestId: number) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}`, payload)
}

const CreateResourceComment = (payload: ResourceCommentParamsDto, requestId: number, resourceId: number) => {
  return axios.post(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}/comments`, payload)
}
const getComments = (params: ResourceCommentParamsDto) => {
  return axios.get(`/hera/api/v1/resource-requests/${params.requestId}/resources/${params.resourceId}/comments`)
}

const resourceAction = (payload: ResourceActionDto, requestId: number) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}/status`, payload)
}

const createProposeRequest = (payload: ProposeRequestPayloadDto, requestId: number, resourceId: number) => {
  return axios.post(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}/proposes`, payload)
}
const getProposedResources = (requestId: number) => {
  return axios.get(`/hera/api/v1/resource-requests/${requestId}/resources`)
}

const deleteProposedResource = (requestId: number, resourceId: number, proposeId: number) => {
  return axios.delete(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}/proposes/${proposeId}/`)
}

const confirmProposedResources = (requestId: number, resourceId: number, payload: ConfirmProposedResourcesDto[]) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}/proposeConfirm/`, payload)
}

const proposedResourcesAction = (requestId: number, resourceId: number, proposeId: number, payload: ProposedResourcesActionDto) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}/resources/${resourceId}/proposes/${proposeId}/status`, payload)
}

const closeRequest = (requestId: number, payload: ProposedResourcesActionDto) => {
  return axios.put(`/hera/api/v1/resource-requests/${requestId}/close`, payload)
}

const getStatusList = () => {
  return axios.get('/core/api/v1/brief/rmStatus')
}

export const resourceService = {
  createResource,
  getResourceRequests,
  updateResources,
  updateRequest,
  CreateResourceComment,
  getComments,
  resourceAction,
  createProposeRequest,
  getProposedResources,
  deleteProposedResource,
  confirmProposedResources,
  proposedResourcesAction,
  closeRequest,
  getStatusList
}
