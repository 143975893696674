// import ENV from '../../.env/index'

export const APP_CONFIGS = {
  APP_OWNER: 'Acentura Inc',
  APP_ENV: process.env.REACT_APP_ENV,
  API_BASE: process.env.REACT_APP_API_BASE,
  // AD Authentication
  AUTHORITY: process.env.REACT_APP_AUTHORITY,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  POS_REDIRECT_URL: process.env.REACT_APP_POST_REDIRECT_URL,
  APP_SCOPES: process.env.REACT_APP_SCOPES,
  // APP Client Urls
  ATHENA_CLIENT: process.env.REACT_APP_ATHENA_CLIENT,
  HERMES_CLIENT: process.env.REACT_APP_HERMES_CLIENT,
  HERA_CLIENT: process.env.REACT_APP_HERA_CLIENT,
  DASHBOARD_CLIENT: process.env.REACT_APP_DASHBOARD_CLIENT,

  // User CookieADLOlympus
  USER_INFO_COOKIE: 'OlympusHeraUserInfoCookie',
  USER_AUTHORIZED_ROLES: 'OlympusHeraUserAuthorizedRoles',
  USER_ACTIVE_ROLE: 'OlympusHeraUserActiveRole',
  // APP ID
  HERA_APPLICATION_ID: 'b09dee3f-3b60-439e-804b-880bb94acd02',
  ATHENA_APPLICATION_ID: '0fd4787f-55a8-40c2-9a39-de37b2ea8239',
  DATA_ENC_SECRET: 'b27aaef8e3a57d858c50956465405f89',
  APPLICATION_ID: ''
}
