
import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, COMMENTS_ACTION_TYPES } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  allComments: {
    isLoading: false,
    data: []
  },
  addComment: {
    isLoading: false,
    data: [],
    status: null
  }
}
const commentsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allComments: {
          ...state.allComments,
          isLoading: true
        }
      }
    case COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allComments: {
          ...state.allComments,
          isLoading: false,
          data: action.data
        }
      }
    case COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allComments: {
          ...state.allComments,
          isLoading: false,
          data: []
        }
      }
    case COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    default:
      return state
  }
}
export default commentsReducer
