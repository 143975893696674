
import { Typography } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { DashboardUtilizationDto } from '../../../utilities/models'
import styles from './ResourceUtilization.module.scss'
const ResourceUtilization: React.FC<{
  trackNames: string[]
  trackwiseEmpCount: number[]
  trackWiseCountData: DashboardUtilizationDto
}> = (props) => {
  const barChartData: ApexOptions = {
    chart: {
      id: 'apexchart-example',
      foreColor: 'black',
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          download: false
        }
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    noData: {
      text: (props.trackWiseCountData.isLoading && 'Loading Data...') ||
        (!props.trackWiseCountData.isLoading && props.trackWiseCountData.data?.length === 0
          ? 'No data to preview'
          : ''),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#6b6b6b',
        fontSize: '20px',
        fontFamily: 'Ubuntu,sans-serif'
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      },
      show: false,
      row: {
        colors: ['transparent'],
        opacity: 1
      }
    },
    xaxis: {
      title: {
        text: '',
        style: {
          fontSize: '14px',
          fontFamily: 'Ubuntu,sans-serif'
        }
      },
      categories: [...props.trackNames, 'Others']
    },
    yaxis: {
      labels: {
        show: true
      },
      title: {
        text: ''
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      offsetX: 40
    },
    series: [{
      name: 'Employees',
      data: props.trackwiseEmpCount
    }],
    colors: ['#00CA8D', '#ed908a']
    // colors: ['#D2691E', '#808000']

  }
  return (
    <React.Fragment>
      {props.trackWiseCountData.isLoading &&
        <Typography className={styles.loadingStyle}>
          Loading Data...
        </Typography>

      }
      {
        (!props.trackWiseCountData.isLoading && props.trackWiseCountData?.data.length === 0) &&
        <Typography className={styles.loadingStyle}>
          No data to preview
        </Typography>
      }
       {
        (!props.trackWiseCountData.isLoading && props.trackWiseCountData?.data.length > 0) &&
        <ReactApexChart
        id="apexchart-example"
        options={barChartData}
        series={barChartData.series}
        type="bar"
        height={300}
      />
      }

    </React.Fragment>
  )
}

export default ResourceUtilization
