import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import styles from './SupervisorViewTable.module.scss'
import {
  Grid,
  Table,
  TableHead,
  TextField,
  TableBody,
  TableContainer,
  CircularProgress,
  Switch,
  styled,
  Autocomplete,
  InputAdornment
} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { InitProjectDto, InitSupervisorsDto } from '../../../utilities/models'
import { AppAuthorizer } from '../../shared'
import { APP_FEATURE_KEYS } from '../../../utilities/constants'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#fe5728'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}))
const SupervisorViewTable: React.FC<{
  defaultProjectsList: Array<InitProjectDto>;
  supervisorsList: Array<InitSupervisorsDto>;
  page: number;
  rowsPerPage: number;
  supervisorListIsloding: boolean;
  searchText: any;
  searchFormData: any
  activeUserRole: any
  // searchSupervisor(event: React.ChangeEvent<HTMLInputElement>): void;
  searchSupervisors(event: any): void;
  onRowStatusChangeTrigger(arg: any): void;
  onInputSearchChange(property: string, value: any): void;

}> = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={3} lg={3} className="sectionTitleHolder">
        <h3>Manage External Supervisor</h3>
        <p>
          Assign/Unassign an External Supervisor from a Project
          <br />
        </p>
        <br />
        {/* <TextField
          size="small"
          fullWidth
          variant="outlined"
          color="primary"
          value={props.searchText}
          placeholder="Search Project/Supervisor"
          onChange={props.searchSupervisor}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            )
          }}
        /> */}
        <Autocomplete
          size="small"
          disablePortal
          disableClearable
          freeSolo
          onChange={(event, value) => props.onInputSearchChange('searchsupervisor', value)}
          value={props.searchFormData.value}
          noOptionsText={''}
          options={props.defaultProjectsList.map((project) => {
            return { name: project.teamName, id: project.teamId }
          })}
          // options={props.defaultProjectsList}
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <li {...props}>{option.name}</li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Project/Supervisor"
              onChange={props.searchSupervisors}
              value={props.searchText}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon fontSize='small' className={styles.icon} />
                  </InputAdornment>
                )
              }}
              // label="Search Project/Supervisor"
              variant="outlined"
            />
          )}
        // onInputChange= {(event, value) => props.searchSupervisors(event, value)}
        //  onChange={(event, value) => props.searchSupervisors(event, value)}
        />
      </Grid>
      <Grid item xs={12} md={9} lg={9}>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left" width={100}>
                  Project
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  External Supervisor
                </StyledTableCell>
                <StyledTableCell align="left" width={100}>
                  Action
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!props.supervisorListIsloding &&
                props.supervisorsList.length > 0 &&
                (props.rowsPerPage > 0
                  ? props.supervisorsList.slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  : props.supervisorsList
                ).map((row: InitSupervisorsDto, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left" width={100}>
                      {row.teamName}
                    </StyledTableCell>
                    <StyledTableCell align="left" width={100}>
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell align="left" width={100}>
                      <AppAuthorizer
                        activeRoleFeatures={props.activeUserRole.data.features}
                        authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_EXTERNAL_SUPERVISOR]}
                      >
                        <AntSwitch
                          checked={row.isEnabled}
                          inputProps={{ 'aria-label': 'ant design' }}
                          onChange={() => props.onRowStatusChangeTrigger(row)}
                        />
                      </AppAuthorizer>

                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {props.supervisorListIsloding && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    <CircularProgress
                      className={styles.spinnerAlign}
                      color="primary"
                      size={20}
                    />{' '}
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {!props.supervisorListIsloding &&
                props.supervisorsList.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7} align="left">
                      No Records Available.
                    </StyledTableCell>
                  </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {props.children}
      </Grid>
    </Grid>
  )
}

export default SupervisorViewTable
