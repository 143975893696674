import { Alert, Divider, Grid, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EmpHistoryAndUtilizeReportForm,
  EmpHistoryAndUtilizeReportTable,
  EmpHistoryAndUtilizeReportTableView, AppTablePagination
} from '../../components'
import {
  alertActions,
  clientAction,
  designationAction,
  reportAction,
  sbuAction,
  userActions
} from '../../redux/actions'
import { AppLayout } from '../../templates'
import { ALLOCATION_TYPES, APP_TABLE_CONFIGS, BREAD_CRUB, DATE_FORMAT, REPORTS_CUSTOMIZED_TABLE_HEADERS, TABLE_HEADERS } from '../../utilities/constants'
import { createTableColumn } from '../../utilities/helpers'
import {
  AlertDto, AllClientHierachyDto, AllocationTypeDto, GetClientHierachyParamDto, GetDisabledSbusParamDto, GetReportParamDto,
  GetUserListBriefParamsDto,
  InitBriefEmployeeDto, InitBriefProjectDto, InitBriefProjectRoleDto, InitialClientDto,
  ReportsCustomizedTableHeaderDto, ReportsDataDto, ReportsFilterFormDto,
  ReportsTabDto, ReportsTableColumnDto, ReportsTableHeaderDto, sbuBriefDto, UserClientHierarchyDto,
  UserClientHierarchyTeamsDto, UserClientHierarchyUserDto
} from '../../utilities/models'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'
import * as Excel from 'exceljs'
import FileSaver from 'file-saver'
const AllocationDetailReport: React.FC = () => {
  const INITIAL_FILTER_STATE: ReportsFilterFormDto = {
    sbu: {
      value: {} as sbuBriefDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    employeeName: {
      value: {} as InitBriefEmployeeDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    clientName: {
      value: {} as InitialClientDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    projectName: {
      value: {} as InitBriefProjectDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    designation: {
      value: {} as InitBriefProjectRoleDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    projectRoleName: {
      value: {} as InitBriefProjectRoleDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    isBillable: {
      value: '',
      validator: 'string',
      isRequired: false,
      error: null,
      disable: false
    },
    allocationType: {
      value: {} as AllocationTypeDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    projectStart: { value: null, validator: 'date', isRequired: false, error: null, disable: false },
    projectEnd: { value: null, validator: 'date', isRequired: false, error: null, disable: false }
  }
  const dispatch = useDispatch()
  const userListBrief = useSelector((state: any) => state.user.userListBrief.data)
  const designationList = useSelector(
    (state: any) => state.designation.designationList.data
  )
  const reports = useSelector(
    (state: any) => state.report.reportDataList.data
  )
  const reportsIsloading = useSelector(
    (state: any) => state.report.reportDataList.isLoading
  )
  const clientHierachy = useSelector(
    (state: any) => state.client.clientHierachy.data
  )
  const clientHierachyIsloading = useSelector(
    (state: any) => state.client.clientHierachy.isLoading
  )
  const reportAlert: AlertDto = useSelector((state: any) => state.alerts.filterReports)
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)
  // const [allEntitledSbuList, setAllEntitledSbuList] = useState<InitSbuDto[]>([])
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<
    UserClientHierarchyDto[]
  >([])
  const [clientHierarchyUsersList, setClientHierarchyUsersList] = useState<
    UserClientHierarchyUserDto[]
  >([])
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<
    UserClientHierarchyTeamsDto[]
  >([])
  const [reportsTableHeaders, setReportsTableHeaders] = useState<ReportsCustomizedTableHeaderDto[]>([])
  const [reportFilterFormData, setReportFilterFormData] = useState<ReportsFilterFormDto>(INITIAL_FILTER_STATE)
  const [headerStatus, setHeaderStatus] = useState<ReportsTableColumnDto>(createTableColumn(true))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [reportTitle, setReportTitle] = useState<string>('Employee Utilization Report')
  const [isHistoricalData, setIsHistoricalData] = useState<boolean>(false)
  const [isDisableExport, setIsDisableExport] = useState<boolean>(true)
  const [allocationList, setAllocationList] = useState<ReportsDataDto[]>([])
  const [isAdvancedFilter, setIsAdvancedFilter] = useState(false)

  const openSelectMenu = Boolean(anchorEl)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  React.useEffect(() => {
    setReportsTableHeaders(REPORTS_CUSTOMIZED_TABLE_HEADERS)
    getAllUserList()
    getAllDesignationList()
    getSBUList()
    getClientHierachy()

    return () => {
      dispatch(reportAction.clearAllocationListForReport())
    }
  }, [])
  React.useEffect(() => {
    // devide  client , project, users from client hierachy api
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    //   setAllEntitledSbuList(filterByClientBy.sbus)
    setClientHierarchyProjectList(filterByClientBy.teams)
    setClientHierarchyUsersList(filterByClientBy.users)
  }, [clientHierachy])
  React.useEffect(() => {
    filterByAllocationList()
  }, [reports])
  const getClientHierachy = () => {
    const clientHeirachyParams: GetClientHierachyParamDto = {
      getDisabled: true
    }
    dispatch(clientAction.getAllClientHierchy(clientHeirachyParams))
  }
  const getAllUserList = () => {
    const userListBrief: GetUserListBriefParamsDto = {
      getAll: true,
      getDisabled: true
    }
    dispatch(userActions.getUserListBrief(userListBrief))
  }
  const getAllDesignationList = () => {
    dispatch(designationAction.getDesignationList())
  }
  const getSBUList = () => {
    const sbuListParams: GetDisabledSbusParamDto = {
      getDisabled: 'true'
    }
    dispatch(sbuAction.allSbuList(sbuListParams))
  }
  const isAdvancedFilterOnclick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsAdvancedFilter(true)
    } else {
      setIsAdvancedFilter(false)
      setReportFilterFormData({
        ...reportFilterFormData,
        projectStart: {
          ...reportFilterFormData['projectStart' as keyof typeof reportFilterFormData],
          value: null
        },
        projectEnd: {
          ...reportFilterFormData['projectEnd' as keyof typeof reportFilterFormData],
          value: null
        },
        designation: {
          ...reportFilterFormData['designation' as keyof typeof reportFilterFormData],
          value: {} as InitBriefProjectRoleDto
        },

        allocationType: {
          ...reportFilterFormData['allocationType' as keyof typeof reportFilterFormData],
          value: {} as AllocationTypeDto

        }
      })
    }
  }
  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    // const entiledSbuList: InitSbuDto[] = []
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.length > 0) {
      for (const client of clientHierachy) {
        // allSbuList?.data?.map((sbu: InitSbuDto) => sbu.sbuId === client.sbuId &&
        //  !entiledSbuList.some((x) => x.sbuId === sbu.sbuId) &&
        // entiledSbuList.push(sbu))
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some(
              (i) => i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some(
                  (i) => i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    // clientHierachyObject.sbus = entiledSbuList
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const filterByAllocationList = (): ReportsDataDto[] => {
    const allocationList: ReportsDataDto[] = []
    if (reports.data?.length > 0) {
      for (const employee of reports.data) {
        if (employee.projectList.length > 0) {
          for (const project of employee.projectList) {
            allocationList.push({
              empId: employee.empId,
              empName: employee.employeeName,
              email: employee.userName,
              designation: employee.designation,
              joinDate: moment(employee.joinDate).format(DATE_FORMAT) === 'Invalid date' ||
                employee.joinDate === null
                ? ''
                : new Date(moment(employee.joinDate).format(DATE_FORMAT)),
              sbu: project.sbu,
              client: project.client,
              project: project.projectName,
              billing: project.projectStartDate !== null ? (project.isBillable === true ? 'Billable' : 'Non-billable') : '',
              role: project.projectRoleName,
              billingStart: project.isBillable === false
                ? 'N/A'
                : moment(project.billingStartDate).format(DATE_FORMAT) === 'Invalid date' ? '' : new Date(moment(project.billingStartDate).format(DATE_FORMAT)),
              billingEnd: project.isBillable === false
                ? 'N/A'
                : moment(project.billingEndDate).format(DATE_FORMAT) === 'Invalid date'
                  ? ''
                  : new Date(moment(project.billingEndDate).format(DATE_FORMAT)),
              allocation: (Number(project.allocationPercentage) / 100),
              startDate: project.projectStartDate === null ||
                moment(project.projectStartDate).format(DATE_FORMAT) === 'Invalid date'
                ? ''
                : new Date(moment(project.projectStartDate).format(DATE_FORMAT)),
              endDate: project.projectEndDate === null ||
                moment(project.projectEndDate).format(DATE_FORMAT) === 'Invalid date'
                ? ''
                : new Date(moment(project.projectEndDate).format(DATE_FORMAT)),
              projectRoleDisplayName: project.projectRoleDisplayName,
              designationDisplayName: employee.designationDisplayName,
              projectStatus: project.teamIsEnabled !== null ? (project.teamIsEnabled === true ? 'Enable' : 'Disable') : '',
              comments: ''
            })
          }
        }
        if (employee.projectList.length === 0) {
          allocationList.push({
            empId: employee.empId,
            empName: employee.employeeName,
            email: employee.userName,
            designation: employee.designation,
            joinDate: moment(employee.joinDate).format(DATE_FORMAT) === 'Invalid date' ||
              employee.joinDate === null
              ? ''
              : new Date(moment(employee.joinDate).format(DATE_FORMAT)),
            sbu: '',
            client: '',
            project: '',
            billing: '',
            role: '',
            billingStart: '',
            billingEnd: '',
            allocation: 0,
            startDate: '',
            endDate: '',
            projectRoleDisplayName: '',
            designationDisplayName: employee.designationDisplayName,
            projectStatus: '',
            comments: ''
          })
        }
      }
    }
    setAllocationList(allocationList)
    return allocationList
  }

  const getAllocationListTrigger = () => {
    getFilteredAllocationList()
    // if (isHistoryReport) {
    //   if (Object.keys(reportFilterFormData.employeeName.value).length === 0) {
    //     setReportFilterFormData({
    //       ...reportFilterFormData,
    //       employeeName: {
    //         ...reportFilterFormData[
    //         'employeeName' as keyof typeof reportFilterFormData
    //         ],
    //         error: 'This field is required',
    //         disable: false,
    //         validator: 'object',
    //         isRequired: true,
    //         value: {} as InitBriefEmployeeDto
    //       }
    //     })
    //   } else {
    //     getFilteredAllocationList()
    //   }
    // } else {
    //   getFilteredAllocationList()
    // }
  }
  // get allocations
  const getFilteredAllocationList = () => {
    const reportParams: GetReportParamDto = {
      username: reportFilterFormData.employeeName.value.username,
      projectId: reportFilterFormData.projectName.value.id,
      designationId: reportFilterFormData.designation.value.id,
      projectRoleId: reportFilterFormData.projectRoleName.value.id,
      clientId: reportFilterFormData.clientName.value.id,
      sbuId: reportFilterFormData.sbu.value.id,
      isBillable: (reportFilterFormData.isBillable.value === 'Billable' ? true : undefined) ||
          (reportFilterFormData.isBillable.value === 'Non-billable' ? false : undefined),
      type: reportFilterFormData.allocationType.value.name,
      getDeallocatedTeams: isHistoricalData,
      requestStartDate: moment(reportFilterFormData.projectStart.value).format(DATE_FORMAT) !== 'Invalid date'
        ? moment(reportFilterFormData.projectStart.value).format(DATE_FORMAT)
        : undefined,
      requestEndDate: moment(reportFilterFormData.projectEnd.value).format(DATE_FORMAT) !== 'Invalid date'
        ? moment(reportFilterFormData.projectEnd.value).format(DATE_FORMAT)
        : undefined

    }
    if (reportFilterFormData.projectStart.value !== null &&
     moment(reportFilterFormData.projectStart.value).format(DATE_FORMAT) === 'Invalid date') {
      // setCustomAlert('Please enter correct project start date')
      setReportFilterFormData({
        ...reportFilterFormData,
        projectStart: {
          ...reportFilterFormData[
          'projectStart' as keyof typeof reportFilterFormData
          ],
          value: reportFilterFormData.projectStart.value,
          error: 'Invalid date',
          disable: false,
          validator: 'date'
        }
      })
    } else if (reportFilterFormData.projectEnd.value !== null &&
      moment(reportFilterFormData.projectEnd.value).format(DATE_FORMAT) === 'Invalid date') {
      // setCustomAlert('Please enter correct project end date')
      setReportFilterFormData({
        ...reportFilterFormData,
        projectEnd: {
          ...reportFilterFormData[
          'projectEnd' as keyof typeof reportFilterFormData
          ],
          value: reportFilterFormData.projectEnd.value,
          error: 'Invalid date',
          disable: false,
          validator: 'date'
        }
      })
    } else if ((reportFilterFormData.projectStart.value !== null &&
      reportFilterFormData.projectEnd.value === null) ||
      (reportFilterFormData.projectEnd.value !== null &&
        reportFilterFormData.projectStart.value === null)
    ) {
      setCustomAlert('Both allocation start and end date is required')
    } else if (reportFilterFormData.projectStart.value !== null &&
      reportFilterFormData.projectEnd.value !== null &&
      reportFilterFormData.projectEnd.value < reportFilterFormData.projectStart.value) {
      setCustomAlert('Allocation end date should be greater than start date ')
    } else {
      if (isHistoricalData) {
        if (Object.keys(reportFilterFormData.employeeName.value).length > 0 ||
      Object.keys(reportFilterFormData.projectName.value).length > 0 ||
      Object.keys(reportFilterFormData.designation.value).length > 0 ||
      Object.keys(reportFilterFormData.projectRoleName.value).length > 0 ||
      Object.keys(reportFilterFormData.clientName.value).length > 0 ||
      Object.keys(reportFilterFormData.sbu.value).length > 0 ||
      Object.keys(reportFilterFormData.allocationType.value).length > 0 ||
      reportFilterFormData.isBillable.value !== '' ||
      reportFilterFormData.projectStart.value !== null ||
      reportFilterFormData.projectEnd.value !== null
        ) {
          dispatch(reportAction.getAllocationListForReport(reportParams))
          setIsDisableExport(false)
        } else {
          const alert: AlertDto = {
            message: 'Please select atleast one filter criteria to view allocation detail report with history',
            severity: 'warning'

          }
          dispatch(alertActions.setReportAlertRequest(alert))
          // setCustomAlert('Please select atleast one filter criteria to view allocation detail report with history')
        }
      } else {
        dispatch(reportAction.getAllocationListForReport(reportParams))
        setIsDisableExport(false)
      }
    }
  }
  // custom alert
  const setCustomAlert = (message: string) => {
    const alert: AlertDto = {
      message: message,
      severity: 'error'

    }
    dispatch(alertActions.setReportAlertRequest(alert))
  }
  // Handle form input
  const onFilterHandleChange = (property: string, value: any) => {
    switch (property) {
      case 'sbu':
        if (value) {
          const filteredClientList = clientHierachy?.filter(
            (client: UserClientHierarchyDto) => client.sbuId === value.id
          )
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          const userList: UserClientHierarchyUserDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some(
                    (i) => i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                  if (team.users && team.users.length) {
                    for (const user of team.users) {
                      const isIncluded = userList.some(
                        (i) => i.userId === user.userId
                      )
                      if (!isIncluded && user.userId !== null) {
                        userList.push({
                          userId: user.userId,
                          username: user.username,
                          firstName: user.firstName,
                          lastName: user.lastName
                        })
                      }
                    }
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          setClientHierarchyUsersList(userList)
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              sbu: {
                ...reportFilterFormData[
                'sbu' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              sbu: {
                ...reportFilterFormData[
                'sbu' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }
        } else {
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              sbu: {
                ...reportFilterFormData[
                'sbu' as keyof typeof reportFilterFormData
                ],
                value: {} as sbuBriefDto,
                error: null
              },
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto,
                error: null
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              sbu: {
                ...reportFilterFormData[
                'sbu' as keyof typeof reportFilterFormData
                ],
                value: {} as sbuBriefDto,
                error: null
              },
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto,
                error: null
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          setClientHierarchyUsersList(filterByClientBy.users)
        }
        break
      case 'clientName':
        if (value) {
          setClientHierarchyProjectList(value.teams)
          const userList: UserClientHierarchyUserDto[] = []
          if (value.teams.length > 0) {
            for (const team of value.teams) {
              if (team.users && team.users.length) {
                for (const user of team.users) {
                  const isIncluded = userList.some(
                    (i) => i.userId === user.userId
                  )
                  if (!isIncluded && user.userId !== null) {
                    userList.push({
                      userId: user.userId,
                      username: user.username,
                      firstName: user.firstName,
                      lastName: user.lastName
                    })
                  }
                }
              }
            }
          }
          setClientHierarchyUsersList(userList)
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }
        } else {
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto,
                error: null
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto,
                error: null
              },
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }
          if (Object.keys(reportFilterFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === reportFilterFormData.sbu.value.id)
            setClientHierarchyclientList(filteredClientList)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
            setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      case 'projectName':
        if (value) {
          setClientHierarchyUsersList(value.users)
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: value
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }
        } else {
          if (Object.keys(reportFilterFormData.employeeName.value).length > 0) {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              },
              designation: {
                ...reportFilterFormData[
                'designation' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }

            })
          } else {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              },
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          }

          if (Object.keys(reportFilterFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === reportFilterFormData.clientName.value.id)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      case 'employeeName':
        if (value) {
          setReportFilterFormData({
            ...reportFilterFormData,
            employeeName: {
              ...reportFilterFormData[
              'employeeName' as keyof typeof reportFilterFormData
              ],
              value: value,
              error: null
            },
            designation: {
              ...reportFilterFormData[
              'designation' as keyof typeof reportFilterFormData
              ],
              value: designationList?.data.find((role: any) => role.projectRoleName === userListBrief?.data.find((emp: any) => emp.userId === value.id)?.designation)?.projectRoleId != null
                ? {
                  id: designationList?.data.find((role: any) => role.projectRoleName === userListBrief?.data.find((emp: any) => emp.userId === value.id)?.designation)?.projectRoleId ?? null,
                  name: designationList?.data.find((role: any) => role.projectRoleName === userListBrief?.data.find((emp: any) => emp.userId === value.id)?.designation)?.projectRoleDisplayName

                } as InitBriefProjectRoleDto
                : {} as InitBriefProjectRoleDto,
              error: null,
              disable: designationList?.data.find((role: any) => role.projectRoleName === userListBrief?.data.find((emp: any) => emp.userId === value.id)?.designation)?.projectRoleId != null
            }
          })
        } else {
          setReportFilterFormData({
            ...reportFilterFormData,
            employeeName: {
              ...reportFilterFormData[
              'employeeName' as keyof typeof reportFilterFormData
              ],
              value: {} as InitBriefEmployeeDto,
              error: null
            },
            designation: {
              ...reportFilterFormData[
              'designation' as keyof typeof reportFilterFormData
              ],
              value: {} as InitBriefProjectRoleDto,
              error: null,
              disable: false
            }
          })
          if (Object.keys(reportFilterFormData.projectName.value).length > 0) {
            const filteredProjectList = clientHierarchyProjectList.filter((project: UserClientHierarchyTeamsDto) => project.teamId === reportFilterFormData.projectName.value.id)
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredProjectList.length > 0) {
              for (const team of filteredProjectList) {
                if (team.users && team.users.length) {
                  for (const user of team.users) {
                    const isIncluded = userList.some(
                      (i) => i.userId === user.userId
                    )
                    if (!isIncluded && user.userId !== null) {
                      userList.push({
                        userId: user.userId,
                        username: user.username,
                        firstName: user.firstName,
                        lastName: user.lastName
                      })
                    }
                  }
                }
              }
            }
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      case 'designation':
        if (value) {
          setReportFilterFormData({
            ...reportFilterFormData,
            designation: {
              ...reportFilterFormData[
              'designation' as keyof typeof reportFilterFormData
              ],
              value: value,
              error: null
            }
          })
          // if (isHistoryReport === true) {
          //   const filteredUserListByDesig = userList?.data.filter((emp: any) => emp.designationId === value.id)

          //   setClientHierarchyUsersList(filteredUserListByDesig)
          // }
        } else {
          setReportFilterFormData({
            ...reportFilterFormData,
            designation: {
              ...reportFilterFormData[
              'designation' as keyof typeof reportFilterFormData
              ],
              value: {} as InitBriefProjectRoleDto,
              error: null,
              disable: false
            }
          })
          // if (isHistoryReport === true) {
          //   const filterByClientBy = filterByClientHirachy()
          //   setClientHierarchyUsersList(filterByClientBy.users)
          // }
        }
        break
      default:
        if (value) {
          setReportFilterFormData({
            ...reportFilterFormData,
            [property]: {
              ...reportFilterFormData[
              property as keyof typeof reportFilterFormData
              ],
              value: value,
              error: null
            }
          })
        } else {
          if (property === 'employeeName') {
            setReportFilterFormData({
              ...reportFilterFormData,
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          } else if (property === 'projectName') {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectName: {
                ...reportFilterFormData[
                'projectName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectDto,
                error: null
              }
            })
          } else if (property === 'projectRoleName') {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectRoleName: {
                ...reportFilterFormData[
                'projectRoleName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefProjectRoleDto,
                error: null
              }
            })
          } else if (property === 'isBillable') {
            setReportFilterFormData({
              ...reportFilterFormData,
              isBillable: {
                ...reportFilterFormData[
                'isBillable' as keyof typeof reportFilterFormData
                ],
                value: ''
              }
            })
          } else if (property === 'clientName') {
            setReportFilterFormData({
              ...reportFilterFormData,
              clientName: {
                ...reportFilterFormData[
                'clientName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitialClientDto
              }
            })
            setReportFilterFormData({
              ...reportFilterFormData,
              employeeName: {
                ...reportFilterFormData[
                'employeeName' as keyof typeof reportFilterFormData
                ],
                value: {} as InitBriefEmployeeDto,
                error: null
              }
            })
          } else if (property === 'allocationType') {
            setReportFilterFormData({
              ...reportFilterFormData,
              allocationType: {
                ...reportFilterFormData[
                'allocationType' as keyof typeof reportFilterFormData
                ],
                value: {} as AllocationTypeDto
              }
            })
          } else if (property === 'projectStart') {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectStart: {
                ...reportFilterFormData[
                'projectStart' as keyof typeof reportFilterFormData
                ],
                value: null,
                error: null
              }
            })
          } else if (property === 'projectEnd') {
            setReportFilterFormData({
              ...reportFilterFormData,
              projectEnd: {
                ...reportFilterFormData[
                'projectEnd' as keyof typeof reportFilterFormData
                ],
                value: null,
                error: null
              }
            })
          }
        }
    }
  }
  const onReportTypeHandleChange = (property: string, value: any) => {
    if (value === true) {
      setIsHistoricalData(true)
    } else {
      setIsHistoricalData(false)
    }
  }
  const clearFilters = () => {
    if (page !== 0) {
      setPage(0)
    }
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    setClientHierarchyUsersList(filterByClientBy.users)
    setReportFilterFormData(INITIAL_FILTER_STATE)
    setAllocationList([])
    setIsHistoricalData(false)
    setIsDisableExport(true)
  }
  // open header selection popup
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  // exit the header selection popup
  const handleClose = () => {
    setAnchorEl(null)
  }
  // select checkbox in header name popup
  const headerSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedHeader: ReportsTableHeaderDto
  ) => {
    if (!event.target.checked) {
      setHeaderStatus({
        ...headerStatus,
        [selectedHeader.key]: false
      })
    } else {
      setHeaderStatus({
        ...headerStatus,
        [selectedHeader.key]: true
      })
    }
  }
  // all column select checkbox
  const allColumnsSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const headers = createTableColumn(true)
      setHeaderStatus({
        ...headers
      })
    } else {
      const headers = createTableColumn(false)
      setHeaderStatus({
        ...headers
      })
    }
  }
  // checked all customize tavle headers
  const allColumnChecked = (headerStatus: ReportsTableColumnDto): boolean => {
    for (const header of Object.values(headerStatus)) {
      if (!header) {
        return false
      }
    }
    return true
  }
  // export Selected ExcelSheet

  const exportSelectedData = (
    tabs: ReportsTabDto,
    columnHeaders: ReportsTableHeaderDto[],
    headerStatus: ReportsTableColumnDto,
    fileName: string
  ) => {
    if (page !== 0) {
      setPage(0)
    }
    let exportedData: any[] = []
    exportedData = [...allocationList]
    // for (const row of exportedData) {
    //   delete row.projectRoleDisplayName
    //   delete row.designationDisplayName
    // }
    // create selected columns data array
    for (const [key, value] of Object.entries(headerStatus)) {
      for (const row of exportedData) {
        if (!value) {
          delete row[key as keyof typeof headerStatus]
        }
        // delete row.id
      }
    }
    // create headerNames for download the excel
    const filteredHeaders: ReportsTableHeaderDto[] = []
    const headers: string[] = []

    for (const column of columnHeaders) {
      if (headerStatus[column.key as keyof typeof TABLE_HEADERS]) {
        filteredHeaders.push(column)
        headers.push(column.key)
      }
    }
    /// add comments column
    filteredHeaders.push({
      id: 17,
      displayName: 'Comments',
      header: 'Comments',
      key: 'comments',
      width: 30,
      dataType: 's'
    })
    const dataSet: any[] = []
    let rowdata
    for (const row of exportedData) {
      for (const column of filteredHeaders) {
        if (column.header !== '') {
          rowdata = {
            empId: row.empId,
            empName: row.empName,
            email: row.email,
            designation: row.designation,
            joinDate: row.joinDate,
            sbu: row.sbu,
            client: row.client,
            project: row.project,
            billing: row.billing,
            role: row.role,
            billingStart: row.billingStart,
            billingEnd: row.billingEnd,
            allocation: row.allocation,
            startDate: row.startDate,
            endDate: row.endDate,
            projectStatus: row.projectStatus,
            comments: ''
          }
        }
      }
      dataSet.push(rowdata)
    }
    for (const [key, value] of Object.entries(headerStatus)) {
      for (const row of dataSet) {
        if (!value) {
          delete row[key as keyof typeof headerStatus]
        }
        // delete row.id
      }
    }

    const workbook = new Excel.Workbook()
    const ws = workbook.addWorksheet('Allocation_detail_report"')
    ws.columns = filteredHeaders
    // const idCol = ws.getColumn('empId')
    // idCol.alignment = { vertical: 'middle', horizontal: 'center' }
    ws.addRows(dataSet)
    ws.eachRow(function (row, rowNumber) {
      ws.getRow(rowNumber).eachCell(function (cell) {
        if (rowNumber === 1) {
          ws.getCell(cell.address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'C7C7C7' }
          }
          ws.getCell(cell.address).alignment = {
            vertical: 'middle', horizontal: 'center'
          }
          ws.getCell(cell.address).font = {
            name: 'Arial',
            family: 2,
            bold: true,
            size: 11
          }
          ws.getCell(cell.address).border = {
            top: { style: 'thin', color: { argb: '696969' } },
            left: { style: 'thin', color: { argb: '696969' } },
            bottom: { style: 'thin', color: { argb: '696969' } },
            right: { style: 'thin', color: { argb: '696969' } }
          }
        }
      })
    })
    workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), 'ALLOCATION_DETAIL_REPORT.xlsx'))
      .catch(err => console.log('Error writing excel export', err))
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    if (property === 'projectStart') {
      setReportFilterFormData({
        ...reportFilterFormData,
        projectStart: {
          ...reportFilterFormData[
          'projectStart' as keyof typeof reportFilterFormData
          ],
          value: reportFilterFormData.projectStart.value,
          error: null
        }
      })
    } else if (property === 'projectEnd') {
      setReportFilterFormData({
        ...reportFilterFormData,
        projectEnd: {
          ...reportFilterFormData[
                'projectEnd' as keyof typeof reportFilterFormData
          ],
          value: reportFilterFormData.projectEnd.value,
          error: null
        }
      })
    }
  }
  const clearReportsAlert = () => {
    dispatch(alertActions.clearReportAlertRequest())
  }
  // pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    // getFilteredOutputData(rowsPerPage, newPage, true)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)

    // getFilteredOutputData(parseInt(event.target.value, 10), 0, true)
  }
  return (
    <React.Fragment>
      <AppLayout
        breadcrumb={BREAD_CRUB.HERA_REPORT}
        componentTitle="Allocation Detail Report"
      >
        <p
          style={{
            paddingBottom: 7,
            margin: 0,
            fontWeight: 400,
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Allocation Detail Reports
        <Tooltip style={{ marginLeft: '5px', marginTop: '3px' }} title="Allocation Detail Report data is displayed based on your entitlements" >
              <InfoOutlinedIcon fontSize="small" ></InfoOutlinedIcon>
            </Tooltip>
        </p>
        {reportAlert.message && (

          <Alert
            onClose={clearReportsAlert}
            severity={reportAlert.severity}
          >
            {reportAlert.message}
          </Alert>

        )}
        <Divider className="dividerStyle" />
        <br />
        <EmpHistoryAndUtilizeReportForm
          isAdvancedFilter={isAdvancedFilter}
          isAdvancedFilterOnclick={isAdvancedFilterOnclick}
          userList={clientHierarchyUsersList || []}
          designationList={designationList?.data || []}
          clientHierachy={clientHierarchyClientList || []}
          reportFilterFormData={reportFilterFormData}
          clientHierarchyProjectList={clientHierarchyProjectList || []}
          onFilterHandleChange={onFilterHandleChange}
          allocationTypes={ALLOCATION_TYPES || []}
          sbuList={allSbuList?.data || []}
          clearFilters={clearFilters}
          onReportTypeHandleChange={onReportTypeHandleChange}
          getFilteredAllocationList={getAllocationListTrigger}
          handleInputFocus={handleInputFocus}
          isHistoricalData={isHistoricalData}
          reportsIsloading={reportsIsloading}
          clientHierachyIsloading={clientHierachyIsloading}
        />
        <br />
        <Divider className="dividerStyle" />
        <br />
        <Grid item xs={12} container className="sectionTitleHolder">
          <Grid item xs={6}>
            {/* <div className={'layout-row'}>
              <h3>Reports</h3>
            </div> */}
          </Grid>
          <Grid item xs={6}>
            <EmpHistoryAndUtilizeReportTableView
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              headerSelect={headerSelect}
              allColumnsSelect={allColumnsSelect}
              allColumnChecked={allColumnChecked}
              exportSelectedData={exportSelectedData}
              headerStatus={headerStatus}
              openSelectMenu={openSelectMenu}
              reportsTableHeaders={reportsTableHeaders}
              isDisableExport={isDisableExport}
            />
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <EmpHistoryAndUtilizeReportTable
            headerStatus={headerStatus}
            page={page}
            rowsPerPage={rowsPerPage}
            filterRows={allocationList || []}
            reportsIsloading={reportsIsloading}
            clientHierachyIsloading={clientHierachyIsloading}
          >
            <AppTablePagination
              data={allocationList || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </EmpHistoryAndUtilizeReportTable>
        </Grid>
      </AppLayout>
    </React.Fragment>
  )
}

export default AllocationDetailReport
