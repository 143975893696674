import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../templates'
import { useDispatch, useSelector } from 'react-redux'
import {
  BreadCrubDto,
  EmployeeAllocationHistoryParams,
  EmployeeDetailsDto,
  EmployeeDetailsParams,
  ProjectDetail,
  AppStateDto
} from '../../utilities/models'
import {
  APP_ACTION_STATUS,
  APP_FEATURE_KEYS,
  APP_ROUTES,
  APP_TABLE_CONFIGS
} from '../../utilities/constants'
import styles from './EmployeeInformation.module.scss'
import moment from 'moment'
import { employeeAction, userActions } from '../../redux/actions'
import { useLocation, useNavigate } from 'react-router-dom'
import { AllocationHistoryViewTable, AppAuthorizer, AppTablePagination } from '../../components'
const EmployeeInformation: React.FC<{}> = (props) => {
  const initialEmployeeDetails: EmployeeDetailsDto = {
    userId: 0,
    username: '#',
    firstName: '',
    lastName: '',
    isEnabled: false,
    isSuperAdmin: false,
    joinDate: '',
    terminationDate: '',
    birthday: '',
    employeeNumber: '',
    projectList: [],
    designation: '',
    applications: []

  }
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // #region States
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const employeeDetails = useSelector(
    (state: any) => state.user.userDetailsV2
  )
  const employeeDetailsIsloading = useSelector(
    (state: any) => state.user.userDetailsV2.isLoading
  )
  const employeeAllocationHistory = useSelector(
    (state: any) => state.employee.employeeAllocationHistory.data
  )
  const authorizedUser = useSelector((state: AppStateDto) => state.auth.authorizedUser)
  const historyIsLoading = useSelector(
    (state: any) => state.employee.employeeAllocationHistory.isLoading
  )
  const [details, setDetails] = useState<EmployeeDetailsDto>(
    initialEmployeeDetails
  )
  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_EMP
  )

  const [currentProjects, setCurrentProjects] = useState<String[]>([])
  const [currentProjectRoles, setCurrentProjectRoles] = useState<String[]>([])
  const [totalAllocation, setTotalAllocation] = useState<number>(0)
  // const [userEmail, setUserEmail] = useState<string>('')
  const params1: EmployeeAllocationHistoryParams = {
    username: authorizedUser.data.username,
    getDeallocatedTeams: true,
    getDisabled: true
  }
  const params2: EmployeeDetailsParams = {
    // view all users permission having RM user, super admin, RM supervisor
    getAll: true,
    username: authorizedUser.data.username
    // getDisabledUsers: 'true'
  }
  useEffect(() => {
    if (location.state !== null || location.state !== undefined) {
      // @ts-ignore
      const { username } = location.state
      params1.username = username
      params2.username = username
      //  setUserEmail(username)
    } else {
      navigate(APP_ROUTES.ALLOCATION_MANAGEMENT)
    }
    dispatch(employeeAction.getEmployeeAllocationHistory(params1))
    dispatch(userActions.getUserDetailsV2(params2))
  }, [])
  useEffect(() => {
    if (employeeDetails.status === APP_ACTION_STATUS.SUCCESS) {
      if (employeeDetails.data.length > 0) {
        setDetails(employeeDetails.data[0])
      } else {
        setDetails(initialEmployeeDetails)
      }
    }
  }, [employeeDetails])
  React.useEffect(() => {
    const projectDetails = getUserRelateProjectDetails()
    setTotalAllocation(projectDetails.totalAllocation)
    setCurrentProjectRoles(projectDetails.projectRoleList)
    setCurrentProjects(projectDetails.projectList)
  }, [employeeAllocationHistory])
  // pagination
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const calcDate = () => {
    let years = 0
    let months = 0
    years = moment().diff(details.joinDate, 'years')
    months = moment().diff(details.joinDate, 'months')
    if (months >= 12) {
      months = months % 12
    }
    const stringDate = years + '.' + months
    return stringDate + ' years'
  }

  const getUserRelateProjectDetails = (): ProjectDetail => {
    const profileDetails = {} as ProjectDetail
    const projectList = []
    const projectRoleList = []
    let totalAllocation = 0
    if (employeeAllocationHistory.length > 0) {
      if (employeeAllocationHistory[0].projectList && employeeAllocationHistory[0].projectList.length > 0) {
        for (const project of employeeAllocationHistory[0]?.projectList) {
          const allocationEndDate = moment(project.projectEndDate).format('YYYY-MM-DD')
          const allocationStartDate = moment(project.projectStartDate).format('YYYY-MM-DD')

          const today = moment().format('YYYY-MM-DD')
          if (allocationStartDate <= today && allocationEndDate >= today) {
            projectList.push(project.projectName)
            projectRoleList.push(project.projectRoleName)
            totalAllocation = totalAllocation + project.allocationPercentage
          }
        }
      }
    }

    profileDetails.projectList = projectList
    profileDetails.projectRoleList = projectRoleList
    profileDetails.totalAllocation = totalAllocation
    return profileDetails
  }

  const breadCrumbArray: BreadCrubDto[] = [
    {
      id: 1,
      title: 'Allocation Management',
      path: APP_ROUTES.ALLOCATION_MANAGEMENT
    },
    {
      id: 2,
      title: details.username,
      path: '#'
    }
  ]
  return (
    <React.Fragment>

      <AppLayout
        breadcrumb={breadCrumbArray}
        componentTitle="Employee Information"
      >

        <p
          style={{
            paddingBottom: 7,
            marginBottom: 10,
            margin: 0,
            fontWeight: 400,
            fontSize: '24px'
          }}
        >
          Employee Information
        </p>
        <Divider className="dividerStyle" />
        <br />
        {(employeeDetailsIsloading || historyIsLoading) &&
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <p className={styles.textStyle} >Loading...</p>
              <CircularProgress
                color="primary" size={50} />
            </Grid>
          </Grid>
        }
        {(!employeeDetailsIsloading || !historyIsLoading) &&
          <>
            <Grid container>
              <Grid item md={6}>
                <Stack>
                  <Grid container>
                    <Grid item>
                      <Avatar
                        className={styles.profile}
                        sx={{ bgcolor: '#0066ff' }}
                        variant="square"
                      >
                        <sup className={styles.profileAvatar}>
                          {details.firstName?.charAt(0) +
                            '' +
                            details.lastName?.charAt(0)}
                        </sup>
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Stack className={styles.stackStyle}>
                        <Typography className={styles.userProfile_name}>
                          {details.firstName + ' ' + details?.lastName}
                        </Typography>
                        <Typography className={styles.userProfileUsername}>
                          {details.username}
                        </Typography>
                        {details.designation !== null
                          ? <Typography className={styles.designation}>
                            <Chip
                              className={styles.tecChipStyle}
                              label={details.designation}
                            />
                          </Typography>
                          : null
                        }

                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                  </Grid>
                </Stack>
              </Grid>
              {/* Right */}
              <Grid item md={6}>
                <Stack>
                  <Grid
                    container
                    style={{ borderLeft: 'solid 5px #0066ff' }}
                    spacing={1}
                  >
                    <Grid item md={4}>
                      <Typography>Experience</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography>-</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography>Tecciance Experience</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography>{calcDate()}</Typography>
                    </Grid>
                  </Grid>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole.data.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
                  >
                    <Grid
                      container
                      style={{ borderLeft: 'solid 5px #0066ff', marginTop: '30px' }}
                      spacing={1}
                    >
                      <Grid item md={4}>
                        <Typography>Current Project(s)</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography>
                          {currentProjects.length === 0 ? '-' : currentProjects.join(',')}
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography>Current Project Role(s)</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography>
                          {currentProjectRoles.length === 0 ? '-' : currentProjectRoles.join(',')}
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography>Total Allocation</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Grid container alignItems={'center'}>
                          <Grid item md={4}>
                            <LinearProgress
                              variant="determinate"
                              color={
                                totalAllocation && totalAllocation > 100
                                  ? 'error'
                                  : 'primary'
                              }
                              sx={{ height: 5, paddingLeft: 5, paddingRight: 5 }}
                              value={totalAllocation || 0}
                            />
                          </Grid>
                          <Grid item md={1} ml={'5px'}>
                            <Typography align={'center'}>
                              {totalAllocation || '0'}%
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AppAuthorizer>

                </Stack>
              </Grid>
            </Grid>
            <AppAuthorizer
              activeRoleFeatures={activeUserRole.data.features}
              authorizedFeatureKey={[
                APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
            >
               <Divider style={{ marginTop: '10px' }} />
            {/* Bottom */}
            <Grid container direction={'row'} alignItems="center">
              <Grid item md={6}>
                <h1>Allocation History</h1>
              </Grid>
              <Grid item md={6}>
                <Grid
                  container
                  direction={'row'}
                  sx={{ backgroundColor: '#f4f4f4' }}
                >
                  <Grid item md={4}>
                    <span className={styles.futureStyle}></span>
                    <span>Future Allocation</span>
                  </Grid>
                  <Grid item md={4}>
                    <span className={styles.activeStyle}></span>
                    <span >Active Allocation</span>
                  </Grid>
                  <Grid item md={4}>
                    <span className={styles.expiredStyle}></span>
                    <span>Expired Allocation</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AllocationHistoryViewTable
              page={page}
              rowsPerPage={rowsPerPage}
              allocationHistory={employeeAllocationHistory || []}
              isLoading={historyIsLoading}
            >
              <AppTablePagination
                data={(employeeAllocationHistory && employeeAllocationHistory[0]?.projectList) || []}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </AllocationHistoryViewTable>
            </AppAuthorizer>

          </>}

      </AppLayout>
    </React.Fragment>
  )
}

export default EmployeeInformation
