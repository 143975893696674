import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import {
  ClientBriefEditDto,
  ProjectEntryFormDto
} from '../../../utilities/models/projectManagement.model'

const ProjectEntryForm: React.FC<{
  projectFormData: ProjectEntryFormDto;
  isShowHelperText: boolean;
  defaultClientList: Array<ClientBriefEditDto>;
  onInputHandleChange(property: string, value: any): void;
  handleInputFocus(property: string): void;
  createProject(): void;
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={3} className="sectionTitleHolder">
          <h3>Create New Project</h3>
          <p>
            Add a new project by providing a name and selecting a client from
            the list and click the create project button.
          </p>
        </Grid>
        <Grid item md={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={props.projectFormData.projectName.value}
                label="Project Name"
                color="primary"
                size="small"
                fullWidth
                onFocus={() => props.handleInputFocus('projectName')}
                helperText={
                  props.isShowHelperText &&
                  props.projectFormData.projectName.error !== null
                    ? 'Project Name is required'
                    : ''
                }
                error={!!props.projectFormData.projectName.error}
                required={props.projectFormData.projectName.isRequired}
                disabled={props.projectFormData.projectName.disable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.onInputHandleChange('projectName', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={props.projectFormData.clientName.value}
                fullWidth
                size="small"
                disabled={props.projectFormData.clientName.disable}
                options={props.defaultClientList.map((client) => {
                  return { name: client.clientName, id: client.clientId }
                })}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name || ''}
                    </li>
                  )
                }}
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, value) =>
                  props.onInputHandleChange('clientName', value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    color="primary"
                    onFocus={() => props.handleInputFocus('clientName')}
                    helperText={
                      props.isShowHelperText &&
                      props.projectFormData.clientName.error !== null
                        ? 'Client is required'
                        : ''
                    }
                    error={!!props.projectFormData.clientName.error}
                    required={props.projectFormData.clientName.isRequired}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                value={props.projectFormData.projectDescription.value}
                label="Project Description"
                color="primary"
                size="small"
                fullWidth
                multiline
                rows={4}
                onFocus={() => props.handleInputFocus('projectDescription')}
                helperText={
                  props.isShowHelperText &&
                  props.projectFormData.projectDescription.error
                }
                error={!!props.projectFormData.projectDescription.error}
                required={props.projectFormData.projectDescription.isRequired}
                disabled={props.projectFormData.projectDescription.disable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.onInputHandleChange(
                    'projectDescription',
                    event.target.value
                  )
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                className={'btnStyle'}
                onClick={props.createProject}
                // disabled={props.authLoading}
              >
                Create Project
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectEntryForm
