import { COMMON_ACTION_TYPES, REPORT_ACTION_TYPES } from '../../utilities/constants'
import { GetReportParamDto } from '../../utilities/models'

const getAllocationListForReport = (params : GetReportParamDto) => {
  return {
    type: REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const getAllocationListForFinanceReport = (params : GetReportParamDto) => {
  return {
    type: REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const clearAllocationListForReport = () => {
  return {
    type: REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.CLEAR
  }
}
const clearAllocationListForFinanceReport = () => {
  return {
    type: REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.CLEAR
  }
}

export const reportAction = {
  getAllocationListForReport,
  clearAllocationListForReport,
  getAllocationListForFinanceReport,
  clearAllocationListForFinanceReport
}
