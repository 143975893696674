import axios from 'axios'
import { CreateSbuParamDto, UpdateSbuParamDto } from '../utilities/models'
import { GetProjectListParamDto } from '../utilities/models/projectManagement.model'

const getAllProjectList = (params: GetProjectListParamDto) => {
  return axios.get('/core/api/v2/teams', { params: params })
}
// const getAllBriefClientList = (params: GetClientListBriefParamDto) => {
//   return axios.get('/v1/clients', { params: params })
// }
const createProject = (payload: CreateSbuParamDto) => {
  return axios.post('/core/api/v1/teams', payload)
}
const updateProject = (payload: UpdateSbuParamDto) => {
  return axios.put('/core/api/v1/teams', payload)
}

export const projectService = {
  getAllProjectList,
  // getAllBriefClientList,
  createProject,
  updateProject

}
