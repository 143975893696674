import { put, call, takeLatest } from 'redux-saga/effects'
import { designationService } from '../../services'
import { COMMON_ACTION_TYPES, DESIGNATION_ACTION_TYPES } from '../../utilities/constants'

function * getDesignationList (action: { type: string }) {
  try {
    // @ts-ignore
    const response = yield call(designationService.getDesignationList)
    yield put({
      type:
      DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * designationSagas () {
  yield takeLatest(DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.REQUEST, getDesignationList)
}
export default designationSagas
