/* eslint-disable no-unused-vars */
// import { AllocationTypeDto } from '../models'
import { AllocationTypeDto } from '../models'
import { ResourcesStatus } from '../models/resourceRequest.model'
import { APP_ROUTES } from './routes.constants'

export enum BROWSER_STORAGE_CONFIGS {
  MAX_COOKIE_SIZE= 4000,
  MAX_LOCAL_STORAGE_SIZE = 4.5e+6
}

export enum APPLICATION_IDS {
  HERA = 4,
  ATHENA =2
}

export const APP_TABLE_CONFIGS = {
  DEFAULT_ROWS_PER_PAGE: 5,
  DEFAULT_ROWS_PER_PAGE_EMP: 10,
  DEFAULT_ROWS_PER_PAGE_OPTIONS: [5, 10, 25, { label: 'All', value: -1 }]

}
export const TIME_COLORED_ALLOWED_DAYS: number = 7

export const ALERT_CONFIGS = {
  TIMEOUT: 6000,
  REPORT_TIMEOUT: 8000,
  AUTO_CLEAR: true
}
export const USER_ROLE_ID = {
  SUPER_ADMIN: 1,
  SUPPORT_SUPERVISOR: 2,
  SUPPORT: 3,
  EMPLOYEE: 4,
  HR_PERSONNAL: 5,
  INTERNAL_SUPERVISOR: 6,
  RM_USER: 7,
  RM_SUPERVISOR: 8,
  ALL_USER_ROLES: -1

}
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_2 = 'DD/MM/YYYY'
export const TIME_FORMAT = 'hh:mm'

export const BREAD_CRUB = {

  SBU_MANAGEMENT: [
    {
      id: 1,
      title: 'SBU Management',
      path: APP_ROUTES.SBU_MANAGEMENT
    }
  ],
  PROJECT_MANAGEMENT: [
    {
      id: 2,
      title: 'Project Management',
      path: APP_ROUTES.PROJECT_MANAGEMENT
    }
  ],
  CLIENT_MANAGEMENT: [
    {
      id: 3,
      title: 'Client Management',
      path: APP_ROUTES.CLIENT_MANAGEMENT
    }
  ],
  SUPERVISOR_MANAGEMENT: [
    {
      id: 4,
      title: 'External Supervisor Management',
      path: APP_ROUTES.SUPERVISOR_MANAGEMENT
    }

  ],
  EMPLOYEE_MANAGEMENT: [
    {
      id: 5,
      title: 'Allocation Management',
      path: APP_ROUTES.ALLOCATION_MANAGEMENT
    }
  ],
  RM: [
    {
      id: 9,
      title: 'RM',
      path: '#'
    },
    {
      id: 10,
      title: 'Resource Request',
      path: APP_ROUTES.CREATE_RESOURCE_REQUEST
    }
  ],
  MANAGE_RESOURCE_REQUESTS: [
    {
      id: 9,
      title: 'RM',
      path: '#'
    },
    {
      id: 10,
      title: 'Manage Resource Request',
      path: APP_ROUTES.VIEW_RESOURCE_REQUESTS
    }
  ],
  HERA_REPORT: [
    {
      id: 6,
      title: 'Report',
      path: '#'
    },
    {
      id: 7,
      title: 'Allocation Detail Report',
      path: APP_ROUTES.HERA_REPORT
    }
  ],
  HERA_FINANCE_REPORT: [
    {
      id: 6,
      title: 'Report',
      path: '#'
    },
    {
      id: 7,
      title: 'Project Detail Report',
      path: APP_ROUTES.HERA_FINANCE_REPORT
    }
  ],
  DASHBOARD: [
    {
      id: 8,
      title: 'Dashboard',
      path: APP_ROUTES.DASHBOARD
    }
  ]

}

export const ALLOCATION_TYPES : AllocationTypeDto[] = [
  {
    id: 1,
    name: 'nonAllocated',
    displayName: 'Non Allocated'
  },
  {
    id: 2,
    name: 'overAllocated',
    displayName: 'Over Allocated'
  },
  {
    id: 3,
    name: 'partiallyAllocated',
    displayName: 'Partially Allocated'
  }

]

export enum APP_FEATURE_KEYS {
   // allocation related
   VIEW_ALL_ALLOCATION = 'FE001',
   CREATE_NEW_ALLOCATION = 'FE002',
   UPDATE_ALLOCATION='FE003',
   DEALLOCATION='FE004',
   VIEW_OWN_ALLOCATION='FE005',
   // sbu related
   VIEW_ALL_SBU ='FE006',
   CRETTE_NEW_SBU='FE007',
   UPDATE_SBU='FE008',
   // client related
   VIEW_ALL_CLIENT='FE009',
   CREATE_NEW_CLIENT ='FE010',
   UPDATE_CLIENT='FE011',
   // project related
   VIEW_ALL_PROJECT='FE012',
   CREATE_NEW_PROJECT = 'FE013',
   UPDATE_PROJECT='FE014',
   // supervisor related
   VIEW_ALL_EXTERNAL_SUPERVISOR= 'FE015',
   CREATE_NEW_EXTERNAL_SUPERVISOR= 'FE016',
   UPDATE_EXTERNAL_SUPERVISOR= 'FE017',
   HERA_ALLOCATION_DETAIL_REPORT= 'FE018',
   HERA_PROJECT_DETAIL_REPORT = 'FE019',
   // dashboard
  HERA_DASHBOARD = 'FE059',
  PROFILE = 'FE061',
  CREATE_RESOURCE_REQUEST = 'FE093',
  UPDATE_RESOURCE_REQUEST = 'FE094',
  VIEW_RESOURCE_REQUESTS = 'FE095',
  UPDATE_RESOURCES = 'FE096',
  BU_RESOURCE_REQUEST_ACTION = 'FE097',
  RM_RESOURCE_REQUEST_ACTTION = 'FE098',
  PROPOSE_RESOURCE = 'FE099',
  DELETE_PROPOSED_RESOURCE = 'FE100',
  CONFIRM_PROPOSED_RESOURCES = 'FE101',
  PROPOSED_RESOURCE_ACTION = 'FE102',
  CLOSE_RESOURCE_REQUEST = 'FE103'
}

export enum RESOURCE_STATUS_TYPES {
  PENDING_STATUS = 1,
  ACCEPT_STATUS = 2,
  REJECT_STATUS = 3,
  REVIEW_ACCEPT_STATUS = 4,
  REVIEW_REJECT_STATUS = 5
}

// export const PROJECT_STATUS_TYPES : ProjectStatusDto[] = [
//   {
//     id: 1,
//     name: 'enable',
//     displayName: 'Enable Projects'
//   },
//   {
//     id: 2,
//     name: 'disable',
//     displayName: 'Disable Projects'
//   }

// ]
// export const ALLOCATION_TYPES = [
//   'PartiallyAllocated', 'OverAllocated', 'FreeAllocated'
// ]

export const EXCELL_CELLS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
