export const AUTH_ACTION_TYPES = {
  AUTHORIZE_USER: 'AUTHORIZE_USER',
  FETCH_AUTHORIZE_USER: 'FETCH_AUTHORIZE_USER',
  FETCH_AUTHORIZE_USER_ROLES: 'FETCH_AUTHORIZE_USER_ROLES',
  SET_ACTIVE_USER_ROLE: 'SET_ACTIVE_USER_ROLE',
  FETCH_ACTIVE_USER_ROLE: 'FETCH_ACTIVE_USER_ROLE',
  LOGOUT: 'LOGOUT'

}

export const USER_ACTION_TYPES = {
  // GET_USER_DETAIL: 'GET_USER_DETAIL',
  GET_USER_DETAIL_V2: 'GET_USER_DETAIL_V2',
  GET_USER_LIST: 'GET_USER_LIST',
  GET_USER_LIST_BRIEF: 'GET_USER_LIST_BRIEF',
  GET_USER_PROFILE: 'GET_USER_PROFILE'

  // AUTHORIZE_USER: 'AUTHORIZE_USER',
  // FETCH_AUTHORIZE_USER: 'FETCH_AUTHORIZE_USER'

}

export const COMMON_ACTION_TYPES = {
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  ERROR: '_ERROR',
  CLEAR: '_CLEAR',
  SET_ALERT_REQ: '_SET_ALERT_REQ',
  SET_ALERT: '_SET_ALERT',
  CLEAR_ALERT: '_CLEAR_ALERT'
}

export const EMPLOYEE_ACTION_TYPES = {
  SET_VALIDATE_EMPLOYEE: 'SET_VALIDATE_EMPLOYEE',
  SET_SELECTED_EMPLOYEE: 'SET_SELECTED_EMPLOYEE',
  SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
  GET_EMPLOYEE_ALLOCATION_LIST: 'GET_EMPLOYEE_ALLOCATION_LIST',
  GET_FILTERED_LIST: 'GET_FILTERED_LIST',
  ALLOCATE_EMPLOYEE: 'ALLOCATE_EMPLOYEE',
  DEALLOCATE_EMPLOYEE: 'DEALLOCATE_EMPLOYEE',
  UPDATE_EMPLOYEE_ALLOCATION: 'UPDATE_EMPLOYEE_ALLOCATION',
  // GET_EMPLOYEE_DETAILS: 'GET_EMPLOYEE_DETAILS',
  GET_EMPLOYEE_ALLOCATION_HISTORY: 'GET_EMPLOYEE_ALLOCATION_HISTORY',
  GET_EMPLOYEE_PROJECT_LIST: 'GET_EMPLOYEE_PROJECT_LIST'
}
export const DESIGNATION_ACTION_TYPES = {
  GET_DESIGNATION_LIST: 'GET_DESIGNATION_LIST'
}
export const CLIENT_ACTION_TYPES = {
  GET_CLIENT_LIST: 'GET_CLIENT_LIST',
  GET_CLIENT_HIERACHY: 'GET_CLIENT_HIERACHY',
  GET_ENTITLED_CLIENT_HIERACHY: 'GET_ENTITLED_CLIENT_HIERACHY',
  CREATE_CLIENT: 'CREATE_CLIENT',
  UPDATE_CLIENT: 'UPDATE_CLIENT',
  CLIENT_STATUS_CHANGE: 'CLIENT_STATUS_CHANGE',
  GET_CLIENT_SBU_LIST: 'GET_CLIENT_SBU_LIST'
}

export const APP_ACTION_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading'
}
export const SBU_ACTION_TYPES = {
  GET_SBU_LIST: 'GET_SBU_LIST',
  GET_ENTITLED_SBU_LIST: 'GET_ENTITLED_SBU_LIST',
  CREATE_SBU: 'CREATE_SBU',
  UPDATE_SBU: 'UPDATE_SBU'
  // SBU_STATUS_CHANGE: 'SBU_STATUS_CHANGE'
}
export const PROJECT_ACTION_TYPES = {
  GET_PROJECT_LIST: 'GET_PROJECT_LIST',
  GET_PROJECT_CLIENT_LIST: 'GET_PROJECT_CLIENT_LIST',
  CREATE_PROJECT: 'CREATE_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT'
  // SBU_STATUS_CHANGE: 'SBU_STATUS_CHANGE'
}
export const SUPERVISOR_ACTION_TYPES = {
  GET_PROJECTS_LIST: 'GET_PROJECTS_LIST',
  GET_SUPERVISORS_LIST: 'GET_SUPERVISORS_LIST',
  CREATE_SUPERVISOR: 'CREATE_SUPERVISOR',
  UNASSIGN_SUPERVISOR: 'UNASSIGN_SUPERVISOR'
}
export const REPORT_ACTION_TYPES = {
  GET_REPORTS_DATA: 'GET_REPORTS_DATA',
  GET_FINANCE_REPORTS_DATA: 'GET_FINANCE_REPORTS_DATA',
  SET_REPORT_FILTER_ALERT: 'SET_REPORT_FILTER_ALERT'

}
export const DASHBOARD_ACTION_TYPES = {
  GET_CLIENT_WISE_DATA: 'GET_CLIENT_WISE_DATA',
  GET_EMP_WISE_DATA: 'GET_EMP_WISE_DATA',
  GET_TRACK_WISE_DATA: 'GET_TRACK_WISE_DATA',
  GET_TIER_WISE_DATA: 'GET_TIER_WISE_DATA'
  // SET_REPORT_FILTER_ALERT: 'SET_REPORT_FILTER_ALERT'

}
export const COMMENTS_ACTION_TYPES = {
  GET_COMMENTS_LIST: 'GET_COMMENTS_LIST',
  ADD_COMMENT: 'ADD_COMMENT'
}

export const RESIGNATION_DETAILS_TYPES = {
  GET_RESIGNATOIN_DETAILS: 'GET_RESIGNATOIN_DETAILS',
  ADD_COMMENT: 'ADD_RESIGNATION_COMMENT'
}

export const RESOURCE_ACTION_TYPES = {
  CREATE_RESOURCE_REQUEST: 'CREATE_RESOURCE_REQUEST',
  GET_RESOURCE_REQUESTS: 'GET_RESOURCE_REQUESTS',
  UPDATE_RESOURCES: 'UPDATE_RESOURCES',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  CREATE_RESOURCE_COMMENT: 'CREATE_RESOURCE_COMMENT',
  GET_RESOURCE_COMMENTS: 'GET_RESOURCE_COMMENTS',
  RESOURCE_ACTION: 'RESOURCE_ACTION',
  CREATE_PROPOSE_REQUEST: 'CREATE_PROPOSE_REQUEST',
  GET_PROPOSED_RESOURCES: 'GET_PROPOSED_RESOURCES',
  DELETE_PROPOSED_RESOURCE: 'DELETE_PROPOSED_RESOURCE',
  CONFIRM_PROPOSED_RESOURCES: 'CONFIRM_PROPOSED_RESOURCES',
  PROPOSED_RESOURCES_ACTION: 'PROPOSED_RESOURCES_ACTION',
  CLOSE_REQUEST: 'CLOSE_REQUEST',
  GET_STATUS_LIST: 'GET_STATUS_LIST'
}

export const HERA_CONFIG_ACTION_TYPES = {
  GET_RESOURCE_MANAGEMENT_CONFIGS: 'GET_RESOURCE_MANAGEMENT_CONFIGS'
}
export const TRACK_ACTION_TYPES = {
  GET_TRACK_LIST: 'GET_TRACK_LIST'
}
