import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from './SbuStatusChangePopup.module.scss'

const SbuStatusChangePopup: React.FC<{
  isStatusChangeDialogOpen: boolean;
  sbuStatusChangeClick(): void;
  sbuStatusChange(): void;
  isStatusChangeRow: any;
}> = (props) => {
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isStatusChangeDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.sbuStatusChangeClick()
        }
      >
        Please Confirm

      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>

        {props.isStatusChangeRow.sbuStatus.value.isEnabled === true &&
          <Typography gutterBottom>
            {`Do you want to Disable the "${props.isStatusChangeRow.sbuStatus.value.sbuName}"`}
          </Typography>
        }
        {props.isStatusChangeRow.sbuStatus.value.isEnabled === false &&
          <Typography gutterBottom>
            {`Do you want to Enable the "${props.isStatusChangeRow.sbuStatus.value.sbuName}"`}
          </Typography>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.sbuStatusChange()}
          autoFocus variant="contained"
          className={'btnStyle'}
        >
          Confirm
        </Button>
        <Button onClick={() => props.sbuStatusChangeClick()}
          autoFocus variant="contained"
          className={'btnStyle'}
          color={'inherit'}

        >
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>

  )
}

export default SbuStatusChangePopup
