import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import styles from './ClientStatusChangePopup.module.scss'

const ClientStatusChangePopup: React.FC<{
  isStatusChangeDialogOpen: boolean;
  clientStatusChangeClick(): void;
  clientStatusChange(): void;
  isStatusChangeRow:any;
}> = (props) => {
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isStatusChangeDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.clientStatusChangeClick()
        }
      >
        Please Confirm

      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
      {props.isStatusChangeRow.clientStatus.value.isEnabled === true &&
          <Typography gutterBottom>
            {`Do you want to Disable the "${props.isStatusChangeRow.clientStatus.value.clientName}"`}
          </Typography>
        }
        {props.isStatusChangeRow.clientStatus.value.isEnabled === false &&
          <Typography gutterBottom>
            {`Do you want to Enable the "${props.isStatusChangeRow.clientStatus.value.clientName}"`}
          </Typography>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.clientStatusChange()}
          autoFocus variant="contained"
          className={'btnStyle'}
        >
          Confirm
        </Button>
        <Button onClick={() => props.clientStatusChangeClick()}
          autoFocus variant="contained"
          className={'btnStyle'}
          color={'inherit'}

        >
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>

  )
}

export default ClientStatusChangePopup
