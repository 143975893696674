import React, { useState } from 'react'
import { Paper, CircularProgress, TextField, Button, DialogActions, DialogContent, Divider, Grid, Typography, Card } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import moment from 'moment'
import { DATE_FORMAT } from '../../../utilities/constants'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
const ResourceRequestStatusPopup: React.FC<{
  isRequestResourceChangePopupOpen: any
  setRequestResourceChangePopupOpen(b: boolean):void
  changeStatus(note: string, requestId: number, statusId: number): void
  statusName: any
  requestDetails: any
  statusId: number | undefined
  resourceActionLoading: boolean
  // updateRequestStatusAlert: any,
  rejectionNoteStatuses: any
  resources: any
}> = (props) => {
  const [text, setText] = useState('')
  const [error, setError] = useState('')
  const onInputHandle = (value: any) => {
    setText(value)
  }
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.isRequestResourceChangePopupOpen}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.setRequestResourceChangePopupOpen(false)}>
      {props.statusName.title} Request
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
      <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
      <Typography sx={{ paddingBottom: '5px' }}>
        Do you want to {props.statusName.title} the following resource request?
      </Typography>
      </Grid>
      <Card sx={{ border: '1px solid grey', boxShadow: 'none', padding: '5px', marginLeft: '15px' }}>
      <Paper sx={{ overflowX: 'auto', boxShadow: 'none', padding: '5px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
        <Typography>RequestId : <strong>{props.requestDetails.requestId}</strong></Typography>
        <Typography> Requested Date : <strong>{moment(props.requestDetails.createdAt).format(DATE_FORMAT)}</strong></Typography>
        </Grid>
        <Grid item xs={6} md={6}>
        <Typography>Client : <strong>{props.requestDetails.clientName}</strong></Typography>
        <Typography>Project : <strong>{props.requestDetails.projectName}</strong></Typography>
        </Grid>
        <Grid item xs={12} md={12}>
        <Divider className="dividerStyle" />
        </Grid>
        <Grid item xs={2} md={2}>
          <strong>Vacancies</strong>
        </Grid>
        {props.resources?.map((childElement: any, childIndex: any) => (
          <React.Fragment key={childIndex}>
            {childIndex > 0 &&
            <Grid item xs={2} md={2}>
            </Grid>
            }
            <Grid item xs={5} md={props.requestDetails.requestType === 1 ? 5 : 4}>
              {childElement.designation}
            </Grid>
            <Grid item md={props.requestDetails.requestType === 1 ? 5 : 6}>
                {props.requestDetails.requestType === 1 ? childElement.noOfVacancies : childElement.replacementUsername}
            </Grid>
            </React.Fragment>
        ))}
        </Grid>
        </Paper>
        </Card>
        <Grid item xs={12} md={12}>
        {props.rejectionNoteStatuses?.filter((x: any) => +x === props.statusId).length > 0
          ? <TextField
          id="outlined-multiline-flexible"
          label={props.statusName.title + ' Note'}
          multiline
          rows={4}
          fullWidth
          value={text}
          required={true}
          error={error !== ''}
          helperText={error}
          onChange={(event) => onInputHandle(event.target.value)}
        />
          : <Typography>
            <InfoOutlinedIcon sx={{ fontSize: '20px', mr: '-1', paddingTop: '5px' }} />
            For approval and close/ complete status notes, use comments available in resources</Typography>}
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={'btnStyle'}
          onClick={() => {
            if (text === '' && (props.rejectionNoteStatuses?.filter((x: any) => +x === props.statusId).length > 0)) {
              setError('Note is Required')
            } else {
              setError('')
              props.changeStatus(text, props.requestDetails.requestId, props.statusId ? props.statusId : 0)
            }
          }}>
            {(props.resourceActionLoading) && (
            <CircularProgress size="12px" />
            )}
          {props.statusName.title}
        </Button>
      </DialogActions >
    </BootstrapDialog >
  )
}

export default ResourceRequestStatusPopup
