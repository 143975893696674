
import { Typography } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { DashboardResourceAllocationDto } from '../../../utilities/models'
import styles from './ResourceAllocation.module.scss'
const ResourceAllocation: React.FC<{
  clientCount: number[]
  clientNames: string[]
  clientWiseCountData: DashboardResourceAllocationDto
}> = (props) => {
  const barChartData: ApexOptions = {
    chart: {
      id: 'apexchart-example',
      foreColor: 'black',
      type: 'bar',
      height: 300,
      toolbar: {
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          download: false
        }
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 1,
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    noData: {
      text: (props.clientWiseCountData.isLoading && 'Loading Data...') ||
        (!props.clientWiseCountData.isLoading && props.clientWiseCountData.data?.length === 0
          ? 'No data to preview'
          : 'Loading'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#6b6b6b',
        fontSize: '20px',
        fontFamily: 'Ubuntu,sans-serif'
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false,
      row: {
        colors: ['white', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      title: {
        text: '',
        style: {
          fontSize: '14px',
          fontFamily: 'Ubuntu,sans-serif'
        }
      },
      categories: props.clientNames
    },
    yaxis: {
      title: {
        text: ''
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      offsetX: 40
    },
    series: [{
      name: 'Resources',
      data: props.clientCount
    }]
    // colors: ['#2F4F4F', '#1e8496']
    // colors: ['#D2691E', '#808000']

  }
  return (
    <React.Fragment>
      {props.clientWiseCountData.isLoading &&
        <Typography className={styles.loadingStyle}>
          Loading Data...
        </Typography>

      }

      {
        (!props.clientWiseCountData.isLoading && props.clientWiseCountData?.data.length === 0) &&
        <Typography className={styles.loadingStyle}>
          No data to preview
        </Typography>
      }
      {
        (!props.clientWiseCountData.isLoading && props.clientWiseCountData?.data.length > 0) &&
        <ReactApexChart
        id="apexchart-example"
        options={barChartData}
        series={barChartData.series}
        type="bar"
        height={400}
      />
      }

    </React.Fragment>
  )
}

export default ResourceAllocation
