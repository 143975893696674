import { put, call, takeEvery, delay } from 'redux-saga/effects'
import { resignationService } from '../../services/resignation.service'
import { ALERT_CONFIGS, COMMON_ACTION_TYPES, RESIGNATION_DETAILS_TYPES } from '../../utilities/constants'
import { AlertActionDto, ResignationCommentParamsDto } from '../../utilities/models'

function * resignationDetails (action: { type: string }) {
  try {
    // @ts-ignore
    const list = yield call(resignationService.getResignationDetails, action.params)
    yield put({
      type:
      RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.SUCCESS,
      data: list.data
    })
  } catch (error) {
    yield put({
      type: RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * addComment (action: { type: string; payload: ResignationCommentParamsDto; resignationId: string}) {
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const addedCommentData = yield call(resignationService.addComment, action.payload, action.resignationId)
    const setAlert: AlertActionDto = {
      message: addedCommentData.data.message,
      severity: 'success',
      type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * resignationSaga () {
  yield takeEvery(
    RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.REQUEST,
    resignationDetails
  )
  yield takeEvery(RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    addComment
  )
}

export default resignationSaga
