import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Box, Button, Divider, Grid, Stack, Tab } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppAuthorizer, AppTablePagination } from '../../components'
import { ResourceRequestFilter } from '../../components/resource-request'
import ResourceRequestsViewTable from '../../components/resource-request/ResourceRequestsViewTable/ResourceRequestsViewTable'
import { alertActions, clientAction, configAction, designationAction, resourceAction, sbuAction, trackAction } from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_FEATURE_KEYS, BREAD_CRUB } from '../../utilities/constants'
import { AlertDto, AllClientHierachyDto, AppStateDto, ResourceManagementConfigDParams, sbuBriefDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto } from '../../utilities/models'
import { FilterDto, RmStatus, UpdateRequestDto, UpdateResourcesDto, ViewResourcesRequestParams } from '../../utilities/models/resourceRequest.model'
const ResourceRequestView: React.FC = () => {
  const INITAL_STATUS: RmStatus = {
    id: 1,
    type: 1,
    name: 'Pending',
    title: 'Pending'
  }
  const INITIAL_FILTER_STATE: FilterDto = {
    sbu: { value: {} as sbuBriefDto, validator: 'object', isRequired: false, error: null, disable: false },
    clientName: { value: {} as UserClientHierarchyDto, validator: 'object', isRequired: false, error: null, disable: false },
    projectName: { value: {} as UserClientHierarchyTeamsDto, validator: 'object', isRequired: false, error: null, disable: false },
    status: { value: INITAL_STATUS, validator: 'string', isRequired: false, error: null, disable: false }
  }
  const INITIAL_RESOURCE: UpdateResourcesDto = {
    requestId: -1,
    resourceRequestId: -1,
    isEnabled: false,
    noOfVacancies: 0,
    deployementStartDate: '',
    deployementEndDate: '',
    allocationPercentage: 0,
    isBillable: false,
    isDeployementExtensionConfirm: false,
    deploymentExtendPeriod: 0,
    jobDescriptionAndRemarks: ''
  }
  const INITIAL_REQUEST: UpdateRequestDto = {
    requestId: 0,
    resourceRequestExtendedDeadline: ''
  }
  const [parentEditRowData, setParentEditRowData] = React.useState<UpdateRequestDto>(INITIAL_REQUEST)
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const [filterFormData, setFilterFormData] = useState(INITIAL_FILTER_STATE)
  const [editRowData, setEditRowData] = useState<UpdateResourcesDto>(INITIAL_RESOURCE)
  const [isEditingRow, setIsEditingRow] = useState<boolean>(false)
  const [isParentEditingRow, setIsParentEditingRow] = useState<boolean>(false)
  const clientHierachy = useSelector((state: any) => state.client.entitledClientHierachy.data)
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<UserClientHierarchyDto[]>([])
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<UserClientHierarchyTeamsDto[]>([])
  const clientHierachyIsLoading = useSelector((state: any) => state.client.clientHierachy.isLoading)
  const updateResourcesAlertRequest: AlertDto = useSelector((state: any) => state.alerts.updateResources)
  const updateRequestAlertRequest: AlertDto = useSelector((state: any) => state.alerts.updateRequest)
  const updateRequestStatusAlert: AlertDto = useSelector((state: any) => state.alerts.resourceAction)
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)
  const entitledSbuList = useSelector((state: any) => state.sbu.entitledSbuList.data)
  const statusList = useSelector((state: any) => state.resource.rmStatusList.data)
  React.useEffect(() => {
    // devide  client , project, users from client hierachy api
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
  }, [clientHierachy])
  React.useEffect(() => {
    getResourceManagementConfigs()
    dispatch(clientAction.getEntitledClientHierchy({}))
    dispatch(clientAction.getAllClientHierchy({ getAll: true }))
    dispatch(sbuAction.allSbuList({ getAll: true }))
    dispatch(sbuAction.entitledSbuList({}))
    getTrackList()
    handleFilter()
    dispatch(designationAction.getDesignationList())
    dispatch(resourceAction.getStatusList())
  }, [])
  React.useEffect(() => {
    if (updateResourcesAlertRequest.severity === 'success') {
      setIsEditingRow(false)
      getProposedRequests(selectedRequest)
    }
  }, [updateResourcesAlertRequest])
  React.useEffect(() => {
    if (updateRequestStatusAlert.severity === 'success') {
      getProposedRequests(selectedRequest)
      handleFilterClear()
    }
  }, [updateRequestStatusAlert])
  React.useEffect(() => {
    if (updateRequestAlertRequest.severity === 'success') {
      setIsParentEditingRow(false)
      handleFilter()
      // setParentEditRowData(INITIAL_REQUEST)
    }
  }, [updateRequestAlertRequest])
  const getTrackList = () => {
    dispatch(trackAction.getTrackList())
  }
  const clearUpdateResourcesAlertRequest = () => {
    dispatch(alertActions.clearUpdateResourcesAlertRequest())
  }
  const clearUpdateRequestAlertRequest = () => {
    dispatch(alertActions.clearUpdateRequestAlertRequest())
  }
  const [page, setPage] = useState(0)
  const [pageReplacements, setPageReplacements] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rowsPerPageReplacements, setRowsPerPageReplacements] = useState(5)
  const [selectedRequestType, setSelectedRequestType] = useState<number>(1)

  const dispatch = useDispatch()
  const resourceRequestsList = useSelector((state: any) => state.resource.resourceRequestsList.data)
  const resourceRequestListIsLoading = useSelector((state: any) => state.resource.resourceRequestsList.isLoading)
  const resourceActionLoading = useSelector((state: any) => state.resource.resourceAction.isLoading)
  const [value, setValue] = React.useState('1')
  const [expandedResource, setExpandedResource] = React.useState<number | null>(null)
  const getResourceRequests = () => {
    const viewResourseRequest: ViewResourcesRequestParams = {
    }
    dispatch(resourceAction.getResourceRequests(viewResourseRequest))
  }
  const addProposeRequestAlert: AlertDto = useSelector((state: any) => state.alerts.createPropose)
  React.useEffect(() => {
    if (addProposeRequestAlert.severity === 'success') {
      handleFilterClear()
      getProposedRequests(selectedRequest)
    }
  }, [addProposeRequestAlert.severity === 'success'])
  const confirmProposedResourcesAlert: AlertDto = useSelector((state: any) => state.alerts.confirmProposedResources)
  React.useEffect(() => {
    if (confirmProposedResourcesAlert.severity === 'success') {
      handleFilter()
      getProposedRequests(selectedRequest)
    }
  }, [confirmProposedResourcesAlert.severity === 'success'])
  const closeRequestAlert: AlertDto = useSelector((state: any) => state.alerts.closeRequest)
  React.useEffect(() => {
    if (closeRequestAlert.severity === 'success') {
      handleFilterClear()
      getProposedRequests(selectedRequest)
    }
  }, [closeRequestAlert.severity === 'success'])
  const getResourceManagementConfigs = () => {
    const params: ResourceManagementConfigDParams = {
      keys: '"on_bench_team_id","bu_resource_request_status_ids","rm_resource_request_status_ids","bu_resource_request_actions_enable_status_ids"' +
      ',"rm_resource_request_actions_enable_status_ids","resource_request_editable_status_ids","resource_editable_status_ids",' +
      '"resource_request_note_enable_status_ids","propose_resources_enable_status_ids","proposed_resource_confirm_enable_status_ids",' +
      '"proposed_resource_delete_enbale_status_ids","proposed_resource_action_enable_status_ids",' +
      '"proposed_resource_action_complete_enable_status_ids","proposed_resource_action_status_ids",' +
      '"proposed_resource_action_complete_status_ids","resource_request_closing_enable_status_ids","resource_request_closing_status_ids"'
    }
    dispatch(configAction.getResourceManagementConfig(params))
  }
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    setSelectedRequestType(+newValue)
  }
  // pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (selectedRequestType === 1) {
      setPage(newPage)
    } else {
      setPageReplacements(newPage)
    }
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (selectedRequestType === 1) {
      setPage(0)
      setRowsPerPage(parseInt(event.target.value, 10))
    } else {
      setPageReplacements(0)
      setRowsPerPageReplacements(parseInt(event.target.value, 10))
    }
  }

  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.length > 0) {
      for (const client of clientHierachy) {
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some((i) =>
              i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some((i) =>
                  i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const handleChangeFilterForm = (property: string, value: any) => {
    switch (property) {
      case 'sbu':
        if (value) {
          const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === value.id)
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          const userList: UserClientHierarchyUserDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some((i) =>
                    i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                  if (team.users && team.users.length) {
                    for (const user of team.users) {
                      const isIncluded = userList.some((i) =>
                        i.userId === user.userId
                      )
                      if (!isIncluded && user.userId !== null) {
                        userList.push({
                          userId: user.userId,
                          username: user.username,
                          firstName: user.firstName,
                          lastName: user.lastName
                        })
                      }
                    }
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: value
            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: {} as sbuBriefDto,
              error: null

            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
        }
        break
      case 'clientName':
        if (value) {
          setClientHierarchyProjectList(value.teams)
          const userList: UserClientHierarchyUserDto[] = []
          if (value.teams.length > 0) {
            for (const team of value.teams) {
              if (team.users && team.users.length) {
                for (const user of team.users) {
                  const isIncluded = userList.some((i) =>
                    i.userId === user.userId
                  )
                  if (!isIncluded && user.userId !== null) {
                    userList.push({
                      userId: user.userId,
                      username: user.username,
                      firstName: user.firstName,
                      lastName: user.lastName
                    })
                  }
                }
              }
            }
          }
          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: value
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
          if (Object.keys(filterFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === filterFormData.sbu.value.id)
            setClientHierarchyclientList(filteredClientList)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
          }
        }
        break
      case 'projectName':
        if (value) {
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: value
            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
          if (Object.keys(filterFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === filterFormData.clientName.value.clientId)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
          }
        }
        break
      default:
        if (value) {
          setFilterFormData({
            ...filterFormData,
            [property]: {
              ...filterFormData[property as keyof typeof filterFormData],
              value: value
            }
          })
        } else {
          if (property === 'projectName') {
            setFilterFormData({
              ...filterFormData,
              projectName: {
                ...filterFormData['projectName' as keyof typeof filterFormData],
                value: {} as UserClientHierarchyTeamsDto,
                error: null

              }
            })
          } else if (property === 'clientName') {
            setFilterFormData({
              ...filterFormData,
              clientName: {
                ...filterFormData['clientName' as keyof typeof filterFormData],
                value: {} as UserClientHierarchyDto

              }
            })
          }
        }
    }
  }
  const handleFilterClear = () => {
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    INITIAL_FILTER_STATE.status.value = { name: '', type: 0, id: 0, title: '' }
    setFilterFormData(INITIAL_FILTER_STATE)
    getResourceRequests()
  }
  const handleFilter = async () => {
    if (selectedRequestType === 1) {
      setPage(0)
    } else {
      setPageReplacements(0)
    }
    const viewResourseRequest: ViewResourcesRequestParams = {
      sbuId: filterFormData.sbu.value.id,
      clientName: filterFormData.clientName.value.clientName,
      projectName: filterFormData.projectName.value.teamName,
      statusId: filterFormData.status.value.id !== 0 ? filterFormData.status.value.id : null
    }
    dispatch(resourceAction.getResourceRequests(viewResourseRequest))
  }
  const disabledChildRow = async (isEnabled: boolean, requestId: number, resourceId: number) => {
    setIsEditingRow(false)
    const updateResource: UpdateResourcesDto = {
      isEnabled: isEnabled
    }
    dispatch(resourceAction.updateResources(updateResource, requestId, resourceId))
  }
  const updateRequest = async (date: string, requestId: number) => {
    setIsParentEditingRow(false)
    const updateResource: UpdateRequestDto = {
      resourceRequestExtendedDeadline: date
    }
    dispatch(resourceAction.updateRequest(updateResource, requestId))
  }

  const navigate = useNavigate()

  const clearRequestStatusAlert = () => {
    dispatch(alertActions.clearUpdateResourceActionAlertRequest)
  }
  const clearCloseRequestAlert = () => {
    dispatch(alertActions.clearCloseRequestAlert)
  }
  const resourcesList = useSelector((state: any) => state.resource.proposedResourcesList.data.data)
  const resourcesListIsLoading = useSelector((state: any) => state.resource.proposedResourcesList.isLoading)
  const getProposedRequests = async (requestId: number) => {
    dispatch(resourceAction.getProposedResources(requestId))
  }
  const [selectedRequest, setSelectedRequest] = React.useState(-1)
  const [openResources, setOpenResources] = React.useState<{resources: any, requestId: number}>({ resources: null, requestId: -1 })
  React.useEffect(() => {
    if (selectedRequest !== -1) {
      setOpenResources({ resources: resourcesList, requestId: selectedRequest })
    }
  }, [resourcesList])
  return (
    <React.Fragment>
      <AppLayout breadcrumb={BREAD_CRUB.MANAGE_RESOURCE_REQUESTS} componentTitle="Manage Resource Request">
      <Grid container direction={'row'} alignItems="center" sx={{ paddingBottom: 2 }}>
          <Grid item md={6}>
        <p style={{
          paddingBottom: 7,
          margin: 0,
          fontWeight: 400,
          fontSize: '24px'
        }}>Manage Resource Request</p>
        </Grid>
        <Grid item md={6}>
        <Stack style={{ float: 'right' }} direction={'row'}>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_RESOURCE_REQUEST]}
              >
                <Button
                  variant="contained"
                  onClick={() => { navigate('/resource-request-create') }}
                  sx={{ textTransform: 'capitalize' }}
                >
                  Create Resource Request
                </Button>
              </AppAuthorizer>
            </Stack>
              </Grid></Grid>
        <Divider className="dividerStyle" />
        <br />
        <Box sx={{ width: '100%', typography: 'body1' }}>
        {updateRequestStatusAlert.message && (
          <Alert
          onClose={clearRequestStatusAlert}
          severity={updateRequestStatusAlert.severity}
          >
          {updateRequestStatusAlert.message}
          </Alert>

        )}
        {closeRequestAlert.message && (
          <Alert
          onClose={clearCloseRequestAlert}
          severity={closeRequestAlert.severity}
          >
          {closeRequestAlert.message}
          </Alert>

        )}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="New Requests" value="1" style={{ textTransform: 'capitalize' }}/>
            <Tab label="Replacement Requests" value="2" style={{ textTransform: 'capitalize' }}/>
          </TabList>
        </Box>
        <Box sx={{ width: '100%', typography: 'body1', padding: '10px' }}>
        <ResourceRequestFilter
              handleChangeFilterForm={handleChangeFilterForm}
              handleFilterClear={handleFilterClear}
              clientHierachyIsLoading={clientHierachyIsLoading}
              clientHierarchyProjectList={clientHierarchyProjectList || []}
              filterFormData={filterFormData}
              clientHierachy={clientHierarchyClientList || []}
              handleFilter={handleFilter}
              sbuList={entitledSbuList?.data || []}
              statusList={statusList?.data || []}
              />
              </Box>
              {updateResourcesAlertRequest.message && (
          <Alert
            className="mb-m"
            onClose={clearUpdateResourcesAlertRequest}
            severity={updateResourcesAlertRequest.severity}
          >
            {updateResourcesAlertRequest.message}
          </Alert>
              )}
              {updateRequestAlertRequest.message && (
          <Alert
            className="mb-m"
            onClose={clearUpdateRequestAlertRequest}
            severity={updateRequestAlertRequest.severity}
          >
            {updateRequestAlertRequest.message}
          </Alert>
              )}
        <TabPanel value="1">
          <ResourceRequestsViewTable
            resourceRequestsList={resourceRequestsList?.data?.filter((x: { requestType: number }) => x.requestType === selectedRequestType) || []}
            resourceRequestListIsLoading={resourceRequestListIsLoading}
            page={page}
            rowsPerPage={rowsPerPage}
            selectedRequestType={selectedRequestType}
            editRowData={editRowData}
            setEditRowData={setEditRowData}
            parentEditRowData={parentEditRowData}
            setParentEditRowData={setParentEditRowData}
            isEditingRow={isEditingRow}
            setIsEditingRow={setIsEditingRow}
            disabledChildRow={disabledChildRow}
            updateRequest={updateRequest}
            isParentEditingRow={isParentEditingRow}
            setIsParentEditingRow={setIsParentEditingRow}
            activeUserRole={activeUserRole}
            resourceActionLoading={resourceActionLoading}
            sbuList={allSbuList?.data || []}
            getProposedRequests={getProposedRequests}
            resourcesList={resourcesList}
            resourcesListIsLoading={resourcesListIsLoading}
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            openResources={openResources}
            setOpenResources={setOpenResources}
            setExpanded={setExpandedResource}
            expanded={expandedResource}
            // updateRequestStatusAlert={updateRequestStatusAlert}
            />
            <AppTablePagination
              data={resourceRequestsList?.data?.filter((x: { requestType: number }) => x.requestType === selectedRequestType) || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TabPanel>
        <TabPanel value="2">
        <ResourceRequestsViewTable
            resourceRequestsList={resourceRequestsList?.data?.filter((x: { requestType: number }) => x.requestType === selectedRequestType) || []}
            resourceRequestListIsLoading={resourceRequestListIsLoading}
            page={pageReplacements}
            rowsPerPage={rowsPerPageReplacements}
            selectedRequestType={selectedRequestType}
            editRowData={editRowData}
            setEditRowData={setEditRowData}
            parentEditRowData={parentEditRowData}
            setParentEditRowData={setParentEditRowData}
            isEditingRow={isEditingRow}
            setIsEditingRow={setIsEditingRow}
            disabledChildRow={disabledChildRow}
            updateRequest={updateRequest}
            isParentEditingRow={isParentEditingRow}
            setIsParentEditingRow={setIsParentEditingRow}
            activeUserRole={activeUserRole}
            resourceActionLoading={resourceActionLoading}
            sbuList={allSbuList?.data || []}
            getProposedRequests={getProposedRequests}
            resourcesList={resourcesList}
            resourcesListIsLoading={resourcesListIsLoading}
            selectedRequest={selectedRequest}
            setSelectedRequest={setSelectedRequest}
            openResources={openResources}
            setOpenResources={setOpenResources}
            setExpanded={setExpandedResource}
            expanded={expandedResource}
            />
            <AppTablePagination
              data={resourceRequestsList?.data?.filter((x: { requestType: number }) => x.requestType === selectedRequestType) || []}
              page={pageReplacements}
              rowsPerPage={rowsPerPageReplacements}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TabPanel>
      </TabContext>
    </Box>
      </AppLayout>
    </React.Fragment>
  )
}

export default ResourceRequestView
