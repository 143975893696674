import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'
import rootSaga from '../sagas'
import { createLogger } from 'redux-logger'

const logger = createLogger()
const sagaMiddleware = createSagaMiddleware()

let _applyMiddleware

if (process.env.APP_ENV === 'local' || process.env.APP_ENV === 'dev') {
  _applyMiddleware = applyMiddleware(
    logger,
    sagaMiddleware

  )
} else {
  _applyMiddleware = applyMiddleware(
    sagaMiddleware

  )
}

const store = createStore(
  // console.log();
  rootReducer,
  composeWithDevTools(
    _applyMiddleware
  )
)

sagaMiddleware.run(rootSaga)

export default store
