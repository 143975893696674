import { put, takeEvery, call } from 'redux-saga/effects'
import { configService } from '../../services'
import { COMMON_ACTION_TYPES, HERA_CONFIG_ACTION_TYPES } from '../../utilities/constants/action.constants'

function * getResourceManagementConfig (action: { type: string }) {
  try {
    // @ts-ignore
    const configList = yield call(configService.getResourceManagementConfig, action.params)
    yield put({
      type:
      HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.SUCCESS,
      data: configList.data
    })
  } catch (error) {
    yield put({
      type: HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * configSaga () {
  yield takeEvery(
    HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.REQUEST,
    getResourceManagementConfig
  )
}
export default configSaga
