
import { Card, CardContent, Grid, Popover, Typography } from '@mui/material'
import React from 'react'
import { DesignationWiseEmpCountDto, TierPhyramidDataDto, TierwiseDataDto, TierWiseEmpCountDto } from '../../../utilities/models'
import styles from './TierPhyramid.module.scss'
const TierPhyramid: React.FC<{
  handlePopoverClose(): void;
  handlePopoverOpen(event: React.MouseEvent<HTMLElement>, popno: number): void;
  openPopup: boolean;
  anchorEl: any;
  tierWiseCount: TierWiseEmpCountDto[]
  TierPhyramidChartData(): TierPhyramidDataDto
  tierWiseCountData: TierwiseDataDto
  popno: number
}> = (props) => {
  const phyramidData = props.TierPhyramidChartData()
  return (
    <React.Fragment>
      <Grid container justifyContent={'center'} spacing={2}>

        {props.tierWiseCountData.isLoading &&
          <Grid>
            <div className={styles.pyramidContainer}>
              <Typography className={styles.loadingStyle}>
                Loading Data...
              </Typography>
            </div></Grid>

        }

        {(!props.tierWiseCountData.isLoading && props.tierWiseCountData?.data.length === 0) &&
          <Grid>
            <div className={styles.pyramidContainer}>
              <Typography className={styles.loadingStyle}>
                No data to preview
              </Typography>
            </div></Grid>

        }
        {
          (!props.tierWiseCountData.isLoading && props.tierWiseCountData?.data.length > 0) &&
          <Grid>
            <div className={styles.pyramidContainer}>
              <div
                onMouseEnter={e => props.handlePopoverOpen(e, 1)}
                onMouseLeave={props.handlePopoverClose}
                id="pyramidLevel4">
                <div className="layout-row">
                  <span className={styles.countSpan3}>{phyramidData.tier01.totalCount}</span>
                  <span className={styles.textSpan}>{'Tier - 01'}</span>
                </div>
              </div>
              {props.popno === 1 &&
                <Popover
                  id="mouse-over-popover"
                  disableScrollLock={true}
                  sx={{
                    pointerEvents: 'none'
                  }}
                  open={props.openPopup}
                  anchorEl={props.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={props.handlePopoverClose}
                  disableRestoreFocus
                >
                  <Card sx={{ minWidth: 390 }} >
                    <CardContent sx={{ backgroundColor: '#ebecf2', padding: '8px' }}>
                      <div className="layout-row">
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item md={10}>
                            <Typography className={styles.popupTitle} >
                              {`${phyramidData.tier01.tierName}`}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={styles.popupTitle}>
                              {phyramidData.tier01.totalCount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                    </CardContent>
                    {phyramidData.tier01.totalCount > 0 &&
                     <CardContent>
                     {
                         (phyramidData.tier01.designationList?.length > 0) &&
                         (!props.tierWiseCountData.isLoading) &&
                         (phyramidData.tier01.designationList).map((desig: DesignationWiseEmpCountDto, index) => (
                           <React.Fragment key={index}>
                             {desig.count > 0
                               ? <div className="layout-row">
                                 <Grid container justifyContent={'center'} spacing={2}>
                                   <Grid item md={10}>
                                     <Typography >
                                       {desig.designationName}
                                     </Typography>
                                   </Grid>
                                   <Grid item md={2}>
                                     <Typography >
                                       {desig.count}
                                     </Typography>
                                   </Grid>
                                 </Grid>
                               </div>
                               : null}
                           </React.Fragment>
                         ))

                       }
                     </CardContent>
                    }

                  </Card>
                </Popover>
              }

              <div id="pyramidLevel3"
                onMouseEnter={e => props.handlePopoverOpen(e, 2)}
                onMouseLeave={props.handlePopoverClose}
              >
                <div className="layout-row">
                  <span className={styles.countSpan} >{phyramidData.tier02.totalCount}</span>
                  <span className={styles.textSpan} >{'Tier - 02'}</span>
                </div>
              </div>
              {props.popno === 2 &&
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none'
                  }}
                  className={styles.popOverStyle}
                  open={props.openPopup}
                  anchorEl={props.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={props.handlePopoverClose}
                  disableRestoreFocus
                >
                  <Card sx={{ minWidth: 390 }}>
                    <CardContent sx={{ backgroundColor: '#ebecf2', padding: '8px' }}>
                      <div className="layout-row">
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item md={10}>
                            <Typography className={styles.popupTitle} >
                              {`${phyramidData.tier02.tierName} `}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={styles.popupTitle}>
                              {phyramidData.tier02.totalCount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                    </CardContent>
                    {phyramidData.tier02.totalCount > 0 &&

                    <CardContent>
                    {
                        (phyramidData.tier02.designationList?.length > 0)
                          ? ((!props.tierWiseCountData.isLoading) &&
                        (phyramidData.tier02.designationList).map((desig: DesignationWiseEmpCountDto, index) => (
                          <React.Fragment key={index}>
                            {desig.count > 0
                              ? <div className="layout-row">
                                <Grid container justifyContent={'center'} spacing={2}>
                                  <Grid item md={10}>
                                    <Typography >
                                      {desig.designationName}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={2}>
                                    <Typography >
                                      {desig.count}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              : null}
                          </React.Fragment>
                        )))
                          : null

                      }
                    </CardContent>
}
                  </Card>
                </Popover>
              }

              <div id="pyramidLevel2"
                onMouseEnter={e => props.handlePopoverOpen(e, 3)}
                onMouseLeave={props.handlePopoverClose}
              >
                <div className="layout-row">
                  <span className={styles.countSpan1}>{phyramidData.tier03.totalCount}</span>
                  <span className={styles.textSpan}>{'Tier - 03'}</span>
                </div>
              </div>
              {props.popno === 3 &&
                <Popover
                  id="mouse-over-popover"
                  disableScrollLock={true}
                  sx={{
                    pointerEvents: 'none',
                    overflow: 'visible'
                  }}
                  open={props.openPopup}
                  anchorEl={props.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={props.handlePopoverClose}
                  disableRestoreFocus
                >
                  <Card sx={{ minWidth: 390 }}>
                    <CardContent sx={{ backgroundColor: '#ebecf2', padding: '8px' }}>
                      <div className="layout-row">
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item md={10}>
                            <Typography className={styles.popupTitle} >
                              {`${phyramidData.tier03.tierName} `}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={styles.popupTitle}>
                              {phyramidData.tier03.totalCount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                    </CardContent>
                    {phyramidData.tier03.totalCount > 0 &&

                    <CardContent>
                    {
                        (phyramidData.tier03.designationList?.length > 0)
                          ? ((!props.tierWiseCountData.isLoading) &&
                        (phyramidData.tier03.designationList).map((desig: DesignationWiseEmpCountDto, index) => (
                          <React.Fragment key={index}>
                            {desig.count > 0
                              ? <div className="layout-row">
                                <Grid container justifyContent={'center'} spacing={2}>
                                  <Grid item md={10}>
                                    <Typography >
                                      {desig.designationName}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={2}>
                                    <Typography >
                                      {desig.count}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              : null
                            }

                          </React.Fragment>
                        ))
                            )
                          : null
                      }
                    </CardContent>}
                  </Card>
                </Popover>
              }

              <div id="pyramidLevel1"
                onMouseEnter={e => props.handlePopoverOpen(e, 4)}
                onMouseLeave={props.handlePopoverClose}
              >
                <div className="layout-row">
                  <span className={styles.countSpan2}>{phyramidData.tier04.totalCount}</span>
                  <span className={styles.textSpan}>{'Tier - 04'}</span>
                </div>
              </div>
              {props.popno === 4 &&
                <Popover
                  id="mouse-over-popover"
                  disableRestoreFocus={true}
                  sx={{
                    pointerEvents: 'none',
                    cursor: 'pointer'

                  }}
                  open={props.openPopup}
                  anchorEl={props.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={props.handlePopoverClose}
                >
                  <Card sx={{ minWidth: 390 }}>
                    <CardContent sx={{ backgroundColor: '#ebecf2', padding: '8px' }}>
                      <div className="layout-row">
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item md={10}>
                            <Typography className={styles.popupTitle} >
                              {`${phyramidData.tier04.tierName} `}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography className={styles.popupTitle}>
                              {phyramidData.tier04.totalCount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                    </CardContent>
                    {phyramidData.tier04.totalCount > 0 &&
                    <CardContent>
                    {
                        (phyramidData.tier04.designationList?.length > 0)
                          ? ((!props.tierWiseCountData.isLoading) &&
                        (phyramidData.tier04.designationList).map((desig: DesignationWiseEmpCountDto, index) => (
                          <React.Fragment key={index}>
                            {desig.count > 0
                              ? <div className="layout-row">
                                <Grid container justifyContent={'center'} spacing={2}>
                                  <Grid item md={10}>
                                    <Typography >
                                      {desig.designationName}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={2}>
                                    <Typography >
                                      {desig.count}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                              : null}
                          </React.Fragment>
                        )))
                          : (
                              null
                            )

                      }
                    </CardContent>
}
                  </Card>
                </Popover>
              }

            </div>
          </Grid>
        }
      </Grid>

    </React.Fragment >
  )
}

export default TierPhyramid
