import { SBU_ACTION_TYPES, COMMON_ACTION_TYPES }
  from '../../utilities/constants/action.constants'
import { CreateSbuParamDto, GetDisabledSbusParamDto, UpdateSbuParamDto } from '../../utilities/models'

const allSbuList = (params : GetDisabledSbusParamDto) => {
  return {
    type: SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}

const entitledSbuList = (params : GetDisabledSbusParamDto) => {
  return {
    type: SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const createSbu = (payload: CreateSbuParamDto) => {
  return {
    type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const updateSbu = (payload: UpdateSbuParamDto) => {
  return {
    type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}

export const sbuAction = {
  allSbuList,
  createSbu,
  updateSbu,
  entitledSbuList

}
