import { AuthorizedUserInfo } from './../../utilities/models/auth.model'
import { AUTH_ACTION_TYPES, COMMON_ACTION_TYPES } from '../../utilities/constants'
import { AuthStateDto, UserRoleDto } from '../../utilities/models'

const INITIAL_STATE: AuthStateDto = {
  userAuthorizing: {
    isAuthorized: false,
    isLoading: false,
    error: null
  },
  authorizedUser: {
    data: {} as AuthorizedUserInfo,
    isLoading: false,
    isFetched: false,
    error: null
  },
  authorizedUserRoles: {
    data: [],
    isLoading: false,
    isFetched: false,
    error: null
  },
  activeUserRole: {
    data: {} as UserRoleDto,
    isLoading: false,
    isFetched: false,
    error: null
  }
}

const authReducer = (state = INITIAL_STATE, action: any): AuthStateDto => {
  switch (action.type) {
    // user authorizing
    case AUTH_ACTION_TYPES.AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        userAuthorizing: {
          ...state.userAuthorizing,
          isLoading: true
        }
      }
    case AUTH_ACTION_TYPES.AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        userAuthorizing: {
          isLoading: false,
          isAuthorized: true,
          error: null
        }
      }
    case AUTH_ACTION_TYPES.AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        userAuthorizing: {
          isLoading: false,
          error: action.error,
          isAuthorized: false
        }
      }
    // fetch authorized user
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.REQUEST:

      return {
        ...state,
        authorizedUser: {
          ...state.authorizedUser,
          isLoading: true,
          isFetched: false
        }
      }
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        authorizedUser: {
          isLoading: false,
          data: action.data,
          isFetched: true,
          error: null
        }
      }
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        authorizedUser: {
          isLoading: false,
          isFetched: true,
          data: {} as AuthorizedUserInfo,
          error: action.error
        }
      }
    // fetch authorized user roles
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER_ROLES + COMMON_ACTION_TYPES.REQUEST:

      return {
        ...state,
        authorizedUserRoles: {
          ...state.authorizedUserRoles,
          isLoading: true,
          isFetched: false
        }
      }
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER_ROLES + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        authorizedUserRoles: {
          isLoading: false,
          data: action.data,
          isFetched: true,
          error: null
        }
      }
    case AUTH_ACTION_TYPES.FETCH_AUTHORIZE_USER_ROLES + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        authorizedUserRoles: {
          isLoading: false,
          isFetched: true,
          data: [],
          error: action.error
        }
      }
    // set active user roles
    case AUTH_ACTION_TYPES.FETCH_ACTIVE_USER_ROLE + COMMON_ACTION_TYPES.REQUEST:

      return {
        ...state,
        activeUserRole: {
          ...state.activeUserRole,
          isFetched: false,
          isLoading: true
        }
      }
    case AUTH_ACTION_TYPES.FETCH_ACTIVE_USER_ROLE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        activeUserRole: {
          isLoading: false,
          data: action.data,
          isFetched: true,
          error: null
        }
      }
    case AUTH_ACTION_TYPES.FETCH_ACTIVE_USER_ROLE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        activeUserRole: {
          isLoading: false,
          isFetched: true,
          data: {} as UserRoleDto,
          error: action.error
        }
      }
    default:
      return state
  }
}

export default authReducer
