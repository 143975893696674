/* eslint-disable no-unused-vars */

export enum APP_ROUTES {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  PROFILE = '/user-profile',
  SBU_MANAGEMENT = '/sbu-management',
  PROJECT_MANAGEMENT = '/project-management',
  CLIENT_MANAGEMENT = '/client-management',
  ALLOCATION_MANAGEMENT = '/allocation-management',
  EMPLOYEE_INFORMATION = '/allocation-management/:userEmail',
  SUPERVISOR_MANAGEMENT = '/external-supervisor-management',
  HERA_REPORT = '/reports/allocation-report',
  HERA_FINANCE_REPORT = '/reports/project-detail-report',
  CREATE_RESOURCE_REQUEST = '/resource-request-create',
  VIEW_RESOURCE_REQUESTS ='/resource-requests-view'

}
