import { delay, put, takeEvery } from 'redux-saga/effects'
import { CLIENT_ACTION_TYPES, COMMENTS_ACTION_TYPES, COMMON_ACTION_TYPES, EMPLOYEE_ACTION_TYPES, REPORT_ACTION_TYPES, RESIGNATION_DETAILS_TYPES, RESOURCE_ACTION_TYPES, SBU_ACTION_TYPES, SUPERVISOR_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AlertActionDto } from '../../utilities/models'

function * createSbuAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}

function * updateSbuAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * createClientAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * updateClientAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * allocateEmployeeALert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * deallocateEmployeeALert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * updateEmployeeAllocationAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * createSupervisorAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * unassignSupervisorAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * validateEmplyeeAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * filterReportAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * addCommentAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * addResignationCommentAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * createResourceAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * createResourceCommentAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}

function * deleteProposedResourceAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}

function * confirmProposedResourcesAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}

function * proposedResourcesActionAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}

function * closeRequestAlert (action: AlertActionDto) {
  try {
    const setAlert: AlertActionDto = {
      type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT,
      message: action.message,
      severity: action.severity
    }
    yield put(setAlert)
  } finally {
    if (action.autoClear) {
      yield delay(action.timeout ? action.timeout : 0)
      yield put({ type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT })
    }
  }
}
function * alertSaga () {
  yield takeEvery(SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT_REQ, createSbuAlert)
  yield takeEvery(SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT_REQ, updateSbuAlert)
  yield takeEvery(CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT_REQ, createClientAlert)
  yield takeEvery(CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT_REQ, updateClientAlert)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ, allocateEmployeeALert)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ, deallocateEmployeeALert)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT_REQ, updateEmployeeAllocationAlert)
  yield takeEvery(SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT_REQ, createSupervisorAlert)
  yield takeEvery(SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT_REQ, unassignSupervisorAlert)
  yield takeEvery(EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT_REQ, validateEmplyeeAlert)
  yield takeEvery(REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.SET_ALERT_REQ, filterReportAlert)
  yield takeEvery(COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ, addCommentAlert)
  yield takeEvery(RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ, addResignationCommentAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT_REQ, createResourceAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT_REQ, createResourceCommentAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT_REQ, deleteProposedResourceAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT_REQ, confirmProposedResourcesAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT_REQ, proposedResourcesActionAlert)
  yield takeEvery(RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT_REQ, closeRequestAlert)
}

export default alertSaga
