import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { APP_CONFIGS, DATE_FORMAT } from '../../../utilities/constants'
import { AllocationDto, DeallocateEmployeeParams, EmployeeDto } from '../../../utilities/models'
import moment from 'moment'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import styles from './EmployeeDeallocationPopup.module.scss'

const EmployeeDeallocationPopup: React.FC<{
  selectedEmployee: EmployeeDto
  selectedProject: AllocationDto
  deallocateEmployeeDialogOpen: boolean
  deallocateEmployee(arg: DeallocateEmployeeParams): void
  toggleDeallocateEmployeeDialog(): void
}> = (props) => {
  const deallocateEmployee = () => {
    const data: DeallocateEmployeeParams = {
      userId: props.selectedEmployee.userId,
      allocationId: props.selectedProject.allocationId,
      isConfirmed: props.selectedProject.isConfirmed !== true,
      applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
    }
    props.deallocateEmployee(data)
  }
  return (
    <BootstrapDialog

      // className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.deallocateEmployeeDialogOpen}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={props.toggleDeallocateEmployeeDialog}>
        Please Confirm
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>

         {props.selectedProject.isConfirmed === false
           ? <>
          <Typography gutterBottom>
          Do you want to <b> confirm the deallocation </b>
          &#39;{props.selectedEmployee?.userName}&#39;
          from project &#39;{props.selectedProject?.projectName}&#39; ? <br/>

        </Typography>
        {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT) >
          moment(new Date()).format(DATE_FORMAT)
          ? <div className={'layout-row worningStyle'}>
            <WarningAmberOutlinedIcon className={styles.iconStyle} />
            <Typography >
              Allocation End Date ( {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT)} ) is not Expired <br />
              So, He/She will be permanently deallocated from {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT)}
              <p style={{ fontSize: '12px', marginTop: '6px', marginBottom: '6px' }}>( Deallocation will be done through scheduler which runs on everyday midnight. )</p>
            </Typography>

          </div>
          : <div className={'layout-row worningStyle'}>
            <Typography >
              He/She will be permanently deallocated from {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT)}
              <p style={{ fontSize: '12px', marginTop: '6px', marginBottom: '6px' }}>( Deallocation will be done through scheduler which runs on everyday midnight. )</p>
            </Typography>
          </div>
        }
        </>
           : <>
        <Typography gutterBottom>
        Do you want to <b>Revoke the deallocation confirmation </b>
        &#39;{props.selectedEmployee?.userName}&#39;
        from project &#39;{props.selectedProject?.projectName}&#39; ?
      </Typography>
      {/* {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT) >
        moment(new Date()).format(DATE_FORMAT)
        ? <div className={'layout-row worningStyle'}>
          <WarningAmberOutlinedIcon className={styles.iconStyle} />
          <Typography >
            Allocation End Date ( {moment(props.selectedProject.projectEndDate).format(DATE_FORMAT)} ) is not Expired
          </Typography>
        </div>
        : null
      } */}
      </>
         }

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={'btnStyle'}
          onClick={deallocateEmployee}>
          Confirm
        </Button>
        <Button
          variant="contained"
          className={'btnStyle'}
          color={'inherit'}
          onClick={props.toggleDeallocateEmployeeDialog}>
          Cancel
        </Button>
      </DialogActions >
    </BootstrapDialog >
  )
}

export default EmployeeDeallocationPopup
