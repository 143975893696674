import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import styles from './ResourceRequestFilter.module.scss'
import { UserClientHierarchyDto, UserClientHierarchyTeamsDto, InitialSbuDto } from '../../../utilities/models'
import { FilterDto, RmStatus } from '../../../utilities/models/resourceRequest.model'
// import { v4 as uuid } from 'uuid'
// const filter = createFilterOptions<InitialSbuDto>()

const ResourceRequestFilter: React.FC<{
  filterFormData: FilterDto;
  handleChangeFilterForm(property: string, value: any): void;
  handleFilterClear(): void;
  clientHierachy: UserClientHierarchyDto[];
  clientHierarchyProjectList: UserClientHierarchyTeamsDto[]
  clientHierachyIsLoading: boolean;
  handleFilter(): void;
  sbuList: InitialSbuDto[]
  statusList: RmStatus[]
}> = (props) => {
  // interface FilmOptionType {
  //   name: string;
  //   id: number;
  // }
  // const statuses: readonly FilmOptionType[] = [
  //   { name: 'Pending', id: 1 },
  //   { name: 'Approved', id: 2 },
  //   { name: 'Rejected', id: 3 },
  //   { name: 'Review and Accepted', id: 4 },
  //   { name: 'Review and Rejected', id: 5 },
  //   { name: 'Incomplete', id: 6 },
  //   { name: 'Closed', id: 7 },
  //   { name: 'In Progress', id: 8 },
  //   { name: 'Pending evaluation', id: 9 },
  //   { name: 'Evaluation completed', id: 10 },
  //   { name: 'Completed', id: 11 }
  // ]
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container spacing={2}>
          <Grid item md={2}>
          <Autocomplete
              color="primary"
              size="small"
              value={props.filterFormData.sbu.value}
              options={props.sbuList.map((sbu) => {
                return { name: sbu.sbuName, id: sbu.sbuId }
              })}
              disabled={props.clientHierachyIsLoading}
              onChange={(event, value) =>
                props.handleChangeFilterForm('sbu', value)
              }
              isOptionEqualToValue={(option, value) =>
                value === undefined || option.name === value.name
              }
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) => (
                <TextField {...params}
                helperText={props.filterFormData.sbu.error}
                error={props.filterFormData.sbu.error !== null}
                label="SBU" />
              )}
            />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              size={'small'}
                    value={props.filterFormData.clientName.value}
                    // onInputChange={(event, newValue) => {
                    //   let value
                    //   if (typeof newValue === 'string') {
                    //     value = {
                    //       clientId: null,
                    //       sbuId: null,
                    //       clientName: newValue,
                    //       teams: []
                    //     }
                    //   } else {
                    //     value = newValue
                    //   }
                    //   props.handleChangeFilterForm('clientName', value)
                    // }}
                    onChange={(event, newValue) => {
                      // let value
                      // if (typeof newValue === 'string') {
                      //   value = {
                      //     clientId: null,
                      //     sbuId: null,
                      //     clientName: newValue,
                      //     teams: []
                      //   }
                      // } else {
                      //   value = newValue
                      // }
                      props.handleChangeFilterForm('clientName', newValue)
                    }}
                    options={props.clientHierachy}
                    getOptionLabel={(option) => option.clientName || ''}
                    //   if (typeof option === 'string') {
                    //     return option
                    //   }
                    //   return option.clientName || ''
                    // }}
                    // renderOption={(props, option) => <li {...props}>{option.clientName}</li>}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.clientName.isRequired}
                      helperText={props.filterFormData.clientName.error}
                      error={props.filterFormData.clientName.error !== null}
                      label="Client" />
                    )}
                  />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              size={'small'}
              value={props.filterFormData.projectName.value}
                    // onInputChange={(event, newValue) => {
                    //   let value
                    //   if (typeof newValue === 'string') {
                    //     value = {
                    //       teamId: null,
                    //       teamName: newValue,
                    //       users: []
                    //     }
                    //   } else {
                    //     value = newValue
                    //   }
                    //   props.handleChangeFilterForm('projectName', value)
                    // }}
                    onChange={(event, newValue) => {
                      // let value
                      // if (typeof newValue === 'string') {
                      //   value = {
                      //     teamId: null,
                      //     teamName: newValue,
                      //     users: []
                      //   }
                      // } else {
                      //   value = newValue
                      // }
                      props.handleChangeFilterForm('projectName', newValue)
                    }}
                    options={props.clientHierarchyProjectList}
                    getOptionLabel={(option) => option.teamName || ''}
                    //   if (typeof option === 'string') {
                    //     return option
                    //   }
                    //   return option.teamName || ''
                    // }}
                    // renderOption={(props, option) => <li {...props}>{option.teamName}</li>}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.projectName.isRequired}
                      helperText={props.filterFormData.projectName.error}
                      error={props.filterFormData.projectName.error !== null}
                      label="Project" />
                    )}
                  />
            </Grid>
            <Grid item md={2}>
                <Autocomplete
                    size={'small'}
                    value={props.filterFormData.status.value}
                    // defaultValue={{ name: 'Pending', id: 1 }}
                    options={props.statusList?.filter((status: RmStatus) => status.type === 1)}
                    onChange={(event, newValue) => { props.handleChangeFilterForm('status', newValue) }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.status.isRequired}
                      helperText={props.filterFormData.status.error}
                      error={props.filterFormData.status.error !== null}
                      label="Status" />
                    )}
                  />
            </Grid>
            <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
            <Button
                className={styles.clearBtnStyle}
                color="inherit"
                variant="contained"
                onClick={props.handleFilterClear}
              >
                Clear
              </Button>
            <Button
                className="filterBtn"
                color="primary"
                variant="contained"
                onClick={props.handleFilter}
              >
                Filter
              </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ResourceRequestFilter
