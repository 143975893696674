import { COMMON_ACTION_TYPES, REPORT_ACTION_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  reportDataList: {
    isLoading: false,
    data: []
  },
  financeReportDataList: {
    isLoading: false,
    data: []
  }
}
const reportReducer = (state = INITIAL_STATE, action : any) => {
  switch (action.type) {
    case REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        reportDataList: {
          ...state.reportDataList,
          isLoading: true
        }
      }
    case REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        reportDataList: {
          ...state.reportDataList,
          isLoading: false,
          data: action.data
        }
      }
    case REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        reportDataList: {
          ...state.reportDataList,
          isLoading: false,
          data: []
        }
      }
    case REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        financeReportDataList: {
          ...state.financeReportDataList,
          isLoading: true
        }
      }
    case REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        financeReportDataList: {
          ...state.financeReportDataList,
          isLoading: false,
          data: action.data
        }
      }
    case REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        financeReportDataList: {
          ...state.financeReportDataList,
          isLoading: false,
          data: []
        }
      }
    case REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        reportDataList: {
          ...state.reportDataList,
          isLoading: false,
          data: []

        }
      }
    case REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.CLEAR:
      return {
        ...state,
        financeReportDataList: {
          ...state.financeReportDataList,
          isLoading: false,
          data: []

        }
      }
    default:
      return state
  }
}

export default reportReducer
