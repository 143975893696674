import React, { useState } from 'react'
import { CloseOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material'
import InsertCommentIcon from '@mui/icons-material/InsertComment'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { Box, Grid, IconButton, LinearProgress, TextField, Autocomplete, Tooltip, Typography } from '@mui/material'
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined'
import { HtmlTooltip, StyledChildTableRow, StyledTableCell } from '../../../assets/theme/theme'
import { APP_FEATURE_KEYS, DATE_FORMAT, TIME_COLORED_ALLOWED_DAYS } from '../../../utilities/constants'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'
import { AllocationDto, EmployeeDto, EmployeeEditFormDto, InitBriefProjectRoleDto, InitProjectRoleDto, UpdateEmployeeAllocationDto } from '../../../utilities/models'
import styles from './EmployeeViewTableChildRows.module.scss'
import { validateFormData } from '../../../utilities/helpers/FormValidator'
import { AppAuthorizer } from '../../shared'
const EmployeeViewTableChildRows = (props: {
  briefProjectRoleList: InitProjectRoleDto[]
  employee: EmployeeDto
  project: AllocationDto
  index: string
  activeUserRole: any,
  selectedEmployee: EmployeeDto | null
  selectedProject: AllocationDto | null
  isRowEditing: boolean
  setSelectedEmployee(arg: EmployeeDto): void
  setSelectedProject(arg: AllocationDto): void
  setIsRowEditing(arg: boolean): void
  toggleDrawer(row: any): void
  toggleDeallocateEmployeeDialog(): void
  toggleUpdateAllocationDialog(): void
  handleUpdateData(data: UpdateEmployeeAllocationDto): void
  toggleAallocationCommentPopup(): void
  deallocationCustomAlert(message: string): void
}) => {
  const INITIAL_EDITING_ROW_STATE: EmployeeEditFormDto = {
    projectRoleDisplayName: {
      value: props.selectedProject != null
        ? {
          id: props.selectedProject.projectRoleId ? props.selectedProject.projectRoleId : props.briefProjectRoleList?.find((role) => role.projectRoleName === props.employee.designation)?.projectRoleId,
          name: props.selectedProject.projectRoleDisplayName ? props.selectedProject.projectRoleDisplayName : props.briefProjectRoleList?.find((role) => role.projectRoleName === props.employee.designation)?.projectRoleDisplayName
        } as InitBriefProjectRoleDto
        : {} as InitBriefProjectRoleDto,
      isRequired: true,
      error: null,
      validator: 'object'
    },
    startDate: {
      value: props.project.projectStartDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    endDate: {
      value: props.project.projectEndDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    allocationPercentage: {
      value: props.project.allocationPercentage != null ? props.project.allocationPercentage.toString() : 100,
      isRequired: true,
      error: null,
      validator: 'number'
    },
    isBillable: {
      value: props.project.isBillable ? 'Billable' : 'Non-billable',
      isRequired: true,
      error: null,
      validator: 'text'
    },
    billingStartDate: {
      value: props.project.billingStartDate,
      isRequired: true,
      error: null,
      validator: 'date'
    },
    billingEndDate: {
      value: props.project.billingEndDate,
      isRequired: true,
      error: null,
      validator: 'date'

    },
    projectId: {
      value: props.project.projectId,
      isRequired: true,
      error: null,
      validator: 'text'
    }
  }
  const convBillable = (billable: boolean) => {
    return billable ? 'Billable' : 'Non-billable'
  }
  const [isEditingRow, setIsEditingRow] = useState(INITIAL_EDITING_ROW_STATE)
  const onEditHandleChange = (property: string, value: any) => {
    switch (property) {
      case 'startDate':
        if (value) {
          setIsEditingRow({
            ...isEditingRow,
            startDate: {
              ...isEditingRow[
              'startDate' as keyof typeof isEditingRow
              ],
              value: value

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: value

            }
          })
        }
        break
      case 'endDate':
        if (value) {
          setIsEditingRow({
            ...isEditingRow,
            endDate: {
              ...isEditingRow[
              'endDate' as keyof typeof isEditingRow
              ],
              value: value

            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: value

            }
          })
        }
        break
      case 'isBillable':
        if (value === 'Billable') {
          setIsEditingRow({
            ...isEditingRow,
            isBillable: {
              ...isEditingRow[
              'isBillable' as keyof typeof isEditingRow
              ],
              value: value

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: isEditingRow.startDate.value
            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: isEditingRow.endDate.value

            }
          })
        } else {
          setIsEditingRow({
            ...isEditingRow,
            isBillable: {
              ...isEditingRow[
              'isBillable' as keyof typeof isEditingRow
              ],
              value: value

            },
            billingStartDate: {
              ...isEditingRow[
              'billingStartDate' as keyof typeof isEditingRow
              ],
              value: null
            },
            billingEndDate: {
              ...isEditingRow[
              'billingEndDate' as keyof typeof isEditingRow
              ],
              value: null

            }
          })
        }
        break
      default:
        setIsEditingRow({
          ...isEditingRow,
          [property]: {
            ...isEditingRow[
            property as keyof typeof isEditingRow
            ],
            value: value
          }
        })
    }
  }
  const handleEdit = (data: AllocationDto) => {
    props.setSelectedEmployee(props.employee)
    props.setSelectedProject(props.project)
    props.setIsRowEditing(true)
  }
  const handleComment = (data: EmployeeDto) => {
    props.setSelectedEmployee(props.employee)
    props.setSelectedProject(props.project)
  }

  const setSelected = () => {
    props.setSelectedEmployee(props.employee)
    props.setSelectedProject(props.project)
  }
  const compareWithCurrentProject = () => {
    let isExist = false
    const currentProjectId = props.project.projectId
    if (props.employee.projectList.length > 0) {
      for (const project of props.employee.projectList) {
        if (currentProjectId === project.projectId) {
          if (moment(project.projectStartDate).format(DATE_FORMAT) > moment(props.project.projectEndDate).format(DATE_FORMAT)) {
            isExist = true
            break
          }
        }
      }
    }
    if (isExist === true) {
      props.deallocationCustomAlert('Can not revoke the deallocation. Already has a future allocation')
    } else {
      props.toggleDeallocateEmployeeDialog()
    }
  }
  const resetUpdateFields = () => {
    setIsEditingRow({
      ...isEditingRow,
      isBillable: {
        ...isEditingRow.isBillable,
        value: props.project.isBillable ? 'Billable' : 'Non-billable'
      },
      endDate: {
        ...isEditingRow.endDate,
        value: props.project.projectEndDate
      },
      allocationPercentage: {
        ...isEditingRow.allocationPercentage,
        value: props.project.allocationPercentage

      }
    })
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        error: null
      }
    })
  }
  const handleUpdateData = async () => {
    if (isEditingRow.isBillable.value !== 'Billable') {
      delete isEditingRow.billingEndDate
      delete isEditingRow.billingStartDate
    }

    const [validateData, isValid] = await validateFormData(isEditingRow)
    setIsEditingRow(validateData)
    if (isValid) {
      const value: boolean = isEditingRow.isBillable.value === 'Billable'
      const data: UpdateEmployeeAllocationDto = {
        projectRoleId: isEditingRow.projectRoleDisplayName.value.id,
        isBillable: value,
        startDate: moment(isEditingRow.startDate.value).format(DATE_FORMAT),
        endDate: moment(isEditingRow.endDate.value).format(DATE_FORMAT),
        billingStartDate: moment(isEditingRow.billingStartDate?.value).format(DATE_FORMAT),
        billingEndDate: moment(isEditingRow.billingEndDate?.value).format(DATE_FORMAT),
        allocationPercentage: isEditingRow.allocationPercentage.value,
        projectId: isEditingRow.projectId.value
      }
      props.handleUpdateData(data)
      props.setIsRowEditing(false)
      props.toggleUpdateAllocationDialog()
    }
  }
  return (
    <>
      <StyledChildTableRow
        className={(moment(props.project.projectEndDate).format(DATE_FORMAT) !== 'Invalid date' &&
          moment(props.project.projectEndDate).subtract(TIME_COLORED_ALLOWED_DAYS, 'days') <= moment() &&
          (moment(props.project.projectEndDate) >= moment()) && props.project.isConfirmed === false)
          ? 'future'
          : moment(props.project.projectEndDate).format(DATE_FORMAT) !== 'Invalid date' &&
            moment(props.project.projectEndDate).format(
              DATE_FORMAT
            ) <= moment(new Date()).format(DATE_FORMAT) && props.project.isConfirmed === false
            ? 'expired'
            : ''}>
        <StyledTableCell padding="checkbox" align="left" width={30} style={{ zIndex: 1, position: 'sticky', minWidth: 30, left: 0, backgroundColor: 'white', borderStyle: 'none', paddingLeft: 0, paddingRight: 0 }}></StyledTableCell>
        <StyledTableCell align="left" width={100} style={{ zIndex: 1, position: 'sticky', minWidth: 80, left: 30, maxWidth: 80, backgroundColor: 'white', borderStyle: 'none' }}>
        </StyledTableCell>
        <StyledTableCell align="left" width={165} style={{ zIndex: 1, position: 'sticky', minWidth: 165, left: 110, backgroundColor: 'white', borderStyle: 'none' }}>
        </StyledTableCell>
        <StyledTableCell align="left" width={100} style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
          {props.project.client}
        </StyledTableCell>
        <StyledTableCell align="left" width={100} style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
          {props.project.projectName}
        </StyledTableCell>
        <StyledTableCell align="center" width={150} style={{ minWidth: 150, maxWidth: 150 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
            props.selectedProject?.projectId === props.project.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? <Grid className='numberTextFielStyle' item md={6}>
              <Autocomplete
                size='small'
                disablePortal
                options={
                  props.briefProjectRoleList?.map((desig: any) => ({
                    id: desig.projectRoleId,
                    name: desig.projectRoleDisplayName,
                    roleName: desig.projectRoleName
                  })) || []
                }
                // renderOption={(props, option, state) => {
                //   return (
                //     <Tooltip placement="right"
                //     title={`${option.roleName}`}
                //     ><Typography style={{ textAlign: 'left', paddingLeft: '6px', paddingBottom: '6px' }}>{`${option.name}`}</Typography></Tooltip>
                //   )
                // }}

                getOptionLabel={(option) => option.name || ''}
                value={isEditingRow.projectRoleDisplayName.value}
                onChange={(event, value) => onEditHandleChange('projectRoleDisplayName', value)}
                disableClearable
                renderInput={(params) =>
                  <TextField variant="standard"
                    required
                    {...params} size={'small'}
                    sx={{ minWidth: 110, margin: 0, padding: 0, textAlign: 'start' }} />}
              />
            </Grid>

            : props.project.projectRoleName

              ? <Tooltip title={props.project.projectRoleName}>
                <Typography>
                  {props.project.projectRoleDisplayName}
                </Typography>
              </Tooltip>
              : <span className={styles.missingInfo}>- -</span>
          }
        </StyledTableCell>
        <StyledTableCell align="left" width={120} style={{ minWidth: 120, paddingLeft: 0 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
            props.selectedProject?.projectId === props.project.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={isEditingRow.startDate.value}
                inputFormat={DATE_FORMAT}
                closeOnSelect={true}
                onChange={(value) => onEditHandleChange('startDate', value)}
                // maxDate={isEditingRow.endDate.value !== null ? moment(isEditingRow.endDate.value) : undefined}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    required={true}
                    variant="standard"
                    size={'small'}
                    onFocus={() => handleInputFocus('startDate')}
                    error={isEditingRow.startDate.error !== null}
                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }} />}
              />
            </LocalizationProvider>
            : < >
              {props.project.projectStartDate && moment(props.project.projectStartDate).format(DATE_FORMAT) !== 'Invalid date'
                ? moment(props.project.projectStartDate).format(DATE_FORMAT)
                : <span className={styles.missingInfo}>- - / - - / - -</span>}

            </>
          }
        </StyledTableCell>
        <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
            props.selectedProject?.projectId === props.project.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={isEditingRow.endDate.value}
                inputFormat={DATE_FORMAT}
                onChange={(value) => onEditHandleChange('endDate', value)}
                minDate={isEditingRow.startDate.value ? moment(isEditingRow.startDate.value) : undefined}
                renderInput={(params) =>
                  <TextField
                    required={true}
                    {...params}
                    variant="standard"
                    size={'small'}
                    error={isEditingRow.endDate.error !== null}
                    onFocus={() => handleInputFocus('endDate')}

                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }}
                  />}
              />
            </LocalizationProvider>
            : <React.Fragment>
              {props.project.projectEndDate &&
                moment(props.project.projectEndDate).format(DATE_FORMAT) !== 'Invalid date'
                ? (moment(props.project.projectEndDate).format(DATE_FORMAT))

                : (<span className={styles.missingInfo}>- - / - - / - -</span>)
              }
            </React.Fragment>
          }
        </StyledTableCell>
        <StyledTableCell align="center" width={110} style={{ minWidth: 110 }}>
          <Grid container alignItems={'center'}>
            {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId && props.selectedProject?.projectId === props.project?.projectId &&
              props.selectedProject?.projectStartDate === props.project.projectStartDate &&
              props.selectedProject?.projectEndDate === props.project.projectEndDate
              ? <Grid className='numberTextFielStyle' item md={12}>
                <TextField variant="standard"
                  style={{ width: '70px' }}
                  required={true}
                  type={'number'}
                  size={'small'}
                  value={isEditingRow.allocationPercentage.value}
                  InputProps={{
                    inputProps: {
                      max: 100, min: 0
                    }
                  }}
                  onChange={(e) => onEditHandleChange('allocationPercentage', e.target.value)}
                  onFocus={() => handleInputFocus('allocationPercentage')}
                  error={isEditingRow.allocationPercentage.error !== null}
                ></TextField>
              </Grid>
              : <>
                {props.project.allocationPercentage !== null
                  ? <>
                    <Grid item md={4}>
                      <LinearProgress
                        style={{ textAlign: 'center' }}
                        variant='determinate'
                        color='primary'
                        value={props.project.allocationPercentage || 0} />
                    </Grid>
                    <Grid item md={2} ml={'5px'}>
                      <Typography align={'center'}>
                        {props.project.allocationPercentage || 0}%
                      </Typography>
                    </Grid>
                  </>
                  : <span className={styles.missingInfo}>- -</span>
                }
              </>}
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" width={110} style={{ minWidth: 110 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId && props.selectedProject?.projectId === props.project?.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? <Autocomplete
              size='small'
              disablePortal
              options={['Billable', 'Non-billable']}
              value={isEditingRow.isBillable.value}
              onChange={(event, value) => onEditHandleChange('isBillable', value)}
              onFocus={() => handleInputFocus('isBillable')}
              disableClearable
              renderInput={(params) =>
                <TextField variant="standard"
                  required={true}
                  error={isEditingRow.isBillable.error !== null}
                  {...params} size={'small'}
                  sx={{ maxWidth: 110 }} />}
            />
            : <>
              {
                (props.project.projectStartDate &&
                  props.project.projectEndDate)
                  ? `${convBillable(props.project.isBillable)}`
                  : <span className={styles.missingInfo}>- -</span>}
              {/* {convBillable(props.project.isBillable)} */}
            </>
          }
        </StyledTableCell>
        <StyledTableCell width={120} style={{ minWidth: 120 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
            props.selectedProject?.projectId === props.project.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingStartDate?.value : null}
                disabled={!(isEditingRow.isBillable.value === 'Billable')}
                inputFormat={DATE_FORMAT}
                onChange={(value) => onEditHandleChange('billingStartDate', value)}
                minDate={moment(isEditingRow.startDate?.value)}
                maxDate={moment(isEditingRow.endDate?.value)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    disabled={!(isEditingRow.isBillable.value === 'Billable')}
                    variant="standard"
                    size={'small'}
                    error={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingStartDate?.error !== null : false}
                    onFocus={() => handleInputFocus('billingStartDate')}
                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }}
                  // sx={{ maxWidth: 110, margin: 0, padding: 0 }}
                  />}
              />
            </LocalizationProvider>
            : <>
              {
                props.project.isBillable === false
                  ? <span >N/A</span>
                  : props.project.billingStartDate &&
                    moment(props.project.billingStartDate).format(DATE_FORMAT) !== 'Invalid date'
                    ? moment(props.project.billingStartDate).format(DATE_FORMAT)
                    : <span className={styles.missingInfo}>- - / - - / - -</span>
              }

            </>
          }
        </StyledTableCell>
        <StyledTableCell width={120} style={{ minWidth: 120 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId &&
            props.selectedProject?.projectId === props.project.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingEndDate?.value : null}
                disabled={!(isEditingRow.isBillable.value === 'Billable')}
                inputFormat={DATE_FORMAT}
                onChange={(value) => onEditHandleChange('billingEndDate', value)}
                minDate={moment(isEditingRow.startDate?.value)}
                maxDate={moment(isEditingRow.endDate?.value)}
                renderInput={(params) =>
                  <TextField
                    disabled={!(isEditingRow.isBillable.value === 'Billable')}
                    {...params}
                    variant="standard"
                    size={'small'}
                    error={isEditingRow.isBillable.value === 'Billable' ? isEditingRow.billingEndDate?.error !== null : false}
                    onFocus={() => handleInputFocus('billingEndDate')}
                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }}
                  />}
              />
            </LocalizationProvider>
            : <>
              {
                props.project.isBillable === false
                  ? <span >N/A</span>
                  : props.project.billingEndDate &&
                    moment(props.project.billingEndDate).format(DATE_FORMAT) !== 'Invalid date'
                    ? moment(props.project.billingEndDate).format(DATE_FORMAT)
                    : <span className={styles.missingInfo}>- - / - - / - -</span>
              }

            </>
          }
        </StyledTableCell>
        <StyledTableCell align="left" width={135} style={{ zIndex: 1, position: 'sticky', minWidth: 135, right: 0 }}>
          {props.isRowEditing && props.selectedEmployee?.userId === props.employee.userId && props.selectedProject?.projectId === props.project?.projectId &&
            props.selectedProject?.projectStartDate === props.project.projectStartDate &&
            props.selectedProject?.projectEndDate === props.project.projectEndDate
            ? <Box width={'100%'}>
              <IconButton size='small' onClick={() => { handleUpdateData() }}>
                <SaveOutlined color='primary' sx={{ fontSize: '20px', mr: '-1' }} />
              </IconButton>
              <IconButton size='small' className='actionClosedButtonStyle' onClick={() => { props.setIsRowEditing(false); resetUpdateFields() }}>
                <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
              </IconButton>
            </Box>
            : <Box width={'100%'}>
              <AppAuthorizer
                activeRoleFeatures={props.activeUserRole && props.activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_ALLOCATION]}
              >
                <IconButton
                  size='small'
                  disabled={props.project.isConfirmed === true}
                  onClick={() => handleEdit(props.project)}>
                  <Tooltip title="Edit">
                    <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                  </Tooltip>
                </IconButton>
              </AppAuthorizer>
              <IconButton
                size='small'
                onClick={() => {
                  handleComment(props.employee)
                  props.toggleAallocationCommentPopup()
                }}
              >
                <Tooltip title="Comment">
                  <InsertCommentIcon sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              {props.project.isConfirmed === true
                ? <AppAuthorizer
                  activeRoleFeatures={props.activeUserRole && props.activeUserRole.data.features}
                  authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                >
                  <IconButton size='small' className='isconfirmedActionButtonStyle'
                    onClick={() => { setSelected(); compareWithCurrentProject() }}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit"><b>Revoke Deallocation</b></Typography>
                          {'Here able to '} <b>{'revoke  '}</b>{' '}
                          {'de-allocation from the project. So user reassigned of this project at the relevant time'}
                          .{' '}<br></br>

                        </React.Fragment>
                      }
                    >
                      <SettingsBackupRestoreOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                    </HtmlTooltip>
                  </IconButton>
                </AppAuthorizer>
                : props.project.allocationId !== null
                  ? (
                    <AppAuthorizer
                    activeRoleFeatures={props.activeUserRole.data.features}
                    authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                  >
                  <IconButton size='small' className='deallocateButtonStyle' onClick={() => { setSelected(); props.toggleDeallocateEmployeeDialog() }}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit"><b>Deallocation</b></Typography>
                          {'Here able to revoke the project allocation'} <b>{'partially. '}</b>{' '}
                          {'But the user able to manage their time entries for the respective period.'}
                          .{' '}<br></br>

                        </React.Fragment>
                      }
                    >
                      <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                    </HtmlTooltip>
                  </IconButton></AppAuthorizer>)
                  : (
                    <AppAuthorizer
                      activeRoleFeatures={props.activeUserRole && props.activeUserRole.data.features}
                      authorizedFeatureKey={[APP_FEATURE_KEYS.DEALLOCATION]}
                    >
                      <IconButton size='small'
                        disabled={true}
                        className='disabledDeallocateButtonStyle'
                        onClick={() => { setSelected(); props.toggleDeallocateEmployeeDialog() }}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit"><b>Deallocation</b></Typography>
                              {'Here able to revoke the project allocation'} <b>{'partially. '}</b>{' '}
                              {'But the user able to manage their time entries for the respective period.'}
                              .{' '}<br></br>

                            </React.Fragment>
                          }
                        >
                          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                        </HtmlTooltip>
                      </IconButton>
                    </AppAuthorizer>)
              }
            </Box>
          }
        </StyledTableCell>
      </StyledChildTableRow>
    </>
  )
}

export default EmployeeViewTableChildRows
