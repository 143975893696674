import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { commentsService } from '../../services/comments.service'
import { ALERT_CONFIGS } from '../../utilities/constants'
import { COMMON_ACTION_TYPES, COMMENTS_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AlertActionDto, CreateClientParamDto } from '../../utilities/models'
function * allComments (action: { type: string }) {
  try {
    // @ts-ignore
    const commentList = yield call(commentsService.getAllComments, action.params)
    yield put({
      type:
        COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: commentList.data
    })
  } catch (error) {
    yield put({
      type: COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * addComment (action: { type: string; payload: CreateClientParamDto; }) {
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const addedCommentData = yield call(commentsService.addComment, action.payload)
    const setAlert: AlertActionDto = {
      message: addedCommentData.data.message,
      severity: 'success',
      type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * commentsSaga () {
  yield takeEvery(
    COMMENTS_ACTION_TYPES.GET_COMMENTS_LIST + COMMON_ACTION_TYPES.REQUEST,
    allComments
  )
  yield takeEvery(COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    addComment
  )
}
export default commentsSaga
