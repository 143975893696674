import { APP_CONFIGS } from './../utilities/constants/config.constants'
import axios from 'axios'
import { msalInstance } from '../index'
import { InteractionRequiredAuthError } from 'msal'
import { exceptionHandler } from '../core/exceptionHandler'
import { authService } from './auth.service'
import CryptoJS from 'crypto-js'
import { UserRoleDto } from '../utilities/models'

axios.defaults.baseURL = APP_CONFIGS.API_BASE
axios.interceptors.request.use(async (request: any) => {
  const msalRequest = {
    scopes: [APP_CONFIGS.APP_SCOPES || '']
  }
  const tokenResponse = await msalInstance.acquireTokenSilent(msalRequest)
    .catch((error: any) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(msalRequest)
      }
    })
  // const _encryptedUserRole = await authService.fetchActiveUserRole()
  // const bytes = _encryptedUserRole !== '' && CryptoJS.AES.decrypt(_encryptedUserRole, APP_CONFIGS.DATA_ENC_SECRET)
  // const activeUserRole: UserRoleDto = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

  request.headers.Authorization = `Bearer ${tokenResponse?.accessToken}`
  request.headers['Application-key'] = APP_CONFIGS.HERA_APPLICATION_ID

  const _encryptedUserRole = await authService.fetchActiveUserRole()
  if (_encryptedUserRole) {
    const bytes = _encryptedUserRole !== '' && CryptoJS.AES.decrypt(_encryptedUserRole, APP_CONFIGS.DATA_ENC_SECRET)
    const activeUserRole: UserRoleDto = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    request.headers['user-role'] = activeUserRole.userRoleKey
  }
  return request
}, (error) => {
  // eslint-disable-next-line no-console
  console.log('Req interceptor Error', error)
})

// Response interceptor to manage responses & errors
axios.interceptors.response.use(async (response) => {
  return response
}, async (error) => {
  return Promise.reject(await exceptionHandler(error.response))
})

export * from './user.service'
export * from './client.service'
export * from './sbu.service'
export * from './project.service'
export * from './employee.service'
export * from './supervisor.service'
export * from './auth.service'
export * from './report.service'
export * from './comments.service'
export * from './dashboard.service'
export * from './designation.service'
export * from './resource.service'
export * from './config.service'
export * from './track.service'
