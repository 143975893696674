import { COMMON_ACTION_TYPES, TRACK_ACTION_TYPES } from '../../utilities/constants'

const getTrackList = () => {
  return {
    type: TRACK_ACTION_TYPES.GET_TRACK_LIST + COMMON_ACTION_TYPES.REQUEST
  }
}

export const trackAction = {
  getTrackList

}
