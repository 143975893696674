import { COMMON_ACTION_TYPES, RESOURCE_ACTION_TYPES } from '../../utilities/constants'
import { ConfirmProposedResourcesDto, CreateResourcesRequestDto, ProposedResourcesActionDto, ProposeRequestPayloadDto, ResourceActionDto, ResourceCommentParamsDto, UpdateRequestDto, UpdateResourcesDto, ViewResourcesRequestParams } from '../../utilities/models/resourceRequest.model'

const creatResourse = (payload: CreateResourcesRequestDto) => {
  return {
    type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const getResourceRequests = (params: ViewResourcesRequestParams) => {
  return {
    type: RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
const updateResources = (payload: UpdateResourcesDto, requestId: number, resourceId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    requestId: requestId,
    resourceId: resourceId
  }
}
const updateRequest = (payload: UpdateRequestDto, requestId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    requestId: requestId
  }
}

const CreateResourceComment = (payload: ResourceCommentParamsDto, requestId: number, resourceId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    requestId: requestId,
    resourceId: resourceId
  }
}

const getComments = (params: ResourceCommentParamsDto) => {
  return {
    type: RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}

const changeStatus = (payload: ResourceActionDto, requestId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    requestId: requestId
  }
}

const CreateProposeRequest = (payload: ProposeRequestPayloadDto[], requestId: number, resourceId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    requestId: requestId,
    resourceId: resourceId
  }
}
const getProposedResources = (requestId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    requestId: requestId
  }
}

const deleteProposedResource = (requestId: number, resourceId: number, proposeId: number) => {
  return {
    type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.REQUEST,
    requestId: requestId,
    resourceId: resourceId,
    proposeId: proposeId
  }
}

const confirmProposedResources = (requestId: number, resourceId: number, payload: ConfirmProposedResourcesDto[]) => {
  return {
    type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    requestId: requestId,
    resourceId: resourceId,
    payload: payload
  }
}

const proposedResourcesAction = (requestId: number, resourceId: number, proposeId: number, payload: ProposedResourcesActionDto) => {
  return {
    type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.REQUEST,
    requestId: requestId,
    resourceId: resourceId,
    proposeId: proposeId,
    payload: payload
  }
}

const closeRequest = (requestId: number, payload: ProposedResourcesActionDto) => {
  return {
    type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    requestId: requestId,
    payload: payload
  }
}

const getStatusList = () => {
  return {
    type: RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.REQUEST
  }
}
export const resourceAction = {
  creatResourse,
  getResourceRequests,
  updateResources,
  updateRequest,
  CreateResourceComment,
  getComments,
  changeStatus,
  CreateProposeRequest,
  getProposedResources,
  deleteProposedResource,
  confirmProposedResources,
  proposedResourcesAction,
  closeRequest,
  getStatusList
}
