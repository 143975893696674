import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { CreateSbuParamDto, GetDisabledSbusParamDto, UpdateSbuParamDto } from '../utilities/models'

const getAllSbuList = (params: GetDisabledSbusParamDto) => {
  return axios.get('/core/api/v1/sbu', { params: params })
}
// const getAllSbuList = () => {
//   return axios.get('/v1/sbu')
// }
const createSbu = (payload: CreateSbuParamDto) => {
  return axios.post('/core/api/v1/sbu', payload)
}
const updateSbu = (payload: UpdateSbuParamDto) => {
  return axios.put('/core/api/v1/sbu', payload)
}

export const sbuService = {
  getAllSbuList,
  createSbu,
  updateSbu

}
