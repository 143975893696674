import { COMMON_ACTION_TYPES, DASHBOARD_ACTION_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  employeeWiseDataList: {
    isLoading: false,
    data: {}
  },
  clientWiseDataList: {
    isLoading: false,
    data: []
  },
  trackWiseDataList: {
    isLoading: false,
    data: []
  },
  tierWiseDataList: {
    isLoading: false,
    data: []
  }
}
const dashboardReducer = (state = INITIAL_STATE, action : any) => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        employeeWiseDataList: {
          ...state.employeeWiseDataList,
          isLoading: true
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        employeeWiseDataList: {
          ...state.employeeWiseDataList,
          isLoading: false,
          data: action.data
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        employeeWiseDataList: {
          ...state.employeeWiseDataList,
          isLoading: false,
          data: {}
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        clientWiseDataList: {
          ...state.clientWiseDataList,
          isLoading: true
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        clientWiseDataList: {
          ...state.clientWiseDataList,
          isLoading: false,
          data: action.data
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        clientWiseDataList: {
          ...state.clientWiseDataList,
          isLoading: false,
          data: []
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        trackWiseDataList: {
          ...state.trackWiseDataList,
          isLoading: true
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        trackWiseDataList: {
          ...state.trackWiseDataList,
          isLoading: false,
          data: action.data
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        trackWiseDataList: {
          ...state.trackWiseDataList,
          isLoading: false,
          data: []
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        tierWiseDataList: {
          ...state.tierWiseDataList,
          isLoading: true
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        tierWiseDataList: {
          ...state.tierWiseDataList,
          isLoading: false,
          data: action.data
        }
      }
    case DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        tierWiseDataList: {
          ...state.tierWiseDataList,
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  }
}

export default dashboardReducer
