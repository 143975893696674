
import { Alert, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, clientAction, projectAction } from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_CONFIGS, APP_FEATURE_KEYS, APP_TABLE_CONFIGS, BREAD_CRUB } from '../../utilities/constants'
import { validateFormData } from '../../utilities/helpers/FormValidator'
import { AppAuthorizer, ProjectEditPopup, ProjectEntryForm, ProjectStatusChangePopup, AppTablePagination, ProjectViewTable } from '../../components'
import { ClientBriefEditDto, CreateProjectParamDto, GetProjectListParamDto, InitClientDto, ProjectDto, ProjectEditEntryFormDto, ProjectEntryFormDto, UpdateProjectParamDto } from '../../utilities/models/projectManagement.model'
import { AlertDto, AppStateDto, GetDisabledClientParamDto } from '../../utilities/models'
const ProjectManagement: React.FC = () => {
  const INITIAL_STATE: ProjectEntryFormDto = {
    projectName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    clientName: {
      value: {} as InitClientDto,
      validator: 'object',
      isRequired: true,
      error: null,
      disable: false
    },
    projectDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const INITIAL_PROJECT_STATUS_STATE = {
    projectStatus: {
      value: {} as ProjectDto
    }
  }
  const INITIAL_EDITING_ROW_STATE: ProjectEditEntryFormDto = {
    projectId: {
      value: -1,
      isRequired: true,
      error: null,
      disable: false
    },
    projectName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    clientName: {
      value: {} as InitClientDto,
      validator: 'object',
      isRequired: true,
      error: null,
      disable: false
    },
    projectDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    },
    isEnabled: {
      value: true,
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const dispatch = useDispatch()
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const allClientList = useSelector((state: any) => state.client.allClientList.data)
  const projectList = useSelector((state: any) => state.projects.allProjectList.data)
  const projectIsloading = useSelector((state: any) => state.projects.allProjectList.isLoading)
  const createProjectAlert: AlertDto = useSelector((state: any) => state.alerts.createProject)
  const updateProjectAlert: AlertDto = useSelector((state: any) => state.alerts.updateProject)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE)
  const [projectFormData, setProjectFormData] = useState<ProjectEntryFormDto>(INITIAL_STATE)
  const [isShowHelperText, setIsShowHelperText] = useState(true)
  const [filteredRows, setFilteredRows] = useState<ProjectDto[]>([])
  const [isEditingRow, setIsEditingRow] = useState(INITIAL_EDITING_ROW_STATE)
  const [isStatusChangeRow, setIsStatusChangeRow] = useState(INITIAL_PROJECT_STATUS_STATE)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [defaultClientList, setDefaultClientList] = useState<ClientBriefEditDto[]>([])
  // const [searchvalue, setSearchValue] = useState('')
  React.useEffect(() => {
    setFilteredRows(projectList)
    if (searchText !== '') {
      searchProject(searchText)
    }
  }, [projectList])
  React.useEffect(() => {
    setDefaultClientList(allClientList?.data)
  }, [allClientList])
  React.useEffect(() => {
    getAllProjectList()
    getClientList()
  }, [])
  React.useEffect(() => {
    if (createProjectAlert.severity === 'success') {
      setProjectFormData({
        ...projectFormData,
        projectName: {
          ...projectFormData['projectName' as keyof typeof projectFormData],
          value: ''
        },
        clientName: {
          ...projectFormData['clientName' as keyof typeof projectFormData],
          value: {} as InitClientDto
        },
        projectDescription: {
          ...projectFormData['projectDescription' as keyof typeof projectFormData],
          value: ''
        }
      })
      getAllProjectList()
    }
  }, [createProjectAlert])
  React.useEffect(() => {
    if (updateProjectAlert.severity === 'success') {
      getAllProjectList()
    }
  }, [updateProjectAlert])
  const getClientList = () => {
    const clientListParams: GetDisabledClientParamDto = {
      getDisabledClients: 'false'
    }
    // dispatch(projectAction.clientList(clientBriefParams))
    dispatch(clientAction.allClientList(clientListParams))
  }

  const clearCreateSbuAlert = () => {
    dispatch(alertActions.clearCreateSbuAlertRequest())
  }
  const clearUpdateSbuAlert = () => {
    dispatch(alertActions.clearUpdateAlertRequest())
  }
  // getAll ProjectList
  const getAllProjectList = () => {
    const projectListParams: GetProjectListParamDto = {
      getAllTeams: true,
      getDisabledTeams: true
    }
    dispatch(projectAction.allProjectList(projectListParams))
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setProjectFormData({
      ...projectFormData,
      [property]: {
        ...projectFormData[property as keyof typeof projectFormData],
        error: null
      }
    })
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        error: null
      }
    })
  }
  // create sbu
  const createProject = async () => {
    const [validateData, isValid] = await validateFormData(projectFormData)
    setProjectFormData(validateData)
    if (isValid) {
      const projectCreateParams: CreateProjectParamDto = {
        teamName: projectFormData.projectName.value.trim(),
        isEnabled: true,
        teamDesc: projectFormData.projectDescription.value,
        clientId: projectFormData.clientName.value.id,
        applications: [
          {
            applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
          },
          {
            applicationKey: APP_CONFIGS.ATHENA_APPLICATION_ID
          }
        ]

      }
      dispatch(projectAction.createProject(projectCreateParams))
    }
  }
  // update sbu
  const updateProject = async () => {
    const [validateData, isValid] = await validateFormData(isEditingRow)
    setIsEditingRow(validateData)
    if (isValid) {
      const projectUpdateParams: UpdateProjectParamDto = {
        teamId: isEditingRow.projectId.value,
        teamName: isEditingRow.projectName.value.trim(),
        teamDesc: isEditingRow.projectDescription.value,
        clientId: isEditingRow.clientName.value.id,
        isEnabled: isEditingRow.isEnabled.value,
        applications: [
          {
            applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
          },
          {
            applicationKey: APP_CONFIGS.ATHENA_APPLICATION_ID
          }
        ]
      }
      dispatch(projectAction.updateProject(projectUpdateParams))
      setIsEditDialogOpen(false)
    }
  }

  // search sbus
  const searchProject = (e: any) => {
    // if (page !== 0) {
    //   setPage(0)
    // }
    let keyword: any
    if (e.target !== undefined) {
      keyword = e.target.value
    } else {
      keyword = e
    }
    // const keyword = e.target.value
    // setSearchValue(keyword)
    setSearchText(keyword)

    if (keyword !== '') {
      // const results = projectList?.data.filter((project: ProjectDto) => {
      //   return project.teamName.toLowerCase().includes(keyword.toLowerCase())
      // })
      // results.sort()
      // setFilteredRows(results)
      const results = projectList
        .filter((project: ProjectDto) => {
          // Filter results by doing case insensitive match on name here
          return project.teamName.toLowerCase().includes(keyword.toLowerCase()) ||
            project.clientName.toLowerCase().includes(keyword.toLowerCase())
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (a.teamName.toLowerCase().indexOf(keyword.toLowerCase()) > b.teamName.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.clientName.toLowerCase().indexOf(keyword.toLowerCase()) > b.clientName.toLowerCase().indexOf(keyword.toLowerCase())
          ) {
            return 1
          } else if (a.teamName.toLowerCase().indexOf(keyword.toLowerCase()) < b.teamName.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.clientName.toLowerCase().indexOf(keyword.toLowerCase()) < b.clientName.toLowerCase().indexOf(keyword.toLowerCase())
          ) {
            return -1
          } else {
            if (a.teamName > b.teamName || a.clientName > b.clientName) { return 1 } else { return -1 }
          }
        })
      setFilteredRows(results)
    } else {
      setFilteredRows(projectList)
    }
  }
  // StatusChange  sbu
  const projectStatusChange = () => {
    const projectstatuschangeParams: UpdateProjectParamDto = {
      teamId: isStatusChangeRow.projectStatus.value.teamId,
      teamName: isStatusChangeRow.projectStatus.value.teamName,
      teamDesc: isStatusChangeRow.projectStatus.value.teamDesc,
      clientId: isStatusChangeRow.projectStatus.value.clientId,
      isEnabled:
        isStatusChangeRow.projectStatus.value.isEnabled !== true,
      applications: [
        {
          applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
        },
        {
          applicationKey: APP_CONFIGS.ATHENA_APPLICATION_ID
        }
      ]
    }
    dispatch(projectAction.updateProject(projectstatuschangeParams))
    setIsStatusChangeDialogOpen(false)
  }

  // Handle form input
  const onInputHandleChange = (
    property: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowHelperText(true)
    setProjectFormData({
      ...projectFormData,
      [property]: {
        ...projectFormData[property as keyof typeof projectFormData],
        value: value
      }
    })
  }

  // Handle form edit
  const onEditHandleChange = (
    property: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowHelperText(true)
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        value: value
      }
    })
  }
  // Editing a row data trigger
  const onRowEditTrigger = (data: ProjectDto) => {
    setIsEditDialogOpen(true)
    setIsEditingRow({
      ...isEditingRow,
      projectId: {
        ...isEditingRow.projectId,
        value: data.teamId
      },
      projectName: {
        ...isEditingRow.projectName,
        value: data.teamName
      },
      clientName: {
        ...isEditingRow.clientName,
        value: {
          id: data.clientId,
          name: data.clientName
        }
      },
      projectDescription: {
        ...isEditingRow.projectDescription,
        value: data.teamDesc
      },
      isEnabled: {
        ...isEditingRow.isEnabled,
        value: data.isEnabled
      }
    })
  }

  const onRowStatusChangeTrigger = (data: ProjectDto) => {
    setIsStatusChangeDialogOpen(true)
    setIsStatusChangeRow({
      ...isStatusChangeRow,
      projectStatus: {
        value: {
          teamId: data.teamId,
          teamName: data.teamName,
          teamDesc: data.teamDesc,
          isEnabled: data.isEnabled,
          clientName: data.clientName,
          clientId: data.clientId,
          applications: data.applications

        }
      }
    })
  }
  //  if edit ok
  const projectEditClick = () => {
    setIsEditDialogOpen(false)
  }

  const projectStatusChangeClick = () => {
    setIsStatusChangeDialogOpen(false)
  }

  // pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <React.Fragment>
      <AppLayout breadcrumb={BREAD_CRUB.PROJECT_MANAGEMENT} componentTitle="Project Management">
        <p style={{
          paddingBottom: 7,
          margin: 0,
          fontWeight: 400,
          fontSize: '24px'
        }}>Project Management</p>
        <Divider className="dividerStyle" />
        <br />
        {createProjectAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearCreateSbuAlert}
            severity={createProjectAlert.severity}
          >
            {createProjectAlert.message}
          </Alert>
        )}
        {updateProjectAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearUpdateSbuAlert}
            severity={updateProjectAlert.severity}
          >
            {updateProjectAlert.message}
          </Alert>
        )}
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_PROJECT]}
        >
          <ProjectEntryForm
            projectFormData={projectFormData}
            defaultClientList={defaultClientList || []}
            isShowHelperText={isShowHelperText}
            onInputHandleChange={onInputHandleChange}
            createProject={createProject}
            handleInputFocus={handleInputFocus}
          />
        </AppAuthorizer>

        <br />
        <Divider className="dividerStyle" />
        <br />
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_PROJECT]}
        >
          <ProjectViewTable
          activeUserRole={activeUserRole}
            projectList={filteredRows || []}
            rowsPerPage={rowsPerPage}
            isEditingRow={isEditingRow}
            isEditDialogOpen={isEditDialogOpen}
            page={page}
            onRowEditTrigger={onRowEditTrigger}
            onRowStatusChangeTrigger={onRowStatusChangeTrigger}
            searchProject={searchProject}
            searchText={searchText}
            projectIsloading={projectIsloading}
          >
            <AppTablePagination
              data={filteredRows || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ProjectViewTable>
        </AppAuthorizer>

          <ProjectEditPopup
            isEditDialogOpen={isEditDialogOpen}
            projectFormData={projectFormData}
            defaultClientList={defaultClientList || []}
            isShowHelperText={isShowHelperText}
            title="Edit Project Details"
            isEditingRow={isEditingRow}
            projectEditClick={projectEditClick}
            onEditHandleChange={onEditHandleChange}
            updateProject={updateProject}
            handleInputFocus={handleInputFocus}
          />
          <ProjectStatusChangePopup
            isStatusChangeDialogOpen={isStatusChangeDialogOpen}
            projectStatusChangeClick={projectStatusChangeClick}
            isStatusChangeRow={isStatusChangeRow}
            projectStatusChange={projectStatusChange}
          />

      </AppLayout>
    </React.Fragment>
  )
}

export default ProjectManagement
