import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import styles from './AppLayoutHeader.module.scss'
// import { APP_ROUTES } from '../../utilities/constants'
import { Link } from '@mui/material'
import { BreadCrubDto } from '../../utilities/models'
import { APP_ROUTES } from '../../utilities/constants'

interface AppLayoutHeaderProps {
  componentBreadCrumb: BreadCrubDto[];
  // componentTitle: string;
}

const AppLayoutHeader = ({
  componentBreadCrumb
}: AppLayoutHeaderProps) => {
  return (
    <div className={styles.pageTitle}>
      <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: '14px' }} className="breadcrumb">
        <Link color="inherit" href={APP_ROUTES.DASHBOARD} underline="none">
          Dashboard
        </Link>
        {componentBreadCrumb && componentBreadCrumb.length > 0 && componentBreadCrumb.map((breadcrub) => (
                <Link key={breadcrub.id}
                color="inherit"
                href={breadcrub.path}
                style={{ textDecoration: 'none' }}>
                  {breadcrub.title}
                </Link>
        ))}
        {/* <Typography style={{ fontSize: '14px' }} >{componentBreadCrumb}</Typography> */}
      </Breadcrumbs>
    </div>
  )
}
export default AppLayoutHeader
