import { COMMON_ACTION_TYPES, DESIGNATION_ACTION_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  designationList: {
    isLoading: false,
    data: []
  }

}
const designationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        designationList: {
          ...state.designationList,
          isLoading: true
        }
      }
    case DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        designationList: {
          isLoading: false,
          data: action.data
        }
      }
    case DESIGNATION_ACTION_TYPES.GET_DESIGNATION_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        designationList: {
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  }
}

export default designationReducer
