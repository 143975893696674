import { Button, Card, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { InitialSbuDto, InitProjectRoleDto } from '../../../utilities/models'
import { CreateResourcesRequestDto } from '../../../utilities/models/resourceRequest.model'
import styles from './ReviewAndSubmit.module.scss'

const ReviewAndSubmit: React.FC<{
  back(): void
  resourcePayload: CreateResourcesRequestDto
  createResourceRequest(): void
  createResourceIsloading: boolean
  allSbuList: InitialSbuDto[]
  briefProjectRoleList: InitProjectRoleDto[]
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} className="sectionTitleHolder">
          <h3>Review Summary</h3>
        </Grid>

        <Grid item md={11}>
          <Grid container spacing={2} className="title-view">
            <Grid item sx={{ padding: '0px 0px 0px 20px' }} xs={2} sm={6} md={12}>
            <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>SBU : &nbsp; </Typography>
              {props.allSbuList.find(a => a.sbuId === props.resourcePayload.sbuId)?.sbuName}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>Client:  &nbsp; </Typography>
              {props.resourcePayload.clientName}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>Project: &nbsp; </Typography>
              {props.resourcePayload.projectName}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}> Request Type: &nbsp; </Typography>
              {props.resourcePayload.requestType === 2 ? 'Replacement Request' : props.resourcePayload.requestType === 1 ? 'New Request' : ''}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}> Request Deadline: &nbsp; </Typography>
              {props.resourcePayload.resourceRequestDeadline}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <Card sx={{ minWidth: 275, backgroundColor: '#FFFFFF', padding: '10px', border: '0.5px solid #8B8B8B', boxShadow: 'none' }}>
                <Grid className={styles.scrollerCard}>
                {props.resourcePayload.resources.map((data, index) => (
                  <Grid container spacing={2} key={index} style={{ padding: '10px 0px 10px 20px' }}>
                    <Grid item md={6}>
                    <div className={ props.resourcePayload.requestType === 2 ? styles.detailLables : styles.detailLables1}>
                      <p className={styles.detailLables}>Replacing Employee :  <span className={styles.grid1}> {data.replacementUsername}  </span></p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      </div>
                      <p className={styles.detailLables} >Required Position : <span className={styles.grid1}>
                      {props.briefProjectRoleList.find(a => a.projectRoleId === data.designationId)?.projectRoleName}</span></p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      <div className={ props.resourcePayload.requestType === 2 ? styles.detailLables1 : styles.detailLables}>
                      <p className={styles.detailLables}>No. of Vacancies :  <span className={styles.grid1}> {data.noOfVacancies}  </span></p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      </div>
                      <p className={styles.detailLables}>Deployment Start : <span className={styles.grid1}> {data.deploymentStartDate} </span></p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      <p className={styles.detailLables}>Deployment End : <span className={styles.grid1}> {data.deploymentEndDate}  </span> </p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      <div className={ data.deploymentExtendPeriod === null ? styles.detailLables1 : styles.detailLables}>
                      <p className={styles.detailLables}>Deployment Extendible Period : <span className={styles.grid1}> {data.deploymentExtendPeriod}&nbsp;{data.deploymentExtendPeriod?.toString() === '1' ? 'month' : data.deploymentExtendPeriod === 0 ? 'month' : data.deploymentExtendPeriod?.toString() === '' ? ' --' : 'months' } </span> </p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      </div>
                      <p className={styles.detailLables}>Billing Status : &nbsp; <span className={styles.grid1}> {data.isBillable === true ? 'Billable' : data.isBillable === false ? 'Non-billable' : '   --'}  </span></p>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                      <p className={styles.detailLables}>Allocation :  &nbsp; <span className={styles.grid1}> {data.allocationPercentage}{data.allocationPercentage?.toString() === '' ? '--' : '%'} </span> </p>
                    </Grid>

                    <Grid item md={6} sx={{ padding: '15px 15px' }}>
                      <Typography sx={{ fontWeight: 'bold', padding: '10px 10px 0px 0px' }}>Job Description:</Typography>
                      <Grid style={{ padding: '10px' }} className={styles.scrollerExpenision}>
                        <div dangerouslySetInnerHTML={{ __html: data.JobDescriptionAndRemarks }} />
                      </Grid>
                    </Grid>

                    <Grid item md={12} sx={{ padding: '10px' }}>
                      <Divider flexItem sx={{ borderRightWidth: 5, bgcolor: '#8B8B8B' }} />
                    </Grid>
                  </Grid>
                ))}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ padding: '20px 10px 10px 0px' }}>
              <Button
                variant="contained"
                color='inherit'
                className={'btnStyle'}
                onClick={props.back}
              >
                Back
              </Button>

              <Button
                variant="contained"
                className={'btnStyle'}
                onClick={props.createResourceRequest}
              >
                {props.createResourceIsloading && (
                <CircularProgress size="13px" className={styles.loading} />
                )}
                Submit Resource Request
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}

export default ReviewAndSubmit
