import { COMMON_ACTION_TYPES, DASHBOARD_ACTION_TYPES } from '../../utilities/constants'
import { DashboardEmpCountParamsDto } from '../../utilities/models'

const getEmployeeWiseDetails = () => {
  return {
    type: DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.REQUEST
  }
}

const getClientWiseDetails = () => {
  return {
    type: DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.REQUEST
  }
}
const getTrackWiseDetails = (params : DashboardEmpCountParamsDto) => {
  return {
    type: DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const getTierWiseDetails = (params : DashboardEmpCountParamsDto) => {
  return {
    type: DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}

export const dashboardAction = {
  getEmployeeWiseDetails,
  getTierWiseDetails,
  getTrackWiseDetails,
  getClientWiseDetails
}
