import { COMMON_ACTION_TYPES, RESIGNATION_DETAILS_TYPES } from '../../utilities/constants'
import { ResignationCommentParamsDto, ResignationDetailsParamDto } from '../../utilities/models'

const resignationDetails = (params: ResignationDetailsParamDto) => {
  return {
    type: RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
const addComment = (payload:ResignationCommentParamsDto, resignationId: number) => {
  return {
    type: RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    payload: payload,
    resignationId: resignationId
  }
}

export const resignationAction = {
  resignationDetails,
  addComment
}
