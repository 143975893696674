
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { EmployeeInformation } from '../components/employee-management'
import { ClientManagement, Dashboard, AllocationDetailReport, EmployeeManagement, ProjectDetailReport, Login, ProjectManagement, SbuManagement, SupervisorManagement, Profile, ResourceRequest } from '../pages'
import ResourceRequestView from '../pages/ResourceRequestView/ResourceRequestView'
import { APP_FEATURE_KEYS, APP_ROUTES } from '../utilities/constants'
import PrivateRoute from './PrivateRoute'
const AppRoutes = () => {
  return (
    <BrowserRouter >
      <Routes>
        <Route path={APP_ROUTES.ROOT} element={<Login />} />
        <Route
          path={APP_ROUTES.PROFILE}
          element={
            <PrivateRoute
              permissionKeys={[APP_FEATURE_KEYS.PROFILE
              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.DASHBOARD}
          element={
            <PrivateRoute
              permissionKeys={[APP_FEATURE_KEYS.HERA_DASHBOARD]}
              redirectTo={APP_ROUTES.ROOT}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.ALLOCATION_MANAGEMENT}
          element={
            <PrivateRoute
              permissionKeys={[APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
              redirectTo={APP_ROUTES.ROOT}>
              <EmployeeManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.EMPLOYEE_INFORMATION}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
              redirectTo={APP_ROUTES.ROOT}>
              <EmployeeInformation />
            </PrivateRoute>
          }
        />
        <Route
          path='*'
          element={<Navigate to={APP_ROUTES.ROOT} />}
        />
        <Route
          path={APP_ROUTES.CLIENT_MANAGEMENT}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_ALL_CLIENT

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <ClientManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.HERA_REPORT}
          element={
            <PrivateRoute
              permissionKeys={[APP_FEATURE_KEYS.HERA_ALLOCATION_DETAIL_REPORT
              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <AllocationDetailReport />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.HERA_FINANCE_REPORT}
          element={
            <PrivateRoute
              permissionKeys={[APP_FEATURE_KEYS.HERA_PROJECT_DETAIL_REPORT]}
              redirectTo={APP_ROUTES.ROOT}>
              <ProjectDetailReport />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.SBU_MANAGEMENT}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_ALL_SBU

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <SbuManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.PROJECT_MANAGEMENT}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_ALL_PROJECT

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <ProjectManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.SUPERVISOR_MANAGEMENT}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_ALL_EXTERNAL_SUPERVISOR

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <SupervisorManagement />
            </PrivateRoute>
          }
        />
      <Route
          path={APP_ROUTES.CREATE_RESOURCE_REQUEST}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.CREATE_RESOURCE_REQUEST

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <ResourceRequest />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.VIEW_RESOURCE_REQUESTS}
          element={
            <PrivateRoute
              permissionKeys={[
                APP_FEATURE_KEYS.VIEW_RESOURCE_REQUESTS

              ]}
              redirectTo={APP_ROUTES.ROOT}>
              <ResourceRequestView />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter >
  )
}
export default AppRoutes
