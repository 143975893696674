import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { ALERT_CONFIGS, COMMON_ACTION_TYPES, RESOURCE_ACTION_TYPES } from '../../utilities/constants'
import { ConfirmProposedResourcesDto, CreateResourcesRequestDto, ProposedResourcesActionDto, ProposeRequestPayloadDto, ResourceActionDto, ResourceCommentParamsDto, UpdateRequestDto, UpdateResourcesDto, ViewResourcesRequestParams } from '../../utilities/models/resourceRequest.model'
import { resourceService } from '../../services/resource.service'
import { AlertActionDto } from '../../utilities/models'

function * createResource (action: { type: string, params: CreateResourcesRequestDto }) {
  try {
    // @ts-ignore
    const data = yield call(resourceService.createResource, action.payload)
    const setAlert: AlertActionDto = {
      message: data.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SUCCESS, data })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.ERROR, error })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * getResourceRequests (action: { type: string, params: ViewResourcesRequestParams }) {
  try {
    // @ts-ignore
    const response = yield call(resourceService.getResourceRequests, action.params)
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * updateResources (action: { type: string, payload: UpdateResourcesDto, requestId: number, resourceId: number }) {
  try {
    // @ts-ignore
    const updateResources = yield call(resourceService.updateResources, action.payload, action.requestId, action.resourceId)
    const setAlert: AlertActionDto = {
      message: updateResources.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * updateRequest (action: { type: string, payload: UpdateRequestDto, requestId: number}) {
  try {
    // @ts-ignore
    const updateRequest = yield call(resourceService.updateRequest, action.payload, action.requestId)
    const setAlert: AlertActionDto = {
      message: updateRequest.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * createResourceComment (action: { type: string, payload: ResourceCommentParamsDto, requestId: number, resourceId: number }) {
  try {
    // @ts-ignore
    const createComment = yield call(resourceService.CreateResourceComment, action.payload, action.requestId, action.resourceId)
    const setAlert: AlertActionDto = {
      message: createComment.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * getComments (action: { type: string, params: ResourceCommentParamsDto }) {
  try {
    // @ts-ignore
    const response = yield call(resourceService.getComments, action.params)
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * resourceAction (action: { type: string, payload: ResourceActionDto, requestId: number}) {
  try {
    // @ts-ignore
    const resourceAction = yield call(resourceService.resourceAction, action.payload, action.requestId)
    const setAlert: AlertActionDto = {
      message: resourceAction.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * createProposeResource (action: { type: string, payload: ProposeRequestPayloadDto, requestId: number, resourceId: number }) {
  try {
    // @ts-ignore
    const createProposeRequest = yield call(resourceService.createProposeRequest, action.payload, action.requestId, action.resourceId)
    const setAlert: AlertActionDto = {
      message: createProposeRequest.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * getProposedResources (action: { type: string, requestId: number }) {
  try {
    // @ts-ignore
    const response = yield call(resourceService.getProposedResources, action.requestId)
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * deleteProposeResource (action: { type: string, requestId: number, resourceId: number, proposeId: number }) {
  try {
    // @ts-ignore
    const deleteProposeRequest = yield call(resourceService.deleteProposedResource, action.requestId, action.resourceId, action.proposeId)
    const setAlert: AlertActionDto = {
      message: deleteProposeRequest.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * confirmProposeResources (action: { type: string, requestId: number, resourceId: number, payload: ConfirmProposedResourcesDto[] }) {
  try {
    // @ts-ignore
    const confirmProposeResources = yield call(resourceService.confirmProposedResources, action.requestId, action.resourceId, action.payload)
    const setAlert: AlertActionDto = {
      message: confirmProposeResources.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * proposeResourcesAction (action: { type: string, requestId: number, resourceId: number, proposeId: number, payload: ProposedResourcesActionDto }) {
  try {
    // @ts-ignore
    const proposeResourcesAction = yield call(resourceService.proposedResourcesAction, action.requestId, action.resourceId, action.proposeId, action.payload)
    const setAlert: AlertActionDto = {
      message: proposeResourcesAction.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * closeRequest (action: { type: string, requestId: number, payload: ProposedResourcesActionDto }) {
  try {
    // @ts-ignore
    const closeRequest = yield call(resourceService.closeRequest, action.requestId, action.payload)
    const setAlert: AlertActionDto = {
      message: closeRequest.data.message,
      severity: 'success',
      type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * getStatusList (action: { type: string }) {
  try {
    // @ts-ignore
    const response = yield call(resourceService.getStatusList)
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: response.data
    })
  } catch (error) {
    yield put({
      type:
      RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * resourceSagas () {
  yield takeEvery(
    RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    createResource
  )
  yield takeEvery(
    RESOURCE_ACTION_TYPES.GET_RESOURCE_REQUESTS + COMMON_ACTION_TYPES.REQUEST,
    getResourceRequests
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    updateResources
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    updateRequest
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.REQUEST,
    createResourceComment
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.GET_RESOURCE_COMMENTS + COMMON_ACTION_TYPES.REQUEST,
    getComments
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.REQUEST,
    resourceAction
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    createProposeResource
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.GET_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    getProposedResources
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.REQUEST,
    deleteProposeResource
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.REQUEST,
    confirmProposeResources
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.REQUEST,
    proposeResourcesAction
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.REQUEST,
    closeRequest
  )
  yield takeEvery(RESOURCE_ACTION_TYPES.GET_STATUS_LIST + COMMON_ACTION_TYPES.REQUEST,
    getStatusList
  )
}

export default resourceSagas
