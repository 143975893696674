import {
  teccianceLogo,
  allAppsIcon,
  athenaIcon,
  heraIcon,
  hermesIcon
} from '../../assets/images'
import {
  APP_ROUTES,
  APP_CONFIGS,
  APP_FEATURE_KEYS
} from '../../utilities/constants'
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined'
import { Collapse, List, ListItemButton, Tooltip } from '@mui/material'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined'
import React, { useState } from 'react'
import AppLayoutHeader from '../AppLayoutHeader/AppLayoutHeader'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { AppStateDto } from '../../utilities/models'
import { useDispatch, useSelector } from 'react-redux'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { AppAuthorizer } from '../../components/shared'
import { BadgeOutlined, ExpandLess, ExpandMore, SupervisorAccountOutlined } from '@mui/icons-material'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { authActions } from '../../redux/actions'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import styles from './AppLayout.module.scss'
const AppLayout: React.FC<{
  children: React.ReactNode;
  breadcrumb: any;
  componentTitle: string;
}> = (props) => {
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // get from local storage
  const authorizedUser = useSelector((state: AppStateDto) => state.auth.authorizedUser)
  const authorizedUserRoles = useSelector((state: AppStateDto) => state.auth.authorizedUserRoles)
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)

  const [sideNavigation, setSideNavigation] = useState(true)
  const [navClass, setNavClass] = useState('')
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [roleListOpen, setRoleListOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  const handleRMClick = () => {
    setOpen1(!open1)
  }
  const handleClickRoleList = () => {
    setRoleListOpen(!roleListOpen)
  }
  React.useEffect(() => {
    if (!sideNavigation) {
      setNavClass('collapsed')
    } else {
      setNavClass('')
    }
  }, [sideNavigation])
  const handleSignOut = (instance: IPublicClientApplication) => {
    dispatch(authActions.logout())
    instance.logoutRedirect().catch(e => {
      // eslint-disable-next-line no-console
      console.error('Sign-out Error', e)
    })
  }
  const setActiveUserRole = (key: string) => {
    window.location.reload()
    dispatch(authActions.setActiveUserRole(key))
  }
  return (
    <React.Fragment>
      <div className={'layout-row authorizedContainer'}>
        <aside className={`layout-row sideNavigation ${navClass}`}>
          <aside className="navBar">
            <div className={`menuBox ${navClass}`}>
              <a
                className="menuIcon"
                onClick={() => setSideNavigation(!sideNavigation)}
              >
                <span></span>
              </a>
            </div>

            <div className="appLinkIconGroup">
              <div className="appLinkIcon">
                <Tooltip title="All Apps" placement="right">
                  <a href={`${APP_CONFIGS.DASHBOARD_CLIENT}dashboard`}>
                    <img src={allAppsIcon} />
                  </a>
                </Tooltip>
              </div>
              <hr />
              <div className="appLinkIcon">
                <Tooltip title="Hermes" placement="right">
                  <a href={`${APP_CONFIGS.HERMES_CLIENT}dashboard`}>
                    <img src={hermesIcon} />
                  </a>
                </Tooltip>
              </div>
              <hr />
              <div className="appLinkIcon">
                <Tooltip title="Athena" placement="right">
                  <a href={`${APP_CONFIGS.ATHENA_CLIENT}dashboard`}>
                    <img src={athenaIcon} />
                  </a>
                </Tooltip>
              </div>
              <hr />
              <div className="appLinkIcon">
                <Tooltip title="Hera" placement="right">
                  <a href={`${APP_CONFIGS.HERA_CLIENT}dashboard`}>
                    <img src={heraIcon} />
                  </a>
                </Tooltip>
              </div>
            </div>
            <div className={'cursorPointer profile'}>
              <span
                className={'infoCircle layout-row layout-align-center center'}
              >
                <sup>{authorizedUser.data.tag}</sup>
              </span>
              <div className="infoMenu">
              <span className={styles.activeUserRole}>{activeUserRole.data.userRoleName}</span>
              <p className="name">
                  {authorizedUser.data.firstName}{' '}
                  {authorizedUser.data.lastName}
                </p>
                <span className="email">{authorizedUser.data.username}</span>
                {authorizedUserRoles.data.length > 1 &&
                   <div
                   className={'switchRole layout-row'}
                 >
                  <div className={'layout-row'}>
                   <a onClick={handleClickRoleList}>
                     Switch Role
                   </a>
                   <ArrowRightOutlinedIcon className="switchRoleArrow" />
                   </div>
                   <div className={'roleMenu layout-row'}>
                     <List disablePadding>
                       {authorizedUserRoles.data.map(i => ({ role: i.userRoleName, key: i.userRoleKey }))
                         .map((role, index) => (
                           <div
                             key={index}
                             onClick={() => setActiveUserRole(role.key)}
                           >
                             <div className={authorizedUserRoles.data.length === index + 1 ? 'switchRoleDiv_last layout-row' : 'switchRoleDiv layout-row'}>
                               <span >{role.role} </span>
                               <br></br>
                             </div>
                           </div>
                         )
                         )}
                     </List>
                   </div>
                 </div>
                }

                { <a onClick={() => {
                  navigate(APP_ROUTES.PROFILE)
                }} >My Profile</a> }
                <a>Help</a>
                <a
                  className="signOut"
                  onClick={() => {
                    handleSignOut(instance)
                  }}
                >
                  Sign Out
                </a>
              </div>
            </div>
          </aside>
          <aside className={`navBarContent ${navClass}`}>
            <div className="contentGroup">
              <img className="logo" src={teccianceLogo} />
              <h1>Olympus Hera</h1>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.HERA_DASHBOARD]}
              >
                <Link to={APP_ROUTES.DASHBOARD} className="navLink">
                  <DashboardOutlinedIcon />
                  Dashboard
                </Link>
              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
              >
                <Link to={APP_ROUTES.ALLOCATION_MANAGEMENT} className="navLink">
                  <BadgeOutlined />
                  Allocations
                </Link>
              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                // added both this two features because of drodowns, .
                // otherwise not relavent features showing in navigation bar
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.VIEW_ALL_SBU, // super admin/ rm user/ rm supervisor
                  APP_FEATURE_KEYS.CRETTE_NEW_SBU // super admin
                ]}
              >
                <Link to={APP_ROUTES.SBU_MANAGEMENT} className="navLink">
                  <AccountTreeOutlinedIcon />
                  SBU
                </Link>
              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_CLIENT,
                  APP_FEATURE_KEYS.CREATE_NEW_CLIENT]}
              >
                <Link to={APP_ROUTES.CLIENT_MANAGEMENT} className="navLink">
                  <BusinessCenterOutlinedIcon />
                  Client
                </Link>
              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.VIEW_ALL_PROJECT,
                  APP_FEATURE_KEYS.CREATE_NEW_PROJECT
                ]}
              >
                <Link to={APP_ROUTES.PROJECT_MANAGEMENT} className="navLink">
                  <InventoryOutlinedIcon />
                  Project
                </Link>
              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.VIEW_ALL_EXTERNAL_SUPERVISOR,
                  APP_FEATURE_KEYS.CREATE_NEW_EXTERNAL_SUPERVISOR

                ]}
              >
                <Link to={APP_ROUTES.SUPERVISOR_MANAGEMENT} className="navLink">
                  <SupervisorAccountOutlined />
                  External Supervisor
                </Link>

              </AppAuthorizer>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.VIEW_RESOURCE_REQUESTS
                ]}
              >
                <Link to={'#'} className="navLink">
                  <div
                    className={'layout-row'}
                    onClick={handleRMClick}
                  >

                    <HowToRegOutlinedIcon color='primary' />
                    Resources

                    <ListItemButton style={{ padding: '0px 5px 0px 57px' }}
                    >
                      {open1
                        ? <ExpandLess />
                        : <ExpandMore />}
                    </ListItemButton>
                  </div>
                </Link>
              </AppAuthorizer>
              <Collapse
                in={open1}
                timeout="auto"
              >
                <List disablePadding>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole.data.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.VIEW_RESOURCE_REQUESTS]}
                  >
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      className="layout-row"
                      to={APP_ROUTES.VIEW_RESOURCE_REQUESTS}
                    >
                      <div
                        className={'netstedDiv  layout-row'}
                      >
                        <span>View Resource Requests</span>
                      </div>
                    </NavLink>
                  </AppAuthorizer>
                  {/* <AppAuthorizer
                    activeRoleFeatures={activeUserRole.data.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.VIEW_RESOURCE_REQUESTS]}
                  >
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      className="layout-row"
                      // to={APP_ROUTES.VIEW_RESOURCE_REQUESTS}
                    > */}
                      {/* <div
                        className={'netstedDiv  layout-row'}
                      >
                        <span>Propose Requests</span>
                      </div> */}
                    {/* </NavLink>
                  </AppAuthorizer> */}
                </List>
              </Collapse>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.HERA_ALLOCATION_DETAIL_REPORT,
                  APP_FEATURE_KEYS.HERA_PROJECT_DETAIL_REPORT
                ]}
              >
                <Link to={'#'} className="navLink">
                  <div
                    className={'layout-row'}
                    onClick={handleClick}
                  >

                    <FileCopyOutlinedIcon color='primary' />
                    <span>Reports</span>

                    <ListItemButton
                      style={{ padding: '0px 5px 0px 70px' }}
                    >
                      {open
                        ? <ExpandLess />
                        : <ExpandMore />}
                    </ListItemButton>
                  </div>
                </Link>
              </AppAuthorizer>
              <Collapse
                in={open}
                timeout="auto"
              >
                <List disablePadding>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole.data.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.HERA_ALLOCATION_DETAIL_REPORT]}
                  >
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      className="layout-row"
                      to={APP_ROUTES.HERA_REPORT}
                    >
                      <div
                        className={'netstedDiv  layout-row'}
                      >
                        <span>Allocation Detail Report</span>
                      </div>
                    </NavLink>
                  </AppAuthorizer>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole.data.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.HERA_PROJECT_DETAIL_REPORT]}
                  >
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      className="layout-row"
                      to={APP_ROUTES.HERA_FINANCE_REPORT}
                    >
                      <div
                        className={'netstedDiv  layout-row'}
                      >
                        <span>Project Detail Report</span>
                      </div>
                    </NavLink>
                  </AppAuthorizer>
                </List>
              </Collapse>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.PROFILE
                ]}
              >
                <Link to={APP_ROUTES.PROFILE} className="navLink">
                  <AccountCircleOutlinedIcon />
                  Profile
                </Link>
              </AppAuthorizer>
            </div>
            {/* <div className="loggedUserDetails">
              <span className="name"> {activeUserRole.data.userRoleName}
              </span><br></br>
              <span className="name">{authorizedUser.data.username}</span>
            </div> */}
          </aside>
        </aside>
        <aside className="content">
          <AppLayoutHeader
            componentBreadCrumb={props.breadcrumb}
          />
          {props.children}
        </aside>
      </div>
    </React.Fragment>
  )
}

export default AppLayout
