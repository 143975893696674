import { EMPLOYEE_ACTION_TYPES, COMMON_ACTION_TYPES, APP_ACTION_STATUS } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  employeeAllocationList: {
    isLoading: false,
    data: []
  },

  updateEmployeeAllocation: {
    isLoading: false,
    data: [],
    status: null
  },
  allocateEmployee: {
    isLoading: false,
    status: null
  },
  deallocateEmployee: {
    isLoading: false,
    status: null
  },
  employeeAllocationHistory: {
    isLoading: false,
    data: []
  }
}
const employeeReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        employeeAllocationList: {
          ...state.employeeAllocationList,
          isLoading: true
        }
      }
    case EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        employeeAllocationList: {
          isLoading: false,
          data: action.data
        }
      }
    case EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        employeeAllocationList: {
          ...state.employeeAllocationList,
          isLoading: false
        }
      }

    case EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateEmployeeAllocation: {
          isLoading: true,
          data: action.data,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateEmployeeAllocation: {
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateEmployeeAllocation: {
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allocateEmployee: {
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allocateEmployee: {
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allocateEmployee: {
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        deallocateEmployee: {
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        deallocateEmployee: {
          isLoading: false,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        deallocateEmployee: {
          isLoading: false,
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        employeeAllocationHistory: {
          ...state.employeeAllocationHistory,
          isLoading: true
        }
      }
    case EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        employeeAllocationHistory: {
          isLoading: false,
          data: action.data
        }
      }
    case EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        employeeAllocationHistory: {
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  }
}

export default employeeReducer
