import axios from 'axios'
import { AllocationCommentParamsDto } from '../utilities/models'

const getAllComments = (params: AllocationCommentParamsDto) => {
  return axios.get(`/hera/api/v1/allocations/${params.allocationId}/comments`, { params: params })
}

const addComment = (payload: AllocationCommentParamsDto) => {
  return axios.post(`/hera/api/v1/allocations/${payload.allocationId}/comments`, payload)
}

export const commentsService = {
  getAllComments,
  addComment

}
