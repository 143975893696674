import { combineReducers } from 'redux'
import alertReducer from './alert.reducer'
import projectReducer from './project.reducer'
import sbuReducer from './sbu.reducer'
import userReducer from './user.reducer'
import clientReducer from './client.reducer'
import employeeReducer from './employee.reducer'
import supervisorReducer from './supervisor.reducer'
import authReducer from './auth.reducer'
import reportReducer from './report.reducer'
import commentsReducer from './comments.reducer'
import dashboardReducer from './dashboard.reducer'
import resignationReducer from './resignation.reducer'
import designationReducer from './designation.reducer'
import resourceReducer from './resource.reducer'
import configReducer from './config.reducer'
import trackReducer from './track.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  sbu: sbuReducer,
  alerts: alertReducer,
  projects: projectReducer,
  client: clientReducer,
  employee: employeeReducer,
  supervisor: supervisorReducer,
  report: reportReducer,
  comments: commentsReducer,
  dashboard: dashboardReducer,
  resignation: resignationReducer,
  designation: designationReducer,
  resource: resourceReducer,
  config: configReducer,
  track: trackReducer

})

export default rootReducer
