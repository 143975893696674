import { Alert, Box, Button, DialogActions, DialogContent, Divider, Grid, CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import moment from 'moment'

import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { DATE_FORMAT_2, TIME_FORMAT } from '../../../utilities/constants'
import { AllocationCommentDto, AllocationDto, AuthorizedUser, EmployeeDto } from '../../../utilities/models'
import styles from '../../employee-management/AllocationCommentPopup/AllocationCommentPopup.module.scss'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  textarea: {
    resize: 'vertical'
  }
}))
const AllocationCommentPopup: React.FC<{
  allocationCommentPopupIsOpen: boolean;
  toggleAllocationCommentPopup(): void;
  addComment(): void;
  addCommentAlertMessage: string;
  clearAddCommentAlert(): void;
  allocationId: number;
  commentsList: Array<AllocationCommentDto>
  isShowHelperText: boolean;
  // handleAllocationCommentInputFocus(property: string): void;
  getAllComments(): void;
  commentsListIsLoading: boolean;
  readMore: boolean;
  onShowMoreText(): void;
  authorizedUser:AuthorizedUser;
  setIsShowHelperText(args: boolean):void;
  setCommentText(args: string):void;
  commentText:string;
  addCommentIsloading:boolean;
  selectedEmployee:EmployeeDto;
  selectedProject:AllocationDto;
}> = (props) => {
  const classes = useStyles()
  const [text, setText] = useState('')
  const onInputHandleCommentFormChange = (property: string, value: any) => {
    props.setIsShowHelperText(true)
    setText(value)
  }
  React.useEffect(() => {
    if (props.allocationId > 0 && props.allocationCommentPopupIsOpen) {
      props.getAllComments()
    }
  }, [props.allocationId])
  return (
    < BootstrapDialog
      className={styles.dialog}
      aria-labelledby="customized-dialog-title"
      open={props.allocationCommentPopupIsOpen} >
      <BootstrapDialogTitle id="customized-dialog-title"
      onClose={() => {
        setText('')
        props.toggleAllocationCommentPopup()
      }}>
        <strong>Comments</strong><br></br>
        <div className={styles.dialogHeading}>
          {props.selectedEmployee.employeeName + ' | ' + props.selectedProject.projectName}
          </div>
        <Divider></Divider>
      </BootstrapDialogTitle>
      <DialogContent className={styles.dialogContent}>
          {props.addCommentAlertMessage && (
              <Alert
                className="mb-m"
                onClose={props.clearAddCommentAlert}
              >
                {props.addCommentAlertMessage}
              </Alert>
          )}
        <Grid container spacing={2} >
          <Grid item lg={12}>
            <TextField
              variant='outlined'
              minRows={3}
              label="Type your comment here..."
              fullWidth
              multiline
              style = {{ width: 550 }}
              // onFocus={() => props.handleAllocationCommentInputFocus('comment')}
              helperText={!props.commentText && !props.isShowHelperText && 'Comment is required'}
              value={text}
              error={!props.commentText && !props.isShowHelperText}
              required={true}
              onChange={(event) => onInputHandleCommentFormChange('comment', event.target.value)}
              inputProps={{ className: classes.textarea }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.cardActionStyle}>
        <Button
          variant="contained"
          className={'btnStyle'}
          onMouseEnter={() => props.setCommentText(text.trim())}
          onClick={() => {
            setText('')
            props.addComment()
          }}>
          {(props.addCommentIsloading) && (
            <CircularProgress size="13px" className={styles.loading} />
          )}
          Add
        </Button>
      </DialogActions>
      <Box style={{ overflow: 'auto' }}>
        <DialogContent className={styles.dataContent} >
          {(props.commentsListIsLoading) &&
          <React.Fragment >

            <Alert style={{ paddingRight: '7px', paddingBottom: '16px', justifyContent: 'left' }} severity="info"
            iconMapping={{
              info: <CircularProgress size={20} />
            }}
            > Loading...</Alert>

            </React.Fragment>

          }
          {(props.commentsList?.length === 0 && !props.commentsListIsLoading) &&
              <Alert style={{ justifyContent: 'left', paddingBottom: '16px' }} severity="info">No Comments Found!</Alert>
          }
          {(props.commentsList?.length > 0 && !props.commentsListIsLoading) && (
            props.commentsList).map((row: AllocationCommentDto, index) => (
            <React.Fragment key={index}>
              <Grid container spacing={2}>
                <Grid item lg={1} md={1} xs={1} alignItems="right" className={styles.infoCircleGrid}>
                  <span
                    className={styles.infoCircle + ' layout-row layout-align-center center'}
                  >
                    <sup>{row.createdBy.charAt(0)}</sup>
                  </span>
                </Grid>
                <Grid item lg={11} md={11} xs={11} sm container>
                <Grid item xs container direction="row" spacing={2}>
                    <Grid item lg={9} md={9} xs={9}>
                      <strong>{row.createdBy}</strong>
                      </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                    <span className={styles.date} style = {{ textAlign: 'right' }}>{moment(row.createdAt).format(DATE_FORMAT_2)} .&nbsp; {moment(row.createdAt).format(TIME_FORMAT)}</span>
                    </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} className={styles.comment}>
                      {row.comment.toString().length > 120
                        ? <p className={styles.comment}>
                          {row.comment.toString().substring(0, 120)}
                          <a onClick={props.onShowMoreText}>
                            {props.readMore && row.comment.toString().substring(60)}
                            <span>{props.readMore ? ' ' : '... '}</span>
                            <span className={styles.readMore}>{props.readMore ? ' Read Less' : ' Read More'}</span></a>
                        </p>
                        : <p>{row.comment}</p>
                      }
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={styles.divider}></Divider>
              </React.Fragment>
          ))}
        </DialogContent>
      </Box>
    </BootstrapDialog >
  )
}

export default AllocationCommentPopup
