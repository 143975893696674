
import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, PROJECT_ACTION_TYPES } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  allProjectList: {
    isLoading: false,
    data: []
  },
  createProject: {
    isLoading: false,
    data: [],
    status: null
  },
  updateProject: {
    isLoading: false,
    data: [],
    status: null
  }

}
const projectReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allProjectList: {
          ...state.allProjectList,
          isLoading: true
        }
      }
    case PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allProjectList: {
          ...state.allProjectList,
          isLoading: false,
          data: action.data
        }
      }
    case PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allProjectList: {
          ...state.allProjectList,
          isLoading: false,
          data: []
        }
      }
    case PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    // case PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST:
    //   return {
    //     ...state,
    //     clientList: {
    //       ...state.clientList,
    //       isLoading: true
    //     }
    //   }
    // case PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.SUCCESS:
    //   return {
    //     ...state,
    //     clientList: {
    //       ...state.clientList,
    //       isLoading: false,
    //       data: action.data
    //     }
    //   }
    // case PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.ERROR:
    //   return {
    //     ...state,
    //     clientList: {
    //       ...state.clientList,
    //       isLoading: false,
    //       data: []
    //     }
    //   }
    default:
      return state
  }
}
export default projectReducer
