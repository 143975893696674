import React, { useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, CircularProgress } from '@mui/material'
import { StyledTableRow, StyledTableCell } from '../../../assets/theme/theme'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import { AllocationDto, EmployeeDto, InitProjectRoleDto, UpdateEmployeeAllocationDto } from '../../../utilities/models'
import { EmployeeViewTableRows } from '..'
// import moment from 'moment'
// import { DATE_FORMAT } from '../../../utilities/constants'

const EmployeeViewTable: React.FC<{
  page: number
  rowsPerPage: number
  briefProjectRoleList: InitProjectRoleDto[]
  employeeAllocationList: EmployeeDto[]
  selectedEmployee: EmployeeDto | null
  selectedProject: AllocationDto | null
  isRowEditing: boolean
  isCollapsed: boolean
  clientHierachyIsLoading: boolean
  setSelectedEmployee(arg: EmployeeDto): void
  setSelectedProject(arg: AllocationDto): void
  setIsRowEditing(arg: any): void
  setIsCollapsed(arg: boolean): void
  toggleDrawer(row: any): void
  toggleDeallocateEmployeeDialog(): void
  toggleUpdateAllocationDialog(): void
  handleUpdateData(date: UpdateEmployeeAllocationDto): void
  compareWithCurrentDate(data: any): boolean;
  addAllocation():void;
  employeeDataSession():void;
  toggleAallocationCommentPopup():void;
  toggleResignationDetailsPopup():void;
  deallocationCustomAlert(message: string):void;
}> = (props) => {
  const employeeAllocationListLoading = useSelector((state: any) => state.employee.employeeAllocationList.isLoading)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    if (employeeAllocationListLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [employeeAllocationListLoading])
  return (
    <React.Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell padding="checkbox" align="left" width={30} style={{ minWidth: 30, position: 'sticky', left: 0, paddingLeft: 0, paddingRight: 0 }} ></StyledTableCell>
              <StyledTableCell align="left" width={80} style={{ minWidth: 80, maxWidth: 80, position: 'sticky', left: 34 }}>Emp. Id</StyledTableCell>
              <StyledTableCell align="left" width={165} style={{ minWidth: 165, maxWidth: 165, position: 'sticky', left: 114 }} >Employee</StyledTableCell>
              <StyledTableCell align="left" width={100} style={{ minWidth: 100 }}>Client</StyledTableCell>
              <StyledTableCell align="left" width={100} style={{ minWidth: 100 }}>Project</StyledTableCell>
              <StyledTableCell align="center" width={150} style={{ minWidth: 150 }}>Role</StyledTableCell>
              <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>Allo. Start</StyledTableCell>
              <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>Allo. End</StyledTableCell>
              <StyledTableCell align="left" width={110} style={{ minWidth: 110 }}>Allocation %</StyledTableCell>
              <StyledTableCell align="left" width={110} style={{ minWidth: 110 }}>Billing</StyledTableCell>
              <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>Billing Start</StyledTableCell>
              <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>Billing End</StyledTableCell>
              <StyledTableCell align="center" width={105} style={{ minWidth: 135, position: 'sticky', right: 0 }}>Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {(isLoading) &&
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress color="primary" size={20} /> Loading...
                </StyledTableCell>
              </StyledTableRow>
            }
            {(!isLoading && props.employeeAllocationList.length === 0) &&
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="left">
                  No Records Available.
                </StyledTableCell>
              </StyledTableRow>
            }
            {((!isLoading) && props.employeeAllocationList.length > 0) && (props.rowsPerPage > 0
              ? props.employeeAllocationList?.slice(
                props.page * props.rowsPerPage,
                props.page * props.rowsPerPage + props.rowsPerPage
              )
              : props.employeeAllocationList
            ).map((row: EmployeeDto, index: number) => (
              <EmployeeViewTableRows
                compareWithCurrentDate={props.compareWithCurrentDate}
                key={uuid()}
                employee={row}
                // employeeAllocationList={props.employeeAllocationList}
                index={row.employeeName + index}
                toggleDrawer={props.toggleDrawer}
                briefProjectRoleList={props.briefProjectRoleList}
                selectedEmployee={props.selectedEmployee}
                setSelectedEmployee={props.setSelectedEmployee}
                selectedProject={props.selectedProject}
                setSelectedProject={props.setSelectedProject}
                isRowEditing={props.isRowEditing}
                setIsRowEditing={props.setIsRowEditing}
                isCollapsed={props.isCollapsed}
                setIsCollapsed={props.setIsCollapsed}
                toggleDeallocateEmployeeDialog={props.toggleDeallocateEmployeeDialog}
                toggleUpdateAllocationDialog={props.toggleUpdateAllocationDialog}
                handleUpdateData={props.handleUpdateData}
                addAllocation={props.addAllocation}
                employeeDataSession={props.employeeDataSession}
                toggleAallocationCommentPopup={props.toggleAallocationCommentPopup}
                toggleResignationDetailsPopup={props.toggleResignationDetailsPopup}
                deallocationCustomAlert={props.deallocationCustomAlert}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.children}
      </React.Fragment>
  )
}

export default EmployeeViewTable
