import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, RESIGNATION_DETAILS_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  resignationDetails: {
    isLoading: false,
    data: []
  },
  addComment: {
    isLoading: false,
    data: [],
    status: null
  }
}

const resignationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        resignationDetails: {
          ...state.resignationDetails,
          isLoading: true
        }
      }
    case RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        resignationDetails: {
          ...state.resignationDetails,
          isLoading: false,
          data: action.data
        }
      }
    case RESIGNATION_DETAILS_TYPES.GET_RESIGNATOIN_DETAILS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        resignationDetails: {
          ...state.resignationDetails,
          isLoading: false,
          data: []
        }
      }
    case RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        addComment: {
          ...state.addComment,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    default:
      return state
  }
}

export default resignationReducer
