import { Autocomplete, Button, Divider, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import {
  EmployeeBreakDown,
  ResourceAllocation,
  ResourceUtilization,
  TierPhyramid
} from '../../components'
import { AppLayout } from '../../templates'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import styles from './Dashboard.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { clientAction, dashboardAction } from '../../redux/actions'
import { AllClientHierachyDto, DashboardEmpCountParamsDto, EmployeeBreakdownDataDto, DashboardFilterDto, InitBriefProjectDto, InitialClientDto, ResourceAllocationDataDto, ResourceUtilizationDataDto, TierPhyramidDataDto, TierWiseEmpCountDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto, EmpBreakdownCountDto, GetClientHierachyParamDto } from '../../utilities/models'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const Dashboard: React.FC = () => {
  const INITIAL_FILTER_STATE: DashboardFilterDto = {
    clientName: {
      value: {} as InitialClientDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    },
    projectName: {
      value: {} as InitBriefProjectDto,
      validator: 'object',
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const dispatch = useDispatch()
  const clientHierachy = useSelector((state: any) => state.client.clientHierachy)
  const clientWiseCount = useSelector((state: any) => state.dashboard.clientWiseDataList)
  const trackWiseCount = useSelector((state: any) => state.dashboard.trackWiseDataList)
  const tierWiseCount = useSelector((state: any) => state.dashboard.tierWiseDataList)
  const employeeBreakdown = useSelector((state: any) => state.dashboard.employeeWiseDataList)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [popno, setPopno] = React.useState<number>(-1)
  const [isFiltered, setisFiltered] = React.useState<boolean>(false)
  const openPopup = Boolean(anchorEl)
  const [clientCount, setClientCount] = useState<number[]>([])
  const [clientNames, setClientNames] = useState<string[]>([])
  const [trackwiseEmpCount, setTrackwiseEmpCount] = useState<number[]>([])
  const [trackNames, setTrackNames] = useState<string[]>([])
  const [dashboardFilterData, setDashboardFilterData] = useState<DashboardFilterDto>(INITIAL_FILTER_STATE)
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<UserClientHierarchyDto[]>([])
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<UserClientHierarchyTeamsDto[]>([])
  const [isOpenfilters, setIsOpenfilters] = useState(false)
  // const result = empCount.length && empCount.reduce((accumulator, current) => {
  //   return accumulator + current
  // }, 0)
  React.useEffect(() => {
    getClientHierachy()
    getClientWiseEmployeeCount()
    getTrackWiseEmployeeCount()
    getTierWiseEmployeeCount()
    getEmployeeBreakdown()
  }, [])
  React.useEffect(() => {
    // devide  client , project, users from client hierachy api
    setClientHierarchyclientList(clientHierachy?.data)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
  }, [clientHierachy])
  React.useEffect(() => {
    const allocationChartData = ResourceAllocationChartData()
    setClientNames(allocationChartData.clientNames)
    setClientCount(allocationChartData.clientCount)

    const utilizationChartData = ResourceUtilizationChartData()
    setTrackwiseEmpCount(utilizationChartData.trackWiseEmpCount)
    setTrackNames(utilizationChartData.trackNames)

    TierPhyramidChartData()
  }, [clientWiseCount, trackWiseCount, tierWiseCount])
  const getClientHierachy = () => {
    const clientHeirachyParams: GetClientHierachyParamDto = {
    }
    dispatch(clientAction.getAllClientHierchy(clientHeirachyParams))
  }
  const getClientWiseEmployeeCount = () => {
    dispatch(dashboardAction.getClientWiseDetails())
  }
  const getEmployeeBreakdown = () => {
    dispatch(dashboardAction.getEmployeeWiseDetails())
  }
  const getTrackWiseEmployeeCount = () => {
    const trackwiseEmpParams: DashboardEmpCountParamsDto = {
      clientId: dashboardFilterData.clientName.value.id,
      projectId: dashboardFilterData.projectName.value.id

    }
    dispatch(dashboardAction.getTrackWiseDetails(trackwiseEmpParams))
  }
  const getTierWiseEmployeeCount = () => {
    const tierwiseEmpParams: DashboardEmpCountParamsDto = {
      clientId: dashboardFilterData.clientName.value.id,
      projectId: dashboardFilterData.projectName.value.id

    }
    dispatch(dashboardAction.getTierWiseDetails(tierwiseEmpParams))
  }
  const getFilteredData = () => {
    setisFiltered(true)
    getTrackWiseEmployeeCount()
    getTierWiseEmployeeCount()
  }
  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.data.length > 0) {
      for (const client of clientHierachy?.data) {
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some(
              (i) => i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some(
                  (i) => i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const filterStatusChange = () => {
    setIsOpenfilters(!isOpenfilters)
  }
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, popno: number) => {
    setAnchorEl(event.currentTarget)
    setPopno(popno)
  }
  const handlePopoverClose = () => { setAnchorEl(null) }
  // Handle form input
  const onFilterHandleChange = (property: string, value: any) => {
    switch (property) {
      case 'clientName':
        if (value) {
          const filteredClientList = clientHierachy?.data.filter(
            (client: UserClientHierarchyDto) => client.clientId === value.id
          )
          filterSelectedHierachy(filteredClientList)
          setDashboardFilterData({
            ...dashboardFilterData,
            clientName: {
              ...dashboardFilterData[
              'clientName' as keyof typeof dashboardFilterData
              ],
              value: value
            },
            projectName: {
              ...dashboardFilterData[
              'projectName' as keyof typeof dashboardFilterData
              ],
              value: {} as InitBriefProjectDto,
              error: null
            }

          })
        } else {
          setisFiltered(false)
          setDashboardFilterData({
            ...dashboardFilterData,
            clientName: {
              ...dashboardFilterData[
              'clientName' as keyof typeof dashboardFilterData
              ],
              value: {} as InitialClientDto,
              error: null
            },
            projectName: {
              ...dashboardFilterData[
              'projectName' as keyof typeof dashboardFilterData
              ],
              value: {} as InitBriefProjectDto,
              error: null
            }

          })
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          const tierwiseEmpParams: DashboardEmpCountParamsDto = {}
          dispatch(dashboardAction.getTierWiseDetails(tierwiseEmpParams))
          const trackwiseEmpParams: DashboardEmpCountParamsDto = {}
          dispatch(dashboardAction.getTrackWiseDetails(trackwiseEmpParams))
        }
        break
      case 'projectName':
        if (value) {
          const filteredProjectList = clientHierarchyProjectList.filter(
            (project: UserClientHierarchyTeamsDto) => project.teamId === value.id
          )
          let filteredClient = {} as InitialClientDto
          if (filteredProjectList.length > 0) {
            if (clientHierachy?.data.length > 0) {
              for (const client of clientHierachy?.data) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    if (team.teamId === filteredProjectList[0].teamId) {
                      filteredClient = {
                        id: client.clientId,
                        name: client.clientName,
                        sbuId: client.sbuId,
                        teams: client.teams

                      }
                    }
                  }
                }
              }
            }
          }
          setDashboardFilterData({
            ...dashboardFilterData,
            clientName: {
              ...dashboardFilterData[
              'clientName' as keyof typeof dashboardFilterData
              ],
              value: filteredClient,
              error: null
            },
            projectName: {
              ...dashboardFilterData[
              'projectName' as keyof typeof dashboardFilterData
              ],
              value: value,
              error: null
            }

          })
        } else {
          setisFiltered(false)
          setDashboardFilterData({
            ...dashboardFilterData,
            clientName: {
              ...dashboardFilterData[
              'clientName' as keyof typeof dashboardFilterData
              ],
              value: {} as InitialClientDto,
              error: null
            },
            projectName: {
              ...dashboardFilterData[
              'projectName' as keyof typeof dashboardFilterData
              ],
              value: {} as InitBriefProjectDto,
              error: null
            }

          })
          const tierwiseEmpParams: DashboardEmpCountParamsDto = {}
          dispatch(dashboardAction.getTierWiseDetails(tierwiseEmpParams))
          const trackwiseEmpParams: DashboardEmpCountParamsDto = {}
          dispatch(dashboardAction.getTrackWiseDetails(trackwiseEmpParams))
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
        }
        break
      default:
        setDashboardFilterData({
          ...dashboardFilterData,
          [property]: {
            ...dashboardFilterData[
            property as keyof typeof dashboardFilterData
            ],
            value: value,
            error: null
          }
        })
    }
  }

  const filterSelectedHierachy = (clients: UserClientHierarchyDto[]) => {
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clients.length > 0) {
      for (const client of clients) {
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some(
              (i) => i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some(
                  (i) => i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    setClientHierarchyProjectList(projectList)
  }
  const ResourceAllocationChartData = (): ResourceAllocationDataDto => {
    const resourceAllocationDataObject = {} as ResourceAllocationDataDto
    const clientCountArray: number[] = []
    const clientNamesArray: string[] = []

    for (const row of clientWiseCount?.data) {
      const clientCount = row.count
      clientCountArray.push(clientCount)
      const clientNames = row.clientName
      clientNamesArray.push(clientNames)
    }

    resourceAllocationDataObject.clientCount = clientCountArray
    resourceAllocationDataObject.clientNames = clientNamesArray
    return resourceAllocationDataObject
  }
  const ResourceUtilizationChartData = (): ResourceUtilizationDataDto => {
    const resourceUtilizationDataObject = {} as ResourceUtilizationDataDto
    const trackWiseEmpCountArray: number[] = []
    const trackNamesArray: string[] = []
    let others = 0
    for (const row of trackWiseCount?.data) {
      if (!(row.trackName === 'Exec') && !(row.trackName === 'Finance') && !(row.trackName === 'Admin')) {
        const trackWiseEmpCount = row.count
        trackWiseEmpCountArray.push(trackWiseEmpCount)
        const trackNames = row.trackName
        trackNamesArray.push(trackNames)
      } else {
        others += row.count
      }
    }
    trackWiseEmpCountArray.push(others)
    resourceUtilizationDataObject.trackWiseEmpCount = trackWiseEmpCountArray
    resourceUtilizationDataObject.trackNames = trackNamesArray
    return resourceUtilizationDataObject
  }
  const EmployeebreakdownData = (): EmployeeBreakdownDataDto => {
    const breakdownDataObject = {} as EmployeeBreakdownDataDto
    const empCountArray: number[] = []
    const typesArray: string[] = []
    let type1 = {} as EmpBreakdownCountDto
    let type2 = {} as EmpBreakdownCountDto
    let type3 = {} as EmpBreakdownCountDto
    let type4 = {} as EmpBreakdownCountDto
    if (employeeBreakdown.data?.teamData?.length > 0) {
      const type1DataObject = employeeBreakdown.data?.teamData.filter((row: EmpBreakdownCountDto) => row.type === 'Bench')
      const type2DataObject = employeeBreakdown.data?.teamData.filter((row: EmpBreakdownCountDto) => row.type === 'Executive')
      const type3DataObject = employeeBreakdown.data?.teamData.filter((row: EmpBreakdownCountDto) => row.type === 'Shared services')
      const type4DataObject = employeeBreakdown.data?.teamData.filter((row: EmpBreakdownCountDto) => row.type === 'Unallocated')
      if (type1DataObject.length > 0) {
        type1 = type1DataObject[0]
      }
      if (type2DataObject.length > 0) {
        type2 = type2DataObject[0]
      }
      if (type3DataObject.length > 0) {
        type3 = type3DataObject[0]
      }
      if (type4DataObject.length > 0) {
        type4 = type4DataObject[0]
      }
      for (const row of employeeBreakdown.data?.teamData) {
        const empCount = row.count
        empCountArray.push(empCount)
        const types = row.type
        typesArray.push(types)
      }
    }
    breakdownDataObject.count = empCountArray
    breakdownDataObject.types = typesArray
    breakdownDataObject.type01 = type1
    breakdownDataObject.type02 = type2
    breakdownDataObject.type03 = type3
    breakdownDataObject.type04 = type4

    return breakdownDataObject
  }
  const TierPhyramidChartData = (): TierPhyramidDataDto => {
    const tierPhramidData = {} as TierPhyramidDataDto
    let tier01 = {} as TierWiseEmpCountDto
    let tier02 = {} as TierWiseEmpCountDto
    let tier03 = {} as TierWiseEmpCountDto
    let tier04 = {} as TierWiseEmpCountDto
    if (tierWiseCount?.data.length > 0) {
      const tier01DataObject = tierWiseCount?.data.filter((row: TierWiseEmpCountDto) => row.tierName === 'Tier-1')
      const tier02DataObject = tierWiseCount?.data.filter((row: TierWiseEmpCountDto) => row.tierName === 'Tier-2')
      const tier03DataObject = tierWiseCount?.data.filter((row: TierWiseEmpCountDto) => row.tierName === 'Tier-3')
      const tier04DataObject = tierWiseCount?.data.filter((row: TierWiseEmpCountDto) => row.tierName === 'Tier-4')
      if (tier01DataObject.length > 0) {
        tier01 = tier01DataObject[0]
      }
      if (tier02DataObject.length > 0) {
        tier02 = tier02DataObject[0]
      }
      if (tier03DataObject.length > 0) {
        tier03 = tier03DataObject[0]
      }
      if (tier04DataObject.length > 0) {
        tier04 = tier04DataObject[0]
      }
    }

    tierPhramidData.tier01 = tier01
    tierPhramidData.tier02 = tier02
    tierPhramidData.tier03 = tier03
    tierPhramidData.tier04 = tier04
    return tierPhramidData
  }
  const clearFilters = () => {
    setisFiltered(false)
    setClientHierarchyclientList(clientHierachy?.data)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    setDashboardFilterData(INITIAL_FILTER_STATE)
    const tierwiseEmpParams: DashboardEmpCountParamsDto = {}
    dispatch(dashboardAction.getTierWiseDetails(tierwiseEmpParams))
    const trackwiseEmpParams: DashboardEmpCountParamsDto = {}
    dispatch(dashboardAction.getTrackWiseDetails(trackwiseEmpParams))
  }
  const breakdownData = EmployeebreakdownData()
  return (
    <React.Fragment>
      <AppLayout breadcrumb={[]} componentTitle="Dashboard">
        <p
          style={{
            paddingBottom: 7,
            margin: 0,
            fontWeight: 400,
            fontSize: '24px'
          }}
        >
          Dashboard
        </p>
        <Divider className="dividerStyle" />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12 } className="sectionTitleHolder">
            <h3>Employee Breakdown {Object.keys(employeeBreakdown.data).length > 0 ? ` | ${employeeBreakdown?.data.totalEmployees} Total Employees` : ''}</h3>
          </Grid>
          {/* <Grid item xs={3} className="sectionTitleHolder">
            <h3>Total Employees : {Object.keys(employeeBreakdown.data).length > 0 ? employeeBreakdown?.data.totalEmployees : ''}</h3>
          </Grid> */}
          <Grid container spacing={2}>
            {employeeBreakdown.isLoading &&
              <Grid item xs={12} style={{ padding: '0' }}>
                <Typography className={styles.loadingStyle}>
                  Loading data...
                </Typography>
              </Grid>
            }
            {
              (!employeeBreakdown.isLoading && Object.keys(employeeBreakdown.data).length === 0) &&
              <Grid item xs={12} style={{ padding: '0' }}>
                <Typography className={styles.loadingStyle}>
                  No data to preview
                </Typography>
              </Grid>
            }
            <Grid item xs={2.8} style={{ padding: '0' }}>
              <EmployeeBreakDown
                breakdownData={breakdownData.type01}
                employeeBreakdown={employeeBreakdown}
                barColor={'#FFBF00'}
              />
            </Grid>
            <Divider orientation="vertical" flexItem className={styles.verticalDividerStyle} />
            <Grid item xs={2.8} style={{ padding: '0' }}>
            <EmployeeBreakDown
                breakdownData={breakdownData.type04}
                employeeBreakdown={employeeBreakdown}
                barColor={'#f04e32'}

              />

            </Grid>
            <Divider orientation="vertical" flexItem className={styles.verticalDividerStyle} />
            <Grid item xs={2.8} style={{ padding: '0' }}>
              <EmployeeBreakDown
                breakdownData={breakdownData.type03}
                employeeBreakdown={employeeBreakdown}
                barColor={'#0091ea'}
              />
            </Grid>
            <Divider orientation="vertical" flexItem className={styles.verticalDividerStyle} />
            <Grid item xs={2.8} style={{ padding: '0' }}>
            <EmployeeBreakDown
                breakdownData={breakdownData.type02}
                employeeBreakdown={employeeBreakdown}
                barColor={'#00CA8D'}
              />
            </Grid>
          </Grid>

        </Grid>
        <br></br>
        <Divider className="dividerStyle" />
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={8} className="sectionTitleHolder" style={{ display: 'flex', alignItems: 'center' }}>
            <h3 style={{ paddingRight: '5px' }}>Client | Resource Allocation</h3>
            <Tooltip style={{ paddingBottom: '3px' }} title="Client | Resource allocation is displayed based on your entitlements" >
              <InfoOutlinedIcon fontSize="small" ></InfoOutlinedIcon>
            </Tooltip>
          </Grid>
          <Grid item xs={12} style={{ padding: '0' }}>
            <ResourceAllocation
              clientNames={clientNames}
              clientCount={clientCount}
              clientWiseCountData={clientWiseCount}
            />
          </Grid>
        </Grid>
        <Divider className="dividerStyle" />
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* <Typography className={styles.titleStyle}>
              {isFiltered === true ? ` ${dashboardFilterData.clientName.value.name} | Resource Utilization ` : 'Organization Level | Resource Utilization'}

            </Typography> */}
          </Grid>
          <Grid item xs={8} style={{ float: 'right' }}>

                <Stack direction="row" spacing={2}>
                {isOpenfilters
                  ? <React.Fragment>
                  {/* <Grid item xs={2.5}> */}
                  <Autocomplete
                    fullWidth
                    color="primary"
                    size="small"
                    // disabled={props.isHistoryReport || props.clientHierachyIsloading}
                    value={dashboardFilterData.clientName.value}
                    options={
                      clientHierarchyClientList?.map((client: UserClientHierarchyDto) => ({
                        id: client.clientId,
                        sbuId: client.sbuId,
                        name: client.clientName,
                        teams: client.teams
                      })) || []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )
                    }}
                    onChange={(event, value) =>
                      onFilterHandleChange('clientName', value)
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option: any) => option.name || ''}
                    renderInput={(params) => (
                      <TextField {...params} label="Client" style={{ minWidth: '180px' }} />
                    )}
                  />
                  {/* </Grid>
              <Grid item xs={2.5}> */}
                  <Autocomplete
                    fullWidth
                    size="small"
                    // disabled={props.isHistoryReport || props.clientHierachyIsloading}
                    value={dashboardFilterData.projectName.value}
                    options={
                      clientHierarchyProjectList?.map((project) => ({
                        id: project.teamId,
                        name: project.teamName,
                        users: project.users
                      })) || []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      )
                    }}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => onFilterHandleChange('projectName', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project"
                        color='primary'
                        style={{ minWidth: '180px' }}
                      />
                    )} />
                  {/* </Grid>
              <Grid item xs={3} > */}
                  <Button
                    variant="contained"
                    color={'inherit'}
                    className={'dashboardbtnStyle'}
                    onClick={clearFilters}
                  // disabled={props.authLoading}
                  >

                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    className={'dashboardbtnStyle'}
                    onClick={getFilteredData}
                    disabled={!(Object.keys(dashboardFilterData.clientName.value).length > 0) &&
                      !(Object.keys(dashboardFilterData.projectName.value).length > 0)
                    }
                  >

                    Filter
                  </Button>
                  </React.Fragment>
                  : <React.Fragment>
                <Grid item xs={12}>
                </Grid>
              </React.Fragment>
                  }
                  <Tooltip title={isOpenfilters === true
                    ? 'Hide Filters'
                    : 'Show Filters'}>
                    <IconButton className={
                      (Object.keys(dashboardFilterData.clientName.value).length > 0) ||
                        (Object.keys(dashboardFilterData.projectName.value).length > 0)

                        ? styles.filterIconButtonClicked
                        : styles.filterIconButton} onClick={filterStatusChange}>
                      <FilterListOutlinedIcon

                        className={styles.filterIcon}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                  {/* </Grid> */}
                </Stack>

          </Grid>

          {/* <Grid item xs={0.5} style={{ float: 'left' }}>
            <Tooltip title={isOpenfilters === true
              ? 'Hide Filters'
              : 'Show Filters'}>
              <IconButton className={
                (Object.keys(dashboardFilterData.clientName.value).length > 0) ||
                (Object.keys(dashboardFilterData.projectName.value).length > 0)

                  ? styles.filterIconButtonClicked
                  : styles.filterIconButton} onClick={filterStatusChange}>
                <FilterListOutlinedIcon

                  className={styles.filterIcon}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>

          </Grid> */}
        </Grid>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={6} className="sectionTitleHolder">
            <Grid item xs={12} style={{ paddingTop: '0', display: 'flex', alignItems: 'center' }}>
              <h3 style={{ paddingRight: '5px' }}>
              {isFiltered === true ? ` ${dashboardFilterData.clientName.value.name} Resource Utilization ` : 'Organization Resource Utilization'}</h3>
            <Tooltip style={{ paddingBottom: '3px' }} title="Resource utilization is displayed based on your entitlements" >
              <InfoOutlinedIcon fontSize="small" ></InfoOutlinedIcon>
            </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <ResourceUtilization
                trackNames={trackNames}
                trackwiseEmpCount={trackwiseEmpCount}
                trackWiseCountData={trackWiseCount}
              />
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5.5} className="sectionTitleHolder">
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ paddingRight: '5px' }}> {isFiltered === true ? ` ${dashboardFilterData.clientName.value.name} Tier Pyramid ` : 'Organization Tier Pyramid'}</h3>
                <Tooltip style={{ paddingBottom: '3px' }} title="Tier pyramid data is displayed based on your entitlements" >
              <InfoOutlinedIcon fontSize="small" ></InfoOutlinedIcon>
            </Tooltip>
              </Grid>

            </Grid>

            <br></br>
            <br></br>
            <Grid item xs={12} style={{ alignItems: 'center' }}>
              <TierPhyramid
                handlePopoverClose={handlePopoverClose}
                handlePopoverOpen={handlePopoverOpen}
                openPopup={openPopup}
                anchorEl={anchorEl}
                TierPhyramidChartData={TierPhyramidChartData}
                tierWiseCount={tierWiseCount?.data}
                popno={popno}
                tierWiseCountData={trackWiseCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </AppLayout>
    </React.Fragment>
  )
}

export default Dashboard
