
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { userService } from '../../services'
import { COMMON_ACTION_TYPES, USER_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { EmployeeDetailsParams } from '../../utilities/models'

function * getUserProfileData (action: any) {
  try {
    // @ts-ignore
    const userProfile = yield call(userService.getUserProfile, action.username)
    // console.log('Saga fetch res', userDetail)
    yield put({ type: USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.SUCCESS, data: userProfile.data })
  } catch (error) {
    yield put({ type: USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.ERROR, error: error })
  }
}
function * getUserDetailV2 (action: { type: string, params: EmployeeDetailsParams }) {
  try {
    // @ts-ignore
    const userDetail = yield call(userService.getUserDetailV2, action.params)
    // console.log('Saga fetch res', userDetail)
    yield put({ type: USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.SUCCESS, data: userDetail.data.data })
  } catch (error) {
    yield put({ type: USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.ERROR, error: error })
  }
}
function * getUserListBrief (action: { type: string, params: string}) {
  try {
    // @ts-ignore
    const userListBrief = yield call(userService.getUserListBrief, action.params)
    // console.log('Saga fetch res', userDetail)
    yield put({
      type: USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.SUCCESS,
      data: userListBrief.data
    })
  } catch (error) {
    yield put({
      type: USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.ERROR,
      error: error
    })
  }
}
// function * getUserList (action: { type: string, params: GetDisabledUsersParamDto }) {
//   try {
//     // @ts-ignore
//     const response = yield call(userService.getUserList, action.params)
//     yield put({
//       type:
//       USER_ACTION_TYPES.GET_USER_LIST + COMMON_ACTION_TYPES.SUCCESS,
//       data: response.data
//     })
//   } catch (error) {
//     yield put({
//       type: USER_ACTION_TYPES.GET_USER_LIST + COMMON_ACTION_TYPES.ERROR,
//       error: error as string
//     })
//   }
// }
// function * getUserInformation (action: { type: string, params: EmployeeDetailsParams }) {
//   try {
//     // @ts-ignore
//     const response = yield call(userService.getUserList, action.params)
//     yield put({
//       type:
//       USER_ACTION_TYPES.GET_USER_DETAIL + COMMON_ACTION_TYPES.SUCCESS,
//       data: response.data
//     })
//   } catch (error) {
//     yield put({
//       type:
//       USER_ACTION_TYPES.GET_USER_DETAIL + COMMON_ACTION_TYPES.ERROR,
//       error: error as string
//     })
//   }
// }
function * userSaga () {
  yield takeEvery(USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.REQUEST, getUserProfileData)
  // yield takeLatest(USER_ACTION_TYPES.GET_USER_LIST + COMMON_ACTION_TYPES.REQUEST, getUserList)
  yield takeLatest(USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.REQUEST, getUserListBrief)
  //  yield takeLatest(USER_ACTION_TYPES.GET_USER_DETAIL + COMMON_ACTION_TYPES.REQUEST, getUserInformation)
  yield takeLatest(USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.REQUEST, getUserDetailV2)
}

export default userSaga
