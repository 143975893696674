import { SaveOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material'
import { Autocomplete, Box, Button, Divider, Grid, IconButton, Table, TableBody, TableContainer, TableHead, TextField, Tooltip, Typography, Checkbox } from '@mui/material'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'
import { StyledTableRow, StyledTableCell } from '../../../assets/theme/theme'
import { DATE_FORMAT } from '../../../utilities/constants'
import { EmployeeDto, InitProjectRoleDto, UserClientHierarchyUserDto } from '../../../utilities/models'
import { FilterFormDto, ResourcesFormDto } from '../../../utilities/models/resourceRequest.model'
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined'
import ResourceRequestRemarks from '../ResourceRequestRemarks/ResourceRequestRemarks'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(() => ({
  option: {
    overflow: 'visible !important'
  }
}))
const ResourceRequestEntries: React.FC<{
  briefEmployeeList: UserClientHierarchyUserDto[]
  briefProjectRoleList: InitProjectRoleDto[]
  handleUpdateData(index: number, element: any): void
  isRowEditing: any
  setIsRowEditing: any
  next(): void
  requestType: any
  handleClear(n: number):void
  formValues: ResourcesFormDto[]
  setFormValues(formValues: any): void
  isRowEditingForm: ResourcesFormDto
  setIsRowEditingForm(r: any): void
  currentIndex: number
  setCurrentIndex(n: number): void
  setIsRemarksPopupOpen(b: boolean): void
  isRemarksPopupOpen: any
  handleClearRow(i: number):void
  filterFormData: FilterFormDto
}> = (props) => {
  const styles = useStyles()
  const today = new Date(Date.now())
  const handleChange = (i: any, e: any, property: string) => {
    const newFormValues = [...props.formValues]
    switch (property) {
      case 'designation':
        if (newFormValues.filter((element) => element.designation.value === e).length > 0 && props.requestType === 1) {
          newFormValues[i].designation.error = 'Position already selected'
        } else {
          newFormValues[i].designation.error = null
          newFormValues[i].designation.value = e
        }
        break
      case 'username':
        if (newFormValues.filter((element) => element.user.value === e).length > 0) {
          newFormValues[i].user.error = 'User already selected'
        } else {
          newFormValues[i].user.error = null
          newFormValues[i].user.value = e
        }
        break
      case 'vacancies':
        if (e > 100 || e < 1) {
          newFormValues[i].vacancies.error = 'invalid data'
        } else {
          newFormValues[i].vacancies.value = e
          newFormValues[i].vacancies.error = null
        }
        break
      case 'deploymentStartDate':
        if (newFormValues[i].deploymentEndDate.value <= e && newFormValues[i].deploymentEndDate.value !== null) {
          newFormValues[i].deploymentStartDate.error = 'Cannot be on or after end date'
        } else {
          newFormValues[i].deploymentStartDate.error = null
          newFormValues[i].deploymentStartDate.value = e
        }
        break
      case 'deploymentEndDate':
        if (newFormValues[i].deploymentStartDate.value >= e) {
          newFormValues[i].deploymentEndDate.error = 'Cannot be on or before start date'
        } else {
          newFormValues[i].deploymentStartDate.error = null
          newFormValues[i].deploymentEndDate.error = null
          newFormValues[i].deploymentEndDate.value = e
        }
        break
      case 'isBillable':
        newFormValues[i].isBillable.value = e
        break
      case 'allocationPercentage':
        newFormValues[i].allocationPercentage.value = e
        break
      case 'isDeploymentExtensionConfirm':
        newFormValues[i].isDeploymentExtensionConfirm.value = e
        break
      case 'deploymentExtendPeriod':
        if (e > 100 || e < 1) {
          newFormValues[i].deploymentExtendPeriod.error = 'invalid data'
        } else {
          newFormValues[i].deploymentExtendPeriod.value = e
          newFormValues[i].deploymentExtendPeriod.error = null
        }
        break
      case 'JobDescriptionAndRemarks':
        if (e.replace(/<(.|\n)*?>/g, '').trim().length <= 0) {
          newFormValues[i].JobDescriptionAndRemarks.error = 'Field is required'
        } else {
          newFormValues[i].JobDescriptionAndRemarks.error = null
          newFormValues[i].JobDescriptionAndRemarks.value = e
        }
        break
      // case 'deadline':
      //   newFormValues[i].deadline.value = e
      //   break
    }
    props.setFormValues(newFormValues)
  }
  const handleEdit = (index: number, element: ResourcesFormDto) => {
    props.setIsRowEditing(true)
    props.setIsRowEditingForm(element)
    // props.setCurrentIndex(index)
  }
  const addFormFields = (i: number) => {
    const resourcesFormDto: ResourcesFormDto = {
      designation: { value: {} as InitProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
      user: { value: {} as EmployeeDto, validator: 'object', isRequired: true, error: null, disable: false },
      vacancies: { value: 0, validator: 'number', isRequired: true, error: null, disable: false },
      deploymentStartDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
      deploymentEndDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
      isDeploymentExtensionConfirm: { value: false, validator: 'object', isRequired: true, error: null, disable: false },
      deploymentExtendPeriod: { value: 1, validator: 'number', isRequired: true, error: null, disable: false },
      JobDescriptionAndRemarks: { value: '', validator: 'object', isRequired: true, error: null, disable: false },
      isBillable: { value: '', validator: 'object', isRequired: false, error: null, disable: false },
      allocationPercentage: { value: 100, validator: 'number', isRequired: false, error: null, disable: false },
      // deadline: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
      index: i
    }
    if (props.requestType === 1) {
      props.setFormValues([...props.formValues, {
        designation: { value: {} as InitProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
        vacancies: { value: 1, validator: 'number', isRequired: true, error: null, disable: false },
        deploymentStartDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
        deploymentEndDate: { value: null, validator: 'object', isRequired: true, error: null, disable: false },
        isDeploymentExtensionConfirm: { value: false, validator: 'object', isRequired: false, error: null, disable: false },
        deploymentExtendPeriod: { value: 1, validator: 'number', isRequired: true, error: null, disable: false },
        JobDescriptionAndRemarks: { value: '', validator: 'object', isRequired: true, error: null, disable: false },
        isBillable: { value: '', validator: 'object', isRequired: false, error: null, disable: false },
        allocationPercentage: { value: 100, validator: 'number', isRequired: false, error: null, disable: false },
        // deadline: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
        index: i
      }])
    } else {
      props.setFormValues([...props.formValues, {
        designation: { value: {} as InitProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
        user: { value: {} as EmployeeDto, validator: 'object', isRequired: true, error: null, disable: false },
        deploymentStartDate: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
        deploymentEndDate: { value: null, validator: 'object', isRequired: true, error: null, disable: false },
        isDeploymentExtensionConfirm: { value: false, validator: 'object', isRequired: false, error: null, disable: false },
        deploymentExtendPeriod: { value: 1, validator: 'number', isRequired: false, error: null, disable: false },
        JobDescriptionAndRemarks: { value: '', validator: 'object', isRequired: true, error: null, disable: false },
        isBillable: { value: '', validator: 'object', isRequired: false, error: null, disable: false },
        allocationPercentage: { value: 100, validator: 'number', isRequired: false, error: null, disable: false },
        // deadline: { value: new Date(), validator: 'object', isRequired: true, error: null, disable: false },
        index: i
      }])
    }
    props.setIsRowEditingForm(resourcesFormDto)
    props.setIsRowEditing(true)
    props.setCurrentIndex(i + 1)
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} className="sectionTitleHolder">
          <h3>Resources</h3>
        </Grid>
        <Grid item md={12}>
        {/* {props.isRowEditingForm.JobDescriptionAndRemarks.error !== null &&
              <Alert severity="error">Job Description is required!</Alert>
          } */}
          </Grid>
        <Grid item md={12}>
          <Grid container spacing={2} sx={{ padding: '0px 20px' }}>
        <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              {props.requestType === 1
                ? <React.Fragment><StyledTableCell align="center" width={100} style={{ minWidth: 200 }}>
                <Tooltip title="Required Position"><span>Required Position</span></Tooltip>
                </StyledTableCell>
              <StyledTableCell align="center" width={100} style={{ minWidth: 100 }}>
              <Tooltip title="Number of Vacancies"><span>No. of Vacancies</span></Tooltip>
                </StyledTableCell></React.Fragment>
                : <React.Fragment><StyledTableCell align="center" width={100} style={{ minWidth: 200 }}>
                <Tooltip title="Replacing Employee"><span>Replacing Employee</span></Tooltip>
                </StyledTableCell>
              <StyledTableCell align="center" width={100} style={{ minWidth: 100 }}>
              <Tooltip title="Required Position"><span>Required Position</span></Tooltip>
                </StyledTableCell></React.Fragment>
              }
              <StyledTableCell align="center" width={120} style={{ minWidth: 120 }}>
              <Tooltip title="Deployment Start Date"><span>Deploy. Start</span></Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center" width={120} style={{ minWidth: 120 }}>

              <Tooltip title="Deployment End Date"><span>Deploy. End</span></Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center" width={100} style={{ minWidth: 100 }}>

                <Tooltip title="Deployment Extendible"><span>Deploy. Extendible</span></Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center" width={120} style={{ minWidth: 120 }}>
              <Tooltip title="Deployment Extension Period (months)"><span>Exten. Period (months)</span></Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center" width={120} style={{ minWidth: 100 }}>
              <Tooltip title="Job Description and Remarks"><span>JD & Remarks</span></Tooltip>
                </StyledTableCell>
              <StyledTableCell align="center" width={110} style={{ minWidth: 110 }}>
              <Tooltip title="Billing"><span>Billing</span></Tooltip>

              </StyledTableCell>
              <StyledTableCell align="center" width={110} style={{ minWidth: 110 }}>
              <Tooltip title="Allocation Percentage"><span>Allocation %</span></Tooltip>

              </StyledTableCell>
              {/* <StyledTableCell align="left" width={120} style={{ minWidth: 120 }}>Request Deadline</StyledTableCell> */}
              <StyledTableCell align="center" width={105} style={{ minWidth: 100, position: 'sticky', right: 0 }}>
              <Tooltip title="Actions"><span>Actions</span></Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
          <React.Fragment>
          {props.formValues.map((element: any, index: any) => (
            <StyledTableRow key={index}>
            {props.requestType === 1
              ? <><StyledTableCell align="left" style={{ minWidth: 150, maxWidth: 150, wordWrap: 'break-word' }}>
              {(props.isRowEditingForm.index === element.index && props.isRowEditing)
                ? <><Autocomplete
                size={'small'}
                      value={element.designation.value}
                      onChange={(event, newValue) => {
                        handleChange(index, newValue, 'designation')
                      }}
                      options={props.briefProjectRoleList}
                      getOptionLabel={(option) => option.projectRoleName || ''}
                      renderInput={(params) => <TextField
                        variant="standard"
                        required={element.designation.isRequired}
                        helperText={element.designation.error !== null && element.designation.error === 'Field is empty' ? '' : element.designation.error}
                        error={element.designation.error !== null}
                        {...params} size={'small'}
                        sx={{ minWidth: 110, margin: 0, padding: 0, textAlign: 'start' }} />}
                    /></>
                : <Typography>
                            {element.designation.value?.projectRoleName}
                          </Typography>
              }
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100 }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <TextField variant="standard"
                          style={{ width: '70px' }}
                          required={element.vacancies.isRequired}
                          type={'number'}
                          size={'small'}
                          value={element.vacancies.value}
                          InputProps={{
                            inputProps: {
                              max: 100, min: 1
                            }
                          }}
                          onChange={(event) => handleChange(index, event.target.value, 'vacancies')}
                          error={element.vacancies.error !== null}
                        // helperText={element.vacancies.error !== null ? 'Required Field' : ''}
                        ></TextField>
              : <Typography>
                {element.vacancies.value}
                          </Typography>
              }
                    </StyledTableCell></>
              : <>
              <StyledTableCell align="left" style={{ minWidth: 200, maxWidth: 200, wordWrap: 'break-word' }}>
              {(props.isRowEditingForm.index === element.index && props.isRowEditing)
                ? <Autocomplete
                  onChange={(event, newValue) => {
                    handleChange(index, newValue, 'username')
                  } }
                  value={element.user.value}
                  options={props.briefEmployeeList}
                  getOptionLabel={(option) => option.userName || ''}
                  classes={{ option: styles.option }}
                  renderInput={(params) =>
                  <TextField
                    variant="standard"
                    required={element.user.isRequired}
                    helperText={element.user.error !== null && element.user.error === 'Field is empty' ? '' : element.user.error}
                    error={element.user.error !== null}
                    {...params} size={'small'}
                    sx={{ minWidth: 110, maxWidth: 200, margin: 0, padding: 0, textAlign: 'start' }}
                  />}
              />
                : <Typography>
                  {element.user.value?.userName}
                        </Typography>
                      }
              </StyledTableCell>
              <StyledTableCell align="center" style={{ minWidth: 200, maxWidth: 200, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <Autocomplete
              value={element.designation.value}
              // onInputChange={(event, newValue) => {
              //   let value
              //   if (typeof newValue === 'string') {
              //     value = {
              //       projectRoleName: newValue,
              //       projectRoleId: 0
              //     }
              //   } else {
              //     value = newValue
              //   }
              //   handleChange(index, value, 'designation')
              // }}
              onChange={(event, newValue) => handleChange(index, newValue, 'designation')}
              options={props.briefProjectRoleList}
              getOptionLabel={(option) => option.projectRoleName || ''}
                // e.g value selected with enter, right from the input
                // if (typeof option === 'string') {
                //   return option
                // }
              // }}
              // selectOnFocus
              // clearOnBlur
              // handleHomeEndKeys
              // renderOption={(props, option) => <li {...props}>{option.projectRoleName}</li>}
              // freeSolo
              renderInput={(params) => <TextField
                variant="standard"
                required={element.designation.isRequired}
                error={element.designation.error !== null}
                {...params} size={'small'}
                sx={{ minWidth: 110, margin: 0, padding: 0, textAlign: 'start' }} />}
            />
              : <Typography>
                          {element.designation.value?.projectRoleName || element.designation.value}
                        </Typography>
                      }
                 </StyledTableCell>
                 </>
            }
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={element.deploymentStartDate.value}
                inputFormat={DATE_FORMAT}
                closeOnSelect={true}
                onChange={(value) => handleChange(index, value, 'deploymentStartDate')}
                minDate={moment(today)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    required={element.deploymentStartDate.isRequired}
                    error={element.deploymentStartDate.error !== null}
                    helperText={element.deploymentStartDate.error}
                    variant="standard"
                    size={'small'}
                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }} />}
              />
            </LocalizationProvider>
              : (moment(element.deploymentStartDate.value).format(DATE_FORMAT))
            }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={element.deploymentEndDate.value}
                inputFormat={DATE_FORMAT}
                closeOnSelect={true}
                defaultCalendarMonth={moment(element.deploymentStartDate.value)}
                onChange={(value) => handleChange(index, value, 'deploymentEndDate')}
                minDate={element.deploymentStartDate.value ? moment(element.deploymentStartDate.value).add(1, 'days') : undefined}
                // maxDate={isEditingRow.endDate.value !== null ? moment(isEditingRow.endDate.value) : undefined}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    variant="standard"
                    size={'small'}
                    required={element.deploymentEndDate.isRequired}
                    error={element.deploymentEndDate.error !== null}
                    helperText={element.deploymentEndDate.error}
                    sx={{
                      svg: { width: '15px', height: '15px' },
                      maxWidth: 100,
                      margin: 0,
                      padding: 0
                    }} />}
              />
            </LocalizationProvider>
              : (moment(element.deploymentEndDate.value).format(DATE_FORMAT))
          }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ textAlign: 'center', minWidth: 100, maxWidth: 100 }}>
              {(props.isRowEditingForm.index === element.index && props.isRowEditing)
                ? <Checkbox
                  checked={element.isDeploymentExtensionConfirm.value}
                  onChange={(event, value) => handleChange(index, value, 'isDeploymentExtensionConfirm')}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                : <Checkbox
                    checked={element.isDeploymentExtensionConfirm.value}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <TextField variant="standard"
                          style={{ width: '70px' }}
                          disabled={element.isDeploymentExtensionConfirm.value === false}
                          required={element.isDeploymentExtensionConfirm.value === true}
                          error={element.deploymentExtendPeriod.error !== null}
                          type={'number'}
                          size={'small'}
                          value={element.isDeploymentExtensionConfirm.value ? element.deploymentExtendPeriod.value : 1}
                          InputProps={{
                            inputProps: {
                              max: 100, min: 1
                            }
                          }}
                          onChange={(event) => handleChange(index, event.target.value, 'deploymentExtendPeriod')}
                        ></TextField>
              : <Typography>
                            {element.isDeploymentExtensionConfirm.value ? element.deploymentExtendPeriod.value : '-'}
                          </Typography>
              }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
             {(props.isRowEditingForm.index === element.index && props.isRowEditing)
               ? <Box width={'100%'}>
            <IconButton
             size='small'
             className={element.JobDescriptionAndRemarks.error !== null ? 'actionClosedButtonStyle' : ''}
             onClick={() => {
               props.setIsRemarksPopupOpen(true)
             } }
            >
              <Tooltip title="Click to add Job Description and Additional Remarks">
                <AppRegistrationOutlinedIcon
                sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              </Box>
               : <Box width={'100%'}>
            <IconButton size='small'
            disabled
            >
                <AppRegistrationOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
              </IconButton>
              </Box>
              }
              {(props.isRemarksPopupOpen && props.isRowEditingForm.index === element.index && props.isRowEditing) &&
              <ResourceRequestRemarks
                buttonText={'Add Job Description'}
                handleChange={handleChange}
                isRemarksPopupOpen={props.isRemarksPopupOpen}
                resourceIndex={index}
                setIsRemarksPopupOpen={props.setIsRemarksPopupOpen}
                elementValue={element.JobDescriptionAndRemarks.value}
                isRowEditingFormValue={props.isRowEditingForm.JobDescriptionAndRemarks.value}
                readOnly={false}
              />
            }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <Autocomplete
              size='small'
              disablePortal
              options={['Billable', 'Non-billable']}
              value={element.isBillable.value}
              onChange={(event, value) => handleChange(index, value, 'isBillable')}
              renderInput={(params) =>
                <TextField variant="standard"
                required={element.isBillable.isRequired}
                error={element.isBillable.error !== null}
                  {...params} size={'small'}
                  sx={{ maxWidth: 110 }} />}
            />
              : <Typography>
                  {element.isBillable.value || '-'}
                </Typography>
            }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word' }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <TextField variant="standard"
                  style={{ width: '70px' }}
                  type={'number'}
                  size={'small'}
                  value={element.allocationPercentage.value}
                  InputProps={{
                    inputProps: {
                      max: 100, min: 0
                    }
                  }}
                  onChange={(event) => handleChange(index, event.target.value, 'allocationPercentage')}
                  required={element.allocationPercentage.isRequired}
                  error={element.allocationPercentage.error !== null}
                ></TextField>
              : <Typography>
                  {element.allocationPercentage.value === '' ? '-' : element.allocationPercentage.value + '%'}
                </Typography>
            }
            </StyledTableCell>
            <StyledTableCell align="center" style={{ minWidth: 100, maxWidth: 100, wordWrap: 'break-word', position: 'sticky', right: 0 }}>
            {(props.isRowEditingForm.index === element.index && props.isRowEditing)
              ? <Box width={'100%'}>
            <IconButton size='small' onClick={() => { props.handleUpdateData(index, props.formValues[index]) }}>
                <Tooltip title="Save to continue">
                <SaveOutlined color='primary' sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              <IconButton
              onClick={() => { props.handleClearRow(index) }}
              size='small' className='actionClosedButtonStyle' >
                <Tooltip title="Delete">
                <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
              </Box>
              : <Box width={'100%'}>
            <IconButton
                  size='small'
                  disabled={props.isRowEditing === true}
                   onClick={() => handleEdit(index, element)}
                  >
                  <Tooltip title="Edit">
                    <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                  </Tooltip>
                </IconButton>
                <IconButton
              onClick={() => { props.handleClearRow(index) }}
              size='small'
              disabled={props.isRowEditing === true}
              classes={{ disabled: 'disabledDeallocateButtonStyle' }}
              color='error'>
                <Tooltip title="Delete">
                <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton>
                </Box>
            }

                        </StyledTableCell>
                      </StyledTableRow>

          ))
            }
             </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={props.isRowEditing === true || (!(props.filterFormData.sbu.value.id && props.filterFormData.clientName.value.clientId && props.filterFormData.projectName.value.teamId))}
        sx={{ textTransform: 'capitalize', color: '8B8B8B' }}
        onClick={() => {
          addFormFields(props.currentIndex)
        }}
      >
        + Add New Resources
        </Button>
      </Grid>
      <br />
        <Divider className="dividerStyle" />
        <br />
        <Button
                className={'cancelBtnStyle'}
                color="inherit"
                variant="contained"
                onClick={() => { props.handleClear(1) }}
              >
                Cancel
              </Button>
              {/* <NavLink to='/abc' className={'btnStyle'} aria-disabled={props.isRowEditing}> */}
          <Button
            variant="contained"
            className={'btnStyle'}
            onClick={props.next}
            disabled={props.isRowEditing === true || props.formValues.length <= 0}
          >
            Next
          </Button>
          {/* </NavLink> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default ResourceRequestEntries
