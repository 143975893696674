import {
  CLIENT_ACTION_TYPES,
  COMMON_ACTION_TYPES,
  EMPLOYEE_ACTION_TYPES,
  PROJECT_ACTION_TYPES,
  REPORT_ACTION_TYPES,
  SBU_ACTION_TYPES,
  SUPERVISOR_ACTION_TYPES,
  COMMENTS_ACTION_TYPES,
  RESIGNATION_DETAILS_TYPES,
  RESOURCE_ACTION_TYPES
} from '../../utilities/constants/action.constants'
import { AlertActionDto } from '../../utilities/models'

const INITIAL_STATE = {
  createSbu: {
    message: null,
    severity: null
  },
  updateSbu: {
    message: null,
    severity: null
  },
  createClient: {
    message: null,
    severity: null
  },
  updateClient: {
    message: null,
    severity: null
  },
  createProject: {
    message: null,
    severity: null
  },
  updateProject: {
    message: null,
    severity: null
  },
  allocateEmployee: {
    message: null,
    severity: null
  },
  deallocateEmployee: {
    message: null,
    severity: null
  },
  validateEmployee: {
    message: null,
    severity: null
  },
  updateEmployeeAllocation: {
    message: null,
    severity: null
  },
  createSupervisor: {
    message: null,
    severity: null
  },
  unassignSupervisor: {
    message: null,
    severity: null
  },
  filterReports: {
    message: null,
    severity: null
  },
  addComment: {
    message: null,
    severity: null
  },
  addResignationComment: {
    message: null,
    severity: null
  },
  addResourceComment: {
    message: null,
    severity: null
  },
  createResource: {
    message: null,
    severity: null
  },
  updateResources: {
    message: null,
    severity: null
  },
  updateRequest: {
    message: null,
    severity: null
  },
  resourceAction: {
    message: null,
    severity: null
  },
  createPropose: {
    message: null,
    severity: null
  },
  deleteProposedResource: {
    message: null,
    severity: null
  },
  confirmProposedResources: {
    message: null,
    severity: null
  },
  proposedResourcesAction: {
    message: null,
    severity: null
  },
  closeRequest: {
    message: null,
    severity: null
  }
}

const alertReducer = (state = INITIAL_STATE, action: AlertActionDto) => {
  switch (action.type) {
    // add create alert
    case SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createSbu: {
          message: action.message,
          severity: action.severity
        }
      }
    case SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createSbu: {
          message: null,
          severity: null
        }
      }
    // add client create alert
    case CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createClient: {
          message: action.message,
          severity: action.severity
        }
      }
    case CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createClient: {
          message: null,
          severity: null
        }
      }
    // add update sbu alter
    case SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateSbu: {
          message: action.message,
          severity: action.severity
        }
      }
    case SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateSbu: {
          message: null,
          severity: null
        }
      }
    // add update sbu alter
    case CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateClient: {
          message: action.message,
          severity: action.severity
        }
      }
    case CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateClient: {
          message: null,
          severity: null
        }
      }
    case PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createProject: {
          message: action.message,
          severity: action.severity
        }
      }
    case PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createProject: {
          message: null,
          severity: null
        }
      }
    // add update sbu alter
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateProject: {
          message: action.message,
          severity: action.severity
        }
      }
    case PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateProject: {
          message: null,
          severity: null
        }
      }
    //  supervisor create
    case SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createSupervisor: {
          message: action.message,
          severity: action.severity
        }
      }
    case SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createSupervisor: {
          message: null,
          severity: null
        }
      }
    // unassign supervisor
    case SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        unassignSupervisor: {
          message: action.message,
          severity: action.severity
        }
      }
    case SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        unassignSupervisor: {
          message: null,
          severity: null
        }
      }
    case EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateEmployeeAllocation: {
          message: action.message,
          severity: action.severity
        }
      }
    case EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateEmployeeAllocation: {
          message: null,
          severity: null
        }
      }
    case EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        allocateEmployee: {
          message: action.message,
          severity: action.severity
        }
      }
    case EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        allocateEmployee: {
          message: null,
          severity: null
        }
      }
    case EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        deallocateEmployee: {
          message: action.message,
          severity: action.severity
        }
      }
    case EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        deallocateEmployee: {
          message: null,
          severity: null
        }
      }
    case EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        validateEmployee: {
          message: action.message,
          severity: action.severity
        }
      }
    case EMPLOYEE_ACTION_TYPES.SET_VALIDATE_EMPLOYEE + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        validateEmployee: {
          message: null,
          severity: null
        }
      }
    case REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        filterReports: {
          message: action.message,
          severity: action.severity
        }
      }
    case REPORT_ACTION_TYPES.SET_REPORT_FILTER_ALERT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        filterReports: {
          message: null,
          severity: null
        }
      }
    // #endregion EMPLOYEE ALERTS
    // comments create
    case COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        addComment: {
          message: action.message,
          severity: action.severity
        }
      }
    case COMMENTS_ACTION_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        addComment: {
          message: null,
          severity: null
        }
      }
    // comments create
    case RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        addResignationComment: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESIGNATION_DETAILS_TYPES.ADD_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        addResignationComment: {
          message: null,
          severity: null
        }
      }
    // create resource comments
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        addResourceComment: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_COMMENT + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        addResourceComment: {
          message: null,
          severity: null
        }
      }
    // add create resource request alert
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createResource: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_RESOURCE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createResource: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateResources: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateResources: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        updateRequest: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.UPDATE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        updateRequest: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        resourceAction: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.RESOURCE_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        resourceAction: {
          message: null,
          severity: null
        }
      }
      // propose resource request
    case RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        createPropose: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.CREATE_PROPOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        createPropose: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        deleteProposedResource: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.DELETE_PROPOSED_RESOURCE + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        deleteProposedResource: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        confirmProposedResources: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.CONFIRM_PROPOSED_RESOURCES + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        confirmProposedResources: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        proposedResourcesAction: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.PROPOSED_RESOURCES_ACTION + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        proposedResourcesAction: {
          message: null,
          severity: null
        }
      }
    case RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.SET_ALERT:
      return {
        ...state,
        closeRequest: {
          message: action.message,
          severity: action.severity
        }
      }
    case RESOURCE_ACTION_TYPES.CLOSE_REQUEST + COMMON_ACTION_TYPES.CLEAR_ALERT:
      return {
        ...state,
        closeRequest: {
          message: null,
          severity: null
        }
      }
    default:
      return state
  }
}

export default alertReducer
