import { put, call, takeEvery } from 'redux-saga/effects'
import { COMMON_ACTION_TYPES, REPORT_ACTION_TYPES } from '../../utilities/constants'
import { GetReportParamDto } from '../../utilities/models'
import { reportService } from '../../services'
function * allocationListForReport (action: { type: string, params: GetReportParamDto }) {
  try {
    // @ts-ignore
    const allocations = yield call(reportService.getAllocationForReport, action.params)
    yield put({
      type:
      REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: allocations.data
    })
  } catch (error) {
    yield put({
      type: REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * allocationListForFinanceReport (action: { type: string, params: GetReportParamDto }) {
  try {
    // @ts-ignore
    const allocations = yield call(reportService.getAllocationForFinanceReport, action.params)
    yield put({
      type:
      REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: allocations.data
    })
  } catch (error) {
    yield put({
      type: REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * reportSagas () {
  yield takeEvery(
    REPORT_ACTION_TYPES.GET_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST,
    allocationListForReport
  )
  yield takeEvery(
    REPORT_ACTION_TYPES.GET_FINANCE_REPORTS_DATA + COMMON_ACTION_TYPES.REQUEST,
    allocationListForFinanceReport
  )
}
export default reportSagas
