import React from 'react'
import { Button, CircularProgress, DialogActions, DialogContent, Typography } from '@mui/material'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import styles from './OverAllocationPopup.module.scss'

const OverAllocationPopup: React.FC<{
  overAllocationDialogOpen: boolean
  allocateEmployeeIsloading: boolean;
  updateEmployeeIsloading: boolean;
  toggleOverAllocationDialog(): void;
  cancelOverAllocationDialog():void;
  overAllocationConfirmation():void;
}> = (props) => {
  return (
    <BootstrapDialog
      // className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.overAllocationDialogOpen}>
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={props.toggleOverAllocationDialog}
      >
        Please Confirm
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
        <Typography gutterBottom>
          There is an  <b>Over Allocation </b>
          {/* &#39;{props.selectedEmployee?.userName}&#39;
          from project &#39;{props.selectedProject?.projectName}&#39; ? */}
        </Typography>
         <div className={'layout-row worningStyle'}>
            <WarningAmberOutlinedIcon className={styles.iconStyle} />
            <Typography >
            Allocation Percentage is over allocated for relevant time period!
            </Typography>
          </div>

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={'btnStyle'}
          disabled={props.allocateEmployeeIsloading || props.updateEmployeeIsloading}
          onClick={props.overAllocationConfirmation}
        >
           {props.allocateEmployeeIsloading && (
                      <CircularProgress size="13px" className={styles.loading} />
           )}
            {props.updateEmployeeIsloading && (
                      <CircularProgress size="13px" className={styles.loading} />
            )}
          Confirm
        </Button>
        <Button
          variant="contained"
          className={'btnStyle'}
          color={'inherit'}
          onClick={props.cancelOverAllocationDialog}>
          Cancel
        </Button>
      </DialogActions >
    </BootstrapDialog >
  )
}

export default OverAllocationPopup
