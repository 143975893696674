import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { ClientEntryFormDto, InitialSbuDto } from '../../../utilities/models'

const ClientEntryForm: React.FC<{
  clientFormData: ClientEntryFormDto;
  sbuList: Array<InitialSbuDto>;
  isShowHelperText:boolean;
  createClient(): void;
  handleInputFocus(property: string): void;
  onInputHandleChange(property: string, value: any): void;

}> = (props) => {
  return (
  <div>
    <Grid container spacing={3}>
      <Grid item md={3} className="sectionTitleHolder">
        <h3>Create New Client</h3>
        <p>Add a new client by providing a name and selecting a SBU from the list.</p>
      </Grid>
      <Grid item md={7} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              value={props.clientFormData.clientName.value}
              label="Client Name"
              color='primary'
              size="small"
              fullWidth
               onFocus={() => props.handleInputFocus('clientName')}
              helperText={ props.isShowHelperText && props.clientFormData.clientName.error !== null ? 'Client Name is required' : ''}
              error={!!props.clientFormData.clientName.error}
              required={props.clientFormData.clientName.isRequired}
              disabled={props.clientFormData.clientName.disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('clientName', event.target.value)} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value = {props.clientFormData.strategicBusinessUnit.value}
              fullWidth
              size="small"
              disabled={props.clientFormData.strategicBusinessUnit.disable}
              options={props.sbuList.map((sbu) => {
                return { name: sbu.sbuName, id: sbu.sbuId }
              })}
              isOptionEqualToValue={(option, value) => option.name === value.name }
              getOptionLabel={(option) => option.name || ''}
              onChange={(event, value) => props.onInputHandleChange('strategicBusinessUnit', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Strategic Business Unit"
                  color='primary'
                   onFocus={() => props.handleInputFocus('strategicBusinessUnit')}
                  helperText={props.isShowHelperText && props.clientFormData.strategicBusinessUnit.error !== null ? 'Strategic Business Unit is required' : ''}
                  error={!!props.clientFormData.strategicBusinessUnit.error}
                  required={props.clientFormData.strategicBusinessUnit.isRequired}

                   />
              )
            }
               />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              value={props.clientFormData.clientDescription.value}
              label="Client Description"
              color='primary'
              size="small"
              fullWidth
              multiline
              rows={4}
              onFocus={() => props.handleInputFocus('clientDescription')}
                 helperText={ props.isShowHelperText && props.clientFormData.clientDescription.error}
              error={!!props.clientFormData.clientDescription.error}
              required={props.clientFormData.clientDescription.isRequired}
              disabled={props.clientFormData.clientDescription.disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('clientDescription', event.target.value)} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              className={'btnStyle'}
              onClick={props.createClient}
            >

              Create Client
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  </div>
  )
}

export default ClientEntryForm
