import { delay, put, takeEvery, call } from 'redux-saga/effects'
import { CLIENT_ACTION_TYPES, COMMON_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AlertActionDto, CreateClientParamDto, GetClientHierachyParamDto, GetDisabledClientParamDto, UpdateClientParamDto } from '../../utilities/models'
import { clientService } from '../../services'
import { ALERT_CONFIGS } from '../../utilities/constants/app.constants'
function * allClientList (action: { type: string, params: GetDisabledClientParamDto }) {
  try {
    // @ts-ignore
    const clientList = yield call(clientService.getAllClientList, action.params)
    yield put({
      type:
      CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: clientList.data
    })
  } catch (error) {
    yield put({
      type: CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * getAllClientHierchy (action: { type: string, params: GetClientHierachyParamDto }) {
  try {
    // @ts-ignore
    const clientHierachy = yield call(clientService.getAllClientHierchy, action.params)
    yield put({
      type:
      CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.SUCCESS,
      data: clientHierachy.data.data
    })
  } catch (error) {
    yield put({
      type: CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * getEntitledClientHierchy (action: { type: string, params: GetClientHierachyParamDto }) {
  try {
    // @ts-ignore
    const clientHierachy = yield call(clientService.getAllClientHierchy, action.params)
    yield put({
      type:
      CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.SUCCESS,
      data: clientHierachy.data.data
    })
  } catch (error) {
    yield put({
      type: CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * updateClient (action: { type: string, payload: UpdateClientParamDto }) {
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const updateSbu = yield call(clientService.updateClient, action.payload)
    const setAlert: AlertActionDto = {
      message: updateSbu.data.message,
      severity: 'success',
      type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * createClient (action: { type: string; payload: CreateClientParamDto; }) {
  try {
    // @ts-ignore

    // eslint-disable-next-line no-undef
    const createdClientData = yield call(clientService.createClient, action.payload)
    const setAlert: AlertActionDto = {
      message: createdClientData.data.message,
      severity: 'success',
      type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * clientSagas () {
  yield takeEvery(
    CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST,
    allClientList
  )
  yield takeEvery(
    CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST,
    getAllClientHierchy
  )
  yield takeEvery(CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.REQUEST,
    updateClient
  )
  yield takeEvery(CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.REQUEST,
    createClient
  )
  yield takeEvery(
    CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST,
    getEntitledClientHierchy
  )
}
export default clientSagas
