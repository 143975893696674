import { COMMON_ACTION_TYPES, USER_ACTION_TYPES } from '../../utilities/constants'
import { EmployeeDetailsParams, GetUserListBriefParamsDto } from '../../utilities/models'

const getUserListBrief = (params?: GetUserListBriefParamsDto) => {
  return {
    type: USER_ACTION_TYPES.GET_USER_LIST_BRIEF + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const getUserDetailsV2 = (params: EmployeeDetailsParams) => {
  return {
    type: USER_ACTION_TYPES.GET_USER_DETAIL_V2 + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}
const getUserProfile = (username: string) => {
  return {
    type: USER_ACTION_TYPES.GET_USER_PROFILE + COMMON_ACTION_TYPES.REQUEST,
    username: username

  }
}
export const userActions = {
  getUserProfile,
  getUserListBrief,
  getUserDetailsV2

}
