import { COMMON_ACTION_TYPES, HERA_CONFIG_ACTION_TYPES }
  from '../../utilities/constants/action.constants'
import { ResourceManagementConfigDParams } from '../../utilities/models'
const getResourceManagementConfig = (params: ResourceManagementConfigDParams) => {
  return {
    type: HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.REQUEST,
    params: params

  }
}

export const configAction = {
  getResourceManagementConfig
}
