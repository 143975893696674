import { Autocomplete, Box, Button, Checkbox, CircularProgress, DialogActions, DialogContent, Divider, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Switch, Table, TableBody, TableHead, TextField, Typography } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle, StyledProposeResourcesRow, StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import styles from '../../resource-request/ResourceRequestsCommentPopup/ResourceRequestsCommentPopup.module.scss'
import styles1 from './ProposeResourcesPopup.module.scss'
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { AlertDto, ProposeResourcesListDto, InitialSbuDto, InitProjectRoleDto, TrackDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto } from '../../../utilities/models'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import { AppTablePagination } from '../../shared'
import { makeStyles } from '@mui/styles'
import { ProposeRequestPayloadDto, ProposeResourceFilter } from '../../../utilities/models/resourceRequest.model'
const useStyles = makeStyles((theme: { palette: { text: { secondary: any } }; spacing: (arg0: number) => any }) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  gpsIcon: {
    position: 'absolute',
    borderLeftColor: '3%'
  },
  input: {
    '& > label': {
      paddingLeft: '1.5em !important'
    },
    '& > div > input': {
      paddingLeft: '1.4em !important'
    }
  }
}))
const ProposeResourcesPopup: React.FC<{
  isProposeResourcesPopupOpen: boolean
  setIsProposeResourcesPopupOpen(args: boolean): void
  createProposeResorce(requestId: number, resourceId: number): void
  resourceId: number
  resourceRequestId: number
  addProposeRequestAlert: AlertDto
  onproposeChange(value: any, event: any, item: any):void
  filterFormData: ProposeResourceFilter
  sbuList: InitialSbuDto[]
  projectList: UserClientHierarchyTeamsDto[]
  clientList: UserClientHierarchyDto[]
  handleChangeFilterForm(args: string, value: any): void
  trackList: TrackDto[]
  designationList: InitProjectRoleDto[]
  getAllocationList(isClear: boolean, value: string | null): void
  employeeAllocationList: ProposeResourcesListDto[]
  employeeAllocationListLoading: boolean
  isLoading: boolean
  page: number
  rowsPerPage: number
  handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
  handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number):void
  proposeResourceLoding: boolean
  proposeItem: ProposeRequestPayloadDto[]
  proposedItems: ProposeRequestPayloadDto[]
  setProposeFilterFormData(): void
}> = (props) => {
  const classes = useStyles()

  const [openAdvancedFilters, setOpenAdvancedFilters] = React.useState(false)
  const [filteredRows, setFilteredRows] = React.useState<ProposeResourcesListDto[] | null>(null)
  React.useEffect(() => {
    props.getAllocationList(false, 'benchResources')
  }, [])
  React.useEffect(() => {
    if (props.addProposeRequestAlert.severity === 'success') {
      props.setIsProposeResourcesPopupOpen(false)
      // props.getProposedRequests(props.resourceRequestId)
    }
  }, [props.addProposeRequestAlert.severity === 'success'])
  const searchEmployees = (e: any) => {
    let keyword = e
    if (e === undefined || e === null) {
      keyword = ''
    } else {
      keyword = e
    }
    // setSearchText(keyword)
    if (keyword !== '') {
      const results = props.employeeAllocationList
        .filter((user: ProposeResourcesListDto) => {
          // Filter results by doing case insensitive match on name here
          return user.employeeName.toLowerCase().includes(keyword.toLowerCase())
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (a.employeeName.toLowerCase().indexOf(keyword.toLowerCase()) > b.employeeName.toLowerCase().indexOf(keyword.toLowerCase())) {
            return 1
          } else if (a.employeeName.toLowerCase().indexOf(keyword.toLowerCase()) < b.employeeName.toLowerCase().indexOf(keyword.toLowerCase())) {
            return -1
          } else {
            if (a.employeeName > b.employeeName) { return 1 } else { return -1 }
          }
        })
      setFilteredRows(results)
    } else {
      setFilteredRows(props.employeeAllocationList)
    }
  }
  return (
    < BootstrapDialog
      className={styles.dialog}
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth='lg'
      open={props.isProposeResourcesPopupOpen} >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => {
          props.setIsProposeResourcesPopupOpen(false)
          props.setProposeFilterFormData()
        }}>
        <strong>Propose Resources</strong><br></br>
        <div className={styles.dialogHeading}>
        </div>
        <Divider></Divider>
      </BootstrapDialogTitle>
      <DialogContent className={styles.dialogContent}>
      {/* {props.addProposeRequestAlert?.message && (
              <Alert
                className="mb-m"
                severity= {props.addProposeRequestAlert.severity}
              >
                {props.addProposeRequestAlert.message}
              </Alert>
      )} */}
        <Grid container spacing={2} mb={2}>
            <Grid item xs={10} sm={10} md={10}>
            <RadioGroup
            onChange={(event, value) => {
              props.handleChangeFilterForm('benchResources', value)
              props.filterFormData.employeeName.value = {} as ProposeResourcesListDto
              props.getAllocationList(false, value)
              setFilteredRows(null)
            }}
            row>
                <FormControlLabel
                checked={props.filterFormData.benchResources.value == null || props.filterFormData.benchResources.value === 'benchResources'}
                disabled={openAdvancedFilters}
                value="benchResources"
                control={<Radio />}
                label="Bench Resources" />
                <FormControlLabel
                 disabled={openAdvancedFilters}
                checked={props.filterFormData.benchResources.value !== null && props.filterFormData.benchResources.value === 'nonBillable'}
                value="nonBillable"
                control={<Radio />} label="Non-billable" />
              </RadioGroup>
              </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <FormControlLabel
                value="start"
                sx={{ verticleAlign: 'middle' }}
                control={<Switch
                  size='small'
                  color="primary"
                  onChange={(event, value) => {
                    setOpenAdvancedFilters(!openAdvancedFilters)
                    props.setProposeFilterFormData()
                    setFilteredRows(null)
                    if (!openAdvancedFilters) {
                      props.getAllocationList(true, null)
                    } else {
                      props.getAllocationList(true, 'benchResources')
                    }
                  }}
                />}
                label="Advanced Filters"
                labelPlacement="start"
                checked={openAdvancedFilters}
              />
              </Grid>
            <Grid item xs={10} sm={10} md={10}>
            <Autocomplete
              size={'small'}
              value={props.filterFormData.employeeName.value}
              onChange={(event, newValue) => {
                props.handleChangeFilterForm('employeeName', newValue)
                // searchEmployees(newValue)
              }}
            disabled={openAdvancedFilters}
              options={props.employeeAllocationList}
              getOptionLabel={(option) => option.userName || ''}
              renderInput={(params) => (
                <TextField {...params}
                required={props.filterFormData.employeeName.isRequired}
                helperText={props.filterFormData.employeeName.error}
                className={classes.input}
          // onChange={searchEmployees}
                error={props.filterFormData.employeeName.error !== null}
                placeholder="Search Employee"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon className={classes.gpsIcon} />
                    </InputAdornment>
                  )
                }}
                />
              )}
            />
                </Grid>
              <Grid item xs={2} sm={2} md={2}>
              <Box display="flex" >
                  <Button
                  className="filterBtn"
                  color="primary"
            disabled={openAdvancedFilters}
                      variant="contained"
                      onClick={() => {
                        searchEmployees(props.filterFormData.employeeName.value.employeeName)
                        // props.getAllocationList(false, props.filterFormData.benchResources.value)
                      }}
                    >
                      Search
                    </Button>
                    </Box>
                </Grid>
                {openAdvancedFilters &&
                <React.Fragment>
              <Grid item xs={3} sm={3} md={3}>
              <Autocomplete
                  color="primary"
                  size="small"
                  value={props.filterFormData.sbu.value}
                  options={props.sbuList.map((sbu) => {
                    return { name: sbu.sbuName, id: sbu.sbuId }
                  })}
                  // disabled={props.clientHierachyIsLoading}
                  onChange={(event, value) =>
                    props.handleChangeFilterForm('sbu', value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || option.name === value.name
                  }
                  getOptionLabel={(option: any) => option.name || ''}
                  renderInput={(params) => (
                    <TextField {...params}
                    // required={props.filterFormData.sbu.isRequired}
                    helperText={props.filterFormData.sbu.error}
                    error={props.filterFormData.sbu.error !== null}
                    label="SBU" />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
              <Autocomplete
              size={'small'}
                    value={props.filterFormData.clientName.value}
                    onChange={(event, newValue) => { props.handleChangeFilterForm('clientName', newValue) }}
                    options={props.clientList}
                    getOptionLabel={(option) => option.clientName || ''}
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.clientName.isRequired}
                      helperText={props.filterFormData.clientName.error}
                      error={props.filterFormData.clientName.error !== null}
                      label="Client" />
                    )}
                  />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
              <Autocomplete
              size={'small'}
              value={props.filterFormData.projectName.value}
                    onChange={(event, newValue) => { props.handleChangeFilterForm('projectName', newValue) }}
                    options={props.projectList}
                    getOptionLabel={(option) => option.teamName || ''}
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.projectName.isRequired}
                      helperText={props.filterFormData.projectName.error}
                      error={props.filterFormData.projectName.error !== null}
                      label="Project" />
                    )}
                  />
                  </Grid>
          {/* <Grid item xs={3} sm={3} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              disablePortal
              id="combo-box-demo"
              disabled
              options={top100Films}
              renderInput={(params) =>
                 <TextField {...params} label="Experience" />
                }
            />
          </Grid> */}
              <Grid item md={3}>
              <Autocomplete
                    size={'small'}
                    value={props.filterFormData.position.value}
                    options={props.designationList}
                    onChange={(event, newValue) => { props.handleChangeFilterForm('position', newValue) }}
                    getOptionLabel={(option) => option.projectRoleName || ''}
                    // renderOption={(props, option) => <li {...props}>{option.projectRoleName}</li>}
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.position.isRequired}
                      helperText={props.filterFormData.position.error}
                      error={props.filterFormData.position.error !== null}
                      label="Position" />
                    )}
                  />
              </Grid>
              <Grid item md={3}>
              <Autocomplete
                    size={'small'}
                    value={props.filterFormData.track.value}
                    options={props.trackList}
                    onChange={(event, newValue) => { props.handleChangeFilterForm('track', newValue) }}
                    getOptionLabel={(option) => option.name || ''}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.track.isRequired}
                      helperText={props.filterFormData.track.error}
                      error={props.filterFormData.track.error !== null}
                      label="Track" />
                    )}
                  />
              </Grid>
              <Grid item xs={3} sm={3} md={3}>
              <Autocomplete
              size='small'
              options={['Billable', 'Non-billable']}
              getOptionLabel={(option) => option || ''}
              value={props.filterFormData.isBillable.value}
              onChange={(event, value) => props.handleChangeFilterForm('isBillable', value)}
              renderInput={(params) =>
                <TextField
                label="Billable/Non-Billable"
                required={false}
                error={props.filterFormData.isBillable.error !== null}
                  {...params}
                  />}
            />
          </Grid>
              <Grid item md={6}>
                  <Box display="flex" justifyContent="flex-end" >
                  <Button
                      className={styles1.clearBtn}
                      color="inherit"
                      variant="contained"
                      onClick={() => {
                        props.getAllocationList(true, null)
                      }}
                    >
                      Clear
                    </Button>
                  <Button
                      className="filterBtn"
                      color="primary"
                      variant="contained"
                      sx = {{ marginRight: 'none !important' }}
                      onClick={() => {
                        props.getAllocationList(false, null)
                      }}
                    >
                      Filter
                    </Button>
                    </Box>
                </Grid>
                </React.Fragment>
                    }
                    </Grid>
                    <Divider />
                <Grid container spacing={2} mt={1} >
                <Grid item md={12}>
                  <Box justifyContent='flex-end' sx={{ float: 'right' }}>
                <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>{props.proposedItems?.length + props.proposeItem?.length} Resources Selected &nbsp; &nbsp; </Typography>
                <Button
                  autoFocus variant="contained"
                  className={'btnStyle'}
                  onClick={() => {
                    props.createProposeResorce(props.resourceRequestId, props.resourceId)
                  }}
                >
              {(props.proposeResourceLoding) && (
              <CircularProgress size="12px" className={styles.loading} />
              )}
                Propose Selected Resource(s)
                </Button>
                </Box>
            </Grid>
            <Grid container spacing={2} sx={{ m: 2 }}>
              <Table>
              <TableHead>
            <StyledTableRow>
            <StyledTableCell align="center" width={'10px'}></StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Employee Name</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Position</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Experience</StyledTableCell>
              <StyledTableCell align="center" width={'100px'}>User Profile</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
          {(props.employeeAllocationListLoading) &&
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center">
                  <CircularProgress color="primary" size={20} /> Loading...
                </StyledTableCell>
              </StyledTableRow>
            }
            {!(props.employeeAllocationListLoading) && (filteredRows === null
              ? props.employeeAllocationList
              : filteredRows
            )?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((item, index) => (
<StyledProposeResourcesRow key={index}>
            <StyledTableCell align="center" width={'10px'}>
            <FormControlLabel
                    control={<Checkbox
                      size='small'
                      checked={props.proposeItem.filter((element) => element.username === item.userName).length > 0 ||
                        props.proposedItems.filter((element) => element.username === item.userName).length > 0}
                      onChange={(event, value) => props.onproposeChange(value, event, item)}
                      disabled={props.proposedItems.filter((element) => element.username === item.userName).length > 0}
                    />}
                    label="" />
            </StyledTableCell>
            <StyledTableCell align="left">{item.employeeName}</StyledTableCell>
            <StyledTableCell align="left">{item.designation}</StyledTableCell>
            <StyledTableCell align="left">{item.skillAndTech}</StyledTableCell>
            <StyledTableCell align="center"><LaunchIcon fontSize='small'/></StyledTableCell>
            </StyledProposeResourcesRow>
            ))}
          {/* {!(props.employeeAllocationListLoading) && props.employeeAllocationList?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
            .map((item, index) => (
            <StyledProposeResourcesRow key={index}>
            <StyledTableCell align="center" width={'10px'}>
            <FormControlLabel
                    control={<Checkbox
                      size='small'
                      checked={props.proposeItem.filter((element) => element.username === item.userName).length > 0 ||
                        props.proposedItems.filter((element) => element.username === item.userName).length > 0}
                      onChange={(event, value) => props.onproposeChange(value, event, item)}
                      disabled={props.proposedItems.filter((element) => element.username === item.userName).length > 0}
                    />}
                    label="" />
            </StyledTableCell>
            <StyledTableCell align="left">{item.employeeName}</StyledTableCell>
            <StyledTableCell align="left">{item.designation}</StyledTableCell>
            <StyledTableCell align="left">{item.skillAndTech}</StyledTableCell>
            <StyledTableCell align="center"><LaunchIcon fontSize='small'/></StyledTableCell>
            </StyledProposeResourcesRow>
            ))} */}
          {!props.employeeAllocationListLoading && props.employeeAllocationList?.length <= 0 &&
            <StyledTableRow><StyledTableCell colSpan={8} align="center">
              No Records Found</StyledTableCell></StyledTableRow>
          }
            </TableBody>
              </Table>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.cardActionStyle}>
        <Grid container spacing={2}>
          <Grid item md={12} sx={{ mx: 2 }}>
          <Box display="flex" justifyContent="flex-end" sx={{ my: 2, ml: 2, mr: 0 }}>
          <AppTablePagination
            data={props.employeeAllocationList}
            page={props.page}
            rowsPerPage={props.rowsPerPage}
            handleChangePage={props.handleChangePage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialog >
  )
}

export default ProposeResourcesPopup
