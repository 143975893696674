import axios from 'axios'
import { EmployeeDetailsParams, GetDisabledUsersParamDto, GetUserListBriefParamsDto } from '../utilities/models'

const getUserList = (params: GetDisabledUsersParamDto) => {
  return axios.get('/core/api/v1/users', { params: params })
}
const getUserListBrief = (params?: GetUserListBriefParamsDto) => {
  return axios.get('/core/api/v1/brief/users', { params: params })
}
const getUserDetailV2 = (params: EmployeeDetailsParams) => {
  return axios.get('/core/api/v2/users', { params: params })
}
const getUserProfile = (username: string) => {
  return axios.get(`/core/api/v2/users/${username}`)
}
export const userService = {
  getUserList,
  getUserListBrief,
  getUserProfile,
  getUserDetailV2
}
