import { Table, TableContainer, TableHead, TableBody, Typography, LinearProgress, Grid, Tooltip, Stack } from '@mui/material'
import React from 'react'
import { StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import moment from 'moment'
import style from './AllocationHistoryViewTable.module.scss'
import { DATE_FORMAT } from '../../../utilities/constants'
import { AllocationDto, EmployeeDto } from '../../../utilities/models/employeeManagement.model'
// import styles from './AllocationHistoryViewTable.module.scss'

const AllocationHistoryViewTable: React.FC<{
  page: number,
  rowsPerPage: number,
  isLoading: boolean,
  allocationHistory: EmployeeDto[]
}> = (props) => {
  const convBillable = (value: boolean) => {
    return value ? 'Billable' : 'Non-billable'
  }
  return (
    <Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
            <StyledTableCell align="left" width={'100px'}>Client</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Project</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Role</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Allo. Start</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Allo. End</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Allocation %</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Billing</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Billing Start</StyledTableCell>
              <StyledTableCell align="left" width={'100px'}>Billing End</StyledTableCell>

            </StyledTableRow>
          </TableHead>
          <TableBody>

            {(!props.isLoading && props.allocationHistory.length > 0 &&
              props.allocationHistory[0]?.projectList.length > 0) &&
              (props.rowsPerPage > 0
                ? props.allocationHistory[0].projectList.slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                : props.allocationHistory[0]?.projectList
              ).map((row: AllocationDto, index: number) => (
                <StyledTableRow key={index}>
                   <StyledTableCell align="left">
                   <Stack direction={'row'} alignItems="left" justifyContent="left" sx={{ height: 'inherit', margin: 0, padding: 0 }}>
                      {
                        row.client !== null
                          ? <React.Fragment>
                            {moment(row.projectStartDate).format(DATE_FORMAT) <= moment().format(DATE_FORMAT) &&
                              moment(row.projectEndDate).format(DATE_FORMAT) >= moment().format(DATE_FORMAT)
                              ? <Tooltip title={'Active Allocation'} sx={{ height: '100%' }}>
                                <span className={style.activeStyle} />
                              </Tooltip>
                              : <React.Fragment>

                                {moment(row.projectEndDate).format(DATE_FORMAT) < moment().format(DATE_FORMAT)
                                  ? <Tooltip title={'Expired Allocation'}>
                                    <span className={style.expiredStyle} />
                                  </Tooltip>
                                  : <Tooltip title={'Future Allocation'}>
                                    <span className={style.futureStyle} />
                                  </Tooltip>

                                }

                              </React.Fragment>
                            }
                          </React.Fragment>
                          : <React.Fragment>
                          </React.Fragment>

                      }
                      <Typography style={{ paddingLeft: '5px' }}>
                        {row.client
                          ? row.client
                          : (<span className={style.missingInfo}>- - / - - / - -</span>)
                        }
                      </Typography>
                    </Stack>
                   </StyledTableCell>
                  <StyledTableCell align="left">
                  {row.projectName
                    ? row.projectName
                    : (<span className={style.missingInfo}>- - / - - / - -</span>)
                        }
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography>
                      {row.projectRoleName
                        ? row.projectRoleName
                        : (<span className={style.missingInfo}>- -</span>)
                      }
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ left: 5 }}>
                      <Typography style={{ paddingLeft: '5px' }}>
                        {row.projectStartDate
                          ? moment(row.projectStartDate).format(DATE_FORMAT)
                          : (<span className={style.missingInfo}>- - / - - / - -</span>)
                        }
                      </Typography>
                    {/* </Stack> */}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography>
                      {row.projectEndDate
                        ? moment(row.projectEndDate).format(DATE_FORMAT)
                        : (<span className={style.missingInfo}>- - / - - / - -</span>)
                      }
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Grid container alignItems={'center'}>
                      <Grid item md={7}>
                        <LinearProgress
                          variant='determinate'
                          color='primary'
                          value={row.allocationPercentage || 0} />
                      </Grid>
                      <Grid item md={3} ml={'5px'}>
                        <Typography align={'center'}>{row.allocationPercentage || 0}%</Typography>
                      </Grid>
                    </Grid>
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Typography>
                      {(row.projectStartDate && row.projectEndDate)
                        ? convBillable(row.isBillable)
                        : (<span className={style.missingInfo}>- -</span>)
                      }
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography>
                      {row.isBillable === false
                        ? <span >N/A</span>
                        : row.billingStartDate && moment(row.billingStartDate).format(DATE_FORMAT) !== 'Invalid date'
                          ? moment(row.billingStartDate).format(DATE_FORMAT)
                          : (<span className={style.missingInfo}>- - / - - / - -</span>)
                      }
                      {/* { row.isBillable === null ? <span className={style.missingInfo}>- - / - - / - -</span> : null } */}

                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography>
                      {row.isBillable === false
                        ? <span >N/A</span>
                        : row.billingEndDate && moment(row.billingEndDate).format(DATE_FORMAT) !== 'Invalid date'
                          ? moment(row.billingEndDate).format(DATE_FORMAT)
                          : (<span className={style.missingInfo}>- - / - - / - -</span>)
                      }

                    </Typography>
                  </StyledTableCell>

                </StyledTableRow>
              ))}
            {(!props.isLoading && props.allocationHistory[0]?.projectList.length === 0) &&
              <StyledTableRow>
                <StyledTableCell colSpan={9} align="left">
                  No Records Available.
                </StyledTableCell>
              </StyledTableRow>
            }
          </TableBody>
        </Table>
      </TableContainer >
      {props.children}
    </Grid>
  )
}

export default AllocationHistoryViewTable
