import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  CircularProgress,
  Tooltip,
  Typography
} from '@mui/material'
import styles from './EmpHistoryAndUtilizeReportTable.module.scss'
import {
  ReportsDataDto,
  ReportsTableColumnDto
} from '../../../utilities/models'
import { DATE_FORMAT } from '../../../utilities/constants'
import moment from 'moment'
const EmpHistoryAndUtilizationReportTable: React.FC<{
  headerStatus: ReportsTableColumnDto;
  filterRows: ReportsDataDto[];
  reportsIsloading: boolean;
  page: number;
  rowsPerPage: number;
  clientHierachyIsloading: boolean;
}> = (props) => {
  // const convBillable = (value: boolean) => {
  //   return value ? 'Billable' : 'Non-billable'
  // }
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <TableContainer>
          <Table className={styles.tableContainer}>
            <TableHead>
              <StyledTableRow>
                {props.headerStatus.empId && (
                  <StyledTableCell align="left" width={80}>
                    Emp-ID
                  </StyledTableCell>
                )}
                {props.headerStatus.empName && (
                  <StyledTableCell align="left" width={100}>
                    Employee
                  </StyledTableCell>
                )}
                {props.headerStatus.email && (
                  <StyledTableCell align="left" width={120}>
                    Email
                  </StyledTableCell>
                )}
                {props.headerStatus.designation && (
                  <StyledTableCell align="left" width={120}>
                    Designation
                  </StyledTableCell>
                )}
                {props.headerStatus.joinDate && (
                  <StyledTableCell align="left" width={95}>
                    Joined Date
                  </StyledTableCell>
                )}
                {/* {props.headerStatus.designationEffectiveDate && <StyledTableCell align="left" width={200}>Designation Effective Date</StyledTableCell>} */}
                {props.headerStatus.sbu && (
                  <StyledTableCell align="left" width={70}>
                    SBU
                  </StyledTableCell>
                )}
                {props.headerStatus.client && (
                  <StyledTableCell align="left" width={100}>
                    Client
                  </StyledTableCell>
                )}
                {props.headerStatus.project && (
                  <StyledTableCell align="left" width={100}>
                    Project
                  </StyledTableCell>
                )}
                {props.headerStatus.projectStatus && (
                  <StyledTableCell align="left" width={110}>
                    Project Status
                  </StyledTableCell>
                )}
                {props.headerStatus.role && (
                  <StyledTableCell align="left" width={110}>
                    Role
                  </StyledTableCell>
                )}
                {props.headerStatus.startDate && (
                  <StyledTableCell align="left" width={85}>
                    Allo. Start
                  </StyledTableCell>
                )}
                {props.headerStatus.endDate && (
                  <StyledTableCell align="left" width={85}>
                     Allo. End
                  </StyledTableCell>
                )}
                {props.headerStatus.allocation && (
                  <StyledTableCell align="left" width={100}>
                    Allocation %
                  </StyledTableCell>
                )}
                {props.headerStatus.billing && (
                  <StyledTableCell align="left" width={100}>
                    Billing
                  </StyledTableCell>
                )}
                {props.headerStatus.billingStart && (
                  <StyledTableCell align="left" width={100}>
                    Billing Start
                  </StyledTableCell>
                )}
                {props.headerStatus.billingEnd && (
                  <StyledTableCell align="left" width={100}>
                    Billing End
                  </StyledTableCell>
                )}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!props.reportsIsloading &&
                props.filterRows.length > 0 &&
                (props.rowsPerPage > 0
                  ? props.filterRows.slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  : props.filterRows
                ).map((row: ReportsDataDto, index) => (
                  <StyledTableRow key={index}>
                    {props.headerStatus.empId && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.empId}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.empName && (
                      <StyledTableCell align="left" width={'120px'}>
                        {row.empName}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.email && (
                      <StyledTableCell align="left" width={'120px'}>
                        {row.email}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.designation && (
                      <StyledTableCell align="left" width={'120px'}>
                        {row.designationDisplayName
                          ? (
                          <Tooltip title={row.designation || ''}>
                            <Typography>
                              {row.designationDisplayName}
                            </Typography>
                          </Tooltip>
                            )
                          : (
                              ''
                            )}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.joinDate && (
                      <StyledTableCell align="left" width={'95px'}>
                        {moment(row.joinDate).format(DATE_FORMAT)}
                      </StyledTableCell>
                    )}
                    {/* {props.headerStatus.designationEffectiveDate && <StyledTableCell align="left" width={'120px'}>{row.designationEffectiveDate}</StyledTableCell>} */}
                    {props.headerStatus.sbu && (
                      <StyledTableCell align="left" width={'70px'}>
                        {row.sbu}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.client && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.client}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.project && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.project}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.projectStatus && (
                      <StyledTableCell align="left" width={110}>
                        {row.projectStatus}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.role && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.projectRoleDisplayName
                          ? (
                          <Tooltip title={row.role || ''}>
                            <Typography>
                              {row.projectRoleDisplayName}
                            </Typography>
                          </Tooltip>
                            )
                          : (
                              ''
                            )}
                      </StyledTableCell>
                    )}

                    {props.headerStatus.startDate && (
                      <StyledTableCell align="left" width={'85px'}>
                        {row.startDate === null ||
                          moment(row.startDate).format(DATE_FORMAT) ===
                          'Invalid date'
                          ? ''
                          : moment(row.startDate).format(DATE_FORMAT)}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.endDate && (
                      <StyledTableCell align="left" width={'85px'}>
                        {row.endDate === null ||
                          moment(row.endDate).format(DATE_FORMAT) ===
                          'Invalid date'
                          ? ''
                          : moment(row.endDate).format(DATE_FORMAT)}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.allocation && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.startDate === null
                          ? ''
                          : row.allocation !== null
                            ? `${Math.round(row.allocation * 100)}%`
                            : ''}
                        {/* {row.allocation !== null ? `${Math.round(row.allocation * 100)}%` : ''} */}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.billing && (
                      <StyledTableCell align="left" width={'100px'}>
                        {' '}
                        {row.startDate === null ? '' : row.billing}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.billingStart && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.billing === 'Non-billable'
                          ? 'N/A'
                          : moment(row.billingStart).format(DATE_FORMAT) ===
                            'Invalid date'
                            ? ''
                            : moment(row.billingStart).format(DATE_FORMAT)}
                      </StyledTableCell>
                    )}
                    {props.headerStatus.billingEnd && (
                      <StyledTableCell align="left" width={'100px'}>
                        {row.billing === 'Non-billable'
                          ? 'N/A'
                          : moment(row.billingEnd).format(DATE_FORMAT) ===
                            'Invalid date'
                            ? ''
                            : moment(row.billingEnd).format(DATE_FORMAT)}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
              {!props.reportsIsloading &&
                props.filterRows.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={16} align="left">
                      No Records To Display.
                    </StyledTableCell>
                  </StyledTableRow>
              )}
              {(props.reportsIsloading) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    <CircularProgress
                      className={styles.spinnerAlign}
                      color="primary"
                      size={20}
                    />{' '}
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {props.children}
      </Grid>
    </Grid>
  )
}

export default EmpHistoryAndUtilizationReportTable
