import { Autocomplete, Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { ProjectEntryFormDto } from '../../../utilities/models/projectManagement.model'
import styles from './ProjectEditPopup.module.scss'

const ProjectEditPopup: React.FC<{
  isEditDialogOpen: boolean;
  title: string;
  isEditingRow: any;
  projectFormData: ProjectEntryFormDto;
  isShowHelperText: boolean;
  defaultClientList: any;
  projectEditClick(): void;
  onEditHandleChange(property: string, value: any): void;
  updateProject(): void;
  handleInputFocus(property: string): void;
}> = (props) => {
  return (
    <BootstrapDialog
      className={styles.dialogCard}
      aria-labelledby="customized-dialog-title"
      open={props.isEditDialogOpen}
    >
      <BootstrapDialogTitle id="customized-dialog-title"
        onClose={() => props.projectEditClick()
        }
      >
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12}>
            <TextField
              value={props.isEditingRow.projectName.value}
              label="Project Name"
              color='primary'
              size="small"
              fullWidth
              onFocus={() => props.handleInputFocus('projectName')}
              helperText={props.isShowHelperText && props.isEditingRow.projectName.error}
              error={!!props.isEditingRow.projectName.error}
              required={props.isEditingRow.projectName.isRequired}
              disabled={props.isEditingRow.projectName.disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onEditHandleChange('projectName', event.target.value)}

            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              value={props.isEditingRow.clientName.value}
              disableClearable
              fullWidth
              size="small"
              disabled={props.isEditingRow.clientName.disable}
              options={props.defaultClientList.map((client: any) => {
                return { name: client.clientName, id: client.clientId }
              })}
              getOptionLabel={(option) => option.name || ''}
              onChange={(event, value) => props.onEditHandleChange('clientName', value)}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  color='primary'
                  onFocus={() => props.handleInputFocus('clientName')}
                  helperText={props.isShowHelperText && props.isEditingRow.clientName.error}
                  error={!!props.isEditingRow.clientName.error}
                  required={props.isEditingRow.clientName.isRequired}
                />
              )} />
          </Grid>
          <Grid item lg={12} >
            <TextField
              value={props.isEditingRow.projectDescription.value}
              label="Project Description"
              color='primary'
              size="small"
              fullWidth
              multiline
              rows={4}
              error={!!props.isEditingRow.projectDescription.error}
              required={props.isEditingRow.projectDescription.isRequired}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onEditHandleChange('projectDescription', event.target.value)}
              onFocus={() => props.handleInputFocus('projectDescription')}

            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.updateProject()}
          autoFocus variant="contained"
          className={'btnStyle'}
        >
          Update Project
        </Button>
      </DialogActions>
    </BootstrapDialog>

  )
}

export default ProjectEditPopup
