
import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, SUPERVISOR_ACTION_TYPES } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  allProjectsList: {
    isLoading: false,
    data: []
  },
  allSupervisorsList: {
    isLoading: false,
    data: []
  },
  createSupervisor: {
    isLoading: false,
    data: [],
    status: null
  },
  unassignSupervisor: {
    isLoading: false,
    data: [],
    status: null
  }
}
const supervisorReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allProjectsList: {
          ...state.allProjectsList,
          isLoading: true
        }
      }
    case SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allProjectsList: {
          ...state.allProjectsList,
          isLoading: false,
          data: action.data
        }
      }
    case SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allProjectsList: {
          ...state.allProjectsList,
          isLoading: false,
          data: []
        }
      }
    case SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allSupervisorsList: {
          ...state.allSupervisorsList,
          isLoading: true
        }
      }
    case SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allSupervisorsList: {
          ...state.allSupervisorsList,
          isLoading: false,
          data: action.data
        }
      }
    case SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allSupervisorsList: {
          ...state.allSupervisorsList,
          isLoading: false,
          data: []
        }
      }
    case SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createSupervisor: {
          ...state.createSupervisor,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createSupervisor: {
          ...state.createSupervisor,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createSupervisor: {
          ...state.createSupervisor,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        unassignSupervisor: {
          ...state.unassignSupervisor,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        unassignSupervisor: {
          ...state.unassignSupervisor,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        unassignSupervisor: {
          ...state.unassignSupervisor,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    default:
      return state
  }
}
export default supervisorReducer
