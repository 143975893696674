import { ReportsCustomizedTableHeaderDto, ReportsTabDto, ReportsTableColumnDto, ReportsTableHeaderDto } from '../models'

export const REPORTS_CUSTOMIZED_TABLE_HEADERS: ReportsCustomizedTableHeaderDto[] = [
  { id: 1, displayName: 'Emp-ID', key: 'empId' },
  { id: 2, displayName: 'Emp-Name', key: 'empName' },
  { id: 3, displayName: 'Email', key: 'email' },
  { id: 4, displayName: 'Designation', key: 'designation' },
  { id: 5, displayName: 'Joined Date', key: 'joinDate' },
  // { id: 6, displayName: 'Designation Effective Date', jsonKey: 'designationEffectiveDate' },
  { id: 6, displayName: 'SBU', key: 'sbu' },
  { id: 7, displayName: 'Client', key: 'client' },
  { id: 8, displayName: 'Project', key: 'project' },
  { id: 9, displayName: 'Project Status', key: 'projectStatus' },
  { id: 10, displayName: 'Role', key: 'role' },
  { id: 11, displayName: 'Allocation Start', key: 'startDate' },
  { id: 12, displayName: 'Allocation End', key: 'endDate' },
  { id: 13, displayName: 'Allocation', key: 'allocation' },
  { id: 14, displayName: 'Billing', key: 'billing' },
  { id: 15, displayName: 'Billing Start', key: 'billingStart' },
  { id: 16, displayName: 'Billing End', key: 'billingEnd' }

]

export const TABLE_HEADERS: ReportsTableColumnDto = {
  empId: true,
  empName: true,
  email: true,
  designation: true,
  joinDate: true,
  // designationEffectiveDate: true,
  sbu: true,
  client: true,
  project: true,
  billing: true,
  role: true,
  billingStart: true,
  billingEnd: true,
  allocation: true,
  startDate: true,
  endDate: true,
  projectStatus: true,
  comments: true
}
export const REPORTS_TABS: ReportsTabDto = {
  displayName: 'Reports',
  jsonKey: 'reports',
  range: 0
}
export const REPORTS_TABLE_HEADERS: ReportsTableHeaderDto[] = [
  { id: 1, displayName: 'Emp-ID', header: 'Emp-ID', key: 'empId', width: 15, dataType: 's' },
  { id: 2, displayName: 'Employee', header: 'Employee', key: 'empName', width: 30, dataType: 's' },
  { id: 3, displayName: 'Email', header: 'Email', key: 'email', width: 45, dataType: 's' },
  { id: 4, displayName: 'Designation', header: 'Designation', key: 'designation', width: 45, dataType: 's' },
  { id: 5, displayName: 'Joined Date', header: 'Joined Date', key: 'joinDate', width: 15, dataType: 'yyyy-mm-dd', style: { numFmt: 'yyyy-mm-dd' } },
  // { id: 6, displayName: 'Designation Effective Date', jsonKey: 'designationEffectiveDate', width: 25, dataType: 's' },
  { id: 6, displayName: 'SBU', header: 'SBU', key: 'sbu', width: 10, dataType: 's' },
  { id: 7, displayName: 'Client', header: 'Client', key: 'client', width: 10, dataType: 's' },
  { id: 8, displayName: 'Project', header: 'Project', key: 'project', width: 13, dataType: 's' },
  { id: 9, displayName: 'Project Status', header: 'Project Status', key: 'projectStatus', width: 18, dataType: 's' },
  { id: 10, displayName: 'Role', header: 'Role', key: 'role', width: 40, dataType: 's' },
  { id: 11, displayName: 'Allocation Start', header: 'Allocation Start', key: 'startDate', width: 18, dataType: 'yyyy-mm-dd', style: { numFmt: 'yyyy-mm-dd' } },
  { id: 12, displayName: 'Allocation End', header: 'Allocation End', key: 'endDate', width: 18, dataType: 'yyyy-mm-dd', style: { numFmt: 'yyyy-mm-dd' } },
  { id: 13, displayName: 'Allocation', header: 'Allocation %', key: 'allocation', width: 18, dataType: '0%', style: { numFmt: '0%' } },
  { id: 14, displayName: 'Billing', header: 'Billing', key: 'billing', width: 15, dataType: 's' },
  { id: 15, displayName: 'Billing Start', header: 'Billing Start', key: 'billingStart', width: 14, dataType: 'yyyy-mm-dd', style: { numFmt: 'yyyy-mm-dd' } },
  { id: 16, displayName: 'Billing End', header: 'Billing End', key: 'billingEnd', width: 14, dataType: 'yyyy-mm-dd', style: { numFmt: 'yyyy-mm-dd' } },
  { id: 17, displayName: 'Comments', header: 'Comments', key: 'comments', width: 100, dataType: 's' }

]
