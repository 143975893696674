/* eslint-disable no-unused-vars */

import React from 'react'
import { EmpBreakdownCountDto, EmpBreakdownDto } from '../../../utilities/models'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styles from './EmployeeBreakDown.module.scss'
const EmployeeBreakDown: React.FC<{
  employeeBreakdown: EmpBreakdownDto
  breakdownData: EmpBreakdownCountDto
  barColor: string
}> = (props) => {
  const total = props.employeeBreakdown.data.totalEmployees
  // const barChartDataType1: ApexOptions = {
  //   chart: {
  //     id: 'apexchart-example',
  //     foreColor: 'black',
  //     type: 'bar',
  //     height: 100,
  //     stacked: true,
  //     // stackType: '100%',
  //     toolbar: {
  //       tools: {
  //         zoom: false,
  //         zoomin: false,
  //         zoomout: false,
  //         pan: false,
  //         reset: false,
  //         download: false
  //       }
  //     },
  //     animations: {
  //       enabled: false
  //     }
  //   },
  //   tooltip: {
  //     enabled: true,
  //     marker: {
  //       show: true
  //     },
  //     fixed: {
  //       enabled: false,
  //       position: 'bottomLeft',
  //       offsetX: 10,
  //       offsetY: 10
  //     }
  //   },
  //   plotOptions: {
  //     bar: {

  //       horizontal: true,
  //       barHeight: '100%',
  //       colors: {
  //         backgroundBarColors: ['#dcdcdc']
  //       },
  //       dataLabels: {
  //         orientation: 'horizontal',
  //         position: 'center'
  //       }

  //     }
  //   },
  //   stroke: {
  //     width: 1,
  //     colors: ['#fff']
  //   },
  //   noData: {
  //     text: (props.employeeBreakdown.isLoading ? 'Loading Data...' : ''),
  //     align: 'center',
  //     verticalAlign: 'top',
  //     offsetX: 0,
  //     offsetY: 0,
  //     style: {
  //       color: '#6b6b6b',
  //       fontSize: '20px',
  //       fontFamily: 'Ubuntu,sans-serif'
  //     }
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     formatter: function (val: number) {
  //       return Math.round((val / total) * 100) + '%'
  //     }

  //   },
  //   grid: {
  //     show: false,
  //     borderColor: 'transparent',
  //     xaxis: {
  //       lines: {
  //         show: false

  //       }
  //     },
  //     yaxis: {
  //       lines: {
  //         show: false

  //       }
  //     },
  //     column: {
  //       colors: ['transparent'],
  //       opacity: 0
  //     },
  //     row: {
  //       colors: ['transparent'],
  //       opacity: 0
  //     }
  //   },
  //   xaxis: {
  //     axisBorder: {
  //       show: false
  //     },
  //     axisTicks: {
  //       show: false
  //     },
  //     labels: {
  //       show: false
  //     },
  //     title: {
  //       text: '',
  //       style: {
  //         fontSize: '14px',
  //         fontFamily: 'Ubuntu,sans-serif'
  //       }
  //     },
  //     categories: ['']
  //   },
  //   yaxis: {
  //     axisBorder: {
  //       show: false
  //     },
  //     axisTicks: {
  //       show: false
  //     },
  //     labels: {
  //       show: false
  //     },
  //     title: {
  //       text: ''
  //     }
  //   },
  //   legend: {
  //     onItemClick: {
  //       toggleDataSeries: false
  //     },
  //     position: 'bottom',
  //     horizontalAlign: 'left',
  //     offsetX: 40
  //   },
  //   series: [
  //     {
  //       name: props.breakdownData.type,
  //       data: [(props.breakdownData.count || 0)]
  //     }
  //   ]
  //   // colors: ['#2F4F4F', '#1e8496']
  //   // colors: ['#D2691E', '#808000']

  // }
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1
    },
    progressLabel: {
      position: 'unset',
      marginLeft: '30px',
      width: '100%',
      height: '100%',
      zIndex: 1,
      top: 200,
      maxHeight: '75px', // borderlinearprogress root.height
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        width: '100%'
      }
    }
  }))
  const classes = useStyles()
  return (
    <React.Fragment>
      {
        (!props.employeeBreakdown.isLoading && Object.keys(props.employeeBreakdown.data).length > 0 && props.employeeBreakdown.data?.teamData?.length > 0) &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                {/* <div className={classes.progressLabel}>
                  <span>{`${Math.round((props.breakdownData.count / total) * 100)}%`}</span>
                </div> */}
                <LinearProgress
                  sx={{
                    '& .MuiLinearProgress-bar1Determinate': {
                      backgroundColor: props.barColor
                    }
                  }}
                  className={styles.linearProgressStyle}
                  variant='determinate'
                  value={Math.round((props.breakdownData.count / total) * 100)} />
              </Box>
              <Typography className={styles.percentageStyle} >
                {`${Math.round((props.breakdownData.count / total) * 100)}%`}
              </Typography>
            </Box>
            <Typography className={styles.titleStyle} >
              {`${props.breakdownData.type}: ${props.breakdownData.count} / ${total} `}
            </Typography>
          </Grid>
        </Grid>

      }

    </React.Fragment>
  )
}

export default EmployeeBreakDown
