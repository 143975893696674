import axios from 'axios'
import { ResourceManagementConfigDParams } from '../utilities/models'

const getResourceManagementConfig = (params: ResourceManagementConfigDParams) => {
  return axios.get('/hera/api/v1/configuration', { params: params })
}

export const configService = {
  getResourceManagementConfig
}
