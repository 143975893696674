import axios from 'axios'
import { AllocateEmployeeParams, DeallocateEmployeeParams, EmployeeAllocationHistoryParams, GetEmployeeFilterListParams, UpdateEmployeeAllocationParams } from '../utilities/models'

const getFilteredList = (params: GetEmployeeFilterListParams) => {
  return axios.get('/hera/api/v1/allocations', { params })
}
const updateEmployeeAllocation = (params: UpdateEmployeeAllocationParams) => {
  return axios.patch('/hera/api/v1/allocations', params)
}
const allocateEmployee = (payload: AllocateEmployeeParams) => {
  return axios.post('/hera/api/v1/allocations', payload)
}
const deallocateEmployee = (params: DeallocateEmployeeParams) => {
  return axios.patch('/hera/api/v1/allocations', params)
}
const getEmployeeAllocationHistory = (params: EmployeeAllocationHistoryParams) => {
  return axios.get('/hera/api/v1/allocations', { params: params })
}

export const employeeService = {
  // getEmployeeAllocationList,
  getFilteredList,
  updateEmployeeAllocation,
  allocateEmployee,
  deallocateEmployee,
  getEmployeeAllocationHistory
}
