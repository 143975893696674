import { COMMON_ACTION_TYPES, EMPLOYEE_ACTION_TYPES } from '../../utilities/constants'
import { AllocateEmployeeParams, DeallocateEmployeeParams, EmployeeAllocationHistoryParams, UpdateEmployeeAllocationParams } from '../../utilities/models'

const getFilteredList = (params: any) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.GET_FILTERED_LIST + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
const updateEmployeeAllocation = (params: UpdateEmployeeAllocationParams) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.UPDATE_EMPLOYEE_ALLOCATION + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
const allocateEmployee = (payload: AllocateEmployeeParams) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.ALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST,
    payload: payload
  }
}
const deallocateEmployee = (params: DeallocateEmployeeParams) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.DEALLOCATE_EMPLOYEE + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}

// const getEmployeeDetails = (params: EmployeeDetailsParams) => {
//   return {
//     type: EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_DETAILS + COMMON_ACTION_TYPES.REQUEST,
//     params: params
//   }
// }
const getEmployeeAllocationHistory = (params: EmployeeAllocationHistoryParams) => {
  return {
    type: EMPLOYEE_ACTION_TYPES.GET_EMPLOYEE_ALLOCATION_HISTORY + COMMON_ACTION_TYPES.REQUEST,
    params: params
  }
}
export const employeeAction = {
  getFilteredList,
  updateEmployeeAllocation,
  allocateEmployee,
  deallocateEmployee,
  // getEmployeeDetails,
  getEmployeeAllocationHistory
}
