
import { APP_ACTION_STATUS, COMMON_ACTION_TYPES, SBU_ACTION_TYPES } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  allSbuList: {
    isLoading: false,
    data: []
  },
  entitledSbuList: {
    isLoading: false,
    data: []
  },
  createSbu: {
    isLoading: false,
    data: [],
    status: null
  },
  updateSbu: {
    isLoading: false,
    data: [],
    status: null
  }

}
const sbuReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allSbuList: {
          ...state.allSbuList,
          isLoading: true
        }
      }
    case SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allSbuList: {
          ...state.allSbuList,
          isLoading: false,
          data: action.data
        }
      }
    case SBU_ACTION_TYPES.GET_SBU_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allSbuList: {
          ...state.allSbuList,
          isLoading: false,
          data: []
        }
      }
    case SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createSbu: {
          ...state.createSbu,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createSbu: {
          ...state.createSbu,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case SBU_ACTION_TYPES.CREATE_SBU + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createSbu: {
          ...state.createSbu,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateSbu: {
          ...state.updateSbu,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateSbu: {
          ...state.updateSbu,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case SBU_ACTION_TYPES.UPDATE_SBU + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateSbu: {
          ...state.updateSbu,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        entitledSbuList: {
          ...state.entitledSbuList,
          isLoading: true
        }
      }
    case SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        entitledSbuList: {
          ...state.entitledSbuList,
          isLoading: false,
          data: action.data
        }
      }
    case SBU_ACTION_TYPES.GET_ENTITLED_SBU_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        entitledSbuList: {
          ...state.entitledSbuList,
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  }
}
export default sbuReducer
