import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { InitProjectDto, SupervisorEntryFormDto } from '../../../utilities/models/supervisorManagement.model'

const SupervisorAssignForm: React.FC<{
  supervisorFormData: SupervisorEntryFormDto;
  isShowHelperText: boolean;
  defaultProjectsList: Array<InitProjectDto>;
  onInputHandleChange(property: string, value: any): void;
  handleInputFocus(property: string): void;
  createSupervisor(): void;
}> = (props) => {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={6} md={3} lg={3} className="sectionTitleHolder" >
          <h3>Assign an External Supervisor</h3>
          <p>Assign an External Supervisor to a Project</p>
        </Grid>
        <Grid item xs={7} md={7} lg={7}>
          <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
          <Autocomplete
              value = {props.supervisorFormData.project.value}
              fullWidth
              size="small"
              disabled={props.supervisorFormData.project.disable}
              options={props.defaultProjectsList.map((team) => {
                return { teamName: team.teamName, teamId: team.teamId }
              })}
              getOptionLabel={(option) => option.teamName || ''}
              onChange={(event, value) => props.onInputHandleChange('project', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project"
                  color='primary'
                   onFocus={() => props.handleInputFocus('project')}
                  helperText={props.isShowHelperText && props.supervisorFormData.project.error !== null ? 'Project is required' : ''}
                  error={!!props.supervisorFormData.project.error}
                  required={props.supervisorFormData.project.isRequired} />
              )} />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
              value={props.supervisorFormData.supervisorEmail.value}
              label="External Supervisor Email"
              color='primary'
              size="small"
              fullWidth
               onFocus={() => props.handleInputFocus('supervisorEmail')}
              helperText={ props.isShowHelperText && props.supervisorFormData.supervisorEmail.error !== null ? 'External Supervisor Email is required' : ''}
              error={!!props.supervisorFormData.supervisorEmail.error}
              required={props.supervisorFormData.supervisorEmail.isRequired}
              disabled={props.supervisorFormData.supervisorEmail.disable}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('supervisorEmail', event.target.value)} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              className={'btnStyle'}
              onClick={props.createSupervisor}
            // disabled={props.authLoading}
            sx={{ marginTop: 2 }}
            >

              Assign
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    </div >
  )
}

export default SupervisorAssignForm
