import { Button, CircularProgress, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { AllocationDto, EmployeeDto, UpdateEmployeeAllocationDto } from '../../../utilities/models'
import styles from './EmployeeAllocationEditPopup.module.scss'

const EmployeeAllocationEditPopup: React.FC<{
  selectedEmployee: EmployeeDto
  selectedProject: AllocationDto
  updateAllocation: UpdateEmployeeAllocationDto
  updateAllocationDialogOpen: boolean
  updateEmployeeIsloading: boolean
  handleUpdateEmployeeAllocation(): void
  toggleUpdateAllocationDialog(): void
}> = (props) => {
  return (
    < BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={props.updateAllocationDialogOpen} >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={props.toggleUpdateAllocationDialog}>
        Please Confirm
      </BootstrapDialogTitle>
      <DialogContent dividers className='dialogContainer'>
        <Typography gutterBottom>
          Do you want to <b>Update the Allocation</b> of &#39;{props.selectedEmployee?.userName}&#39;
          in project &#39;{props.selectedProject?.projectName}&#39; ?
        </Typography>
        {/* {props.updateAllocation.allocationPercentage > 100

          ? <div className={'layout-row worningStyle'}>
            <WarningAmberOutlinedIcon className={styles.iconStyle} />
            <Typography >
              Allocation Percentage ( {props.updateAllocation.allocationPercentage} ) is Over allocated relevent time period!
            </Typography>
          </div>
          : null
        } */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={'btnStyle'}
          onClick={() => props.handleUpdateEmployeeAllocation()}>
            {props.updateEmployeeIsloading && (
                      <CircularProgress size="13px" className={styles.loading} />
            )}
          Confirm
        </Button>
        <Button
          variant="contained"
          className={'btnStyle'}
          color={'inherit'}
          onClick={props.toggleUpdateAllocationDialog}>
          Cancel
        </Button>
      </DialogActions >
    </BootstrapDialog >
  )
}

export default EmployeeAllocationEditPopup
