import { IconButton, Box, Tooltip, TextField, Typography, Checkbox, Autocomplete, TableRow, IconButtonProps, Collapse, Paper, Table, TableHead } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { APP_FEATURE_KEYS, APP_TABLE_CONFIGS, DATE_FORMAT } from '../../../utilities/constants'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import { EditOutlined, SaveOutlined, CloseOutlined } from '@mui/icons-material'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { ConfirmProposedResourcesDto, ProposedResources, ProposedResourcesActionDto, ProposeRequestPayloadDto, ProposeResourceFilter, ResourceCommentParamsDto, UpdateResourcesDto, UpdateResourcesFormDto } from '../../../utilities/models/resourceRequest.model'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import { ResourceChildTableCell, ResourceTableCell } from '../../../assets/theme/theme'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ResourceRequestRemarks from '../ResourceRequestRemarks/ResourceRequestRemarks'
import { validateFormData } from '../../../utilities/helpers'
import { alertActions, employeeAction, resourceAction } from '../../../redux/actions'
import { AppAuthorizer } from '../../shared'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ResourceRequestsCommentPopup from '../ResourceRequestsCommentPopup/ResourceRequestsCommentPopup'
import { AlertDto, AppStateDto, AllClientHierachyDto, GetEmployeeFilterListParams, InitialSbuDto, InitProjectRoleDto, ProposeResourcesListDto, sbuBriefDto, TrackDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto, ResourceManagementConfigDto } from '../../../utilities/models'
import ProposeResourcesPopup from '../ProposeResourcesPopup/ProposeResourcesPopup'
import ProposedResourcesRows from '../ProposedResourcesRows/ProposedResourcesRows'
import styles from './ResourceRequestsViewRows.module.scss'
const ResourceRequestsViewRows: React.FC<{
  selectedRequestType:any
  childElement: any
  isEditingRow: any
  requestId: any
  setIsEditingRow(b: boolean):void
  setEditRowData(a:any):void
  editRowData: any
  parentElement: any
  disabledChildRow(resource: boolean, requestId: number, resourceId: number): void
  setIsParentEditingRow(args: boolean): void
  resourcesEditableStatuses: any
  sbuList: InitialSbuDto[]
  openResources: any
  setOpenResources(args: any):void
  setSelectedRequest(args: any):void
  expanded: number | null
  setExpanded(args: number | null): void
}> = (props) => {
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const configList = useSelector((state: any) => state.config.configList.data)
  const benchResource = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'on_bench_team_id')?.values
  const proposeStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'propose_resources_enable_status_ids')
  const proposeStatuses = proposeStatus?.values.split(',')
  const proposeConfirmStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'proposed_resource_confirm_enable_status_ids')
  const proposeConfirmStatuses = proposeConfirmStatus?.values.split(',')

  const dispatch = useDispatch()
  const INITIAL_RESOURCE_STATE: UpdateResourcesFormDto = {
    vacancies: { value: props.childElement.noOfVacancies, validator: 'numbergreaterthanzero', isRequired: true, error: null, disable: false },
    deploymentStartDate: { value: props.childElement.deploymentStartDate, validator: 'object', isRequired: true, error: null, disable: false },
    deploymentEndDate: { value: props.childElement.deploymentEndDate, validator: 'object', isRequired: true, error: null, disable: false },
    isDeployementExtensionConfirm: { value: props.childElement.isDeployementExtensionConfirm, validator: 'object', isRequired: false, error: null, disable: false },
    deploymentExtendPeriod: { value: props.childElement.deploymentExtendPeriod, validator: 'numbergreaterthanzero', isRequired: false, error: null, disable: false },
    JobDescriptionAndRemarks: { value: props.childElement.JobDescriptionAndRemarks, validator: 'object', isRequired: true, error: null, disable: false },
    isBillable: { value: props.childElement.isBillable, validator: 'object', isRequired: false, error: null, disable: false },
    allocationPercentage: { value: props.childElement.allocationPercentage, validator: 'number', isRequired: false, error: null, disable: false },
    isEnabled: { value: props.childElement.isEnabled, validator: 'object', isRequired: false, error: null, disable: false }
  }
  const [isRowEditingForm, setIsRowEditingForm] = useState<UpdateResourcesFormDto>(INITIAL_RESOURCE_STATE)
  const [isJDOpen, setIsJDOpen] = useState<boolean>(false)
  const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false)
  const [isProposeResourcesPopupOpen, setIsProposeResourcesPopupOpen] = useState<boolean>(false)
  const [proposeItem, setProposeItem] = useState<ProposeRequestPayloadDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const commentList = useSelector((state: any) => state.resource.commentList.data)
  const commentListIsLoading = useSelector((state: any) => state.resource.commentList.isLoading)
  const employeeAllocationList = useSelector((state: any) => state.employee.employeeAllocationList.data)
  const employeeAllocationListLoading = useSelector((state: any) => state.employee.employeeAllocationList.isLoading)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
  )
  useEffect(() => {
    if (employeeAllocationListLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [employeeAllocationListLoading])

  // pagination

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const getComments = (requestId: number, resourceId: number) => {
    const getCommentParams: ResourceCommentParamsDto = {
      requestId: requestId,
      resourceId: resourceId
    }
    dispatch(resourceAction.getComments(getCommentParams))
  }
  const handleEdit = (element: any, n: number) => {
    props.setIsEditingRow(true)
    setIsRowEditingForm(INITIAL_RESOURCE_STATE)
    const updateResource: UpdateResourcesDto = {
      requestId: props.requestId,
      resourceRequestId: n,
      isEnabled: element.isEnabled,
      noOfVacancies: element.noOfVacancies,
      deployementStartDate: element.deployementStartDate,
      deployementEndDate: element.deployementEndDate,
      allocationPercentage: element.allocationPercentage,
      isBillable: element.isBillable,
      isDeployementExtensionConfirm: element.isDeploymentExtensionConfirm,
      deploymentExtendPeriod: element.isDeploymentExtensionConfirm ? element.deploymentExtendPeriod : null,
      jobDescriptionAndRemarks: element.JobDescriptionAndRemarks
    }
    props.setEditRowData(updateResource)
  }
  const handleChange = (e: any, property: string) => {
    switch (property) {
      case 'deploymentStartDate':
        if (moment(isRowEditingForm.deploymentEndDate.value).format(DATE_FORMAT) <= moment(e).format(DATE_FORMAT)) {
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
            property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: 'Cannot be on or after end date'
            }
          })
        } else {
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
            property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: null
            }
          })
        }
        break
      case 'deploymentEndDate':
        if (isRowEditingForm.deploymentStartDate.value >= e) {
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
            property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: 'Cannot be on or before start date'
            }
          })
        } else {
          isRowEditingForm.deploymentStartDate.error = null
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
            property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: null
            }
          })
        }
        break
      case 'JobDescriptionAndRemarks':
        if (e.replace(/<(.|\n)*?>/g, '').trim().length <= 0) {
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
              property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: 'Field is required'
            }
          })
        } else {
          setIsRowEditingForm({
            ...isRowEditingForm,
            [property]: {
              ...isRowEditingForm[
              property as keyof typeof isRowEditingForm
              ],
              value: e,
              error: null
            }
          })
        }
        break
      default:
        setIsRowEditingForm({
          ...isRowEditingForm,
          [property]: {
            ...isRowEditingForm[
          property as keyof typeof isRowEditingForm
            ],
            value: e,
            error: null
          }
        })
        break
    }
  }
  const handleChildUpdate = async (element: UpdateResourcesFormDto, requestId: number, resourceId: number) => {
    if (isRowEditingForm.deploymentStartDate.error === null && isRowEditingForm.deploymentEndDate.error === null && isRowEditingForm.JobDescriptionAndRemarks.error === null) {
      const [validatedFormData, isValid] = await validateFormData(element)
      setIsRowEditingForm(validatedFormData)
      if (isValid) {
        const updateResource: UpdateResourcesDto = {
          isEnabled: true,
          noOfVacancies: +element.vacancies.value,
          deployementStartDate: moment(element.deploymentStartDate.value).format(DATE_FORMAT),
          deployementEndDate: moment(element.deploymentEndDate.value).format(DATE_FORMAT),
          allocationPercentage: element.allocationPercentage.value ? +element.allocationPercentage.value : null,
          isBillable: element.isBillable.value === 'Billable' ? true : element.isBillable.value !== null ? false : null,
          isDeployementExtensionConfirm: element.isDeployementExtensionConfirm.value,
          deploymentExtendPeriod: element.isDeployementExtensionConfirm.value === true ? element.deploymentExtendPeriod.value ? +element.deploymentExtendPeriod.value : null : null,
          jobDescriptionAndRemarks: element.JobDescriptionAndRemarks.value
        }
        dispatch(resourceAction.updateResources(updateResource, requestId, resourceId))
      }
    }
  }
  // add comment
  const addResourceCommentAlert: AlertDto = useSelector((state: any) => state.alerts.addResourceComment)
  const addResourceCommentIsLoading = useSelector((state: any) => state.resource.createResourceComment.isLoading)
  const addProposeRequestAlert: AlertDto = useSelector((state: any) => state.alerts.createPropose)
  const proposeResourceLoding = useSelector((state: any) => state.resource.createProposeRequest.isLoading)
  const addResourceComment = async (text: string, requestId: number, resourceId: number) => {
    const allocationCommentParams: ResourceCommentParamsDto = {
      comment: text
    }
    dispatch(resourceAction.CreateResourceComment(allocationCommentParams, requestId, resourceId))
  }
  const clearAddCommentAlert = () => {
    dispatch(alertActions.clearAddResourceCommentAlertRequest())
  }
  // const [expanded, setExpanded] = React.useState<number | null>(null)

  // added propose request

  const getAllocationList = (isClear: boolean, value: any) => {
    setPage(0)
    let FilterAllocationParams : GetEmployeeFilterListParams = {
    }
    if (!isClear) {
      FilterAllocationParams = {
        username: proposeFilterFormData.employeeName.value.userName === '' ? undefined : proposeFilterFormData.employeeName.value.userName,
        projectId: value === 'benchResources' ? benchResource : proposeFilterFormData.projectName.value.teamId,
        sbuId: proposeFilterFormData.sbu.value.id,
        clientId: proposeFilterFormData.clientName.value.clientId,
        projectRoleId: proposeFilterFormData.position.value?.projectRoleId,
        trackId: proposeFilterFormData.track.value?.id,
        isBillable: value === 'nonBillable' ? false : proposeFilterFormData.isBillable.value === '' ? null : proposeFilterFormData.isBillable.value === 'Billable'
      }
    } else {
      if (value) {
        FilterAllocationParams = {
          projectId: value === 'benchResources' ? benchResource : null,
          isBillable: value === 'nonBillable' ? false : null
        }
      } else {
        setProposeFilterFormData(INITIAL_PROPOSE_FILTER_STATE)
      }
    }
    dispatch(employeeAction.getFilteredList(FilterAllocationParams))
  }
  const setProposeFilterFormDataToInitial = () => {
    setProposeFilterFormData(INITIAL_PROPOSE_FILTER_STATE)
  }
  const deleteProposeResourceAlert: AlertDto = useSelector((state: any) => state.alerts.deleteProposedResource)
  const deleteProposedResource = (proposeId: number) => {
    dispatch(resourceAction.deleteProposedResource(props.requestId, props.childElement.resourceId, proposeId))
  }
  const onproposeChange = (value: any, event: React.ChangeEvent<HTMLInputElement>, item: ProposeResourcesListDto) => {
    const array = [...proposeItem]
    const Item: ProposeRequestPayloadDto = {
      id: item.userId,
      username: item.userName,
      experience: item.experience,
      qualification: item.qualification,
      skillAndTech: item.skillAndTech
    }
    if (event.target.checked === true) {
      const index = array?.findIndex((data) => data.id === item.userId)
      if (index === -1) {
        array?.push(Item)
      }
    } else {
      const index = array?.findIndex((data) => data.id === item.userId)
      if (index !== -1) {
        array.splice(index, 1)
      }
    }
    setProposeItem(array)
  }

  const createProposeResorce = async (requestId: number, resourceId: number) => {
    if (proposeItem.length > 0) {
      dispatch(resourceAction.CreateProposeRequest(proposeItem, requestId, resourceId))
    }
  }
  React.useEffect(() => {
    if (addProposeRequestAlert.severity === 'success') {
      setProposeItem([])
    }
  }, [addProposeRequestAlert.severity === 'success'])
  const confirmProposedResources = async (requestId: number, resourceId: number, proposedResources: ProposedResources[]) => {
    const proposeIds: ConfirmProposedResourcesDto[] = proposedResources.map((element) => { return { proposeId: element.proposedResourcesId } })
    dispatch(resourceAction.confirmProposedResources(requestId, resourceId, proposeIds))
  }

  const proposedResourcesAction = async (resourceId: number, proposeId: number, status: ProposedResourcesActionDto) => {
    dispatch(resourceAction.proposedResourcesAction(props.requestId, resourceId, proposeId, status))
  }

  const handleExpandClick = (resourceId: number) => {
    if (props.expanded === resourceId) {
      props.setExpanded(null)
    } else {
      props.setExpanded(resourceId)
    }
    // setSelectedResource(resourceId)
  }
  interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }))
  const INITIAL_PROPOSE_FILTER_STATE: ProposeResourceFilter = {
    benchResources: { value: null, validator: 'string', isRequired: true, error: null, disable: false },
    sbu: { value: {} as sbuBriefDto, validator: 'object', isRequired: false, error: null, disable: false },
    clientName: { value: {} as UserClientHierarchyDto, validator: 'object', isRequired: false, error: null, disable: false },
    projectName: { value: {} as UserClientHierarchyTeamsDto, validator: 'object', isRequired: false, error: null, disable: false },
    employeeName: { value: {} as ProposeResourcesListDto, validator: 'object', isRequired: false, error: null, disable: false },
    experience: { value: 0, validator: 'number', isRequired: false, error: null, disable: false },
    position: { value: {} as InitProjectRoleDto, validator: 'object', isRequired: false, error: null, disable: false },
    track: { value: {} as TrackDto, validator: 'object', isRequired: false, error: null, disable: false },
    isBillable: { value: '', validator: 'string', isRequired: false, error: null, disable: false }
  }
  const [proposeFilterFormData, setProposeFilterFormData] = useState(INITIAL_PROPOSE_FILTER_STATE)

  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.length > 0) {
      for (const client of clientHierachy) {
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some((i) =>
              i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some((i) =>
                  i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const clientHierachy = useSelector((state: any) => state.client.clientHierachy.data)
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<UserClientHierarchyDto[]>(clientHierachy)
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<UserClientHierarchyTeamsDto[]>(filterByClientHirachy()?.teams)
  const trackList = useSelector((state: any) => state.track.trackList.data)
  const designationList = useSelector((state: any) => state.designation.designationList.data)

  const handleChangeProposeFilterForm = (property: string, value: any) => {
    switch (property) {
      case 'sbu':
        if (value) {
          const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === value.id)
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          const userList: UserClientHierarchyUserDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some((i) =>
                    i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                  if (team.users && team.users.length) {
                    for (const user of team.users) {
                      const isIncluded = userList.some((i) =>
                        i.userId === user.userId
                      )
                      if (!isIncluded && user.userId !== null) {
                        userList.push({
                          userId: user.userId,
                          username: user.username,
                          firstName: user.firstName,
                          lastName: user.lastName
                        })
                      }
                    }
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          // setClientHierarchyUsersList(userList)
          setProposeFilterFormData({
            ...proposeFilterFormData,
            sbu: {
              ...proposeFilterFormData['sbu' as keyof typeof proposeFilterFormData],
              value: value
            },
            clientName: {
              ...proposeFilterFormData['clientName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyDto
            },
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
              value: {} as ProposeResourcesListDto,
              error: null

            }
          })
        } else {
          setProposeFilterFormData({
            ...proposeFilterFormData,
            sbu: {
              ...proposeFilterFormData['sbu' as keyof typeof proposeFilterFormData],
              value: {} as sbuBriefDto,
              error: null

            },
            clientName: {
              ...proposeFilterFormData['clientName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            }
          })
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          // setClientHierarchyUsersList(filterByClientBy.users)
        }
        break
      case 'clientName':
        if (value) {
          setClientHierarchyProjectList(value.teams)
          const userList: UserClientHierarchyUserDto[] = []
          if (value.teams.length > 0) {
            for (const team of value.teams) {
              if (team.users && team.users.length) {
                for (const user of team.users) {
                  const isIncluded = userList.some((i) =>
                    i.userId === user.userId
                  )
                  if (!isIncluded && user.userId !== null) {
                    userList.push({
                      userId: user.userId,
                      username: user.username,
                      firstName: user.firstName,
                      lastName: user.lastName
                    })
                  }
                }
              }
            }
          }
          // setClientHierarchyUsersList(userList)

          setProposeFilterFormData({
            ...proposeFilterFormData,
            clientName: {
              ...proposeFilterFormData['clientName' as keyof typeof proposeFilterFormData],
              value: value
            },
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
              value: {} as ProposeResourcesListDto,
              error: null

            }
          })
        } else {
          setProposeFilterFormData({
            ...proposeFilterFormData,
            clientName: {
              ...proposeFilterFormData['clientName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyDto,
              error: null

            },
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
              value: {} as ProposeResourcesListDto,
              error: null
            }
          })
          if (Object.keys(proposeFilterFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === proposeFilterFormData.sbu.value.id)
            setClientHierarchyclientList(filteredClientList)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            // setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
            // setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      case 'projectName':
        if (value) {
          // setClientHierarchyUsersList(value.users)
          setProposeFilterFormData({
            ...proposeFilterFormData,
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: value
            },
            employeeName: {
              ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
              value: {} as ProposeResourcesListDto,
              error: null

            }
          })
        } else {
          setProposeFilterFormData({
            ...proposeFilterFormData,
            projectName: {
              ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
              value: {} as UserClientHierarchyTeamsDto,
              error: null

            },
            employeeName: {
              ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
              value: {} as ProposeResourcesListDto,
              error: null

            }
          })
          if (Object.keys(proposeFilterFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === proposeFilterFormData.clientName.value.clientId)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            // setClientHierarchyUsersList(userList)
          } else {
            // const filterByClientBy = filterByClientHirachy()
            // setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      default:
        if (value) {
          setProposeFilterFormData({
            ...proposeFilterFormData,
            [property]: {
              ...proposeFilterFormData[property as keyof typeof proposeFilterFormData],
              value: value
            }
          })
        } else {
          if (property === 'employeeName') {
            setProposeFilterFormData({
              ...proposeFilterFormData,
              employeeName: {
                ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
                value: {} as ProposeResourcesListDto,
                error: null

              }
            })
          } else if (property === 'projectName') {
            setProposeFilterFormData({
              ...proposeFilterFormData,
              projectName: {
                ...proposeFilterFormData['projectName' as keyof typeof proposeFilterFormData],
                value: {} as UserClientHierarchyTeamsDto,
                error: null

              }
            })
          } else if (property === 'clientName') {
            setProposeFilterFormData({
              ...proposeFilterFormData,
              clientName: {
                ...proposeFilterFormData['clientName' as keyof typeof proposeFilterFormData],
                value: {} as UserClientHierarchyDto

              }
            })
            setProposeFilterFormData({
              ...proposeFilterFormData,
              employeeName: {
                ...proposeFilterFormData['employeeName' as keyof typeof proposeFilterFormData],
                value: {} as ProposeResourcesListDto,
                error: null

              }
            })
          } else if (property === 'isBillable') {
            setProposeFilterFormData({
              ...proposeFilterFormData,
              [property]: {
                ...proposeFilterFormData[property as keyof typeof proposeFilterFormData],
                value: ''
              }
            })
          } else {
            setProposeFilterFormData({
              ...proposeFilterFormData,
              [property]: {
                ...proposeFilterFormData[property as keyof typeof proposeFilterFormData],
                value: null
              }
            })
          }
        }
    }
  }

  return (
    <React.Fragment>
    <TableRow>
      {/* {props.childElement.proposedResources.length > 0 && */}
    <ResourceTableCell align="center" padding="checkbox" width={30} style={{ minWidth: 30 }}>
    <ExpandMore
          expand={props.expanded === props.childElement.resourceId}
          onClick={(value) => handleExpandClick(props.childElement.resourceId)}
          aria-label="Show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
    </ResourceTableCell>
{/* } */}
    {props.selectedRequestType === 1
      ? (
      <React.Fragment>
        <ResourceTableCell align="center" width={150} style={{ minWidth: 150 }}>
          <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}> {props.childElement.designation}</Typography>
          </ResourceTableCell>
      <ResourceTableCell align="center" width={100} style={{ minWidth: 100 }}>
        {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
          ? <TextField variant="standard"
                    style={{ width: '70px', zIndex: 1 }}
                    required={isRowEditingForm.vacancies.isRequired}
                    type={'number'}
                    size={'small'}
                    value={isRowEditingForm.vacancies.value}
                    InputProps={{
                      inputProps: {
                        max: 100, min: 1
                      }
                    }}
                    onChange={(event) => handleChange(event.target.value, 'vacancies')}
                    error={isRowEditingForm.vacancies.error !== null}
                  ></TextField>
          : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''} > {props.childElement.noOfVacancies}</Typography>
                  }
        </ResourceTableCell>
        </React.Fragment>
        )
      : <React.Fragment><ResourceTableCell align="center" width={20} style={{ minWidth: 20 }}>
        <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}>{props.childElement.replacementUsername}</Typography>
        </ResourceTableCell>
          <ResourceTableCell align="center" width={120} style={{ minWidth: 120 }}>
          <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}>
            {props.childElement.designation}
            </Typography>
            </ResourceTableCell>
            </React.Fragment>
          }
          <ResourceTableCell align="center" width={110} style={{ minWidth: 110 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              views={['year', 'month', 'day']}
              value={isRowEditingForm.deploymentStartDate.value}
              inputFormat={DATE_FORMAT}
              closeOnSelect={true}
              onChange={(value) => handleChange(value, 'deploymentStartDate')}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required={isRowEditingForm.deploymentStartDate.isRequired}
                  error={isRowEditingForm.deploymentStartDate.error !== null}
                  helperText={isRowEditingForm.deploymentStartDate.error}
                  variant="standard"
                  size={'small'}
                  sx={{
                    svg: { width: '15px', height: '15px' },
                    maxWidth: 100,
                    margin: 0,
                    padding: 0
                  }} />}
            />
          </LocalizationProvider>
            : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}> {moment(props.childElement.deploymentStartDate).format(DATE_FORMAT)}</Typography>
          }
            </ResourceTableCell>
          <ResourceTableCell align="center" width={110} style={{ minWidth: 110 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              views={['year', 'month', 'day']}
              value={isRowEditingForm.deploymentEndDate.value}
              inputFormat={DATE_FORMAT}
              closeOnSelect={true}
              onChange={(value) => handleChange(value, 'deploymentEndDate')}
                minDate={isRowEditingForm.deploymentStartDate.value ? moment(isRowEditingForm.deploymentStartDate.value).add(1, 'days') : undefined}
                renderInput={(params) =>
                <TextField
                  {...params}
                  required={isRowEditingForm.deploymentEndDate.isRequired}
                  error={isRowEditingForm.deploymentEndDate.error !== null}
                  helperText={isRowEditingForm.deploymentEndDate.error}
                  variant="standard"
                  size={'small'}
                  sx={{
                    svg: { width: '15px', height: '15px' },
                    maxWidth: 100,
                    margin: 0,
                    padding: 0
                  }} />}
            />
          </LocalizationProvider>
            : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}> {moment(props.childElement.deploymentEndDate).format(DATE_FORMAT)}</Typography>
          }
            </ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={100} style={{ minWidth: 100 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? <Checkbox
                  checked={isRowEditingForm.isDeployementExtensionConfirm.value}
                  onChange={(event, value) => handleChange(value, 'isDeployementExtensionConfirm')}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
            : <Checkbox
                  checked={props.childElement.isDeployementExtensionConfirm}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
            }
          </ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={50} style={{ minWidth: 50 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? <TextField variant="standard"
                    style={{ width: '70px', zIndex: 1 }}
                    required={isRowEditingForm.deploymentExtendPeriod.isRequired}
                    type={'number'}
                    size={'small'}
                    value={isRowEditingForm.deploymentExtendPeriod.value}
                    disabled={!isRowEditingForm.isDeployementExtensionConfirm.value}
                    InputProps={{
                      inputProps: {
                        max: 100, min: 1
                      }
                    }}
                    onChange={(event) => handleChange(event.target.value, 'deploymentExtendPeriod')}
                    error={isRowEditingForm.deploymentExtendPeriod.error !== null}
                    helperText={isRowEditingForm.deploymentExtendPeriod.error}
                  ></TextField>
            : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}>
            {props.childElement.deploymentExtendPeriod || '-'}</Typography>
                  }</ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={100} style={{ minWidth: 100 }}>
          <Box width={'100%'}>
      <IconButton
       size='small'
       onClick={() => {
         setIsJDOpen(true)
       } }
       disabled={!props.childElement.isEnabled || (props.editRowData.resourceRequestId !== props.childElement.resourceId && props.isEditingRow === true)}
       className={isRowEditingForm.JobDescriptionAndRemarks.error !== null ? 'actionClosedButtonStyle' : ''}
      >
        <Tooltip title="Click to display Job Description and Additional Remarks">
          <AppRegistrationOutlinedIcon
          sx={{ fontSize: '20px', mr: '-1' }} />
          </Tooltip>
        </IconButton>
        {isJDOpen && (
          <ResourceRequestRemarks
          buttonText={'Save Changes'}
          handleEditChange={handleChange}
          isRemarksPopupOpen={isJDOpen}
          resourceIndex={props.childElement.resourceId}
          setIsRemarksPopupOpen={setIsJDOpen}
          elementValue={props.childElement.JobDescriptionAndRemarks}
          isRowEditingFormValue={isRowEditingForm.JobDescriptionAndRemarks.value}
          readOnly={!props.isEditingRow}
          resourceRequestId={props.requestId}
        />
        )}
        </Box>
          </ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={80} style={{ minWidth: 80 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? <Autocomplete
              size='small'
              disablePortal
              options={['Billable', 'Non-billable']}
              value={isRowEditingForm.isBillable.value ? 'Billable' : isRowEditingForm.isBillable.value !== null ? 'Non-Billable' : ''}
              onChange={(event, value) => handleChange(value, 'isBillable')}
              renderInput={(params) =>
                <TextField variant="standard"
                required={isRowEditingForm.isBillable.isRequired}
                error={isRowEditingForm.isBillable.error !== null}
                  {...params} size={'small'}
                  sx={{ maxWidth: 110 }} />}
            />
            : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}>
                  {props.childElement.isBillable !== null ? props.childElement.isBillable ? 'Billable' : 'Non_Billable' : '-'}
                </Typography>
            }</ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={80} style={{ minWidth: 80 }}>
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? <TextField variant="standard"
                  style={{ width: '70px' }}
                  type={'number'}
                  size={'small'}
                  value={isRowEditingForm.allocationPercentage.value}
                  InputProps={{
                    inputProps: {
                      max: 100, min: 0
                    }
                  }}
                  onChange={(event) => handleChange(event.target.value, 'allocationPercentage')}
                  required={isRowEditingForm.allocationPercentage.isRequired}
                  error={isRowEditingForm.allocationPercentage.error !== null}
                ></TextField>
            : <Typography className={!props.childElement.isEnabled ? styles.disabled : ''}>
                  {props.childElement.allocationPercentage === null ? '-' : props.childElement.allocationPercentage + '%'}
                </Typography>
            }
          </ResourceTableCell>
          <ResourceTableCell align="center" width={80} style={{ minWidth: 80 }}>
            <Typography className={!props.childElement.isEnabled ? styles.disabled : ''} >{props.childElement.statusName}</Typography>
            </ResourceTableCell>
          <ResourceTableCell className={!props.childElement.isEnabled ? styles.disabled : ''} align="center" width={150} style={{ minWidth: 150, position: 'sticky', right: 0 }}>
          <AppAuthorizer
                activeRoleFeatures={activeUserRole?.data.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.UPDATE_RESOURCES

                ]}
              >
          {(props.editRowData.resourceRequestId === props.childElement.resourceId && props.isEditingRow)
            ? <React.Fragment><IconButton size='small'
                onClick={() => {
                  handleChildUpdate(isRowEditingForm, props.requestId, props.childElement.resourceId)
                } }
              >
                <Tooltip title="Save to continue">
                  <SaveOutlined color='primary' sx={{ fontSize: '20px', mr: '-1' }} />
                </Tooltip>
              </IconButton><IconButton
                onClick={() => {
                  props.setIsEditingRow(false)
                  setIsRowEditingForm(INITIAL_RESOURCE_STATE)
                } }
                size='small' className='actionClosedButtonStyle'>
                  <Tooltip title="Close">
                    <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                  </Tooltip>
                </IconButton></React.Fragment>
            : <React.Fragment>
           {props.resourcesEditableStatuses?.filter((x: any) => +x === props.childElement.statusId).length > 0 && (
          <IconButton
                  size='small'
                  disabled={props.isEditingRow === true || props.childElement.isEnabled !== true}
                  onClick={(event) => {
                    event.stopPropagation()
                    handleEdit(props.childElement, props.childElement.resourceId)
                    props.setIsParentEditingRow(false)
                  } }
                >
                  <Tooltip title="Edit">
                    <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                  </Tooltip>
                </IconButton>
           )}
           {props.resourcesEditableStatuses?.filter((x: any) => +x === props.childElement.statusId).length > 0 &&
        props.childElement.isEnabled === false &&
        <IconButton
          onClick={() => {
            props.disabledChildRow(true, props.requestId, props.childElement.resourceId)
          }
          }
        >
          <Tooltip title="Enable">
            <CheckCircleOutlineIcon
             fontSize="small" style={{ color: '#4CAF50' }}></CheckCircleOutlineIcon>
          </Tooltip>
          </IconButton>
        }
           {props.resourcesEditableStatuses?.filter((x: any) => +x === props.childElement.statusId).length > 0 &&
           props.childElement.isEnabled &&
        <IconButton
        onClick={() => {
          props.disabledChildRow(false, props.requestId, props.childElement.resourceId)
        }
        }
      >
          <Tooltip title="Disable">
            <DoDisturbOutlinedIcon
              // onClick={() => props.disabledChildRow(false, props.editRowData.resourceId, props.childElement.resourceId)}
              fontSize="small" style={{ color: '#e90c10' }}></DoDisturbOutlinedIcon>
          </Tooltip>
          </IconButton>
        }

        </React.Fragment>
          }
        </AppAuthorizer>

        <IconButton
        onClick={() => {
          setIsCommentOpen(true)
        } }
        size='small'
      >
          <Tooltip title="Comment">
            <CommentOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
          </Tooltip>
        </IconButton>

{isCommentOpen && (
<ResourceRequestsCommentPopup
isCommentPopupOpen={isCommentOpen}
resourceId={props.childElement.resourceId}
setIsCommentPopupOpen={setIsCommentOpen}
resourceRequestId={props.requestId}
addResourceComment={addResourceComment}
getAllComments={getComments}
commentList={commentList?.data}
commentListIsLoading={commentListIsLoading}
addResourceCommentAlert = {addResourceCommentAlert}
clearAddCommentAlert = {clearAddCommentAlert}
addResourceCommentIsLoading={addResourceCommentIsLoading}
/>
)}
        {/* propose/ confirm propose resources buttons */}
        {props.expanded === props.childElement.resourceId &&
        proposeConfirmStatuses?.filter((x: any) => +x === props.childElement.statusId).length > 0 &&
        props.childElement.isEnabled &&
          <AppAuthorizer
          activeRoleFeatures={activeUserRole?.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CONFIRM_PROPOSED_RESOURCES]}
        >
          <IconButton
                  onClick={() => {
                    confirmProposedResources(props.requestId, props.childElement.resourceId, props.childElement.proposedResources)
                  } }
                  disabled={addProposeRequestAlert.severity === 'success' || props.childElement.proposedResources?.length <= 0}
                  size='small'
                  color='primary'
                >
                    <Tooltip title="Confirm Proposed Resources">
                      <SaveOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                    </Tooltip>
                  </IconButton>
                  </AppAuthorizer>
        }
        {props.expanded !== props.childElement.resourceId && proposeStatuses?.filter((x: any) => +x === props.childElement.statusId).length > 0 && props.childElement.isEnabled &&
          <AppAuthorizer
                        activeRoleFeatures={activeUserRole?.data.features}
                        authorizedFeatureKey={[APP_FEATURE_KEYS.PROPOSE_RESOURCE]}
                      >
           <IconButton
                  onClick={() => {
                    setIsProposeResourcesPopupOpen(true)
                  } }
                  disabled={addProposeRequestAlert.severity === 'success'}
                  size='small'
                >
                    <Tooltip title="Propose Request">
                      <AddCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1' }} />
                    </Tooltip>
                  </IconButton>
                  </AppAuthorizer>
          }
          {isProposeResourcesPopupOpen && (
            <ProposeResourcesPopup
            isProposeResourcesPopupOpen={isProposeResourcesPopupOpen}
            setIsProposeResourcesPopupOpen={setIsProposeResourcesPopupOpen}
            createProposeResorce={createProposeResorce}
            resourceId={props.childElement.resourceId}
            resourceRequestId={props.requestId}
            addProposeRequestAlert={addProposeRequestAlert}
            onproposeChange={onproposeChange}
            filterFormData={proposeFilterFormData}
            handleChangeFilterForm={handleChangeProposeFilterForm}
            sbuList={props.sbuList}
            projectList={clientHierarchyProjectList}
            clientList={clientHierarchyClientList}
            trackList={trackList.data}
            designationList={designationList.data}
            getAllocationList={getAllocationList}
            employeeAllocationList={employeeAllocationList.data || []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            employeeAllocationListLoading={employeeAllocationListLoading}
            isLoading={isLoading}
            proposeResourceLoding={proposeResourceLoding}
            proposeItem={proposeItem}
            proposedItems={props.childElement.proposedResources}
            setProposeFilterFormData={setProposeFilterFormDataToInitial}
            />
          )}
          </ResourceTableCell>
      </TableRow>
      {props.expanded === props.childElement.resourceId && (
      <TableRow>
        <ResourceTableCell colSpan={1}></ResourceTableCell>
        <ResourceTableCell colSpan={9}>
         <Collapse in={props.expanded === props.childElement.resourceId} timeout="auto" unmountOnExit>
          <Paper sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
              <ResourceChildTableCell>Employee</ResourceChildTableCell>
              <ResourceChildTableCell>Email</ResourceChildTableCell>
              <ResourceChildTableCell>Position</ResourceChildTableCell>
              <ResourceChildTableCell>Experience</ResourceChildTableCell>
              <ResourceChildTableCell>User Profile</ResourceChildTableCell>
              <ResourceChildTableCell>Status</ResourceChildTableCell>
              <ResourceChildTableCell>Actions</ResourceChildTableCell>
              </TableRow>
            </TableHead>
            <ProposedResourcesRows
              proposedResources={props.childElement.proposedResources}
              deleteProposedResource={deleteProposedResource}
              deleteProposeResourceAlert={deleteProposeResourceAlert}
              resource={props.childElement}
              expandedResource={props.expanded}
              setIsProposeResourcesPopupOpen={setIsProposeResourcesPopupOpen}
              proposeStatuses={proposeStatuses}
              proposedResourcesAction={proposedResourcesAction}
              />
          </Table>
          </Paper>
      </Collapse>
      </ResourceTableCell>
      <ResourceTableCell colSpan={2}></ResourceTableCell>
      </TableRow>
      )}
      </React.Fragment>
  )
}
export default ResourceRequestsViewRows
