import { delay, put, takeEvery, call } from 'redux-saga/effects'
import { projectService } from '../../services/project.service'
import { ALERT_CONFIGS } from '../../utilities/constants'
import { COMMON_ACTION_TYPES, PROJECT_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { CreateSbuParamDto, UpdateSbuParamDto } from '../../utilities/models'
import { AlertActionDto } from '../../utilities/models/alert.models'
import { GetProjectListParamDto } from '../../utilities/models/projectManagement.model'
function * allProjectList (action: { type: string, params: GetProjectListParamDto }) {
  try {
    // @ts-ignore
    const projectList = yield call(projectService.getAllProjectList, action.params)
    yield put({
      type:
        PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: projectList.data
    })
  } catch (error) {
    yield put({
      type: PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

// function * clientList (action: { type: string, params: GetClientListBriefParamDto }) {
//   try {
//     // @ts-ignore
//     const clientList = yield call(projectService.getAllBriefClientList, action.params)
//     yield put({
//       type:
//         PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.SUCCESS,
//       data: clientList.data
//     })
//   } catch (error) {
//     yield put({
//       type: PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.ERROR,
//       error: error as string
//     })
//   }
// }
function * updateProject (action: { type: string, payload: UpdateSbuParamDto }) {
  try {
    // @ts-ignore
    yield call(projectService.updateProject, action.payload)

    const setAlert: AlertActionDto = {
      message: 'Project updated successfully!',
      severity: 'success',
      type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const message = error as string
    if (message === 'Team name already exsists!') {
      const setAlert: AlertActionDto = {
        message: 'Project name already exists!',
        severity: 'error',
        type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
      }
      yield put({ type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.ERROR })
      yield put(setAlert)
    } else {
      const setAlert: AlertActionDto = {
        message: error as string,
        severity: 'error',
        type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
      }

      yield put({ type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.ERROR })
      yield put(setAlert)
    }
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * createProject (action: { type: string, payload: CreateSbuParamDto }) {
  try {
    // @ts-ignore

    const createProject = yield call(projectService.createProject, action.payload)
    const message = createProject.data.message
    if (message === 'Team name already exsists!') {
      const setAlert: AlertActionDto = {
        message: 'Project name already exists!',
        severity: 'error',
        type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
      }
      yield put({ type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.ERROR })
      yield put(setAlert)
    } else {
      const setAlert: AlertActionDto = {
        message: 'Project Created Successfully!',
        severity: 'success',
        type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
      }
      yield put({ type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SUCCESS })
      yield put(setAlert)
    }
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}

function * projectSaga () {
  yield takeEvery(
    PROJECT_ACTION_TYPES.GET_PROJECT_LIST + COMMON_ACTION_TYPES.REQUEST,
    allProjectList
  )
  // yield takeEvery(
  //   PROJECT_ACTION_TYPES.GET_PROJECT_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST,
  //   clientList
  // )
  yield takeEvery(PROJECT_ACTION_TYPES.UPDATE_PROJECT + COMMON_ACTION_TYPES.REQUEST,
    updateProject
  )
  yield takeEvery(PROJECT_ACTION_TYPES.CREATE_PROJECT + COMMON_ACTION_TYPES.REQUEST,
    createProject
  )
}
export default projectSaga
