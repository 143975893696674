import { Grid, CircularProgress } from '@mui/material'
import React from 'react'
import { InitialSbuDto } from '../../../utilities/models'
import { ResourceRequest } from '../../../utilities/models/resourceRequest.model'
import ResourceRequestsViewParentRows from '../ResourceRequestsViewParentRows/ResourceRequestsViewParentRows'
const ResourceRequestsViewTable: React.FC<{
  resourceRequestsList: ResourceRequest[]
  page: number;
  rowsPerPage: number;
  resourceRequestListIsLoading: any
  selectedRequestType: number
  editRowData: any
  setEditRowData(rowData: any):void
  parentEditRowData: any
  setParentEditRowData(rowData: any):void
  isEditingRow:any
  setIsEditingRow(b: boolean):void
  disabledChildRow(resource: any, requestId: number, resourceId: number):void
  updateRequest(date: string, requestId: number): void
  isParentEditingRow: any
  setIsParentEditingRow(b: boolean): void
  activeUserRole: any
  resourceActionLoading: boolean
  sbuList: InitialSbuDto[]
  getProposedRequests(args:number):void
  resourcesList: any
  resourcesListIsLoading: any
  selectedRequest:any
  setSelectedRequest(args:any):void
  openResources:any
  setOpenResources(args:any):void
  setExpanded(args: number | null): void
  expanded: any
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container spacing={2} >
          {!props.resourceRequestListIsLoading &&
                props.resourceRequestsList.length > 0 &&
                (props.rowsPerPage > 0
                  ? props.resourceRequestsList.slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  : props.resourceRequestsList).map((element: any, index: any) => (
          <ResourceRequestsViewParentRows
          key={index}
          row={element}
          selectedRequestType={props.selectedRequestType}
          editRowData={props.editRowData}
          setEditRowData={props.setEditRowData}
          parentEditRowData={props.parentEditRowData}
          setParentEditRowData={props.setParentEditRowData}
          isEditingRow={props.isEditingRow}
          setIsEditingRow={props.setIsEditingRow}
          disabledChildRow={props.disabledChildRow}
          updateRequest={props.updateRequest}
          isParentEditingRow={props.isParentEditingRow}
          setIsParentEditingRow={props.setIsParentEditingRow}
          resourceActionLoading={props.resourceActionLoading}
          sbuList={props.sbuList}
          getProposedRequests={props.getProposedRequests}
          setSelectedRequest={props.setSelectedRequest}
          selectedRequest={props.selectedRequest}
          openResources={props.openResources}
          setOpenResources={props.setOpenResources}
          resourcesList={props.resourcesList}
          resourcesListIsLoading={props.resourcesListIsLoading}
          setExpanded={props.setExpanded}
          expanded={props.expanded}
           />
                ))}
           {props.resourceRequestListIsLoading && (
                <Grid>
                    <CircularProgress
                      color="primary"
                      size={20}
                    />{' '}
                    Loading...
            </Grid>
           )}
              {!props.resourceRequestListIsLoading &&
                props.resourceRequestsList.length === 0 && (
                  <Grid>
                      No Records Available.
                    </Grid>
              )}
        </Grid>
      </Grid>
      </Grid>
    </div>
  )
}
export default ResourceRequestsViewTable
