import { Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { SbuEntryFormDto } from '../../../utilities/models'

const SbuEntryForm: React.FC<{
  sbuFormData: SbuEntryFormDto;
  isShowHelperText:boolean;
  onInputHandleChange(property: string, value: any): void;
  handleInputFocus(property: string): void;
  createSbu(): void;
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={3} className="sectionTitleHolder">
          <h3>Create New SBU</h3>
          <p>Add a new Strategic Business Unit (SBU) by providing a name and description and click the create SBU button.</p>
        </Grid>
        <Grid item md={7} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                value={props.sbuFormData.sbuName.value}
                label="SBU Name"
                color='primary'
                size="small"
                fullWidth
                  onFocus={() => props.handleInputFocus('sbuName')}
                helperText={ props.isShowHelperText && props.sbuFormData.sbuName.error !== null ? 'Sbu Name is required' : ''}
                error={!!props.sbuFormData.sbuName.error}
                required={props.sbuFormData.sbuName.isRequired}
                disabled={props.sbuFormData.sbuName.disable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('sbuName', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                value={props.sbuFormData.sbuDescription.value}
                label="SBU Description"
                color='primary'
                size="small"
                fullWidth
                multiline
                rows={4}
                  onFocus={() => props.handleInputFocus('sbuDescription')}
                 helperText={ props.isShowHelperText && props.sbuFormData.sbuDescription.error}
                error={!!props.sbuFormData.sbuDescription.error}
                required={props.sbuFormData.sbuDescription.isRequired}
                disabled={props.sbuFormData.sbuDescription.disable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onInputHandleChange('sbuDescription', event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
            <Button
            variant="contained"
            className={'btnStyle'}
            onClick={props.createSbu}
            // disabled={props.authLoading}
          >

            Create SBU
          </Button>
              </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

export default SbuEntryForm
