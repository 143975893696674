import axios from 'axios'
import { DashboardEmpCountParamsDto } from '../utilities/models'

const getEmployeeWiseDetails = () => {
  return axios.get('/hera/api/v1/dashboard/empBreakdown')
}
const getClientWiseDetails = () => {
  return axios.get('/hera/api/v1/dashboard/clientWiseEmpCount')
}
const getTrackWiseDetails = (params: DashboardEmpCountParamsDto) => {
  return axios.get('/hera/api/v1/dashboard/trackWiseEmpCount', { params: params })
}
const getTierWiseDetails = (params: DashboardEmpCountParamsDto) => {
  return axios.get('/hera/api/v1/dashboard/tierWiseEmpCount', { params: params })
}

export const dashboardService = {
  getEmployeeWiseDetails,
  getClientWiseDetails,
  getTrackWiseDetails,
  getTierWiseDetails
}
