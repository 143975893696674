/* eslint-disable react/react-in-jsx-scope */
import { createTheme, Dialog, DialogTitle, IconButton, styled, TableCell, tableCellClasses, TableRow, Theme, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
const primaryFontSize = 14
export const PrimaryTheme:Theme = createTheme({
  palette: {
    action: {
      hover: '#f4f4f4'
    },
    primary: {
      main: '#fe5728'
    },
    secondary: {
      main: '#000000'
    }
  },
  typography: {
    fontFamily: [
      'PT Sans',
      'sans-serif'
    ].join(','),
    fontSize: primaryFontSize,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    body1: {
      fontSize: primaryFontSize
    }
  }

})
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f4f4f4',
    fontWeight: '600',
    fontSize: 16,
    // color: theme.palette.common.white,

    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'inherit',
    //  backgroundColor: '#FFFFFF',
    fontSize: 14,
    padding: '6px'

  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f4f4f4'
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))
export const StyledParentTableRow = styled(TableRow)(({ theme }) => ({
  '&': {
    backgroundColor: '#FFFFFF'
  },
  '&.future': {
    backgroundColor: '#FFD67E'
  },
  '&.expired': {
    backgroundColor: '#FF9F98'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }

}))
export const StyledChildTableRow = styled(TableRow)(({ theme }) => ({

  '&': {
    backgroundColor: '#f4f4f4'
  },
  '&.future': {
    backgroundColor: '#FFD67E'
  },
  '&.expired': {
    backgroundColor: '#FF9F98'
  },
  '&:child td, &:last-child th': {
    border: 0
  }

  // '&:nth-of-type(odd)': {
  //   backgroundColor: '#FFFFFF'
  // }
  // hide last border
  // '&:last-child td, &:last-child th': {
  // border: 0
  // }
}))

export const ResourceTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f0f0f0',
    fontWeight: '600',
    fontSize: 14,
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#fbfbfb',
    fontSize: 12,
    padding: '6px'
  }
}))

export const ResourceChildTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E4E4E4',
    fontWeight: '600',
    fontSize: 12,
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#F4F4F4',
    fontSize: 12,
    padding: '6px'

  }
}))

export const StyledProposeResourcesRow = styled(TableRow)(({ theme }) => ({
  '&': {
    backgroundColor: '#FFFFFF'
  },
  '&.future': {
    backgroundColor: '#FFFFFF'
  },
  '&.expired': {
    backgroundColor: '#FFFFFF'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }

}))

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiDialogContent-root': {
    //  padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    paddingBottom: 16,
    paddingTop: 16
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.2);'

  }

}))

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle style={{ fontWeight: 600, fontSize: 16, textAlign: 'left', textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose
        ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
          )
        : null}
    </DialogTitle>
  )
}
export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#D3D3D3',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))
