import { put, takeEvery, call, delay } from 'redux-saga/effects'
import { supervisorService } from '../../services/supervisor.service'
import { ALERT_CONFIGS } from '../../utilities/constants'
import { COMMON_ACTION_TYPES, SUPERVISOR_ACTION_TYPES } from '../../utilities/constants/action.constants'
import { AlertActionDto, CreateSupervisorParamDto, UnassignSupervisorParamDto } from '../../utilities/models'
function * allProjectsList (action: { type: string }) {
  try {
    // @ts-ignore
    const projectsList = yield call(supervisorService.getAllProjectsList, action.params)
    yield put({
      type:
        SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: projectsList.data
    })
  } catch (error) {
    yield put({
      type: SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * allSupervisorsList (action: { type: string }) {
  try {
    // @ts-ignore
    const supervisorsList = yield call(supervisorService.getAllSupervisorsList, action.params)
    yield put({
      type:
        SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.SUCCESS,
      data: supervisorsList.data
    })
  } catch (error) {
    yield put({
      type: SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * createSupervisor (action: { type: string, payload: CreateSupervisorParamDto }) {
  try {
    // @ts-ignore
    const createSupervisor = yield call(supervisorService.createSupervisor, action.payload)
    const setAlert: AlertActionDto = {
      message: createSupervisor.data.message,
      severity: 'success',
      type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const message = error as string
    if (message === 'Email already in use!') {
      const setAlert: AlertActionDto = {
        message: 'This assignment already exists!',
        severity: 'error',
        type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT
      }
      yield put({ type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.ERROR })
      yield put(setAlert)
    } else {
      const setAlert: AlertActionDto = {
        message: error as string,
        severity: 'error',
        type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT
      }
      yield put({ type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.ERROR })
      yield put(setAlert)
    }
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * unassignSupervisor (action: { type: string, payload: UnassignSupervisorParamDto }) {
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const unassignSupervisor = yield call(supervisorService.unassignSupervisor, action.payload)
    const setAlert: AlertActionDto = {
      message: unassignSupervisor.data.message,
      severity: 'success',
      type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT
    }
    yield put({ type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SUCCESS })
    yield put(setAlert)
  } catch (error: any) {
    const setAlert: AlertActionDto = {
      message: error as string,
      severity: 'error',
      type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.SET_ALERT
    }

    yield put({ type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.ERROR })
    yield put(setAlert)
  } finally {
    yield delay(ALERT_CONFIGS.TIMEOUT)
    yield put({ type: SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.CLEAR_ALERT })
  }
}
function * supervisorSaga () {
  yield takeEvery(
    SUPERVISOR_ACTION_TYPES.GET_PROJECTS_LIST + COMMON_ACTION_TYPES.REQUEST,
    allProjectsList
  )
  yield takeEvery(
    SUPERVISOR_ACTION_TYPES.GET_SUPERVISORS_LIST + COMMON_ACTION_TYPES.REQUEST,
    allSupervisorsList
  )
  yield takeEvery(SUPERVISOR_ACTION_TYPES.CREATE_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST,
    createSupervisor
  )
  yield takeEvery(SUPERVISOR_ACTION_TYPES.UNASSIGN_SUPERVISOR + COMMON_ACTION_TYPES.REQUEST,
    unassignSupervisor
  )
}
export default supervisorSaga
