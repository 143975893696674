
import { put, call, takeEvery } from 'redux-saga/effects'
import { COMMON_ACTION_TYPES, DASHBOARD_ACTION_TYPES } from '../../utilities/constants'
import { dashboardService } from '../../services'
import { DashboardEmpCountParamsDto } from '../../utilities/models'

function * employeeWiseDetails (action: { type: string }) {
  try {
    // @ts-ignore
    const empWiseData = yield call(dashboardService.getEmployeeWiseDetails)
    yield put({
      type:
      DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: empWiseData.data.data
      // data: CLIENT_COUNT_LIST

    })
  } catch (error) {
    yield put({
      type: DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * clientWiseDetails (action: { type: string }) {
  try {
    // @ts-ignore
    const clientWiseData = yield call(dashboardService.getClientWiseDetails)
    yield put({
      type:
      DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: clientWiseData.data.data
      // data: CLIENT_COUNT_LIST

    })
  } catch (error) {
    yield put({
      type: DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}

function * trackWiseDetails (action: { type: string, params: DashboardEmpCountParamsDto }) {
  try {
    // @ts-ignore
    const trackwiseData = yield call(dashboardService.getTrackWiseDetails, action.params)
    yield put({
      type:
      DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: trackwiseData.data.data
      // data: TRACK_COUNT_LIST
    })
  } catch (error) {
    yield put({
      type: DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * tierWiseDetails (action: { type: string, params: DashboardEmpCountParamsDto }) {
  try {
    // @ts-ignore
    const tierwiseData = yield call(dashboardService.getTierWiseDetails, action.params)
    yield put({
      type:
      DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.SUCCESS,
      data: tierwiseData.data.data

    })
  } catch (error) {
    yield put({
      type: DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.ERROR,
      error: error as string
    })
  }
}
function * dashboardSagas () {
  yield takeEvery(
    DASHBOARD_ACTION_TYPES.GET_TRACK_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    trackWiseDetails
  )
  yield takeEvery(
    DASHBOARD_ACTION_TYPES.GET_EMP_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    employeeWiseDetails
  )
  yield takeEvery(
    DASHBOARD_ACTION_TYPES.GET_TIER_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    tierWiseDetails
  )
  yield takeEvery(
    DASHBOARD_ACTION_TYPES.GET_CLIENT_WISE_DATA + COMMON_ACTION_TYPES.REQUEST,
    clientWiseDetails
  )
}
export default dashboardSagas
