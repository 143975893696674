import { Autocomplete, Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material'
import React from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from '../../../assets/theme/theme'
import { ClientEntryFormDto, InitialSbuDto } from '../../../utilities/models'
import styles from './ClientEditPopup.module.scss'

const ClientEditPopup: React.FC<{
  isEditDialogOpen: boolean;
  title: string;
  isEditingRow: any;
  sbuList: Array<InitialSbuDto>;
  clientFormData: ClientEntryFormDto;
  isShowHelperText: boolean;
  clientEditClick(): void;
  onEditHandleChange(property: string, value: any): void;
  updateClient(): void;
  handleInputFocus(property: string): void;
}> = (props) => (
  <BootstrapDialog
    className={styles.dialogCard}
    aria-labelledby="customized-dialog-title"
    open={props.isEditDialogOpen}
  >
    <BootstrapDialogTitle id="customized-dialog-title"
      onClose={() => props.clientEditClick()}
    >
      {props.title}

    </BootstrapDialogTitle>
    <DialogContent dividers className='dialogContainer'>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <TextField
            value={props.isEditingRow.clientName.value}
            label="Client Name"
            color='primary'
            size="small"
            fullWidth
            onFocus={() => props.handleInputFocus('clientName')}
            helperText={props.isShowHelperText && props.isEditingRow.clientName.error}
            error={!!props.isEditingRow.clientName.error}
            required={props.isEditingRow.clientName.isRequired}
            disabled={props.isEditingRow.clientName.disable}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onEditHandleChange('clientName', event.target.value)} />
        </Grid>
        <Grid item lg={12}>
          <Autocomplete
             value = {props.isEditingRow.strategicBusinessUnit.value}
            disableClearable
            fullWidth
            size="small"
            options={props.sbuList.map((sbu) => {
              return { name: sbu.sbuName, id: sbu.sbuId }
            })}
            isOptionEqualToValue={(option, value) =>
              option.name === value.name
            }
            getOptionLabel={(option) => option.name || ''}
            onChange={(event, value) => props.onEditHandleChange('strategicBusinessUnit', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Strategic Business Unit"
                color='primary'
                 onFocus={() => props.handleInputFocus('strategicBusinessUnit')}
                helperText={props.isShowHelperText && props.isEditingRow.strategicBusinessUnit.error}
                error={!!props.isEditingRow.strategicBusinessUnit.error}
                required={props.isEditingRow.strategicBusinessUnit.isRequired}
                 />
            )} />
        </Grid>
        <Grid item lg={12}>
          <TextField
            value={props.isEditingRow.clientDescription.value}
            label="Client Description"
            color='primary'
            size="small"
            fullWidth
            multiline
            rows={4}
            error={!!props.isEditingRow.clientDescription.error}
            required={props.isEditingRow.clientDescription.isRequired}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onEditHandleChange('clientDescription', event.target.value)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.updateClient()}
        autoFocus variant="contained"
        className={'btnStyle'}
      >
        Update Client
      </Button>
    </DialogActions>
  </BootstrapDialog>

)

export default ClientEditPopup
