import { Alert, Button, Divider, Grid, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import AppLayout from '../../templates/AppLayout/AppLayout'
import { ALLOCATION_TYPES, APP_CONFIGS, APP_FEATURE_KEYS, APP_TABLE_CONFIGS, BREAD_CRUB, DATE_FORMAT } from '../../utilities/constants'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, clientAction, commentsAction, designationAction, employeeAction, sbuAction, userActions, resignationAction } from '../../redux/actions'
import {
  AlertDto, AllClientHierachyDto, AllocateEmployeeDto, AllocateEmployeeParams, AllocationCommentFormDto,
  AllocationCommentParamsDto, AllocationDto, AllocationTypeDto, AppStateDto, AuthorizedUser, DeallocateEmployeeParams,
  EmployeeDto, EmployeeEntryFormDto, EmployeeFilterFormDto, GetClientHierachyParamDto, GetDisabledSbusParamDto, GetEmployeeFilterListParams,
  GetUserListBriefParamsDto,
  InitBriefEmployeeDto, InitBriefProjectDto, InitBriefProjectRoleDto, InitEmployeeDto, InitialClientDto,
  InitProjectRoleDto, ResignationCommentFormDto, ResignationCommentParamsDto, ResignationDetailsParamDto,
  ResingationDetailsDto, sbuBriefDto, UpdateEmployeeAllocationDto, UpdateEmployeeAllocationParams, UserClientHierarchyDto,
  UserClientHierarchyTeamsDto, UserClientHierarchyUserDto
} from '../../utilities/models'
import styles from './EmployeeManagement.module.scss'
import moment from 'moment'
import {
  AllocationCommentPopup, EmployeeAllocationEntryForm,
  EmployeeAllocationEditPopup, EmployeeAllocationFilterForm,
  EmployeeDeallocationPopup, EmployeeViewTable, OverAllocationPopup,
  AppAuthorizer, AppTablePagination, ResignationDetailsPopup
}
  from '../../components'
import { validateFormData } from '../../utilities/helpers/FormValidator'
const EmployeeManagement: React.FC = () => {
  const INITIAL_STATE: EmployeeEntryFormDto = {
    employeeName: { value: {} as InitBriefEmployeeDto, validator: 'object', isRequired: true, error: null, disable: false },
    sbu: { value: {} as sbuBriefDto, validator: 'object', isRequired: false, error: null, disable: false },
    clientName: { value: {} as InitialClientDto, validator: 'object', isRequired: false, error: null, disable: false },
    projectName: { value: {} as InitBriefProjectDto, validator: 'object', isRequired: true, error: null, disable: false },
    projectRoleName: { value: {} as InitBriefProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
    startDate: { value: new Date() || null, validator: 'date', isRequired: true, error: null, disable: false },
    endDate: { value: null, validator: 'date', isRequired: true, error: null, disable: false },
    allocationPercentage: { value: 100, validator: 'number', isRequired: true, error: null, disable: false },
    isBillable: { value: 'Billable', validator: 'string', isRequired: false, error: null, disable: false },
    billingStartDate: { value: new Date() || null, validator: 'date', isRequired: true, error: null, disable: false },
    billingEndDate: { value: null, validator: 'date', isRequired: true, error: null, disable: false }
  }
  const INITIAL_FILTER_STATE: EmployeeFilterFormDto = {
    sbu: { value: {} as sbuBriefDto, validator: 'object', isRequired: true, error: null, disable: false },
    employeeName: { value: {} as InitBriefEmployeeDto, validator: 'object', isRequired: true, error: null, disable: false },
    clientName: { value: {} as InitialClientDto, validator: 'object', isRequired: true, error: null, disable: false },
    projectName: { value: {} as InitBriefProjectDto, validator: 'object', isRequired: true, error: null, disable: false },
    projectRoleName: { value: {} as InitBriefProjectRoleDto, validator: 'object', isRequired: true, error: null, disable: false },
    isBillable: { value: '', validator: 'string', isRequired: true, error: null, disable: false },
    allocationType: { value: {} as AllocationTypeDto, validator: 'object', isRequired: true, error: null, disable: false },
    requestedDate: { value: null, validator: 'date', isRequired: false, error: null, disable: false }
  }
  // #region sample models
  const INITIAL_EMPLOYEE: EmployeeDto = {
    userId: -1,
    empId: '',
    userName: '',
    employeeName: '',
    isEnable: true,
    designation: '',
    designationDisplayName: '',
    isRequestResign: false,
    projectList: []
  }
  const INITIAL_ALLOCATE: AllocateEmployeeDto = {
    username: '',
    allocationPercentage: 0,
    isBillable: true,
    applicationKey: '',
    startData: new Date(),
    endDate: new Date(),
    teamId: -1,
    projectRoleId: -1
  }
  const INITIAL_PROJECT: AllocationDto = {
    projectId: -1,
    allocationId: -1,
    projectName: '',
    projectRoleId: -1,
    projectRoleName: '',
    projectRoleDisplayName: '',
    client: '',
    projectStartDate: new Date(),
    projectEndDate: new Date(),
    allocationPercentage: 0,
    billingStartDate: new Date(),
    billingEndDate: new Date(),
    isBillable: true,
    isConfirmed: false,
    isAllocate: true
  }

  const IINITIAL_UPDATE: UpdateEmployeeAllocationDto = {
    isBillable: true,
    startDate: moment(moment.now()).format(DATE_FORMAT),
    endDate: moment(moment.now()).format(DATE_FORMAT),
    billingStartDate: moment(moment.now()).format(DATE_FORMAT),
    billingEndDate: moment(moment.now()).format(DATE_FORMAT),
    allocationPercentage: 0,
    projectRoleId: -1,
    projectId: -1
  }
  // #endregion Sample Data
  const dispatch = useDispatch()
  const employeeAllocationList = useSelector(
    (state: any) => state.employee.employeeAllocationList.data
  )
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const authorizedUser: AuthorizedUser = useSelector((state: any) => state.auth.authorizedUser.data)
  const clientHierachy = useSelector((state: any) => state.client.clientHierachy.data)
  const clientHierachyIsLoading = useSelector((state: any) => state.client.clientHierachy.isLoading)
  const designationList = useSelector((state: any) => state.designation.designationList.data)
  const allocateEmployeeIsloading = useSelector((state: any) => state.employee.allocateEmployee.isLoading)
  const updateEmployeeIsloading = useSelector((state: any) => state.employee.updateEmployeeAllocation.isLoading)
  // const userList = useSelector((state: any) => state.user.userList.data)
  const userListBrief = useSelector((state: any) => state.user.userListBrief.data)
  const allocateEmployeeAlert: AlertDto = useSelector((state: any) => state.alerts.allocateEmployee)
  const deallocateEmployeeAlert: AlertDto = useSelector((state: any) => state.alerts.deallocateEmployee)
  const validateEmployeeAlert: AlertDto = useSelector((state: any) => state.alerts.validateEmployee)
  const updateEmployeeAllocationAlert: AlertDto = useSelector((state: any) => state.alerts.updateEmployeeAllocation)
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)

  const resignationDetails = useSelector((state: any) => state.resignation.resignationDetails.data)
  const [resignationList, setResignationList] = useState<ResingationDetailsDto[]>([])
  const resignationDetailsIsloading = useSelector((state: any) => state.resignation.resignationDetails.isLoading)
  const addResignationCommentAlert: AlertDto = useSelector((state: any) => state.alerts.addResignationComment)
  const addResignationCommentIsloading = useSelector((state: any) => state.resignation.addComment.isLoading)
  const INITIAL_STATE_RESIGNATION_COMMENT_FORM: ResignationCommentFormDto = {
    resignationComment: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    }
  }
  const [resignationCommentFormData, setResignationCommentFormData] = useState<ResignationCommentFormDto>(INITIAL_STATE_RESIGNATION_COMMENT_FORM)
  const [resignationCommentText, setResignationCommentText] = useState('')

  // #region States
  const [isAdvancedFilter, setIsAdvancedFilter] = useState(false)
  const [createEmployeeFormData, setCreateEmployeeFormData] =
    useState(INITIAL_STATE)
  const [isShowHelperText, setIsShowHelperText] = useState(true)

  // const [allEntitledSbuList, setAllEntitledSbuList] = useState<InitSbuDto[]>([])
  const [briefEmployeeList, setBriefEmployeeList] = useState<InitEmployeeDto[]>([])
  const [briefProjectRoleList, setBriefProjectRoleList] = useState<InitProjectRoleDto[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeDto>(INITIAL_EMPLOYEE)
  const [selectedProject, setSelectedProject] = useState<AllocationDto>(INITIAL_PROJECT)
  const [isRowEditing, setIsRowEditing] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [updateAllocation, setUpdateAllocation] = useState<UpdateEmployeeAllocationDto>(IINITIAL_UPDATE)
  const [newAllocation, setNewAllocation] = useState<AllocateEmployeeDto>(INITIAL_ALLOCATE)
  const searchBillableList = ['Billable', 'Non-billable']
  const [readMore, setReadMore] = useState(false)

  const [isCheckedOverallocation, setIsCheckedOverallocation] = useState<boolean>(true)
  const [overAllocationDialogOpen, setOverAllocationDialogOpen] = useState<boolean>(false)
  const [clientHierarchyClientList, setClientHierarchyclientList] = useState<UserClientHierarchyDto[]>([])
  const [clientHierarchyUsersList, setClientHierarchyUsersList] = useState<UserClientHierarchyUserDto[]>([])
  const [clientHierarchyProjectList, setClientHierarchyProjectList] = useState<UserClientHierarchyTeamsDto[]>([])

  // Drawers, Dialogs
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [deallocateEmployeeDialogOpen, setDeallocateEmployeeDialogOpen] = useState<boolean>(false)
  const [updateAllocationDialogOpen, setUpdateAllocationDialogOpen] = useState<boolean>(false)

  const [isNewAllocation, setIsNewAllocation] = useState<boolean>(false)
  // const [isValidUpdateData, setIsValidUpdateData] = useState<boolean>(true)

  const [employeeAllocationDetail, setEmployeeAllocationDetail] = useState<AllocateEmployeeParams>({} as AllocateEmployeeParams)
  const [updateEmployeeAllocationDetail, setUpdateEmployeeAllocationDetail] = useState<UpdateEmployeeAllocationParams>({} as UpdateEmployeeAllocationParams)
  const [filterFormData, setFilterFormData] = useState(INITIAL_FILTER_STATE)
  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_EMP)

  const addCommentAlert: AlertDto = useSelector((state: any) => state.alerts.addComment)
  const addCommentIsloading = useSelector((state: any) => state.comments.addComment.isLoading)
  const [allocationCommentPopupIsOpen, setAllocationCommentPopupIsOpen] = useState<boolean>(false)
  const [resignationDetailsPopupIsOpen, setResignationDetailsPopupIsOpen] = useState<boolean>(false)

  // #endregion State
  const INITIAL_STATE_COMMENT_FORM: AllocationCommentFormDto = {
    comment: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    }
  }
  const [allocationCommentFormData, setAllocationCommentFormData] = useState<AllocationCommentFormDto>(INITIAL_STATE_COMMENT_FORM)
  const [commentText, setCommentText] = useState('')
  const commentsList = useSelector((state: any) => state.comments.allComments.data.data)
  const commentsListIsLoading = useSelector((state: any) => state.comments.allComments.isLoading)
  // #region Effects
  useEffect(() => {
    dispatch(designationAction.getDesignationList())
    getClientHierachy()
    getAllUserList()
    getSBUList()
    const savedObj = sessionStorage.getItem('savedFilterFormData')
    if (savedObj == null) {
      getEmployeeAllocationList()
    } else {
      const getFilterFormData = JSON.parse(sessionStorage.savedFilterFormData)
      if (Object.keys(getFilterFormData.allocationType.value).length > 0 ||
      (moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) !== 'Invalid date' &&
        moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) !== null)
      ) {
        setIsAdvancedFilter(true)
      }
      setFilterFormData({
        ...filterFormData,
        sbu: {
          ...filterFormData['sbu' as keyof typeof filterFormData],
          value: getFilterFormData.sbu.value
        },
        clientName: {
          ...filterFormData['clientName' as keyof typeof filterFormData],
          value: getFilterFormData.clientName.value
        },
        projectName: {
          ...filterFormData['projectName' as keyof typeof filterFormData],
          value: getFilterFormData.projectName.value,
          error: null
        },
        employeeName: {
          ...filterFormData['employeeName' as keyof typeof filterFormData],
          value: getFilterFormData.employeeName.value,
          error: null
        },
        isBillable: {
          ...filterFormData['isBillable' as keyof typeof filterFormData],
          value: getFilterFormData.isBillable.value,
          error: null
        },
        projectRoleName: {
          ...filterFormData['projectRoleName' as keyof typeof filterFormData],
          value: getFilterFormData.projectRoleName.value,
          error: null
        },
        allocationType: {
          ...filterFormData['allocationType' as keyof typeof filterFormData],
          value: getFilterFormData.allocationType.value,
          error: null
        },
        requestedDate: {
          ...filterFormData['requestedDate' as keyof typeof filterFormData],
          value: getFilterFormData.requestedDate.value,
          error: null
        }
      })
      const FilterAllocationParams: GetEmployeeFilterListParams = {
        username: getFilterFormData.employeeName.value.username,
        sbuId: getFilterFormData.sbu.value.id,
        projectId: getFilterFormData.projectName.value.id,
        clientId: getFilterFormData.clientName.value.id,
        projectRoleId: getFilterFormData.projectRoleName.value.id,
        isBillable:
          (getFilterFormData.isBillable.value === 'Billable' ? true : null) ||
          (getFilterFormData.isBillable.value === 'Non-billable' ? false : null),
        type: getFilterFormData.allocationType.value.name,
        requestedDate: moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) !== 'Invalid date' ? moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) : undefined,
        getOnAndBefore: (moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) !== 'Invalid date' &&
          moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) !== null)
          ? true
          : undefined
      }
      if (getFilterFormData.requestedDate.value !== null && moment(getFilterFormData.requestedDate.value).format(DATE_FORMAT) === 'Invalid date') {
        const alert: AlertDto = {
          message: 'Please enter correct Allocation end date',
          severity: 'error'

        }
        dispatch(alertActions.setValidateEmployeeAlertRequest(alert))
      } else {
        dispatch(employeeAction.getFilteredList(FilterAllocationParams))
      }
    }
  }, [])
  React.useEffect(() => {
    if (employeeAllocationList.data?.length <= 10) {
      setPage(0)
    }
  }, [employeeAllocationList])
  React.useEffect(() => {
    // devide  client , project, users from client hierachy api
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    setClientHierarchyUsersList(filterByClientBy.users)
    setBriefProjectRoleList(designationList?.data)
    setBriefEmployeeList(userListBrief?.data)
  }, [userListBrief, designationList, clientHierachy])
  React.useEffect(() => {
    setResignationList(resignationDetails.data)
  }, [resignationDetails])
  React.useEffect(() => {
    if (allocateEmployeeAlert.severity === 'success') {
      if (allocateEmployeeAlert.message === 'over allocate notify') {
        setOverAllocationDialogOpen(true)
        setIsCheckedOverallocation(false)
      } else {
        getClientHierachy()
        setIsNewAllocation(false)
        setDrawerOpen(false)
        // if (drawerOpen) {
        //   toggleDrawer(selectedEmployee)
        // }
        setCreateEmployeeFormData({
          ...createEmployeeFormData,
          employeeName: {
            ...createEmployeeFormData[
            'employeeName' as keyof typeof createEmployeeFormData
            ],
            value: {} as InitBriefEmployeeDto,
            error: null,
            isRequired: true,
            disable: false,
            validator: 'object'
          },
          sbu: {
            ...createEmployeeFormData[
            'sbu' as keyof typeof createEmployeeFormData
            ],
            value: {} as sbuBriefDto,
            error: null,
            isRequired: false,
            disable: false,
            validator: 'object'
          },
          clientName: {
            ...createEmployeeFormData[
            'clientName' as keyof typeof createEmployeeFormData
            ],
            value: {} as InitialClientDto,
            error: null,
            isRequired: false,
            disable: false,
            validator: 'object'
          },
          projectName: {
            ...createEmployeeFormData[
            'projectName' as keyof typeof createEmployeeFormData
            ],
            value: {} as InitBriefProjectDto,
            validator: 'object',
            isRequired: true,
            error: null,
            disable: false
          },
          projectRoleName: {
            ...createEmployeeFormData[
            'projectRoleName' as keyof typeof createEmployeeFormData
            ],
            value: {} as InitBriefProjectRoleDto,
            validator: 'object',
            isRequired: true,
            error: null,
            disable: false
          },
          startDate: {
            ...createEmployeeFormData[
            'startDate' as keyof typeof createEmployeeFormData
            ],
            value: new Date() || null,
            isRequired: true,
            disable: false,
            error: null,
            validator: 'date'
          },
          endDate: {
            ...createEmployeeFormData[
            'endDate' as keyof typeof createEmployeeFormData
            ],
            value: null,
            isRequired: true,
            disable: false,
            error: null,
            validator: 'date'
          },
          allocationPercentage: {
            ...createEmployeeFormData[
            'allocationPercentage' as keyof typeof createEmployeeFormData
            ],
            value: 100,
            validator: 'number',
            isRequired: true,
            error: null,
            disable: false
          },
          isBillable: {
            ...createEmployeeFormData[
            'isBillable' as keyof typeof createEmployeeFormData
            ],
            value: 'Billable',
            validator: 'string',
            isRequired: true,
            error: null,
            disable: false
          },
          billingStartDate: {
            ...createEmployeeFormData[
            'billingStartDate' as keyof typeof createEmployeeFormData
            ],
            value: new Date() || null,
            isRequired: true,
            disable: false,
            error: null,
            validator: 'date'
          },
          billingEndDate: {
            ...createEmployeeFormData[
            'billingEndDate' as keyof typeof createEmployeeFormData
            ],
            value: null,
            isRequired: true,
            disable: false,
            error: null,
            validator: 'date'
          }
        })
        // setSelectedEmployee(INITIAL_EMPLOYEE)
        handleFilter()
        setEmployeeAllocationDetail({} as AllocateEmployeeParams)
        setUpdateEmployeeAllocationDetail({} as UpdateEmployeeAllocationParams)
      }
    }
  }, [allocateEmployeeAlert])
  React.useEffect(() => {
    if (deallocateEmployeeAlert.severity === 'success') {
      handleFilter()
    }
  }, [deallocateEmployeeAlert])
  React.useEffect(() => {
    if (updateEmployeeAllocationAlert.severity === 'success') {
      // dispatch(clientAction.getAllClientHierchy())
      if (updateEmployeeAllocationAlert.message === 'over allocate notify') {
        setOverAllocationDialogOpen(true)
        setIsCheckedOverallocation(false)
      } else {
        handleFilter()
        setEmployeeAllocationDetail({} as AllocateEmployeeParams)
        setUpdateEmployeeAllocationDetail({} as UpdateEmployeeAllocationParams)
      }
    }
  }, [updateEmployeeAllocationAlert])
  React.useEffect(() => {
    if (addCommentAlert.severity === 'success') {
      setAllocationCommentFormData({
        comment: {
          ...allocationCommentFormData['comment' as keyof typeof allocationCommentFormData],
          value: ''
        }
      })
      getAllComments()
    }
  }, [addCommentAlert])
  React.useEffect(() => {
    if (addResignationCommentAlert.severity === 'success') {
      setResignationCommentFormData({
        resignationComment: {
          ...resignationCommentFormData['resignationComment' as keyof typeof resignationCommentFormData],
          value: ''
        }
      })
      getResignationDetails()
    }
  }, [addResignationCommentAlert])
  const clearAddCommentAlert = () => {
    dispatch(alertActions.clearAddCommentAlertRequest())
  }
  const getClientHierachy = () => {
    const clientHeirachyParams: GetClientHierachyParamDto = {
      getAll: true
    }
    dispatch(clientAction.getAllClientHierchy(clientHeirachyParams))
  }
  const getAllUserList = () => {
    const userListBrief: GetUserListBriefParamsDto = {
      getAll: true

    }
    dispatch(userActions.getUserListBrief(userListBrief))
  }
  const addComment = async () => {
    if (commentText) {
      const allocationCommentParams: AllocationCommentParamsDto = {
        allocationId: selectedProject.allocationId,
        comment: commentText // allocationCommentFormData.comment.value
      }
      dispatch(commentsAction.addComment(allocationCommentParams))
    } else {
      setIsShowHelperText(false)
    }
  }
  const getAllComments = () => {
    const commentsListParams: AllocationCommentParamsDto = {
      allocationId: selectedProject.allocationId
    }
    dispatch(commentsAction.allComments(commentsListParams))
  }
  // const getAllUserList = () => {
  //   const userListParams: GetDisabledUsersParamDto = {
  //     getDisabledUsers: 'false'
  //   }
  //   dispatch(userActions.getUserList(userListParams))
  // }
  const getSBUList = () => {
    const sbuListParams: GetDisabledSbusParamDto = {
      // getDisabledSbus: 'false'
      getAll: true
    }
    dispatch(sbuAction.allSbuList(sbuListParams))
  }

  const filterByClientHirachy = (): AllClientHierachyDto => {
    const clientHierachyObject = {} as AllClientHierachyDto
    // const entiledSbuList: InitSbuDto[] = []
    const projectList: UserClientHierarchyTeamsDto[] = []
    const userList: UserClientHierarchyUserDto[] = []
    if (clientHierachy?.length > 0) {
      for (const client of clientHierachy) {
        // allSbuList?.data?.map((sbu: InitSbuDto) => sbu.sbuId === client.sbuId &&
        // !entiledSbuList.some((x) => x.sbuId === sbu.sbuId) &&
        // entiledSbuList.push(sbu)
        // )
        if (client.teams && client.teams.length > 0) {
          for (const team of client.teams) {
            const isIncluded = projectList.some((i) =>
              i.teamId === team.teamId
            )
            if (!isIncluded && team.teamId !== null) {
              projectList.push({
                teamId: team.teamId,
                teamName: team.teamName,
                users: team.users
              })
            }
            if (team.users && team.users.length) {
              for (const user of team.users) {
                const isIncluded = userList.some((i) =>
                  i.userId === user.userId
                )
                if (!isIncluded && user.userId !== null) {
                  userList.push({
                    userId: user.userId,
                    username: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName
                  })
                }
              }
            }
          }
        }
      }
    }
    // clientHierachyObject.sbus = entiledSbuList
    clientHierachyObject.teams = projectList
    clientHierachyObject.users = userList
    return clientHierachyObject
  }
  const isAdvancedFilterOnclick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsAdvancedFilter(true)
    } else {
      setIsAdvancedFilter(false)
      setFilterFormData({
        ...filterFormData,
        allocationType: {
          ...filterFormData['allocationType' as keyof typeof filterFormData],
          value: {} as AllocationTypeDto
        },
        requestedDate: {
          ...filterFormData['requestedDate' as keyof typeof filterFormData],
          value: null,
          error: null
        }
      })
      // handleFilterClear()
    }
  }

  const handleChangeFilterForm = (property: string, value: any) => {
    switch (property) {
      case 'sbu':
        if (value) {
          const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === value.id)
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          const userList: UserClientHierarchyUserDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some((i) =>
                    i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                  if (team.users && team.users.length) {
                    for (const user of team.users) {
                      const isIncluded = userList.some((i) =>
                        i.userId === user.userId
                      )
                      if (!isIncluded && user.userId !== null) {
                        userList.push({
                          userId: user.userId,
                          username: user.username,
                          firstName: user.firstName,
                          lastName: user.lastName
                        })
                      }
                    }
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          setClientHierarchyUsersList(userList)
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: value
            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as InitialClientDto
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as InitBriefProjectDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            sbu: {
              ...filterFormData['sbu' as keyof typeof filterFormData],
              value: {} as sbuBriefDto,
              error: null

            },
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as InitialClientDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as InitBriefProjectDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          setClientHierarchyUsersList(filterByClientBy.users)
        }
        break
      case 'clientName':
        if (value) {
          setClientHierarchyProjectList(value.teams)
          const userList: UserClientHierarchyUserDto[] = []
          if (value.teams.length > 0) {
            for (const team of value.teams) {
              if (team.users && team.users.length) {
                for (const user of team.users) {
                  const isIncluded = userList.some((i) =>
                    i.userId === user.userId
                  )
                  if (!isIncluded && user.userId !== null) {
                    userList.push({
                      userId: user.userId,
                      username: user.username,
                      firstName: user.firstName,
                      lastName: user.lastName
                    })
                  }
                }
              }
            }
          }
          setClientHierarchyUsersList(userList)

          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: value
            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as InitBriefProjectDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            clientName: {
              ...filterFormData['clientName' as keyof typeof filterFormData],
              value: {} as InitialClientDto,
              error: null

            },
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as InitBriefProjectDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
          if (Object.keys(filterFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === filterFormData.sbu.value.id)
            setClientHierarchyclientList(filteredClientList)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
            setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      case 'projectName':
        if (value) {
          setClientHierarchyUsersList(value.users)
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: value
            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
        } else {
          setFilterFormData({
            ...filterFormData,
            projectName: {
              ...filterFormData['projectName' as keyof typeof filterFormData],
              value: {} as InitBriefProjectDto,
              error: null

            },
            employeeName: {
              ...filterFormData['employeeName' as keyof typeof filterFormData],
              value: {} as InitBriefEmployeeDto,
              error: null

            }
          })
          if (Object.keys(filterFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === filterFormData.clientName.value.id)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyUsersList(filterByClientBy.users)
          }
        }
        break
      default:
        if (value) {
          setFilterFormData({
            ...filterFormData,
            [property]: {
              ...filterFormData[property as keyof typeof filterFormData],
              value: value
            }
          })
        } else {
          if (property === 'employeeName') {
            setFilterFormData({
              ...filterFormData,
              employeeName: {
                ...filterFormData['employeeName' as keyof typeof filterFormData],
                value: {} as InitBriefEmployeeDto,
                error: null

              }
            })
          } else if (property === 'projectName') {
            setFilterFormData({
              ...filterFormData,
              projectName: {
                ...filterFormData['projectName' as keyof typeof filterFormData],
                value: {} as InitBriefProjectDto,
                error: null

              }
            })
          } else if (property === 'projectRoleName') {
            setFilterFormData({
              ...filterFormData,
              projectRoleName: {
                ...filterFormData['projectRoleName' as keyof typeof filterFormData],
                value: {} as InitBriefProjectRoleDto,
                error: null

              }
            })
          } else if (property === 'isBillable') {
            setFilterFormData({
              ...filterFormData,
              isBillable: {
                ...filterFormData['isBillable' as keyof typeof filterFormData],
                value: ''

              }
            })
          } else if (property === 'clientName') {
            setFilterFormData({
              ...filterFormData,
              clientName: {
                ...filterFormData['clientName' as keyof typeof filterFormData],
                value: {} as InitialClientDto

              }
            })
            setFilterFormData({
              ...filterFormData,
              employeeName: {
                ...filterFormData['employeeName' as keyof typeof filterFormData],
                value: {} as InitBriefEmployeeDto,
                error: null

              }
            })
          } else if (property === 'allocationType') {
            setFilterFormData({
              ...filterFormData,
              allocationType: {
                ...filterFormData['allocationType' as keyof typeof filterFormData],
                value: {} as AllocationTypeDto

              }
            })
          } else if (property === 'requestedDate') {
            setFilterFormData({
              ...filterFormData,
              requestedDate: {
                ...filterFormData['requestedDate' as keyof typeof filterFormData],
                value: null,
                error: null

              }
            })
          }
        }
    }
  }

  const createEmployee = async () => {
    setOverAllocationDialogOpen(false)
    let dateTag = true
    // if new allocation
    if (isNewAllocation === true) {
      // when non billable
      if (createEmployeeFormData.isBillable.value !== 'Billable') {
        delete createEmployeeFormData.billingStartDate
        delete createEmployeeFormData.billingEndDate
        const [validateData, isValid] = await validateFormData(
          createEmployeeFormData
        )
        setCreateEmployeeFormData(validateData)
        if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
        ) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        }
        if (isValid && dateTag) {
          const employeeCreateParams: AllocateEmployeeParams = {
            isCheck: isCheckedOverallocation,
            username: createEmployeeFormData.employeeName?.value.username,
            userEntitilementId: createEmployeeFormData.projectName.value.id,
            projectRoleId: createEmployeeFormData.projectRoleName.value.id,
            startDate: moment(createEmployeeFormData.startDate.value).format(
              DATE_FORMAT
            ),
            endDate: moment(createEmployeeFormData.endDate.value).format(
              DATE_FORMAT
            ),
            allocationPercentage:
              createEmployeeFormData.allocationPercentage.value,
            isBillable: createEmployeeFormData.isBillable.value === 'Billable',
            applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
          }
          setEmployeeAllocationDetail(employeeCreateParams)
          dispatch(employeeAction.allocateEmployee(employeeCreateParams))
          setIsCheckedOverallocation(true)
        }
      } else {
        // when billable
        const [validateData, isValid] = await validateFormData(
          createEmployeeFormData
        )
        setCreateEmployeeFormData(validateData)
        // check start date and end date( when user type)
        if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT))) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing end date should be greater than billing start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
        ) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT))) {
          setCreateEmployeeFormData({
            ...validateData,
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing end date should be greater than billing start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) &&
            ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
              moment(createEmployeeFormData.endDate?.value).format(DATE_FORMAT))
            )
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingStartDate?.value,
              error: 'Billing Start date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing End date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) ||
            (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) <
              moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT))
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingStartDate?.value,
              error: 'Billing Start date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing End date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        }

        // if isvalid=true and date validation=true
        if (isValid && dateTag) {
          const employeeCreateParams: AllocateEmployeeParams = {
            isCheck: isCheckedOverallocation,
            username: createEmployeeFormData.employeeName?.value.username,
            userEntitilementId: createEmployeeFormData.projectName.value.id,
            projectRoleId: createEmployeeFormData.projectRoleName.value.id,
            billingStartDate: moment(createEmployeeFormData?.billingStartDate?.value).format(
              DATE_FORMAT
            ),
            billingEndDate: moment(createEmployeeFormData?.billingEndDate?.value).format(DATE_FORMAT),
            startDate: moment(createEmployeeFormData.startDate.value).format(
              DATE_FORMAT
            ),
            endDate: moment(createEmployeeFormData.endDate.value).format(
              DATE_FORMAT
            ),
            allocationPercentage:
              createEmployeeFormData.allocationPercentage.value,
            isBillable: createEmployeeFormData.isBillable.value === 'Billable',
            applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
          }
          setEmployeeAllocationDetail(employeeCreateParams)
          dispatch(employeeAction.allocateEmployee(employeeCreateParams))
          setIsCheckedOverallocation(true)
          // setIsNewAllocation(false)
          // toggleDrawer()
        }
      }
    } else {
      if (createEmployeeFormData.isBillable.value !== 'Billable') {
        delete createEmployeeFormData.billingStartDate
        delete createEmployeeFormData.billingEndDate
        const [validateData, isValid] = await validateFormData(
          createEmployeeFormData
        )
        setCreateEmployeeFormData(validateData)
        // check start date and end date( when user type)
        if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
        ) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        }

        if (isValid && dateTag) {
          const employeeCreateParams: AllocateEmployeeParams = {
            isCheck: isCheckedOverallocation,
            username: selectedEmployee.userName,
            userEntitilementId: createEmployeeFormData.projectName.value.id,
            projectRoleId: createEmployeeFormData.projectRoleName.value.id,
            startDate: moment(createEmployeeFormData.startDate.value).format(
              DATE_FORMAT
            ),
            endDate: moment(createEmployeeFormData.endDate.value).format(
              DATE_FORMAT
            ),
            allocationPercentage:
              createEmployeeFormData.allocationPercentage.value,
            isBillable: createEmployeeFormData.isBillable.value === 'Billable',
            applicationKey: APP_CONFIGS.HERA_APPLICATION_ID
          }
          setEmployeeAllocationDetail(employeeCreateParams)
          dispatch(employeeAction.allocateEmployee(employeeCreateParams))
          setIsCheckedOverallocation(true)
          // setIsNewAllocation(false)
          // toggleDrawer()
        }
      } else {
        // when billable
        const [validateData, isValid] = await validateFormData(
          createEmployeeFormData
        )
        setCreateEmployeeFormData(validateData)
        // check start date and end date( when user type)
        if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT))) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing end date should be greater than billing start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
        ) {
          setCreateEmployeeFormData({
            ...validateData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: 'Allocation end date should be greater than start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT))) {
          setCreateEmployeeFormData({
            ...validateData,
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing end date should be greater than billing start date',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) &&
            ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
              moment(createEmployeeFormData.endDate?.value).format(DATE_FORMAT))
            )
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingStartDate?.value,
              error: 'Billing Start date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing End date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT)) ||
            (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) <
              moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT))
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingStartDate?.value,
              error: 'Billing Start date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        } else if ((moment(createEmployeeFormData.startDate.value).format(DATE_FORMAT) !== 'Invalid date') &&
          (moment(createEmployeeFormData.billingStartDate?.value).format(DATE_FORMAT) !== 'Invalid date') &&
          ((moment(createEmployeeFormData.billingEndDate?.value).format(DATE_FORMAT) >
            moment(createEmployeeFormData.endDate.value).format(DATE_FORMAT))
          )) {
          setCreateEmployeeFormData({
            ...validateData,
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.billingEndDate?.value,
              error: 'Billing End date should be exist in Allocation Start/End date range',
              isRequired: true,
              disable: false,
              validator: 'date'
            }
          })
          dateTag = false
        }

        if (isValid && dateTag) {
          const employeeCreateParams: AllocateEmployeeParams = {
            isCheck: isCheckedOverallocation,
            username: selectedEmployee.userName,
            userEntitilementId: createEmployeeFormData.projectName.value.id,
            projectRoleId: createEmployeeFormData.projectRoleName.value.id,
            startDate: moment(createEmployeeFormData.startDate.value).format(
              DATE_FORMAT
            ),
            endDate: moment(createEmployeeFormData.endDate.value).format(
              DATE_FORMAT
            ),
            billingStartDate: moment(createEmployeeFormData?.billingStartDate?.value).format(
              DATE_FORMAT
            ),
            billingEndDate: moment(createEmployeeFormData?.billingEndDate?.value).format(DATE_FORMAT),
            allocationPercentage:
              createEmployeeFormData.allocationPercentage.value,
            isBillable: createEmployeeFormData.isBillable.value === 'Billable',
            applicationKey: APP_CONFIGS.APPLICATION_ID
          }
          setEmployeeAllocationDetail(employeeCreateParams)
          dispatch(employeeAction.allocateEmployee(employeeCreateParams))
          setIsCheckedOverallocation(true)
          // setIsNewAllocation(false)
          // toggleDrawer()
        }
      }
    }
  }

  const overAllocationConfirmation = () => {
    if (isNewAllocation && employeeAllocationDetail && createEmployeeFormData.employeeName?.value.username === employeeAllocationDetail?.username) {
      employeeAllocationDetail.isCheck = false
      dispatch(employeeAction.allocateEmployee(employeeAllocationDetail))
      setIsCheckedOverallocation(true)
      setOverAllocationDialogOpen(false)
    } else if (!isNewAllocation && employeeAllocationDetail && selectedEmployee.userName === employeeAllocationDetail?.username) {
      employeeAllocationDetail.isCheck = false
      dispatch(employeeAction.allocateEmployee(employeeAllocationDetail))
      setIsCheckedOverallocation(true)
      setOverAllocationDialogOpen(false)
    } else if (!isNewAllocation && updateEmployeeAllocationDetail && updateEmployeeAllocationDetail.allocationId === selectedProject.allocationId) {
      updateEmployeeAllocationDetail.isCheck = false
      dispatch(employeeAction.updateEmployeeAllocation(updateEmployeeAllocationDetail))
      setIsCheckedOverallocation(true)
      setOverAllocationDialogOpen(false)
    }
  }

  const updateAllocationTrigger = () => {
    if (selectedProject.allocationId !== null) {
      const updateEmployeeParams: UpdateEmployeeAllocationParams = {
        userId: selectedEmployee.userId,
        allocationId: selectedProject.allocationId,
        projectRoleId: updateAllocation.projectRoleId,
        applicationKey: APP_CONFIGS.APPLICATION_ID,
        isBillable: updateAllocation.isBillable,
        startDate: updateAllocation.startDate,
        endDate: updateAllocation.endDate,
        billingStartDate: updateAllocation.isBillable === true ? updateAllocation.billingStartDate : null,
        billingEndDate: updateAllocation.isBillable === true ? updateAllocation.billingEndDate : null,
        allocationPercentage: updateAllocation.allocationPercentage,
        isCheck: isCheckedOverallocation
      }
      setUpdateEmployeeAllocationDetail(updateEmployeeParams)
      dispatch(employeeAction.updateEmployeeAllocation(updateEmployeeParams))
      toggleUpdateAllocationDialog()
    } else {
      const employeeCreateParams: AllocateEmployeeParams = {
        isCheck: isCheckedOverallocation,
        username: selectedEmployee.userName,
        userEntitilementId: updateAllocation.projectId,
        projectRoleId: updateAllocation.projectRoleId,
        billingStartDate: updateAllocation.isBillable === true
          ? moment(updateAllocation?.billingStartDate).format(
            DATE_FORMAT
          )
          : null,
        billingEndDate: updateAllocation.isBillable === true ? moment(updateAllocation?.billingEndDate).format(DATE_FORMAT) : null,
        startDate: moment(updateAllocation.startDate).format(
          DATE_FORMAT
        ),
        endDate: moment(updateAllocation.endDate).format(
          DATE_FORMAT
        ),
        allocationPercentage:
          updateAllocation.allocationPercentage,
        isBillable: updateAllocation.isBillable,
        applicationKey: APP_CONFIGS.APPLICATION_ID
      }
      setEmployeeAllocationDetail(employeeCreateParams)
      dispatch(employeeAction.allocateEmployee(employeeCreateParams))
      toggleUpdateAllocationDialog()
      setIsCheckedOverallocation(true)
    }
  }
  // custom alert
  const setCustomAlert = (message: string) => {
    const alert: AlertDto = {
      message: message,
      severity: 'error'

    }
    dispatch(alertActions.setValidateEmployeeAlertRequest(alert))
    toggleUpdateAllocationDialog()
  }
  const deallocationCustomAlert = (message: string) => {
    const alert: AlertDto = {
      message: message,
      severity: 'error'

    }
    dispatch(alertActions.setValidateEmployeeAlertRequest(alert))
  }
  const handleUpdateEmployeeAllocation = async () => {
    // handleOverAllocationWithUpdateEmployee()
    if (!updateAllocation.startDate || !updateAllocation.endDate ||
      !updateAllocation.projectRoleId || (updateAllocation.allocationPercentage !== 0 && !updateAllocation.allocationPercentage)
    ) {
      setCustomAlert('All mandatory fields should be filled')
    } else if (updateAllocation.allocationPercentage > 100) {
      setCustomAlert('Allocation Percentage should be less than or equal 100')
    } else if (updateAllocation.startDate === 'Invalid date' ||
      updateAllocation.endDate === 'Invalid date') {
      setCustomAlert('Allocation start/end date should be valid format or should be filled')
    } else if (updateAllocation.isBillable === true &&
      (updateAllocation.billingStartDate === 'Invalid date' ||
        updateAllocation.billingEndDate === 'Invalid date')) {
      setCustomAlert('Billing start/end date should be valid format or should be filled')
    } else if (updateAllocation.isBillable === true &&
      (!updateAllocation.billingEndDate || !updateAllocation.billingStartDate
      )

    ) {
      setCustomAlert('All mandatory fields should be filled')
    } else if (updateAllocation.isBillable === true &&
      (updateAllocation.billingStartDate !== 'Invalid date' ||
        updateAllocation.startDate !== 'Invalid date') &&
      (updateAllocation.startDate >
        updateAllocation.endDate)

    ) {
      setCustomAlert('Allocation end date should be greater than billing start date')
    } else if (updateAllocation.isBillable === true &&
      (updateAllocation.billingStartDate !== 'Invalid date' ||
        updateAllocation.startDate !== 'Invalid date') &&
      (updateAllocation.billingStartDate >
        updateAllocation.billingEndDate)

    ) {
      setCustomAlert('Billing end date should be greater than billing start date')
    } else if (moment(selectedProject.projectStartDate).format(DATE_FORMAT) !== updateAllocation.startDate &&
      moment(selectedProject.projectEndDate).format(DATE_FORMAT) !== updateAllocation.endDate
    ) {
      if (updateAllocation.startDate > updateAllocation.endDate) {
        setCustomAlert('Allocation end date should be greater than start date')
      } else if (
        updateAllocation.isBillable === true && (
          updateAllocation.billingStartDate < updateAllocation.startDate ||
          updateAllocation.billingEndDate > updateAllocation.endDate)

      ) {
        setCustomAlert('Billing Start/End date should be exist in Allocation Start/End date range')
      } else {
        updateAllocationTrigger()
      }
    } else if (updateAllocation.isBillable === true &&
      moment(selectedProject.projectStartDate).format(DATE_FORMAT) !==
      updateAllocation.startDate &&
      moment(selectedProject.billingStartDate).format(DATE_FORMAT) !==
      updateAllocation.billingStartDate &&
      updateAllocation.startDate < updateAllocation.billingStartDate) {
      setCustomAlert('Billing Start date should be exist in Allocation Start/End date range')
    } else if (updateAllocation.isBillable === true &&
      moment(selectedProject.billingEndDate).format(DATE_FORMAT) !==
      updateAllocation.billingEndDate &&
      moment(selectedProject.billingStartDate).format(DATE_FORMAT) !==
      updateAllocation.billingStartDate &&
      (updateAllocation.billingEndDate > moment(selectedProject.projectEndDate).format(DATE_FORMAT) ||
        updateAllocation.billingStartDate < moment(selectedProject.projectStartDate).format(DATE_FORMAT))

    ) {
      setCustomAlert('Billing Start date should be exist in Allocation Start/End date range')
    } else if (updateAllocation.isBillable === true &&
      moment(selectedProject.billingEndDate).format(DATE_FORMAT) !==
      updateAllocation.billingEndDate &&
      moment(selectedProject.projectEndDate).format(DATE_FORMAT) ===
      updateAllocation.endDate &&
      updateAllocation.billingEndDate > moment(selectedProject.projectEndDate).format(DATE_FORMAT)) {
      setCustomAlert('Billing End date should be exist in Allocation Start/End date range')
    } else if (moment(selectedProject.projectStartDate).format(DATE_FORMAT) !== updateAllocation.startDate) {
      if (updateAllocation.startDate > moment(selectedProject.projectEndDate).format(DATE_FORMAT)) {
        setCustomAlert('Allocation end date should be greater than start date')
      } else if (updateAllocation.isBillable === true &&
        updateAllocation.billingStartDate < updateAllocation.startDate
      ) {
        setCustomAlert('Billing Start date should be exist in Allocation Start/End date range')
      } else {
        updateAllocationTrigger()
      }
    } else if (moment(selectedProject.projectEndDate).format(DATE_FORMAT) !== updateAllocation.endDate) {
      if (moment(selectedProject.projectStartDate).format(DATE_FORMAT) > updateAllocation.endDate) {
        setCustomAlert('Allocation end date should be greater than start date')
      } else if (updateAllocation.isBillable === true &&
        updateAllocation.billingEndDate > updateAllocation.endDate
      ) {
        setCustomAlert('Billing End date should be exist in Allocation Start/End date range')
      } else {
        updateAllocationTrigger()
      }
    } else if (updateAllocation.isBillable === true &&
      moment(selectedProject.billingStartDate).format(DATE_FORMAT) !== updateAllocation.billingStartDate &&
      moment(selectedProject.billingEndDate).format(DATE_FORMAT) === updateAllocation.billingEndDate &&
      moment(selectedProject.projectStartDate).format(DATE_FORMAT) > updateAllocation.billingStartDate
    ) {
      setCustomAlert('Billing Start date should be exist in Allocation Start/End date range')
    } else {
      updateAllocationTrigger()
    }
  }

  const deallocateEmployee = async (data: DeallocateEmployeeParams) => {
    dispatch(employeeAction.deallocateEmployee(data))
    toggleDeallocateEmployeeDialog()
  }
  const compareWithCurrentDate = (data: any) => {
    let isExpired = false
    for (const project of data) {
      if (moment(project.projectEndDate).format(DATE_FORMAT) < moment(new Date()).format(DATE_FORMAT)) {
        isExpired = true
        break
      }
    }
    return isExpired
  }

  const onInputHandleChange = (property: string, value: any) => {
    setIsShowHelperText(true)
    switch (property) {
      case 'employeeName':
        if (value) {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            employeeName: {
              ...createEmployeeFormData[
              'employeeName' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'object',
              isRequired: true,
              disable: false

            },
            projectRoleName: {
              ...createEmployeeFormData[
              'projectRoleName' as keyof typeof createEmployeeFormData
              ],
              value: briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === value.id)?.designation)?.projectRoleId != null
                ? {
                  id: briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === value.id)?.designation)?.projectRoleId ?? null,
                  name: '[ ' + briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === value.id)?.designation)?.projectRoleDisplayName + ' ] ' +
                    briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === value.id)?.designation)?.projectRoleName ?? null
                } as InitBriefProjectRoleDto
                : {} as InitBriefProjectRoleDto,
              error: null,
              validator: 'object',
              isRequired: true,
              disable: false

            }
          })
        }
        break
      case 'sbu':
        if (value) {
          const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === value.id)
          setClientHierarchyclientList(filteredClientList)
          const projectList: UserClientHierarchyTeamsDto[] = []
          if (filteredClientList.length > 0) {
            for (const client of filteredClientList) {
              if (client.teams && client.teams.length > 0) {
                for (const team of client.teams) {
                  const isIncluded = projectList.some((i) =>
                    i.teamId === team.teamId
                  )
                  if (!isIncluded && team.teamId !== null) {
                    projectList.push({
                      teamId: team.teamId,
                      teamName: team.teamName,
                      users: team.users
                    })
                  }
                }
              }
            }
          }
          setClientHierarchyProjectList(projectList)
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            sbu: {
              ...createEmployeeFormData['sbu' as keyof typeof createEmployeeFormData],
              value: value,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'
            },
            clientName: {
              ...createEmployeeFormData['clientName' as keyof typeof createEmployeeFormData],
              value: {} as InitialClientDto,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'
            },
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: {} as InitBriefProjectDto,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'

            }
          })
        } else {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            sbu: {
              ...createEmployeeFormData['sbu' as keyof typeof createEmployeeFormData],
              value: {} as sbuBriefDto,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'

            },
            clientName: {
              ...createEmployeeFormData['clientName' as keyof typeof createEmployeeFormData],
              value: {} as InitialClientDto,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'

            },
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: {} as InitBriefProjectDto,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'

            }
          })
          setClientHierarchyclientList(clientHierachy)
          const filterByClientBy = filterByClientHirachy()
          setClientHierarchyProjectList(filterByClientBy.teams)
          setClientHierarchyUsersList(filterByClientBy.users)
        }
        break
      case 'clientName':
        if (value) {
          setClientHierarchyProjectList(value.teams)
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            clientName: {
              ...createEmployeeFormData['clientName' as keyof typeof createEmployeeFormData],
              value: value,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'
            },
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: {} as InitBriefProjectDto,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'

            }

          })
        } else {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            clientName: {
              ...createEmployeeFormData['clientName' as keyof typeof createEmployeeFormData],
              value: {} as InitialClientDto,
              error: null,
              isRequired: false,
              disable: false,
              validator: 'object'

            },
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: {} as InitBriefProjectDto,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'

            }
          })
          if (Object.keys(createEmployeeFormData.sbu.value).length > 0) {
            const filteredClientList = clientHierachy?.filter((client: UserClientHierarchyDto) => client.sbuId === createEmployeeFormData.sbu.value.id)
            const projectList: UserClientHierarchyTeamsDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
          }
        }
        break
      case 'projectName':
        if (value) {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: value,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'
            }
          })
        } else {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            projectName: {
              ...createEmployeeFormData['projectName' as keyof typeof createEmployeeFormData],
              value: {} as InitBriefProjectDto,
              error: null,
              isRequired: true,
              disable: false,
              validator: 'object'

            }
          })
          if (Object.keys(createEmployeeFormData.clientName.value).length > 0) {
            const filteredClientList = clientHierarchyClientList?.filter((client: UserClientHierarchyDto) => client.clientId === createEmployeeFormData.clientName.value.id)
            const projectList: UserClientHierarchyTeamsDto[] = []
            const userList: UserClientHierarchyUserDto[] = []
            if (filteredClientList.length > 0) {
              for (const client of filteredClientList) {
                if (client.teams && client.teams.length > 0) {
                  for (const team of client.teams) {
                    const isIncluded = projectList.some((i) =>
                      i.teamId === team.teamId
                    )
                    if (!isIncluded && team.teamId !== null) {
                      projectList.push({
                        teamId: team.teamId,
                        teamName: team.teamName,
                        users: team.users
                      })
                      if (team.users && team.users.length) {
                        for (const user of team.users) {
                          const isIncluded = userList.some(
                            (i) => i.userId === user.userId
                          )
                          if (!isIncluded && user.userId !== null) {
                            userList.push({
                              userId: user.userId,
                              username: user.username,
                              firstName: user.firstName,
                              lastName: user.lastName
                            })
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            setClientHierarchyProjectList(projectList)
            setClientHierarchyUsersList(userList)
          } else {
            const filterByClientBy = filterByClientHirachy()
            setClientHierarchyProjectList(filterByClientBy.teams)
          }
        }
        break
      case 'projectRoleName':
        if (value) {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            projectRoleName: {
              ...createEmployeeFormData[
              'projectRoleName' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'object',
              isRequired: true,
              disable: false

            }
          })
        }
        break
      case 'startDate':
        if (value) {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            startDate: {
              ...createEmployeeFormData[
              'startDate' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            },
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            }
          })
        }
        break
      case 'endDate':
        if (value) {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            endDate: {
              ...createEmployeeFormData[
              'endDate' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            }
          })
        }
        break
      case 'isBillable':
        if (value === 'Billable') {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            isBillable: {
              ...createEmployeeFormData[
              'isBillable' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'string',
              isRequired: true,
              disable: false

            },
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.startDate.value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: createEmployeeFormData.endDate.value,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            }
          })
        } else {
          setCreateEmployeeFormData({
            ...createEmployeeFormData,
            isBillable: {
              ...createEmployeeFormData[
              'isBillable' as keyof typeof createEmployeeFormData
              ],
              value: value,
              error: null,
              validator: 'string',
              isRequired: true,
              disable: false

            },
            billingStartDate: {
              ...createEmployeeFormData[
              'billingStartDate' as keyof typeof createEmployeeFormData
              ],
              value: null,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false
            },
            billingEndDate: {
              ...createEmployeeFormData[
              'billingEndDate' as keyof typeof createEmployeeFormData
              ],
              value: null,
              error: null,
              validator: 'date',
              isRequired: true,
              disable: false

            }
          })
        }
        break
      default:
        setCreateEmployeeFormData({
          ...createEmployeeFormData,
          [property]: {
            ...createEmployeeFormData[
            property as keyof typeof createEmployeeFormData
            ],
            value: value,
            error: null
          }
        })
    }
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setCreateEmployeeFormData({
      ...createEmployeeFormData,
      [property]: {
        ...createEmployeeFormData[
        property as keyof typeof createEmployeeFormData
        ],
        error: null
      }
    })
  }
  // get employee  list
  const getEmployeeAllocationList = () => {
    const FilterAllocationParams: GetEmployeeFilterListParams = {}
    dispatch(employeeAction.getFilteredList(FilterAllocationParams))
    // dispatch(employeeAction.getEmployeeAllocationList())
  }

  // #region pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilter = async () => {
    // setPage(0)
    setIsCollapsed(false)
    const FilterAllocationParams: GetEmployeeFilterListParams = {
      username: filterFormData.employeeName.value.username,
      sbuId: filterFormData.sbu.value.id,
      projectId: filterFormData.projectName.value.id,
      clientId: filterFormData.clientName.value.id,
      projectRoleId: filterFormData.projectRoleName.value.id,
      isBillable:
        (filterFormData.isBillable.value === 'Billable' ? true : null) ||
        (filterFormData.isBillable.value === 'Non-billable' ? false : null),
      type: filterFormData.allocationType.value.name,
      requestedDate: moment(filterFormData.requestedDate.value).format(DATE_FORMAT) !== 'Invalid date' ? moment(filterFormData.requestedDate.value).format(DATE_FORMAT) : undefined,
      getOnAndBefore: (moment(filterFormData.requestedDate.value).format(DATE_FORMAT) !== 'Invalid date' &&
        moment(filterFormData.requestedDate.value).format(DATE_FORMAT) !== null)
        ? true
        : undefined
    }
    if (filterFormData.requestedDate.value !== null && moment(filterFormData.requestedDate.value).format(DATE_FORMAT) === 'Invalid date') {
      const alert: AlertDto = {
        message: 'Please enter correct Requested date',
        severity: 'error'

      }
      dispatch(alertActions.setValidateEmployeeAlertRequest(alert))
    } else {
      dispatch(employeeAction.getFilteredList(FilterAllocationParams))
    }
  }
  const handleFilterClear = () => {
    sessionStorage.removeItem('savedFilterFormData')
    setIsCollapsed(false)
    setPage(0)
    setClientHierarchyclientList(clientHierachy)
    const filterByClientBy = filterByClientHirachy()
    setClientHierarchyProjectList(filterByClientBy.teams)
    setClientHierarchyUsersList(filterByClientBy.users)
    setFilterFormData(INITIAL_FILTER_STATE)
    getEmployeeAllocationList()
  }
  const handleUpdateData = (data: UpdateEmployeeAllocationDto) => {
    setUpdateAllocation(data)
  }
  const toggleDrawerClose = () => {
    setDrawerOpen(false)
    setCreateEmployeeFormData(INITIAL_STATE)
    setSelectedEmployee(INITIAL_EMPLOYEE)
  }
  const addAllocation = () => {
    setIsNewAllocation(false)
  }
  const toggleDrawer = (row: any) => {
    setDrawerOpen(!drawerOpen)
    setIsCollapsed(false)
    if (isNewAllocation !== true) {
      if (row.userId > -1) {
        setCreateEmployeeFormData({
          ...createEmployeeFormData,
          employeeName: {
            ...createEmployeeFormData[
            'employeeName' as keyof typeof createEmployeeFormData
            ],
            value: { id: row.userId, name: row.employeeName } as InitBriefProjectRoleDto,
            error: null,
            validator: 'object',
            isRequired: false,
            disable: false

          },
          projectRoleName: {
            ...createEmployeeFormData[
            'projectRoleName' as keyof typeof createEmployeeFormData
            ],
            value: briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === row.userId)?.designation)?.projectRoleId != null
              ? {
                id: briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === row.userId)?.designation)?.projectRoleId ?? null,
                name: '[ ' + briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === row.userId)?.designation)?.projectRoleDisplayName + ' ] ' +
                  briefProjectRoleList.find((role: any) => role.projectRoleName === briefEmployeeList.find((emp) => emp.userId === row.userId)?.designation)?.projectRoleName ?? null
              } as InitBriefProjectRoleDto
              : {} as InitBriefProjectRoleDto,
            error: null,
            validator: 'object',
            isRequired: true,
            disable: false

          }
        })
      }
    }
  }
  const employeeDataSession = () => {
    sessionStorage.setItem('savedFilterFormData', JSON.stringify(filterFormData))
  }
  const toggleDeallocateEmployeeDialog = () => {
    setDeallocateEmployeeDialogOpen(!deallocateEmployeeDialogOpen)
  }
  const toggleUpdateAllocationDialog = () => {
    setUpdateAllocationDialogOpen(!updateAllocationDialogOpen)
  }

  const cancelOverAllocationDialog = () => {
    toggleOverAllocationDialog()
    setIsCheckedOverallocation(true)
    setEmployeeAllocationDetail({} as AllocateEmployeeParams)
    setUpdateEmployeeAllocationDetail({} as UpdateEmployeeAllocationParams)
  }
  const toggleOverAllocationDialog = () => {
    if (!allocateEmployeeIsloading || !updateEmployeeIsloading) {
      setOverAllocationDialogOpen(!overAllocationDialogOpen)
    }
  }
  const onShowMoreText = () => {
    setReadMore(!readMore)
  }
  const clearAllocateEmployeeAlert = () => {
    dispatch(alertActions.clearAllocateEmployeeAlertRequest())
  }
  const clearDeallocateEmployeeAlert = () => {
    dispatch(alertActions.clearDeallocateEmployeeAlertRequest())
  }
  const clearUpdateEmployeeAllocationAlert = () => {
    dispatch(alertActions.clearUpdateEmployeeAllocationAlertRequest())
  }
  const toggleAllocationCommentPopup = () => {
    setAllocationCommentPopupIsOpen(!allocationCommentPopupIsOpen)
    setAllocationCommentFormData({
      comment: {
        ...allocationCommentFormData['comment' as keyof typeof allocationCommentFormData],
        value: ''
      }
    })
    if (allocationCommentPopupIsOpen) {
      setIsShowHelperText(true)
    }
  }
  const getResignationDetails = () => {
    const params: ResignationDetailsParamDto = {
      username: selectedEmployee.userName
    }
    dispatch(resignationAction.resignationDetails(params))
  }

  const toggleResignationDetailsPopup = () => {
    setResignationDetailsPopupIsOpen(!resignationDetailsPopupIsOpen)

    setResignationCommentFormData({
      resignationComment: {
        ...resignationCommentFormData['resignationComment' as keyof typeof resignationCommentFormData],
        value: ''
      }
    })

    if (resignationDetailsPopupIsOpen) {
      setIsShowHelperText(true)
      setSelectedEmployee(INITIAL_EMPLOYEE)
    }
  }
  const addResignationComment = async (resignationId: number) => {
    if (resignationCommentText) {
      const resignationCommentParams: ResignationCommentParamsDto = {
        comment: resignationCommentText
      }
      dispatch(resignationAction.addComment(resignationCommentParams, resignationId))
    } else {
      setIsShowHelperText(false)
    }
  }
  const clearAddResignationCommentAlert = () => {
    dispatch(alertActions.clearAddResignationCommentAlertRequest())
  }
  return (
    <React.Fragment>
      <AppLayout
        breadcrumb={BREAD_CRUB.EMPLOYEE_MANAGEMENT}
        componentTitle="Allocation Management"
      >
        <AllocationCommentPopup
          allocationCommentPopupIsOpen={allocationCommentPopupIsOpen}
          toggleAllocationCommentPopup={toggleAllocationCommentPopup}
          addComment={addComment}
          addCommentAlertMessage={addCommentAlert.message}
          clearAddCommentAlert={clearAddCommentAlert}
          allocationId={selectedProject.allocationId}
          commentsList={commentsList}
          isShowHelperText={isShowHelperText}
          // handleAllocationCommentInputFocus={handleAllocationCommentInputFocus}
          getAllComments={getAllComments}
          commentsListIsLoading={commentsListIsLoading}
          readMore={readMore}
          onShowMoreText={onShowMoreText}
          authorizedUser={authorizedUser}
          setIsShowHelperText={setIsShowHelperText}
          setCommentText={setCommentText}
          commentText={commentText}
          addCommentIsloading={addCommentIsloading}
          selectedEmployee={selectedEmployee}
          selectedProject={selectedProject}
        />
        <ResignationDetailsPopup
          resignationDetailsPopupIsOpen={resignationDetailsPopupIsOpen}
          toggleResignationDetailsPopup={toggleResignationDetailsPopup}
          employeeName={selectedEmployee.employeeName}
          userId={selectedEmployee.userId}
          resignationList={resignationList}
          getResignationDetails={getResignationDetails}
          resignationDetailsIsloading={resignationDetailsIsloading}
          readMore={readMore}
          onShowMoreText={onShowMoreText}
          authorizedUser={authorizedUser}
          addResignationComment={addResignationComment}
          isShowHelperText={isShowHelperText}
          setIsShowHelperText={setIsShowHelperText}
          addResignationCommentIsloading={addResignationCommentIsloading}
          addResignationCommentAlert={addResignationCommentAlert}
          setResignationCommentText={setResignationCommentText}
          resignationCommentText={resignationCommentText}
          clearAddResignationCommentAlert={clearAddResignationCommentAlert}
          resignationCommentFormData={resignationCommentFormData}
        />
        <OverAllocationPopup
          overAllocationDialogOpen={overAllocationDialogOpen}
          allocateEmployeeIsloading={allocateEmployeeIsloading}
          updateEmployeeIsloading={updateEmployeeIsloading}
          cancelOverAllocationDialog={cancelOverAllocationDialog}
          toggleOverAllocationDialog={toggleOverAllocationDialog}
          overAllocationConfirmation={overAllocationConfirmation}
        />

          <EmployeeAllocationEditPopup
            selectedEmployee={selectedEmployee}
            updateEmployeeIsloading={updateEmployeeIsloading}
            selectedProject={selectedProject}
            updateAllocation={updateAllocation}
            updateAllocationDialogOpen={updateAllocationDialogOpen}
            handleUpdateEmployeeAllocation={handleUpdateEmployeeAllocation}
            toggleUpdateAllocationDialog={toggleUpdateAllocationDialog}
          />
          <EmployeeDeallocationPopup
            selectedEmployee={selectedEmployee}
            selectedProject={selectedProject}
            deallocateEmployeeDialogOpen={deallocateEmployeeDialogOpen}
            deallocateEmployee={deallocateEmployee}
            toggleDeallocateEmployeeDialog={toggleDeallocateEmployeeDialog}
          />

        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_ALLOCATION]}
        >
          <EmployeeAllocationEntryForm
            allocateEmployeeIsloading={allocateEmployeeIsloading}
            createEmployeeFormData={createEmployeeFormData}
            isShowHelperText={isShowHelperText}
            selectedEmployee={selectedEmployee}
            employeeList={briefEmployeeList || []}
            clientHierarchyProjectList={clientHierarchyProjectList || []}
            designationList={briefProjectRoleList || []}
            sbuList={allSbuList?.data || []}
            clientHierachy={clientHierarchyClientList || []}
            isNewAllocation={isNewAllocation}
            newAllocation={newAllocation}
            drawerOpen={drawerOpen}
            toggleDrawerClose={toggleDrawerClose}
            onInputHandleChange={onInputHandleChange}
            createEmployee={createEmployee}
            setNewAllocation={setNewAllocation}
            setIsNewAllocation={setIsNewAllocation}
            toggleDrawer={toggleDrawer}
            handleInputFocus={handleInputFocus}
          />
        </AppAuthorizer>
        <Grid container direction={'row'} alignItems="center">
          <Grid item md={6}>
            <p
              style={{
                paddingBottom: 7,
                marginBottom: 10,
                margin: 0,
                fontWeight: 400,
                fontSize: '24px'
              }}
            >
              Allocation Management
            </p>
          </Grid>
          <Grid item md={6}>
            <Stack style={{ float: 'right' }} direction={'row'}>
              <AppAuthorizer
                activeRoleFeatures={activeUserRole.data.features}
                authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_ALLOCATION]}
              >
                <Button
                  className={styles.allocationBtnStyle}
                  variant="contained"
                  onClick={() => {
                    setIsNewAllocation(true)
                    toggleDrawer(INITIAL_EMPLOYEE)
                  }}
                >
                  New Allocation
                </Button>
              </AppAuthorizer>
            </Stack>
          </Grid>
        </Grid>
        <Divider className="dividerStyle" />
        {allocateEmployeeAlert.message && allocateEmployeeAlert.message !== 'over allocate notify' && (
          <div
            style={{
              position: 'fixed',
              width: '96%',
              left: '50px',
              alignContent: 'center',
              top: '2px',
              zIndex: 1002
            }}
          >
            <Alert
              onClose={clearAllocateEmployeeAlert}
              severity={allocateEmployeeAlert.severity}
            >
              {allocateEmployeeAlert.message}
            </Alert>
          </div>
        )}
        {deallocateEmployeeAlert.message && (
          <div
            style={{
              position: 'fixed',
              width: '96%',
              left: '50px',
              top: '2px',
              zIndex: 1002
            }}
          >
            <Alert
              onClose={clearDeallocateEmployeeAlert}
              severity={deallocateEmployeeAlert.severity}
            >
              {deallocateEmployeeAlert.message}
            </Alert>
          </div>
        )}
        {updateEmployeeAllocationAlert.message && updateEmployeeAllocationAlert.message !== 'over allocate notify' && (
          <div
            style={{
              position: 'fixed',
              width: '96%',
              left: '50px',
              top: '2px',
              zIndex: 1002
            }}
          >
            <Alert
              onClose={clearUpdateEmployeeAllocationAlert}
              severity={updateEmployeeAllocationAlert.severity}
            >
              {updateEmployeeAllocationAlert.message}
            </Alert>
          </div>
        )}
        {validateEmployeeAlert.message && (
          <div
            style={{
              position: 'fixed',
              width: '96%',
              left: '50px',
              top: '2px',
              right: '2px',
              zIndex: 1002
            }}
          >
            <Alert
              onClose={clearUpdateEmployeeAllocationAlert}
              severity={validateEmployeeAlert.severity}
            >
              {validateEmployeeAlert.message}
            </Alert>
          </div>
        )}
        <br />
        <Grid container spacing={2}>
          <EmployeeAllocationFilterForm
            handleChangeFilterForm={handleChangeFilterForm}
            isAdvancedFilterOnclick={isAdvancedFilterOnclick}
            handleFilterClear={handleFilterClear}
            handleFilter={handleFilter}
            sbuList={allSbuList?.data || []}
            clientHierachyIsLoading={clientHierachyIsLoading}
            clientHierarchyProjectList={clientHierarchyProjectList || []}
            searchBillableList={searchBillableList}
            clientHierarchyUsersList={clientHierarchyUsersList || []}
            filterFormData={filterFormData}
            clientHierachy={clientHierarchyClientList || []}
            isAdvancedFilter={isAdvancedFilter}
            briefProjectRoleList={briefProjectRoleList || []}
            allocatioTypes={ALLOCATION_TYPES || []}
          />
          <Grid item md={12}>
            <div className={`layout-row ${styles.divStyle}`} >
              <span className={styles.resignedStyle}></span>
              <span>Resignation requested</span>
            </div>

          </Grid>
          <AppAuthorizer
            activeRoleFeatures={activeUserRole.data.features}
            authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_ALLOCATION]}
          >
            <Grid item md={12}>
              <EmployeeViewTable
                page={page}
                rowsPerPage={rowsPerPage}
                employeeAllocationList={employeeAllocationList.data || []}
                briefProjectRoleList={briefProjectRoleList || []}
                selectedEmployee={selectedEmployee}
                selectedProject={selectedProject}
                isRowEditing={isRowEditing}
                isCollapsed={isCollapsed}
                clientHierachyIsLoading={clientHierachyIsLoading}
                addAllocation={addAllocation}
                compareWithCurrentDate={compareWithCurrentDate}
                setSelectedEmployee={setSelectedEmployee}
                setSelectedProject={setSelectedProject}
                setIsRowEditing={setIsRowEditing}
                setIsCollapsed={setIsCollapsed}
                toggleDrawer={toggleDrawer}
                toggleDeallocateEmployeeDialog={toggleDeallocateEmployeeDialog}
                toggleUpdateAllocationDialog={toggleUpdateAllocationDialog}
                handleUpdateData={handleUpdateData}
                employeeDataSession={employeeDataSession}
                toggleAallocationCommentPopup={toggleAllocationCommentPopup}
                toggleResignationDetailsPopup={toggleResignationDetailsPopup}
                deallocationCustomAlert={deallocationCustomAlert}
              >
                <AppTablePagination
                  data={employeeAllocationList.data || []}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </EmployeeViewTable>
            </Grid>
          </AppAuthorizer>
        </Grid>
      </AppLayout>
    </React.Fragment >
  )
}

export default EmployeeManagement
