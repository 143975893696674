import { Alert, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SbuEditPopup,
  SbuEntryForm,
  SbuViewTable,
  SbuStatusChangePopup,
  AppTablePagination
  , AppAuthorizer
} from '../../components'
import { alertActions, sbuAction } from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_FEATURE_KEYS, APP_TABLE_CONFIGS, BREAD_CRUB } from '../../utilities/constants'
import {
  CreateSbuParamDto,
  GetDisabledSbusParamDto,
  SbuDto,
  SbuEditEntryFormDto,
  SbuEntryFormDto,
  UpdateSbuParamDto
} from '../../utilities/models/sbuManagement.model'
import { AlertDto, AppStateDto, InitSbuDto } from '../../utilities/models'
import { validateFormData } from '../../utilities/helpers/FormValidator'

const SbuManagement: React.FC = () => {
  const INITIAL_STATE: SbuEntryFormDto = {
    sbuName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    sbuDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const INITIAL_SBU_STATUS_STATE = {
    sbuStatus: {
      value: {} as InitSbuDto
    }
  }
  const INITIAL_EDITING_ROW_STATE: SbuEditEntryFormDto = {
    sbuId: {
      value: -1,
      isRequired: true,
      error: null,
      disable: false
    },
    sbuName: {
      value: '',
      validator: 'text',
      isRequired: true,
      error: null,
      disable: false
    },
    sbuDescription: {
      value: '',
      validator: 'text',
      isRequired: false,
      error: null,
      disable: false
    },
    isEnabled: {
      value: true,
      isRequired: false,
      error: null,
      disable: false
    }
  }
  const dispatch = useDispatch()
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const allSbuList = useSelector((state: any) => state.sbu.allSbuList.data)
  const sbuLIsloading = useSelector(
    (state: any) => state.sbu.allSbuList.isLoading
  )

  const createSbuAlert: AlertDto = useSelector(
    (state: any) => state.alerts.createSbu
  )
  const updateSbuAlert: AlertDto = useSelector(
    (state: any) => state.alerts.updateSbu
  )

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE
  )
  const [sbuFormData, setSbuFormData] =
    useState<SbuEntryFormDto>(INITIAL_STATE)
  const [isShowHelperText, setIsShowHelperText] = useState(true)
  const [filteredRows, setFilteredRows] = useState<SbuDto[]>([])
  const [isEditingRow, setIsEditingRow] = useState(INITIAL_EDITING_ROW_STATE)
  const [isStatusChangeRow, setIsStatusChangeRow] = useState(
    INITIAL_SBU_STATUS_STATE
  )
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] =
    useState(false)
  const [searchText, setSearchText] = useState('')
  React.useEffect(() => {
    setFilteredRows(allSbuList?.data)
    if (searchText !== '') {
      searchSbus(searchText)
    }
  }, [allSbuList])
  React.useEffect(() => {
    getAllSbuList()
  }, [])
  React.useEffect(() => {
    if (createSbuAlert.severity === 'success') {
      setSbuFormData({
        ...sbuFormData,
        sbuName: {
          ...sbuFormData['sbuName' as keyof typeof sbuFormData],
          value: ''
        },
        sbuDescription: {
          ...sbuFormData['sbuDescription' as keyof typeof sbuFormData],
          value: ''
        }
      })
      getAllSbuList()
    }
  }, [createSbuAlert])
  React.useEffect(() => {
    if (updateSbuAlert.severity === 'success') {
      getAllSbuList()
    }
  }, [updateSbuAlert])

  const clearCreateSbuAlert = () => {
    dispatch(alertActions.clearCreateSbuAlertRequest())
  }
  const clearUpdateSbuAlert = () => {
    dispatch(alertActions.clearUpdateAlertRequest())
  }
  // get sbu list
  const getAllSbuList = () => {
    const sbuListParams: GetDisabledSbusParamDto = {
      getDisabled: 'true',
      getAll: true
    }
    dispatch(sbuAction.allSbuList(sbuListParams))
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setSbuFormData({
      ...sbuFormData,
      [property]: {
        ...sbuFormData[property as keyof typeof sbuFormData],
        error: null
      }
    })
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        error: null
      }
    })
  }
  // create sbu
  const createSbu = async () => {
    const [validateData, isValid] = await validateFormData(sbuFormData)
    setSbuFormData(validateData)
    if (isValid) {
      const sbuCreateParams: CreateSbuParamDto = {
        sbuName: sbuFormData.sbuName.value.trim(),
        sbuDesc: sbuFormData.sbuDescription.value
      }
      dispatch(sbuAction.createSbu(sbuCreateParams))
      getAllSbuList()
    }
  }
  // update sbu
  const updateSbu = async () => {
    const [validateData, isValid] = await validateFormData(isEditingRow)
    setIsEditingRow(validateData)
    if (isValid) {
      const sbuUpdateParams: UpdateSbuParamDto = {
        sbuId: isEditingRow.sbuId.value,
        sbuName: isEditingRow.sbuName.value.trim(),
        sbuDesc: isEditingRow.sbuDescription.value,
        isEnabled: isEditingRow.isEnabled.value
      }
      dispatch(sbuAction.updateSbu(sbuUpdateParams))
      setIsEditDialogOpen(false)
      getAllSbuList()
    }
  }

  // search sbus
  const searchSbus = (e: any) => {
    // if (page !== 0) {
    //   setPage(0)
    // }
    let keyword: any
    if (e.target !== undefined) {
      keyword = e.target.value
    } else {
      keyword = e
    }
    setSearchText(keyword)
    if (keyword !== '') {
      // const results = allSbuList?.data.filter((sbu: SbuDto) => {
      //   return sbu.sbuName.toLowerCase().includes(keyword.toLowerCase())
      // })
      const results = allSbuList?.data
        .filter((sbu: SbuDto) => {
          // Filter results by doing case insensitive match on name here
          return sbu.sbuName.toLowerCase().includes(keyword.toLowerCase())
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (a.sbuName.toLowerCase().indexOf(keyword.toLowerCase()) > b.sbuName.toLowerCase().indexOf(keyword.toLowerCase())) {
            return 1
          } else if (a.sbuName.toLowerCase().indexOf(keyword.toLowerCase()) < b.sbuName.toLowerCase().indexOf(keyword.toLowerCase())) {
            return -1
          } else {
            if (a.sbuName > b.sbuName) { return 1 } else { return -1 }
          }
        })
      setFilteredRows(results)
    } else {
      setFilteredRows(allSbuList?.data)
    }
  }
  // update sbu
  const sbuStatusChange = () => {
    const sbustatuschangeParams: UpdateSbuParamDto = {
      sbuId: isStatusChangeRow.sbuStatus.value.sbuId,
      sbuName: isStatusChangeRow.sbuStatus.value.sbuName,
      sbuDesc: isStatusChangeRow.sbuStatus.value.sbuDesc,
      isEnabled:
        isStatusChangeRow.sbuStatus.value.isEnabled !== true
    }
    dispatch(sbuAction.updateSbu(sbustatuschangeParams))
    setIsStatusChangeDialogOpen(false)
  }

  // Handle form input
  const onInputHandleChange = (
    property: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowHelperText(true)
    setSbuFormData({
      ...sbuFormData,
      [property]: {
        ...sbuFormData[property as keyof typeof sbuFormData],
        value: value
      }
    })
  }

  // Handle form edit
  const onEditHandleChange = (
    property: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowHelperText(true)
    setIsEditingRow({
      ...isEditingRow,
      [property]: {
        ...isEditingRow[property as keyof typeof isEditingRow],
        value: value
      }
    })
  }
  // Editing a row data trigger
  const onRowEditTrigger = (data: SbuDto) => {
    setIsEditDialogOpen(true)
    setIsEditingRow({
      ...isEditingRow,
      sbuId: {
        ...isEditingRow.sbuId,
        value: data.sbuId
      },
      sbuName: {
        ...isEditingRow.sbuName,
        value: data.sbuName
      },
      sbuDescription: {
        ...isEditingRow.sbuDescription,
        value: data.sbuDesc
      },
      isEnabled: {
        ...isEditingRow.isEnabled,
        value: data.isEnabled
      }
    })
  }

  const onRowStatusChangeTrigger = (data: SbuDto) => {
    setIsStatusChangeDialogOpen(true)
    setIsStatusChangeRow({
      ...isStatusChangeRow,
      sbuStatus: {
        value: {
          sbuId: data.sbuId,
          sbuName: data.sbuName,
          sbuDesc: data.sbuDesc,
          isEnabled: data.isEnabled
        }
      }
    })
  }
  //  if edit ok
  const sbuEditClick = () => {
    setIsEditDialogOpen(false)
  }

  const sbuStatusChangeClick = () => {
    setIsStatusChangeDialogOpen(false)
  }

  // pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <React.Fragment>
      <AppLayout breadcrumb={BREAD_CRUB.SBU_MANAGEMENT} componentTitle="SBU Management">
        <p style={{
          paddingBottom: 7,
          margin: 0,
          fontWeight: 400,
          fontSize: '24px'
        }}>SBU Management</p>
        <Divider className="dividerStyle" />
        <br />
        {createSbuAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearCreateSbuAlert}
            severity={createSbuAlert.severity}
          >
            {createSbuAlert.message}
          </Alert>
        )}
        {updateSbuAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearUpdateSbuAlert}
            severity={updateSbuAlert.severity}
          >
            {updateSbuAlert.message}
          </Alert>
        )}
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CRETTE_NEW_SBU]}
        >
          <SbuEntryForm
            sbuFormData={sbuFormData}
            isShowHelperText={isShowHelperText}
            onInputHandleChange={onInputHandleChange}
            createSbu={createSbu}
            handleInputFocus={handleInputFocus}
          />
        </AppAuthorizer>

        <br />
        <Divider className="dividerStyle" />
        <br />
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_SBU]}
        >
          <SbuViewTable
          activeUserRole={activeUserRole}
            sbuList={filteredRows || []}
            rowsPerPage={rowsPerPage}
            isEditingRow={isEditingRow}
            isEditDialogOpen={isEditDialogOpen}
            page={page}
            onRowEditTrigger={onRowEditTrigger}
            onRowStatusChangeTrigger={onRowStatusChangeTrigger}
            sbuEditClick={sbuEditClick}
            searchSbus={searchSbus}
            searchText={searchText}
            sbuListIsloding={sbuLIsloading}
          >
            <AppTablePagination
              data={filteredRows || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </SbuViewTable>
        </AppAuthorizer>

          <SbuEditPopup
            isEditDialogOpen={isEditDialogOpen}
            sbuFormData={sbuFormData}
            isShowHelperText={isShowHelperText}
            title="Edit SBU Details"
            isEditingRow={isEditingRow}
            sbuEditClick={sbuEditClick}
            onEditHandleChange={onEditHandleChange}
            updateSbu={updateSbu}
            handleInputFocus={handleInputFocus}
          />

          <SbuStatusChangePopup
            isStatusChangeDialogOpen={isStatusChangeDialogOpen}
            sbuStatusChangeClick={sbuStatusChangeClick}
            isStatusChangeRow={isStatusChangeRow}
            sbuStatusChange={sbuStatusChange}
          />

      </AppLayout>
    </React.Fragment>
  )
}

export default SbuManagement
