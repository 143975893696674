import { Autocomplete, Grid, TextField } from '@mui/material'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'
import { DATE_FORMAT } from '../../../utilities/constants'
import { InitialSbuDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto } from '../../../utilities/models'
// import { v4 as uuid } from 'uuid'
// const filter = createFilterOptions<InitialSbuDto>()

const ResourceRequestEntryFormFilter: React.FC<{
  filterFormData: any;
  handleChangeFilterForm(property: string, value: any): void;
  handleFilterClear(): void;
  clientHierachy: UserClientHierarchyDto[];
  clientHierarchyProjectList: UserClientHierarchyTeamsDto[]
  sbuList: InitialSbuDto[];
  clientHierachyIsLoading: boolean;
  onRequestType(requestType: number):void
  requestType: any
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} className="sectionTitleHolder">
          <h3>Basic Information</h3>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={3}>
            <Grid container spacing={2}>
            <Grid item>
                <input type="radio" value="NewRequest" name="requestType"
                checked={props.requestType === 1}
                onChange={() => props.onRequestType(1)}
                /> New Request
                </Grid>
                <Grid item>
                <input type="radio" value="Replacement" name="requestType"
                checked={props.requestType === 2}
                onChange={() => props.onRequestType(2)}
                 /> Replacement Request
                 </Grid>
                </Grid>
                </Grid>
          <Grid item md={2}>
            <Autocomplete
              color="primary"
              size="small"
              value={props.filterFormData.sbu.value}
              options={props.sbuList.map((sbu) => {
                return { name: sbu.sbuName, id: sbu.sbuId }
              })}
              disabled={props.clientHierachyIsLoading}
              onChange={(event, value) =>
                props.handleChangeFilterForm('sbu', value)
              }
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === '' || option.name === value.name
              }
              getOptionLabel={(option: any) => option.name || ''}
              renderInput={(params) => (
                <TextField {...params}
                required={props.filterFormData.sbu.isRequired}
                helperText={props.filterFormData.sbu.error}
                error={props.filterFormData.sbu.error !== null}
                label="SBU" />
              )}
            />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              size={'small'}
                    value={props.filterFormData.clientName.value}
                    // onInputChange={(event, newValue) => {
                    //   // let value
                    //   // if (typeof newValue === 'string') {
                    //   //   value = {
                    //   //     clientId: null,
                    //   //     sbuId: null,
                    //   //     clientName: newValue,
                    //   //     teams: []
                    //   //   }
                    //   // } else {
                    //   //   value = newValue
                    //   // }
                    //   props.handleChangeFilterForm('clientName', newValue)
                    // }}
                    onChange={(event, newValue) => {
                      // let value
                      // if (typeof newValue === 'string') {
                      //   value = {
                      //     clientId: null,
                      //     sbuId: null,
                      //     clientName: newValue,
                      //     teams: []
                      //   }
                      // } else {
                      //   value = newValue
                      // }
                      props.handleChangeFilterForm('clientName', newValue)
                    }}
                    options={props.clientHierachy}
                    getOptionLabel={(option) => option.clientName || ''}
                      // e.g value selected with enter, right from the input
                    //   if (typeof option === 'string') {
                    //     return option
                    //   }
                    //   return option.clientName || ''
                    // }}
                    // selectOnFocus
                    // clearOnBlur
                    // handleHomeEndKeys
                    // renderOption={(props, option) => <li {...props}>{option.clientName}</li>}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.clientName.isRequired}
                      helperText={props.filterFormData.clientName.error}
                      error={props.filterFormData.clientName.error !== null}
                      label="Client" />
                    )}
                  />
          </Grid>
          <Grid item md={2}>
            <Autocomplete
              size={'small'}
              value={props.filterFormData.projectName.value}
                    // onInputChange={(event, newValue) => {
                    //   let value
                    //   if (typeof newValue === 'string') {
                    //     value = {
                    //       teamId: props.filterFormData.projectName.value.teamId,
                    //       teamName: newValue,
                    //       users: []
                    //     }
                    //   } else {
                    //     value = newValue
                    //   }
                    //   props.handleChangeFilterForm('projectName', value)
                    // }}
                    onChange={(event, newValue) => {
                      // let value
                      // if (typeof newValue === 'string') {
                      //   value = {
                      //     teamId: null,
                      //     teamName: newValue,
                      //     users: []
                      //   }
                      // } else {
                      //   value = newValue
                      // }
                      props.handleChangeFilterForm('projectName', newValue)
                    }}
                    options={props.clientHierarchyProjectList}
                    getOptionLabel={(option) => option.teamName || ''}
                      // e.g value selected with enter, right from the input
                    //   if (typeof option === 'string') {
                    //     return option
                    //   }
                    //   return option.teamName || ''
                    // }}
                    // selectOnFocus
                    // clearOnBlur
                    // handleHomeEndKeys
                    // renderOption={(props, option) => <li {...props}>{option.teamName}</li>}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params}
                      required={props.filterFormData.projectName.isRequired}
                      helperText={props.filterFormData.projectName.error}
                      error={props.filterFormData.projectName.error !== null}
                      label="Project" />
                    )}
                  />
            </Grid>
            <Grid item md={3}>
            < LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                views={['year', 'month', 'day']}
                value={props.filterFormData.resourceRequestDeadline.value}
                inputFormat={DATE_FORMAT}
                closeOnSelect={true}
                onChange={(value) => props.handleChangeFilterForm('resourceRequestDeadline', value)}
                minDate={moment(new Date(Date.now()))}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Resource Request Deadline"
                    variant="outlined"
                    size="small"
                    onFocus={(value) => props.handleChangeFilterForm('resourceRequestDeadline', value)}
                    required={props.filterFormData.resourceRequestDeadline.isRequired}
                   error={props.filterFormData.resourceRequestDeadline.error !== null}
                    sx={{
                      maxWidth: 200,
                      minWidth: 200,
                      margin: 0,
                      padding: 0
                    }} />}
              />
            </LocalizationProvider>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ResourceRequestEntryFormFilter
