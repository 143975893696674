import React from 'react'

import { StyledTableCell, StyledTableRow } from '../../../assets/theme/theme'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import styles from './SbuViewTable.module.scss'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import { Grid, Table, TableHead, TextField, Tooltip, TableBody, InputAdornment, TableContainer, CircularProgress } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { SbuDto } from '../../../utilities/models/sbuManagement.model'
import { AppAuthorizer } from '../../shared'
import { APP_FEATURE_KEYS } from '../../../utilities/constants'

const SbuEntryTable: React.FC<{
  activeUserRole: any;
  sbuList: Array<SbuDto>;
  page: number;
  rowsPerPage: number;
  isEditingRow: any;
  isEditDialogOpen: boolean;
  sbuListIsloding: boolean;
  searchText: string;
  searchSbus(event: any): void;
  onRowEditTrigger(arg: any): void;
  onRowStatusChangeTrigger(arg: any): void;
  sbuEditClick(): void;
}> = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={3} className="sectionTitleHolder">
        <h3>Manage SBU</h3>
        <p>Click the edit icon under Action to edit an existing SBU. <br /> <br />
          Click on the enable/disable icons to Activate/Deactivate SBU.</p> <br />
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          color="primary"
          value={props.searchText}
          placeholder="Search SBU"
          // label="Search By SBU name"
          onChange={props.searchSbus}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon fontSize='small' />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item md={9}>
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left" >Name</StyledTableCell>
                <StyledTableCell align="left" >Description</StyledTableCell>
                <StyledTableCell align="left" >Status</StyledTableCell>
                <StyledTableCell align="left" width={70} >Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(!props.sbuListIsloding && props.sbuList.length > 0) && (props.rowsPerPage > 0
                ? props.sbuList.slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                : props.sbuList
              ).map((row: SbuDto, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left" >{row.sbuName}</StyledTableCell>
                  <StyledTableCell align="left" >{row.sbuDesc}</StyledTableCell>
                  <StyledTableCell align="left" >
                    {row.isEnabled === true &&
                      <span className={styles.enabled}>Enabled</span>
                    }
                    {row.isEnabled === false &&
                      <span className={styles.disabled}>Disabled</span>
                    }
                  </StyledTableCell>
                  <StyledTableCell align="left" width={70}>
                    <div className="layout-row">
                      <AppAuthorizer
                        activeRoleFeatures={props.activeUserRole.data.features}
                        authorizedFeatureKey={[APP_FEATURE_KEYS.UPDATE_SBU]}
                      >
                        <Tooltip title="Edit SBU">
                          <EditOutlinedIcon
                            onClick={() => props.onRowEditTrigger(row)}
                            className="editIconStyle" fontSize="small"></EditOutlinedIcon>
                        </Tooltip>
                        {row.isEnabled === false &&
                          <Tooltip title="Enable SBU">
                            <CheckCircleOutlineIcon
                              onClick={() => props.onRowStatusChangeTrigger(row)} fontSize="small" style={{ color: '#4CAF50' }}></CheckCircleOutlineIcon>
                          </Tooltip>
                        }
                        {row.isEnabled === true &&
                          <Tooltip title="Disable SBU">
                            <DoDisturbOutlinedIcon
                              onClick={() => props.onRowStatusChangeTrigger(row)} fontSize="small" style={{ color: '#e90c10' }}></DoDisturbOutlinedIcon>
                          </Tooltip>
                        }</AppAuthorizer>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {props.sbuListIsloding &&
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    <CircularProgress className={styles.spinnerAlign} color="primary" size={20} /> Loading...
                  </StyledTableCell>
                </StyledTableRow>
              }

              {(!props.sbuListIsloding && props.sbuList.length === 0) &&
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    No Records Available.
                  </StyledTableCell>
                </StyledTableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {props.children}
      </Grid>
    </Grid>
  )
}

export default SbuEntryTable
