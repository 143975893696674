import { Alert, Menu, TableCell, MenuItem, Divider, ListItemIcon, IconButton, Table, TableHead, TableBody, TableRow, Box, Tooltip, Typography, TextField, Grid, Accordion, AccordionDetails, AccordionSummary, Paper, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'
import { ResourceActionDto, UpdateRequestDto, ResourceRequestFormUpdateDto, ProposedResourcesActionDto, RmStatus } from '../../../utilities/models/resourceRequest.model'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { APP_FEATURE_KEYS, DATE_FORMAT } from '../../../utilities/constants'
import moment from 'moment'
import { EditOutlined, MoreVertOutlined, SaveOutlined, CloseOutlined } from '@mui/icons-material'
import ResourceRequestsViewRows from '../ResourceRequestsViewRows/ResourceRequestsViewRows'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import { AppAuthorizer } from '../../shared'
import { resourceAction } from '../../../redux/actions'
import ResourceRequestStatusPopup from '../ResourceRequestStatusPopup/ResourceRequestStatusPopup'
import { ResourceTableCell } from '../../../assets/theme/theme'
import styles from './ResourceRequestsViewParentRows.module.scss'
import { AlertDto, InitialSbuDto, AppStateDto, ResourceManagementConfigDto } from '../../../utilities/models'
const ResourceRequestsParentRows: React.FC<{
  row: any,
  selectedRequestType: any
  editRowData: any
  setEditRowData(rowData: any):void
  parentEditRowData: any
  setParentEditRowData(rowData: any):void
  isEditingRow:any
  setIsEditingRow(b: boolean):void
  disabledChildRow(resource: any, requestId: number, resourceId: number):void
  updateRequest(date: string, requestId: number): void
  isParentEditingRow: any
  setIsParentEditingRow(b: boolean): void
  resourceActionLoading: boolean
  sbuList: InitialSbuDto[]
  getProposedRequests(args: number): void
  selectedRequest: any
  setSelectedRequest(args: number):void
  setOpenResources(args: any): void
  openResources :any
  resourcesList: any
  resourcesListIsLoading: any
  setExpanded(args: number | null): void
  expanded: any
  // updateRequestStatusAlert: any
}> = (props) => {
  const RESOURCE_REQUEST_STATUS_CONFIG_DATA = useSelector((state: AppStateDto) => state.resource.rmStatusList.data)
  const configList = useSelector((state: any) => state.config.configList.data)
  const buStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'bu_resource_request_status_ids')
  const buStatuses = buStatus?.values.split(',')
  const rmStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'rm_resource_request_status_ids')
  const rmStatuses = rmStatus?.values.split(',')
  const buEnabledStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'bu_resource_request_actions_enable_status_ids')
  const buEnabledStatuses = buEnabledStatus?.values.split(',')
  const rmEnabledStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'rm_resource_request_actions_enable_status_ids')
  const rmEnabledStatuses = rmEnabledStatus?.values.split(',')
  const resourceRequestEditable = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'resource_request_editable_status_ids')
  const resourceRequestEditableStatuses = resourceRequestEditable?.values.split(',')
  const resourcesEditable = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'resource_editable_status_ids')
  const resourcesEditableStatuses = resourcesEditable?.values.split(',')
  const isRejectionNote = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'resource_request_note_enable_status_ids')
  const rejectionNoteStatuses = isRejectionNote?.values.split(',')
  const resourceRequestEnabledStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'resource_request_closing_enable_status_ids')
  const resourceRequestEnabledStatuses = resourceRequestEnabledStatus?.values.split(',')
  const resourceRequestStatus = configList.data?.find((e: ResourceManagementConfigDto) => e.key === 'resource_request_closing_status_ids')
  const resourceRequestStatuses = resourceRequestStatus?.values.split(',')

  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  const INITIAL_VALUE: ResourceRequestFormUpdateDto = {
    requestId: { value: 0, validator: 'number', isRequired: false, error: null, disable: false },
    resourceRequestExtendedDeadline: { value: props.row.resourceRequestExtendedDeadline ? moment(props.row.resourceRequestExtendedDeadline) : moment(props.row.resourceRequestDeadline).add(1, 'days'), validator: 'object', isRequired: true, error: null, disable: false }
  }
  const [isParentEditingData, setIsParentEditingData] = React.useState(INITIAL_VALUE)
  // const [resourcesList1, setResourcesList] = useState<[]>([])
  const handleEdit = (element: any, n: number) => {
    props.setIsEditingRow(false)
    props.setIsParentEditingRow(true)
    // setIsRowEditingResourceId(n)
    const updateRequest: UpdateRequestDto = {
      requestId: n,
      resourceRequestExtendedDeadline: element
    }
    props.setParentEditRowData(updateRequest)
  }
  const handleChange = (e: any, property: string) => {
    switch (property) {
      case 'resourceRequestExtendedDeadline':
        if (moment(props.row.resourceRequestDeadline) >= e) {
          setIsParentEditingData({
            ...isParentEditingData,
            [property]: {
              ...isParentEditingData[
              property as keyof typeof isParentEditingData
              ],
              value: e,
              error: 'Cannot be on or before start date'
            }
          })
        } else {
          setIsParentEditingData({
            ...isParentEditingData,
            [property]: {
              ...isParentEditingData[
              property as keyof typeof isParentEditingData
              ],
              value: e,
              error: null
            }
          })
        }
        break
      default:
        setIsParentEditingData({
          ...isParentEditingData,
          [property]: {
            ...isParentEditingData[
            property as keyof typeof isParentEditingData
            ],
            value: e,
            error: null
          }
        })
        break
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget) }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = () => { setAnchorEl(null) }
  const openMore = Boolean(anchorEl)
  const [isRequestResourceChangePopupOpen, setRequestResourceChangePopupOpen] = React.useState(false)
  const dispatch = useDispatch()
  const changeStatus = async (note: string, requestId: number, statusId: number) => {
    const getCloseRequestStatuses = resourceRequestStatuses?.map((key: any) => key.split('-').map((status: number) => (status)))
    const closeRequestStatuses = getCloseRequestStatuses?.map((key: any) => key[0])
    const isCloseRequest = closeRequestStatuses.some((status: number) => +status === statusId)
    if (isCloseRequest) {
      const closeRequestDto: ProposedResourcesActionDto = {
        statusId: statusId
      }
      dispatch(resourceAction.closeRequest(requestId, closeRequestDto))
    } else {
      const resourceActionDto: ResourceActionDto = {
        statusId: statusId,
        rejectionNote: note
      }
      dispatch(resourceAction.changeStatus(resourceActionDto, requestId))
    }
  }
  const [selectedStatus, setSelectedStatus] = React.useState(0)
  const addProposeRequestAlert: AlertDto = useSelector((state: any) => state.alerts.createPropose)
  const today = moment(new Date())
  const deleteProposeResourceAlert: AlertDto = useSelector((state: any) => state.alerts.deleteProposedResource)
  const confirmProposedResourcesAlert: AlertDto = useSelector((state: any) => state.alerts.confirmProposedResources)
  const proposedResourcesActionAlert: AlertDto = useSelector((state: any) => state.alerts.proposedResourcesAction)
  React.useEffect(() => {
    if (deleteProposeResourceAlert.severity === 'success' && props.selectedRequest === props.row.requestId) {
      props.getProposedRequests(props.selectedRequest)
    }
  }, [deleteProposeResourceAlert.severity === 'success'])
  React.useEffect(() => {
    if (proposedResourcesActionAlert.severity === 'success' && props.selectedRequest === props.row.requestId) {
      props.getProposedRequests(props.selectedRequest)
    }
  }, [proposedResourcesActionAlert.severity === 'success'])
  return (
      <React.Fragment>
         <Grid container spacing={2} >
        <Grid item md={12} sx={{ marginTop: '0.5px' }}>
        <Accordion
        defaultExpanded={(props.editRowData.requestId === props.row.requestId)}
        expanded={props.selectedRequest === props.row.requestId}
        sx={{ padding: '5px', marginBottom: '5px', marginTop: '5px', border: '1px solid grey', boxShadow: 'none' }}
        TransitionProps={{ unmountOnExit: true }}
        onChange = {(e, expanded) => {
          props.setIsEditingRow(false)
          if (expanded) {
            props.setSelectedRequest(props.row.requestId)
            if (props.openResources?.requestId !== props.row.requestId) {
              props.getProposedRequests(props.row.requestId)
              // setFrom(true)
            }
          } else {
            props.setSelectedRequest(-1)
          }
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ maxheight: '40px', height: '40px', minHeight: '10px' }}
        >

          <Grid container spacing={2} >
        <Grid item md={1}>
          <strong>{props.row.requestId}</strong>
        </Grid>
        <Grid item md={2}>
          <Typography>
        <AccountTreeOutlinedIcon sx={{ fontSize: '20px', mr: '5px', verticalAlign: 'middle' }}/>
        {props.row.sbuName}
        </Typography>
        </Grid>
        <Grid item md={2}>
          <BusinessCenterOutlinedIcon sx={{ fontSize: '20px', mr: '5px', verticalAlign: 'middle' }} />
          {props.row.clientName}
          </Grid>
          <Grid item md={2}>
          <InventoryOutlinedIcon sx={{ fontSize: '20px', mr: '5px', verticalAlign: 'middle' }}/>
          {props.row.projectName}
          </Grid>
          <Grid item md={2}>
          <span style={{ color: '#8b8b8b' }}>
          {moment.duration(today.diff(moment(props.row.createdAt), 'days'), 'days').asDays()} days ago
          </span>
          </Grid>
          <Grid item md={2}>
          <Box width={'100%'} className={styles.box}>
          {rejectionNoteStatuses?.filter((x: any) => +x === props.row.statusId).length > 0
            ? (
                  <span className={styles.status + ' ' + styles.reject}>{props.row.statusName}</span>
              )
            : (
            <span className={styles.status}>
                    {props.row.statusName}</span>
              )}
          </Box>
          </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails >
        <Divider/>
        <Grid container spacing={2} sx={{ paddingTop: '5px' }}>
        <Grid item md={2}>
          Requested Date
        </Grid>
        <Grid item md={2}>
       Request Deadline
        </Grid>
          <Grid item md={2}>
            Extended Deadline
          </Grid>
          </Grid>
        <Grid container spacing={2} >
        <Grid item md={2}>
         <strong>{moment(props.row.createdAt).format(DATE_FORMAT)}</strong>
        </Grid>
        <Grid item md={2}>
        <strong>
          {props.row.resourceRequestDeadline ? moment(props.row.resourceRequestDeadline).format(DATE_FORMAT) : '-'}
          </strong>
        </Grid>
          <Grid item md={2}>
          {(props.parentEditRowData.requestId === props.row.requestId && props.isParentEditingRow)
            ? < LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      views={['year', 'month', 'day']}
                      value={isParentEditingData.resourceRequestExtendedDeadline.value}
                      inputFormat={DATE_FORMAT}
                      closeOnSelect={true}
                      onChange={(value) => handleChange(value, 'resourceRequestExtendedDeadline')}
                      // maxDate={isEditingRow.endDate.value !== null ? moment(isEditingRow.endDate.value) : undefined}
                      minDate={moment(props.row.resourceRequestDeadline).add(1, 'days')}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          required={isParentEditingData.resourceRequestExtendedDeadline.isRequired}
                          error={isParentEditingData.resourceRequestExtendedDeadline.error !== null}
                          helperText={isParentEditingData.resourceRequestExtendedDeadline.error}
                          // helperText={isRowEditingForm.deploymentStartDate.error}
                          variant="standard"
                          size={'small'}
                          sx={{
                            svg: { width: '15px', height: '15px' },
                            maxWidth: 100,
                            margin: 0,
                            padding: 0
                          }} />}
                    />
                    </LocalizationProvider>
            : <Typography>
              <strong>
   {props.row.resourceRequestExtendedDeadline === null ? '-' : moment(props.row.resourceRequestExtendedDeadline).format(DATE_FORMAT)}
   </strong> </Typography>
              }
          </Grid>
          <Grid item md={6}>
          <Box width={'100%'} className={styles.box}>
              {(props.parentEditRowData.requestId === props.row.requestId && props.isParentEditingRow)
                ? <AppAuthorizer
                activeRoleFeatures={activeUserRole?.data?.features}
                authorizedFeatureKey={[
                  APP_FEATURE_KEYS.UPDATE_RESOURCE_REQUEST

                ]}
              >
                <IconButton size='small'
                  onClick={() => {
                    if (isParentEditingData.resourceRequestExtendedDeadline.error === null) {
                      props.updateRequest(moment(isParentEditingData.resourceRequestExtendedDeadline.value).format(DATE_FORMAT), props.parentEditRowData.requestId)
                    }
                  } }
                >
                  <Tooltip title="Save to continue">
                    <SaveOutlined color='primary' sx={{ fontSize: '20px', mr: '-1' }} />
                  </Tooltip>
                </IconButton><IconButton
                  onClick={() => { props.setIsEditingRow(false); props.setIsParentEditingRow(false) } }
                  size='small' className='actionClosedButtonStyle'>
                    <Tooltip title="Close">
                      <CloseOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                    </Tooltip>
                  </IconButton>
                  </AppAuthorizer>
                : <React.Fragment>
                  {resourceRequestEditableStatuses?.filter((x: any) => +x === props.row.statusId).length > 0 && (
                  <AppAuthorizer
                  activeRoleFeatures={activeUserRole?.data?.features}
                  authorizedFeatureKey={[
                    APP_FEATURE_KEYS.UPDATE_RESOURCE_REQUEST
                  ]}
                >
                  <IconButton
                    size='small'
                    disabled={props.isParentEditingRow === true || props.isEditingRow === true}
                    onClick={() => {
                      props.setIsEditingRow(false)
                      handleEdit(props.row.resourceRequestExtendedDeadline, props.row.requestId)
                    } }
                  >
                    <Tooltip title="Edit">
                      <EditOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                    </Tooltip>
                  </IconButton>
                </AppAuthorizer>
                  )}
                <AppAuthorizer
                  activeRoleFeatures={activeUserRole?.data?.features}
                  authorizedFeatureKey={[
                    APP_FEATURE_KEYS.BU_RESOURCE_REQUEST_ACTION
                  ]}
                >
                   {(props.selectedRequest === props.row.requestId && props.openResources.requestId !== props.row.requestId && props.openResources.resources?.length <= 0
                     ? props.resourcesList
                     : props.openResources?.resources
                   )?.filter((element: any) => element.isEnabled).length > 0 &&
                  (buEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length > 0) && (
                    <React.Fragment>
                    <IconButton
                              size='small'
                              onClick={handleClick}
                              disabled={buEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length <= 0 ||
                                props.isEditingRow === true || props.isParentEditingRow === true}
                            >
                              <Tooltip title='More'>
                                <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                              </Tooltip>
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={openMore}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt: 1.5,
                                  '& .MuiAvatar-root': {
                                    width: 30,
                                    height: 30,
                                    ml: -0.5,
                                    mr: 1
                                  },
                                  '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0
                                  }
                                }
                              }}
                              transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top'
                              }}
                              anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom'
                              }}
                            >
                                {buEnabledStatuses?.find((e: any) => +e === props.row.statusId) && (
                                  buStatuses?.map((key: any, index: number) => key.split('-').map((status: any, index1: number) => (
                                    <div key={index}>
                                      {index1 === 0 && (
                                        <React.Fragment>
                                      <MenuItem
                                        // disabled={!props.employee.isEnable}
                                        onClick={() => {
                                          setRequestResourceChangePopupOpen(true)
                                          setSelectedStatus(+status)
                                        } }
                                      >
                                        <ListItemIcon>
                                          {rejectionNoteStatuses?.filter((x: any) => +x === +status).length > 0
                                            ? <CancelOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'red' }} />
                                            : <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'blue' }} />}
                                        </ListItemIcon>
                                        {RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === +status)?.title} Request
                                      </MenuItem>
                                      {index < (buStatuses?.length - 1) && (
                                        <Divider />
                                      )}
                                      </React.Fragment>
                                      )}
                                    </div>
                                  ))))}
                              </Menu>
                              </React.Fragment>
                   )}
                  </AppAuthorizer>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole?.data?.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.RM_RESOURCE_REQUEST_ACTTION
                    ]}
                  >
                    {(props.selectedRequest === props.row.requestId && props.openResources.requestId !== props.row.requestId && props.openResources.resources?.length <= 0
                      ? props.resourcesList
                      : props.openResources?.resources
                    )?.filter((element: any) => element.isEnabled === true).length > 0 &&
                    (rmEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length > 0) && (
                      <React.Fragment>
                    <IconButton
                      size='small'
                      onClick={handleClick}
                      disabled={rmEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length <= 0 ||
                        props.isEditingRow === true || props.isParentEditingRow === true}
                    >
                      <Tooltip title='More'>
                        <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                      </Tooltip>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={openMore}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 30,
                            ml: -0.5,
                            mr: 1
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                      }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                      }}
                    >
                       {rmEnabledStatuses?.find((e: any) => +e === props.row.statusId) && (
                         rmStatuses?.map((key: any, index: number) => key.split('-').map((status: any, index1: number) => (
                          <div key={index}>
                            {index1 === 0 && (
                              <React.Fragment>
                            <MenuItem
                              // disabled={!props.employee.isEnable}
                              onClick={() => {
                                setRequestResourceChangePopupOpen(true)
                                setSelectedStatus(+status)
                              } }
                            >
                              <ListItemIcon>
                                {rejectionNoteStatuses?.filter((x: any) => +x === +status).length > 0
                                  ? <CancelOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'red' }} />
                                  : <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'blue' }} />
                                } </ListItemIcon>
                              {RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === +status)?.title} Request
                            </MenuItem>
                            {index < (buStatuses?.length - 1) && (
                                <Divider />
                            )}
                            </React.Fragment>
                            )}
                          </div>
                         )
                         )))}
                    </Menu>
                    </React.Fragment>
                    )}
                  </AppAuthorizer>
                  <AppAuthorizer
                    activeRoleFeatures={activeUserRole?.data?.features}
                    authorizedFeatureKey={[
                      APP_FEATURE_KEYS.CLOSE_RESOURCE_REQUEST
                    ]}
                  >
                    {(resourceRequestEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length > 0) && (
                      <React.Fragment>
                    <IconButton
                      size='small'
                      onClick={handleClick}
                      // disabled={resourceRequestEnabledStatuses?.filter((x: any) => +x === props.row.statusId).length <= 0 ||
                      //   props.isEditingRow === true || props.isParentEditingRow === true}
                    >
                      <Tooltip title='More'>
                        <MoreVertOutlined sx={{ fontSize: '20px', mr: '-1' }} />
                      </Tooltip>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={openMore}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 30,
                            ml: -0.5,
                            mr: 1
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                          }
                        }
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                      }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                      }}
                    >
                       {resourceRequestStatuses?.map((key: any, index: number) => key.split('-').map((status: any, index1: number) => (
                          <div key={index}>
                            {index1 === 0 && (
                              <React.Fragment>
                            <MenuItem
                              // disabled={!props.employee.isEnable}
                              onClick={() => {
                                setRequestResourceChangePopupOpen(true)
                                setSelectedStatus(+status)
                              } }
                            >
                              <ListItemIcon>
                                <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '20px', mr: '-1', color: 'green' }} />
                              </ListItemIcon>
                              {RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === +status)?.title} Request
                            </MenuItem>
                            {index < (resourceRequestStatuses?.length - 1) && (
                              <Divider />
                            )}
                            </React.Fragment>
                            )}
                          </div>
                       )
                       ))}
                    </Menu>
                    </React.Fragment>
                    )}
                  </AppAuthorizer>
              {isRequestResourceChangePopupOpen === true && (
                  <ResourceRequestStatusPopup
                  isRequestResourceChangePopupOpen={isRequestResourceChangePopupOpen}
                  setRequestResourceChangePopupOpen={setRequestResourceChangePopupOpen}
                  changeStatus={changeStatus}
                  statusName={RESOURCE_REQUEST_STATUS_CONFIG_DATA?.data?.find((e: RmStatus) => e.id === selectedStatus)}
                  requestDetails={props.row}
                  resources={props.openResources.requestId !== props.row.requestId ? props.resourcesList : props.openResources?.resources}
                  statusId={selectedStatus}
                  resourceActionLoading={props.resourceActionLoading}
                  rejectionNoteStatuses={rejectionNoteStatuses}
                  // updateRequestStatusAlert={updateRequestStatusAlert}
  />
              )}
              </React.Fragment>
            }
                </Box>
          </Grid>
          </Grid>
          <Grid container spacing={2} >
        <Grid item md={12}>
        {addProposeRequestAlert?.message && (
              <Alert
                className="mb-m"
                severity= {addProposeRequestAlert.severity}
              >
                {addProposeRequestAlert.message}
              </Alert>
        )}
         {deleteProposeResourceAlert?.message && (
              <Alert
                className="mb-m"
                severity= {deleteProposeResourceAlert.severity}
              >
                {deleteProposeResourceAlert.message}
              </Alert>
         )}
         {confirmProposedResourcesAlert?.message && (
              <Alert
                className="mb-m"
                severity= {confirmProposedResourcesAlert.severity}
              >
                {confirmProposedResourcesAlert.message}
              </Alert>
         )}
         {proposedResourcesActionAlert?.message && (
              <Alert
                className="mb-m"
                severity= {proposedResourcesActionAlert.severity}
              >
                {proposedResourcesActionAlert.message}
              </Alert>
         )}
        <Paper sx={{ overflowX: 'auto' }}>
        <Table>
                <TableHead>
            <TableRow sx={{ background: '#E4E4E4' }}>
              <ResourceTableCell padding="checkbox" colSpan={1} style={{ minWidth: 20 }}></ResourceTableCell>
                {props.selectedRequestType === 1
                  ? (
                <><ResourceTableCell align="center" width={150} style={{ minWidth: 150 }}>
                  <Tooltip title="Required Postion"><span>Required Position</span></Tooltip>
                  </ResourceTableCell>
                <ResourceTableCell align="center" width={100} style={{ minWidth: 100 }}>
                <Tooltip title="No. of Vacancies"><span>No. of Vacancies</span></Tooltip>
                  </ResourceTableCell></>
                    )
                  : (
                <><ResourceTableCell align="center" width={20} style={{ minWidth: 20 }}>
                <Tooltip title="Username"><span>Username</span></Tooltip>
                </ResourceTableCell>
                <ResourceTableCell align="center" width={120} style={{ minWidth: 120 }}>
                <Tooltip title="Required Position"><span>Required Position</span></Tooltip>
                  </ResourceTableCell></>
                    )
              }
              <ResourceTableCell align="center" width={110} style={{ minWidth: 110 }}>
              <Tooltip title="Deployment Start Date"><span>Deploy. Start</span></Tooltip>
              </ResourceTableCell>
              <ResourceTableCell align="center" width={110} style={{ minWidth: 110 }}>
              <Tooltip title="Deployment End Date"><span>Deploy. End</span></Tooltip>
              </ResourceTableCell>
                <ResourceTableCell align="center" width={100} style={{ minWidth: 100 }}>
                <Tooltip title="Deployment Extendible"><span>Deploy. Extendible</span></Tooltip>
                </ResourceTableCell>
              <ResourceTableCell align="center" width={100} style={{ minWidth: 100 }}>
              <Tooltip title="Deployment Extension Period (months)"><span>Exten. Period (months)</span></Tooltip></ResourceTableCell>
              <ResourceTableCell align="center" width={100} style={{ minWidth: 100 }}>
              <Tooltip title="Job Description and Remarks"><span>JD & Remarks</span></Tooltip>
              </ResourceTableCell>
              <ResourceTableCell align="center" width={80} style={{ minWidth: 80 }}>
              <Tooltip title="Billing"><span>Billing</span></Tooltip>
              </ResourceTableCell>
              <ResourceTableCell align="center" width={80} style={{ minWidth: 80 }}>
              <Tooltip title="Allocation Percentage"><span>Alloc. %</span></Tooltip>
              </ResourceTableCell>
              <ResourceTableCell align="center" style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} >
              <Tooltip title="Status"><span>Status</span></Tooltip>
              </ResourceTableCell>
              <ResourceTableCell align="center" style={{ paddingBottom: 0, paddingTop: 0, padding: 0, position: 'sticky', right: 0 }} >
              <Tooltip title="Actions"><span>Actions</span></Tooltip>
              </ResourceTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {props.resourcesListIsLoading &&
              <TableRow><TableCell colSpan={4}><CircularProgress className={styles.spinnerAlign} color="primary" size={20} />
              Loading...</TableCell></TableRow>
            }
            {!props.resourcesListIsLoading &&
            <React.Fragment>
            {(props.selectedRequest === props.row.requestId && props.openResources.requestId !== props.row.requestId && props.openResources.resources?.length <= 0
              ? props.resourcesList
              : props.openResources?.resources
            )?.map((childElement: any, childIndex: any) => (
            <React.Fragment key={childIndex}>
            <ResourceRequestsViewRows
              selectedRequestType={props.selectedRequestType}
              childElement={childElement}
              isEditingRow={props.isEditingRow}
              requestId={props.row.requestId}
              setIsEditingRow={props.setIsEditingRow}
              setEditRowData={props.setEditRowData}
              editRowData={props.editRowData}
              parentElement={props.row}
              disabledChildRow={props.disabledChildRow}
              setIsParentEditingRow={props.setIsParentEditingRow}
              resourcesEditableStatuses={resourcesEditableStatuses}
              sbuList={props.sbuList}
              setOpenResources={props.setOpenResources}
              openResources={props.openResources}
              setSelectedRequest={props.setSelectedRequest}
              expanded={props.expanded}
              setExpanded={props.setExpanded}
            />
            </React.Fragment>
            ))}
            </React.Fragment>
}
            {!props.resourcesListIsLoading && props.openResources.resources?.length <= 0 &&
            <TableRow><TableCell colSpan={3}>
              No Records Found...</TableCell></TableRow>
            }
            {/* {from && props.openResources?.filter((x: any) => x.requestId === props.row.requestId).length <= 0 &&
              <TableRow><TableCell colSpan={3}>
            No Records Found...</TableCell></TableRow>
            } */}
            </TableBody>
            </Table>
            </Paper>
            </Grid>
            </Grid>
        {props.row.rejectionNote && (
          <Grid container spacing={2} sx={{ paddingTop: '10px' }} >
          <Grid item md={12}>
           <span style={{ color: 'red' }}>Rejection Note: </span>{props.row.rejectionNote}
        </Grid>
        </Grid>
        )}
        </AccordionDetails>
      </Accordion>
      </Grid>
      </Grid>
      </React.Fragment>
  )
}
export default ResourceRequestsParentRows
