import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { AllocationTypeDto, ReportsFilterFormDto, InitialSbuDto, InitProjectRoleDto, UserClientHierarchyDto, UserClientHierarchyTeamsDto, UserClientHierarchyUserDto } from '../../../utilities/models'
import { v4 as uuid } from 'uuid'
import styles from './FinanceReportForm.module.scss'
import { DATE_FORMAT } from '../../../utilities/constants'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
const FinanceReportForm: React.FC<{
  reportFilterFormData: ReportsFilterFormDto;
  onFilterHandleChange(property: string, value: any): void;
  onReportTypeHandleChange(property: string, value: any): void;
  clearFilters(): void;
  isAdvancedFilterOnclick(event: React.ChangeEvent<HTMLInputElement>): void;
  userList: UserClientHierarchyUserDto[];
  designationList: InitProjectRoleDto[];
  clientHierachy: UserClientHierarchyDto[];
  clientHierarchyProjectList: UserClientHierarchyTeamsDto[];
  allocationTypes: AllocationTypeDto[];
  sbuList: InitialSbuDto[];
  getFilteredAllocationList(): void;
  handleInputFocus(property: string): void;
  isHistoricalData: boolean;
  reportsIsloading: boolean;
  clientHierachyIsloading: boolean;
  isAdvancedFilter: boolean;
}> = (props) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date From"
                  views={['year', 'month', 'day']}
                  // maxDate={ props.createEmployeeFormData.endDate.value ? moment(props.createEmployeeFormData.endDate.value) : undefined }
                  value={props.reportFilterFormData.projectStart.value}
                  inputFormat={DATE_FORMAT}
                  onChange={(value) => props.onFilterHandleChange('projectStart', value)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      color='primary'
                      size="small"
                      {...params}
                      error={!!props.reportFilterFormData.projectStart.error}
                      helperText={props.reportFilterFormData.projectStart.error}
                      required={props.reportFilterFormData.projectStart.isRequired}
                      onFocus={() => props.handleInputFocus('projectStart')}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={2.4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date To"
                  views={['year', 'month', 'day']}
                  // maxDate={ props.createEmployeeFormData.endDate.value ? moment(props.createEmployeeFormData.endDate.value) : undefined }
                  value={props.reportFilterFormData.projectEnd.value}
                  inputFormat={DATE_FORMAT}
                  onChange={(value) => props.onFilterHandleChange('projectEnd', value)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      color='primary'
                      size="small"
                      {...params}
                      error={!!props.reportFilterFormData.projectEnd.error}
                      helperText={props.reportFilterFormData.projectEnd.error}
                      required={props.reportFilterFormData.projectEnd.isRequired}
                      onFocus={() => props.handleInputFocus('projectEnd')}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2.4} sm={2.4}>
              <Autocomplete
                value={props.reportFilterFormData.sbu.value}
                fullWidth
                size="small"
                options={props.sbuList.map((sbu) => {
                  return { name: sbu.sbuName, id: sbu.sbuId }
                })}
                disabled={props.clientHierachyIsloading}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )
                }}
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, value) => props.onFilterHandleChange('sbu', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="SBU"
                    color='primary'
                  />
                )} />
            </Grid>
            <Grid item xs={2.4} sm={2.4}>
              <Autocomplete
                color="primary"
                size="small"
                // disableClearable
                disabled={props.clientHierachyIsloading}
                value={props.reportFilterFormData.clientName.value}
                options={
                  props.clientHierachy.map((client: UserClientHierarchyDto) => ({
                    id: client.clientId,
                    sbuId: client.sbuId,
                    name: client.clientName,
                    teams: client.teams
                  })) || []
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={uuid()}>
                      {option.name}
                    </li>
                  )
                }}
                onChange={(event, value) =>
                  props.onFilterHandleChange('clientName', value)
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.name || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Client" />
                )}
              />
            </Grid>

            <Grid item xs={2.4} sm={2.4}>
              <Autocomplete
                fullWidth
                size="small"
                disabled={props.clientHierachyIsloading}
                value={props.reportFilterFormData.projectName.value}
                options={
                  props.clientHierarchyProjectList?.map((project) => ({
                    id: project.teamId,
                    name: project.teamName,
                    users: project.users
                  })) || []
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )
                }}
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, value) => props.onFilterHandleChange('projectName', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project"
                    color='primary'
                  />
                )} />
            </Grid>
            {/* <Grid item xs={2.4} sm={2.4}>
              <Autocomplete
                fullWidth
                size="small"
                value={props.reportFilterFormData.employeeName.value}
                disabled={props.clientHierachyIsloading}
                options={props.userList.map((emp) => {
                  return { name: emp.firstName + ' ' + emp.lastName, id: emp.userId, username: emp.username }
                })}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )
                }}
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, value) => props.onFilterHandleChange('employeeName', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee"
                    color='primary'
                    required={props.reportFilterFormData.employeeName.isRequired}
                    error={props.reportFilterFormData.employeeName.error !== null}
                    helperText={props.reportFilterFormData.employeeName.error}
                    onFocus={() => props.handleInputFocus('employeeName')}
                  />
                )} />
            </Grid> */}
             <Grid item xs={2.4} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={['Billable', 'Non-billable']}
                    value={props.reportFilterFormData.isBillable.value}
                    onChange={(event, value) => props.onFilterHandleChange('isBillable', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Billable"
                        color='primary'
                      />
                    )} />
                </Grid>

            {
              props.isAdvancedFilter === false
                ? <Grid item md={2.4}>
                  <FormControlLabel
                    control={<Checkbox
                      onChange={(event, value) => props.onReportTypeHandleChange('reportType', value)}
                      size='small'
                      checked={props.isHistoricalData}
                    />}
                    label="Include History" />
                </Grid>
                : null
            }

            {props.isAdvancedFilter
              ? <React.Fragment>
                <Grid item xs={2.4} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={
                      props.designationList.map((desig: any) => ({
                        id: desig.projectRoleId,
                        name: desig.projectRoleDisplayName,
                        roleName: desig.projectRoleName
                      })) || []
                    }
                    disabled={props.reportFilterFormData.designation.disable}
                    value={props.reportFilterFormData.designation.value}
                    renderOption={(props, option, state) => {
                      return (
                        <Box component={'li'} {...props} key={option.id}>
                          <Tooltip placement="right"
                            title={`${option.roleName}`}
                          ><Typography
                            style={{ textAlign: 'left', paddingLeft: '6px', paddingBottom: '6px' }}>
                              {`${option.name}`}</Typography>
                          </Tooltip>
                        </Box>
                      )
                    }}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => props.onFilterHandleChange('designation', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Designation"
                        color='primary'
                      />
                    )} />
                </Grid>
                <Grid item xs={2.4} sm={2.4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={
                      props.designationList.map((desig: any) => ({
                        id: desig.projectRoleId,
                        name: desig.projectRoleDisplayName,
                        roleName: desig.projectRoleName
                      })) || []
                    }
                    value={props.reportFilterFormData.projectRoleName.value}
                    renderOption={(props, option, state) => {
                      return (
                        <Box component={'li'} {...props} key={option.id}>
                          <Tooltip placement="right"
                            title={`${option.roleName}`}
                          ><Typography
                            style={{ textAlign: 'left', paddingLeft: '6px', paddingBottom: '6px' }}>
                              {`${option.name}`}</Typography>
                          </Tooltip>
                        </Box>
                      )
                    }}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => props.onFilterHandleChange('projectRoleName', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project Role"
                        color='primary'
                      />
                    )} />
                </Grid>
                <Grid item md={2.4}>
                  <FormControlLabel
                    control={<Checkbox
                      onChange={(event, value) => props.onReportTypeHandleChange('reportType', value)}
                      size='small'
                      checked={props.isHistoricalData}
                    />}
                    label="Include History" />
                </Grid>
                <Grid item xs={2.4} sm={2.4}></Grid>
                <Grid item xs={2.4} sm={2.4}></Grid>

                <Grid item xs={2.4} sm={2.4}></Grid>
              </React.Fragment>
              : null
              //   <Grid item xs={4.8} sm={4.8}>
              // </Grid>
            }
            <Grid item md={7.2}>
              <Stack style={{ float: 'right' }} direction={'row'}>
                <FormControlLabel
                  value="start"
                  className={styles.switchStyle}
                  control={<Switch

                    size='small'
                    color="primary"
                    onChange={props.isAdvancedFilterOnclick}
                  />}
                  label="Advanced Filter"
                  labelPlacement="start"
                  checked={props.isAdvancedFilter}
                />
                <Button
                  variant="contained"
                  className={'btnStyle'}
                  onClick={props.getFilteredAllocationList}
                // disabled={props.isChangedForm}
                >
                  {props.reportsIsloading && (
                    <CircularProgress size="13px" className={styles.loading} />
                  )}
                  View Report
                </Button>
                <Button
                  variant="contained"
                  color='inherit'
                  className={'btnStyle'}
                  onClick={props.clearFilters}
                // disabled={props.authLoading}
                >

                  Reset
                </Button>
              </Stack>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </div >
  )
}

export default FinanceReportForm
