import { COMMON_ACTION_TYPES, HERA_CONFIG_ACTION_TYPES } from '../../utilities/constants'

const INITIAL_STATE = {
  configList: {
    isLoading: false,
    data: []
  }
}
const configReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        configList: {
          ...state.configList,
          isLoading: true
        }
      }
    case HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        configList: {
          ...state.configList,
          isLoading: false,
          data: action.data
        }
      }
    case HERA_CONFIG_ACTION_TYPES.GET_RESOURCE_MANAGEMENT_CONFIGS + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        configList: {
          ...state.configList,
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  };
}

export default configReducer
