import { Alert, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  alertActions,
  projectAction,
  supervisorAction
} from '../../redux/actions'
import { AppLayout } from '../../templates'
import { APP_FEATURE_KEYS, APP_TABLE_CONFIGS, BREAD_CRUB } from '../../utilities/constants'
import { validateFormData } from '../../utilities/helpers/FormValidator'
import {
  SupervisorAssignForm,
  SupervisorViewTable,
  AppTablePagination
  , AppAuthorizer
} from '../../components'
import {
  AlertDto,
  AppStateDto,
  CreateSupervisorParamDto,
  GetProjectListParamDto,
  GetSupervisorsListParamDto,
  InitProjectDto,
  InitSupervisorsDto,
  SupervisorEntryFormDto,
  UnassignSupervisorParamDto
} from '../../utilities/models'
import UnassignSupervisorPopup from '../../components/supervisor-management/UnassignSupervisorPopup/UnassignSupervisorPopup'

const SupervisorManagement: React.FC = () => {
  const INITIAL_STATE: SupervisorEntryFormDto = {
    supervisorEmail: {
      value: '',
      validator: 'email',
      isRequired: true,
      error: null,
      disable: false
    },
    project: {
      value: {} as InitProjectDto,
      validator: 'object',
      isRequired: true,
      error: null,
      disable: false
    }
  }
  const PROJECT_INITIAL_STATE = {
    project: {
      value: {} as InitProjectDto
    }
  }
  const INITIAL_SUPERVISOR_STATUS_STATE = {
    supervisorStatus: {
      value: {} as InitSupervisorsDto
    }
  }

  const dispatch = useDispatch()
  const supervisorsList = useSelector(
    (state: any) => state.supervisor.allSupervisorsList.data
  )
  // get from local storage
  const activeUserRole = useSelector((state: AppStateDto) => state.auth.activeUserRole)
  // const projectList = useSelector((state: any) => state.supervisor.allProjectsList.data)
  const projectList = useSelector(
    (state: any) => state.projects.allProjectList.data
  )
  const supervisorListIsloading = useSelector(
    (state: any) => state.supervisor.allSupervisorsList.isLoading
  )

  const createSupervisorAlert: AlertDto = useSelector(
    (state: any) => state.alerts.createSupervisor
  )
  const unassignSupervisorAlert: AlertDto = useSelector(
    (state: any) => state.alerts.unassignSupervisor
  )

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    APP_TABLE_CONFIGS.DEFAULT_ROWS_PER_PAGE_EMP
  )
  const [supervisorFormData, setSupervisorFormData] =
    useState<SupervisorEntryFormDto>(INITIAL_STATE)
  const [searchFormData, setSearchFormData] = useState(PROJECT_INITIAL_STATE)
  const [searchText, setSearchText] = useState('')
  const [searchvalue, setSearchValue] = useState('')
  const [searchProject, setSearchProject] = useState('')

  const [isShowHelperText, setIsShowHelperText] = useState(true)
  const [filteredRows, setFilteredRows] = useState(supervisorsList.data) // <InitSupervisorsDto[]>([])
  const [isUnassignRow, setIsStatusChangeRow] = useState(
    INITIAL_SUPERVISOR_STATUS_STATE
  )
  const [isStatusChangeDialogOpen, setIsStatusChangeDialogOpen] =
    useState(false)
  React.useEffect(() => {
    const supervisors: Array<InitSupervisorsDto> = []
    if (supervisorsList.data) {
      // @ts-ignore
      supervisorsList.data.forEach((teamRow) => {
        const teamID = teamRow.teamId
        const teamName = teamRow.teamName
        teamRow.emails.forEach(
          (supervisor: { relId: any; isEnabled: any; email: any }) => {
            const row = {
              relId: supervisor.relId,
              isEnabled: supervisor.isEnabled,
              email: supervisor.email,
              teamName: teamName,
              teamId: teamID
            }

            supervisors.push(row)
          }
        )
      })
    }
    setFilteredRows(supervisors)
    searchProjectAndSupervisors(searchvalue)
    searchProjects(searchProject)
  }, [supervisorsList])
  React.useEffect(() => {
    getAllSupervisorList()
    getAllProjectList()
  }, [])
  React.useEffect(() => {
    if (createSupervisorAlert?.severity === 'success') {
      setSupervisorFormData({
        ...supervisorFormData,
        supervisorEmail: {
          ...supervisorFormData[
          'supervisorEmail' as keyof typeof supervisorFormData
          ],
          value: ''
        },
        project: {
          ...supervisorFormData['project' as keyof typeof supervisorFormData],
          value: {} as InitProjectDto
        }
      })
      getAllSupervisorList()
    }
  }, [createSupervisorAlert])
  React.useEffect(() => {
    if (unassignSupervisorAlert.severity === 'success') {
      getAllSupervisorList()
    }
  }, [unassignSupervisorAlert])
  // getAll ProjectList
  const getAllProjectList = () => {
    const enableProjectParamas: GetProjectListParamDto = {
      getDisabledTeams: false
    }
    dispatch(projectAction.allProjectList(enableProjectParamas))
  }
  const clearCreateSupervisorAlert = () => {
    dispatch(alertActions.clearCreateSupervisorAlertRequest())
  }
  const clearUnassignSupervisorAlert = () => {
    dispatch(alertActions.clearUnassignSupervisorAlertRequest())
  }
  // Handle form input
  const onInputSearchChange = (property: string, value: any) => {
    setSearchValue('')
    setSearchFormData({
      ...supervisorFormData,
      [property]: {
        ...searchFormData[property as keyof typeof searchFormData],
        value: value
      }
    })
    setSearchProject(value.name)
    searchProjects(value.name)
  }
  const searchProjects = (value: any) => {
    // if (page !== 0) {
    //   setPage(0)
    // }
    const supervisors: Array<InitSupervisorsDto> = []
    if (supervisorsList.data) {
      // @ts-ignore
      supervisorsList.data.forEach((teamRow) => {
        const teamID = teamRow.teamId
        const teamName = teamRow.teamName
        teamRow.emails.forEach(
          (supervisor: { relId: any; isEnabled: any; email: any }) => {
            const row = {
              relId: supervisor.relId,
              isEnabled: supervisor.isEnabled,
              email: supervisor.email,
              teamName: teamName,
              teamId: teamID
            }
            supervisors.push(row)
          }
        )
      })
    }
    if (value !== '') {
      const results = supervisors
        ?.filter((supervisor: InitSupervisorsDto) => {
          // Filter results by doing case insensitive match on name here
          return (
            supervisor.teamName.toLowerCase().includes(value.toLowerCase())
          )
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (
            a.teamName.toLowerCase().indexOf(value.toLowerCase()) >
            b.teamName.toLowerCase().indexOf(value.toLowerCase())
          ) {
            return 1
          } else if (
            a.teamName.toLowerCase().indexOf(value.toLowerCase()) <
            b.teamName.toLowerCase().indexOf(value.toLowerCase())
          ) {
            return -1
          } else {
            if (a.teamName > b.teamName) {
              return 1
            } else {
              return -1
            }
          }
        })
      setFilteredRows(results)
    }
  }
  const searchProjectAndSupervisors = (event: any) => {
    // if (page !== 0) {
    //   setPage(0)
    // }
    setSearchProject('')
    const supervisors: Array<InitSupervisorsDto> = []
    if (supervisorsList.data) {
      // @ts-ignore
      supervisorsList.data.forEach((teamRow) => {
        const teamID = teamRow.teamId
        const teamName = teamRow.teamName
        teamRow.emails.forEach(
          (supervisor: { relId: any; isEnabled: any; email: any }) => {
            const row = {
              relId: supervisor.relId,
              isEnabled: supervisor.isEnabled,
              email: supervisor.email,
              teamName: teamName,
              teamId: teamID
            }
            supervisors.push(row)
          }
        )
      })
    }
    let keyword: any
    if (event.target !== undefined) {
      keyword = event.target.value
    } else {
      keyword = event
    }

    setSearchValue(keyword)
    if (keyword !== '') {
      const results = supervisors
        ?.filter((supervisor: InitSupervisorsDto) => {
          // Filter results by doing case insensitive match on name here
          return (
            supervisor.email.toLowerCase().includes(keyword.toLowerCase()) ||
            supervisor.teamName.toLowerCase().includes(keyword.toLowerCase())
          )
        })
        .sort((a: any, b: any) => {
          // Sort results by matching name with keyword position in name
          if (
            a.email.toLowerCase().indexOf(keyword.toLowerCase()) >
            b.email.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.teamName.toLowerCase().indexOf(keyword.toLowerCase()) >
            b.teamName.toLowerCase().indexOf(keyword.toLowerCase())
          ) {
            return 1
          } else if (
            a.email.toLowerCase().indexOf(keyword.toLowerCase()) <
            b.email.toLowerCase().indexOf(keyword.toLowerCase()) ||
            a.teamName.toLowerCase().indexOf(keyword.toLowerCase()) <
            b.teamName.toLowerCase().indexOf(keyword.toLowerCase())
          ) {
            return -1
          } else {
            if (a.email > b.email || a.teamName > b.teamName) {
              return 1
            } else {
              return -1
            }
          }
        })
      setFilteredRows(results)
    } else {
      setSearchText(keyword)
      setFilteredRows(supervisors)
    }
  }

  // getAll SupervisorList
  const getAllSupervisorList = () => {
    const supervisorListParams: GetSupervisorsListParamDto = {
      getDisabledEmails: 'true'
    }
    dispatch(supervisorAction.allSupervisorsList(supervisorListParams))
  }
  // when focus the input field
  const handleInputFocus = (property: string) => {
    setSupervisorFormData({
      ...supervisorFormData,
      [property]: {
        ...supervisorFormData[property as keyof typeof supervisorFormData],
        error: null
      }
    })
  }
  // create supervisor-project
  const createsupervisor = async () => {
    const [validateData, isValid] = await validateFormData(supervisorFormData)
    setSupervisorFormData(validateData)
    if (isValid) {
      const supervisorCreateParams: CreateSupervisorParamDto = {
        teamId: supervisorFormData.project.value.teamId,
        emails: [supervisorFormData.supervisorEmail.value.toLowerCase()],
        isEnabled: true
      }
      dispatch(supervisorAction.createSupervisor(supervisorCreateParams))
      getAllSupervisorList()
    }
  }
  // unassign supervisor
  const unassignSupervisor = () => {
    const unassignSupervisorchangeParams: UnassignSupervisorParamDto = {
      data: [
        {
          relId: isUnassignRow.supervisorStatus.value.relId,
          isEnabled: isUnassignRow.supervisorStatus.value.isEnabled !== true
        }
      ]
    }
    dispatch(
      supervisorAction.unassignSupervisor(unassignSupervisorchangeParams)
    )
    setIsStatusChangeDialogOpen(false)
  }

  // Handle form input
  const onInputHandleChange = (
    property: string,
    value: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsShowHelperText(true)
    setSupervisorFormData({
      ...supervisorFormData,
      [property]: {
        ...supervisorFormData[property as keyof typeof supervisorFormData],
        value: value
      }
    })
  }

  const onRowStatusChangeTrigger = (data: InitSupervisorsDto) => {
    setIsStatusChangeDialogOpen(true)
    setIsStatusChangeRow({
      ...isUnassignRow,
      supervisorStatus: {
        value: {
          relId: data.relId,
          teamId: data.teamId,
          teamName: data.teamName,
          email: data.email,
          isEnabled: data.isEnabled
        }
      }
    })
  }

  const supervisorStatusChangeClick = () => {
    setIsStatusChangeDialogOpen(false)
  }

  // pagination
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  // pagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <React.Fragment>
      <AppLayout
        breadcrumb={BREAD_CRUB.SUPERVISOR_MANAGEMENT}
        componentTitle="External Supervisor Management"
      >
        <p
          style={{
            paddingBottom: 7,
            margin: 0,
            fontWeight: 400,
            fontSize: '24px'
          }}
        >
          External Supervisor Management
        </p>

        <Divider className="dividerStyle" />
        <br />
        {createSupervisorAlert?.message && (
          <Alert
            className="mb-m"
            onClose={clearCreateSupervisorAlert}
            severity={createSupervisorAlert.severity}
          >
            {createSupervisorAlert.message}
          </Alert>
        )}
        {unassignSupervisorAlert.message && (
          <Alert
            className="mb-m"
            onClose={clearUnassignSupervisorAlert}
            severity={unassignSupervisorAlert.severity}
          >
            {unassignSupervisorAlert.message}
          </Alert>
        )}
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.CREATE_NEW_EXTERNAL_SUPERVISOR]}
        >
          <SupervisorAssignForm
            supervisorFormData={supervisorFormData}
            defaultProjectsList={projectList || []}
            isShowHelperText={isShowHelperText}
            onInputHandleChange={onInputHandleChange}
            createSupervisor={createsupervisor}
            handleInputFocus={handleInputFocus}
          />
        </AppAuthorizer>

        <br />
        <Divider className="dividerStyle" />
        <br />
        <AppAuthorizer
          activeRoleFeatures={activeUserRole.data.features}
          authorizedFeatureKey={[APP_FEATURE_KEYS.VIEW_ALL_EXTERNAL_SUPERVISOR]}
        >
          <SupervisorViewTable
          activeUserRole={activeUserRole}
            defaultProjectsList={projectList.data || []}
            searchText={searchText}
            supervisorsList={filteredRows || []}
            rowsPerPage={rowsPerPage}
            page={page}
            searchFormData={searchFormData}
            onRowStatusChangeTrigger={onRowStatusChangeTrigger}
            supervisorListIsloding={supervisorListIsloading}
            searchSupervisors={searchProjectAndSupervisors}
            onInputSearchChange={onInputSearchChange}
          >
            <AppTablePagination
              data={filteredRows || []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </SupervisorViewTable>
        </AppAuthorizer>

          <UnassignSupervisorPopup
            isUnassignSupervisorDialogOpen={isStatusChangeDialogOpen}
            unassignSupervisorChangeClick={supervisorStatusChangeClick}
            isUnassignRow={isUnassignRow}
            unassignSupervisorChange={unassignSupervisor}
          />

      </AppLayout>
    </React.Fragment>
  )
}

export default SupervisorManagement
