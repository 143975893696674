import { CLIENT_ACTION_TYPES, COMMON_ACTION_TYPES, APP_ACTION_STATUS } from '../../utilities/constants/action.constants'

const INITIAL_STATE = {
  allClientList: {
    isLoading: false,
    data: []
  },
  clientHierachy: {
    isLoading: false,
    data: [],
    status: null
  },
  entitledClientHierachy: {
    isLoading: false,
    data: [],
    status: null
  },
  createClient: {
    isLoading: false,
    data: [],
    status: null
  },
  updateClient: {
    isLoading: false,
    data: [],
    status: null
  },
  clientStatusChange: {
    isLoading: false,
    data: [],
    status: null
  }
}
const clientReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        allClientList: {
          ...state.allClientList,
          isLoading: true
        }
      }
    case CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        allClientList: {
          ...state.allClientList,
          isLoading: false,
          data: action.data
        }
      }
    case CLIENT_ACTION_TYPES.GET_CLIENT_LIST + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        allClientList: {
          ...state.allClientList,
          isLoading: false,
          data: []
        }
      }
    case CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        clientHierachy: {
          ...state.clientHierachy,
          isLoading: true
        }
      }
    case CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        clientHierachy: {
          ...state.clientHierachy,
          isLoading: false,
          data: action.data
        }
      }
    case CLIENT_ACTION_TYPES.GET_CLIENT_HIERACHY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        clientHierachy: {
          ...state.clientHierachy,
          isLoading: false,
          data: []
        }
      }
    case CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case CLIENT_ACTION_TYPES.CREATE_CLIENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case CLIENT_ACTION_TYPES.UPDATE_CLIENT + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case CLIENT_ACTION_TYPES.CLIENT_STATUS_CHANGE + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        clientStatusChange: {
          ...state.clientStatusChange,
          isLoading: true,
          status: APP_ACTION_STATUS.LOADING
        }
      }
    case CLIENT_ACTION_TYPES.CLIENT_STATUS_CHANGE + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        clientStatusChange: {
          ...state.clientStatusChange,
          isLoading: false,
          data: action.data,
          status: APP_ACTION_STATUS.SUCCESS
        }
      }
    case CLIENT_ACTION_TYPES.CLIENT_STATUS_CHANGE + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        clientStatusChange: {
          ...state.clientStatusChange,
          isLoading: false,
          data: [],
          status: APP_ACTION_STATUS.ERROR
        }
      }
    case CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.REQUEST:
      return {
        ...state,
        entitledClientHierachy: {
          ...state.entitledClientHierachy,
          isLoading: true
        }
      }
    case CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        entitledClientHierachy: {
          ...state.entitledClientHierachy,
          isLoading: false,
          data: action.data
        }
      }
    case CLIENT_ACTION_TYPES.GET_ENTITLED_CLIENT_HIERACHY + COMMON_ACTION_TYPES.ERROR:
      return {
        ...state,
        entitledClientHierachy: {
          ...state.entitledClientHierachy,
          isLoading: false,
          data: []
        }
      }
    default:
      return state
  }
}

export default clientReducer
